import React from 'react'
import uiSettings from '../../assets/ui-settings'

export default function Separator() {
  return (
    <div style={{width: '100%', marginTop: '28px', display: 'flex', flexDirection: "row", justifyContent: 'space-between', flexWrap: "nowrap", alignItems: 'center'}}>
        <span style={{height: "1px ", fontSize: '1px', width: '50%', backgroundColor: uiSettings.colors.secondary}}>.</span>
        <p style={{margin: '0px 18px 2px 18px', color: uiSettings.colors.black}}>Or</p>
        <span style={{height: "1px ", fontSize: '1px', width: '50%', backgroundColor: uiSettings.colors.secondary}}>.</span>
    </div>
  )
}
