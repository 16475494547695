import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import Logo from '../../reusableComponents/logo'
import { AccountCircle, FeedbackOutlined, GridView, InsertChartOutlined, ListAltOutlined, LogoutOutlined, NotificationsNoneOutlined, PeopleOutline, QuizOutlined, Search, SettingsOutlined } from '@mui/icons-material'
import { Alert, Avatar, Badge, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import Dashboard from './dashbord'
import Users from './users'
import User from './user'
import Quizzes from './quizzes'
import NewQuiz from './newQuiz'
import AllQuizzes from './allQuizzes'
import Questions from './questions'
import NewQuestion from './newQuestion'
import DataTable from './dataGrid'
import QuestionDetails from './questionDetailes'
import EditQuestion from './editQuestion'
import NotFound from './NotFound'
import ComingSoon from './ComingSoon'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import AddUser from './addUser'
import Abonnement from './abonnement'
import AddAbonnement from './addAbonnement '
import AbonnementRequests from './abonnementRequests'
import CreateExam from './createExam'
import EditExam from './editExam'

const Home = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const Sidebar = styled.div`
    height: 100vh;
    width: 296px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Container = styled.div`
    height: 100vh;
    width: calc(100% - 296px);
    background-color: #F5F5F5;
`

const Elements = styled.div`
    width: 80%;
    margin: 0px 10%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 16px;
`
const ElementSelected = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    background-color: ${uiSettings.colors.blue};
    color: ${uiSettings.colors.white};
    box-shadow: 0px 2px 15px 0px #008DFF40;
`

const Element= styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    background-color: ${uiSettings.colors.white};
    color: ${uiSettings.colors.secondary};
    cursor: pointer;
    transition: 200ms;
    &:hover{
        background-color:  ${uiSettings.colors.bgBlue};
    }
`

const ElementIcon = styled.div`
    padding-right: 16px;
`

const Navbar = styled.div`
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 10vh;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: end;
    background: ${uiSettings.colors.white};
    align-items: center;
`
const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
`

const NavbarInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
`

const Content = styled.div`
    width: calc(100% - 0px);
    margin: 0px;
    height: calc(90vh - 0px);
    overflow-y: auto;
`



const styles = {icon: {fontSize: '18px', padding: '0px', paddingRight: '6px', }}




export default function Panel({windowSize}) {

    const navigate = useNavigate()
    const [selectedSidebareElement, setSelectedSidebareElement] = useState('Dashboard');
    
    const [isSidebareOpened, setIsSidebareOpened] = useState(true);
    const [isPhone, setIsPhone] = useState(false);


    function handleSidebareChange(item){
        if(item === "Questions" || item === "Dashboard" || item === 'Users' || item === 'User' || item === 'Abonnement' || item === 'AbonnementRequests' || item === 'Exams'){
            setSelectedSidebareElement(item)
            console.log(item) 
            navigate('/admin/'+ item)
        }
       // setSelectedSidebareElement(item)
       // console.log(item) 
       // navigate('/admin/'+ item)
    }

    useEffect(() => {
        setSelectedSidebareElement(window.location.pathname.split('/')[2])
    }, []);


   // check login

   const [authenticated, setAuthenticated] = useState(false);
   const [loading, setLoading] = useState(true);
   const [type, setType] = useState('Login');
    const [adminUsername, setAdminUsername] = useState('Admin');

   useEffect(() => {
       axios.post("/check_admin_access")
       .then(function (response) {
        console.log(response)
           setLoading(false)
           if(response.data.admin && response.data.admin.length > 0){
            setAdminUsername(response.data.admin)
           }else{
               setAdminUsername("Automed Admin")
           }
       })
       .catch(function (error) {
          console.log(error)
          if(error.response.status === 403){
              navigate('/admin/login', { replace: true });
          }else{
              // handleError(error)
              setAlertMessage('An unexpected error has occured.')
              setAlertType('error')
              setOpenAlert(true)
          }
       })
   }, []);


   const [openWaitModal2, setOpenWaitModal2] = useState(false);
    const onCloseWaitModal2 = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setOpenWaitModal2(false);
    };

    const [loadingLogout, setLoadingLogout] = useState(false);
    function logout() {
        setLoadingLogout(true)
        axios.post("/admin/logout_admin")
       .then(function (response) {
            setLoadingLogout(false)
            navigate('/admin/login', { replace: true });
       })
       .catch(function (error) {
            setLoadingLogout(false)
            console.log(error)
            // handleError(error)
            setAlertMessage('An unexpected error has occured.')
            setAlertType('error')
            setOpenAlert(true)
       })
    }

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');

    const handleClickAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpenAlert(false);
    };



  return (
        <Home>
                <Sidebar style={{display: loading ? 'none' : 'unset'}} >
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', height: '10vh',  alignItems: 'center'}}>
                        <Logo style={{ }}></Logo>
                    </div>
                    <Elements>
                        <Element className={selectedSidebareElement === 'Dashboard' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Dashboard')}>
                            <GridView style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Dashboard</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Users' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Users')}>
                            <PeopleOutline style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Users</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Exams' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Exams')}>
                            <ListAltOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Exams</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Questions' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Questions')}>
                            <QuizOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Questions</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Abonnement' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Abonnement')}>
                            <QuizOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Abonnement</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'AbonnementRequests' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('AbonnementRequests')}>
                            <QuizOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Abon. requests</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Analytics' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Analytics')}>
                            <InsertChartOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Analytics✨</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Feedback' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Feedback')}>
                            <FeedbackOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Feedback✨</p>
                        </Element>
                        <Element disabled className={selectedSidebareElement === 'Settings' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Settings')}>
                            <SettingsOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Settings✨</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Profile' ? 'Active-sidebare-element' : ''} onClick={(e) => handleSidebareChange('Profile')}>
                            <AccountCircle style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Profile✨</p>
                        </Element>
                        <Element className={selectedSidebareElement === 'Logout' ? 'Active-sidebare-element' : ''}  onClick={(e) => setOpenWaitModal2(true)}>
                            <LogoutOutlined style={{...styles.icon}} />
                            <p style={{...uiSettings.typos.paragraphDesktop}}>Logout</p>
                        </Element>
                    </Elements>
                </Sidebar>
        
        
                <Container style={{display: loading ? 'none' : 'unset'}}>
        
        
                    <Navbar>
                        <NavbarSearch style={{width: '385px', display: 'none'}}>
                            <TextField
                                id=""
                                placeholder={'Search'}
                                onchange
                                variant='outlined'
                                type={'text'}
                                style={{width: "100%", backgroundColor: uiSettings.colors.beige1, borderRadius: '8px 0px 0px 8px', }}
                                InputProps={{
                                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                                }}
                            />
                            <Search style={{padding: '15px 20px', fontSize: '26px', backgroundColor: uiSettings.colors.yellow, borderRadius: '0px 8px 8px 0px', marginLeft: '-5px', zIndex: '10'}}/>
                        </NavbarSearch>
                        <NavbarInfo>
                            {/* <Badge disabled badgeContent={4} style={{margin: "0px 45px 0px 0px"}}  sx={{"& .MuiBadge-badge": {color: uiSettings.colors.black ,backgroundColor: uiSettings.colors.yellow }}}>
                                <NotificationsNoneOutlined color="action" />
                            </Badge> */}
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}>
                                <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                                    {adminUsername ? adminUsername[0] : 'A'}
                                </Avatar>
                                <div style={{marginLeft: '7px'}}>
                                   <p style={{ ...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap '}}}>{adminUsername}</p> 
                                   <p style={{ ...uiSettings.typos.link_mid, ...{color: uiSettings.colors.lightGray}}}> Admin</p> 
                                </div>
                            </div>
                        </NavbarInfo>
                    </Navbar>
        
                    {/* // container    **************** */}
        
        
                    <Content>
                        <Routes >
                            <Route exact path="/" element={<ComingSoon />}></Route>
                            <Route exact path="/dashboard" element={<ComingSoon />}></Route>
                            {/* <Route exact path="/dashboard" element={<Dashboard  />}></Route> */}
                            <Route exact path="/Users" element={<Users  />}></Route>
                            <Route exact path="/Users/user/:id" element={<User />}></Route>
                            <Route exact path="/Users/addUser" element={<AddUser />}></Route>
                            <Route exact path="/Exams" element={<Quizzes windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Exams/NewExam" element={<CreateExam windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Exams/:id" element={<EditExam windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Exams/AllExams" element={<AllQuizzes windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Questions" element={<Questions  />}></Route>
                            <Route exact path="/Questions/NewQuestion" element={<NewQuestion windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Questions/Question/:id" element={<QuestionDetails windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Questions/Question/:id/edit" element={<EditQuestion windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Abonnement" element={<Abonnement windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/AbonnementRequests" element={<AbonnementRequests windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/Abonnement/:id" element={<AddAbonnement windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone} />}></Route>
                            <Route exact path="/*" element={<NotFound />}></Route>
                        </Routes>
                    </Content>
                </Container>

                <CircularProgress style={{alignSelf: 'center', display: !loading ? 'none' : 'unset'}}/>


                <Dialog open={openWaitModal2} fullWidth={true} maxWidth={'sm'} >
                    <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Logout</DialogTitle>
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                        <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Do you want to logout?</p>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
                        <LoadingButton loading={loadingLogout} loadingPosition='end'   sx={{ textTransform: 'none' }} onClick={()=> {logout()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Logout</LoadingButton>
                    </DialogActions>
                </Dialog>

                <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                    </Alert>
                </Snackbar>
        </Home>


  )
}
