import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Autocomplete, Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Slider, Snackbar, TextField } from '@mui/material';
import { Add, AddOutlined, AddToPhotosOutlined, CheckCircleOutlineOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, Filter, FilterList, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, PeopleAltOutlined, QuizOutlined, RemoveOutlined, RemoveRedEyeOutlined, SearchOutlined } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import MyQuillEditor from './textEditor';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import MyDraftEditor from './MyDraftEditor';
import MyQuillEditorAdvanced from './textEditorAdvanced';
import MyQuillEditorAdvanced2 from './textEditorAdvanced2';


const CustomPaper = (props) => {
    return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

function valuetext(value) {
    return `${value}°C`;
}

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Container = styled.div`
    width: calc(100% - 24px);
    padding: 12px;
    border-radius: 15px;
    background-color: white;
    min-height: 50px;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: 0px;
`

const OptionList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10px;
    padding: 5px 0px;
    border-radius: 7px !important;
    overflow: auto;
    height: 300px;
    background-color: ${uiSettings.colors.bgblue};
    padding-bottom: 10px;

`

const OptionItem = styled.div`
    width: calc(100% - 30px);
    padding: 15px !important;
    background-color: ${uiSettings.colors.bgblue};
    color: ${uiSettings.colors.secondary};
    border-bottom: 1px solid ${uiSettings.colors.bgBlue};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px !important;
`
const OptionStatus = styled.div`
    background-color: ${uiSettings.colors.yellow} !important;
    color: black !important;
    font-size: 15px !important ;
    padding: 0px 10px !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`



export default function NewQuestion() {
    const Navigate = useNavigate()
    const [selectedTypesOfquestions, setSelectedTypesOfquestions] = useState([]); // State to store selected options
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedTypesOfquestions(newValue);
    };

    const [selectedsources, setSelectedsources] = useState([]); // State to store selected options
    const handleSourceChange = (event, newValue) => {
        setSelectedsources(newValue);
    };

    const [isAllDates, setIsAllDates] = useState(true);
    const [value, setValue] = React.useState([2004, 2013]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [numberOfQuestions, setnumberOfQuestions] = useState(120);
    function handleChangeNumberOfQuestions(type, number){
        if(type === 'new'){
            if(number >= 0 && number < 10001){
                setnumberOfQuestions( parseInt(number))
            }
        }
        else if(type === 'remove'){
            if(numberOfQuestions > 0){
                setnumberOfQuestions(numberOfQuestions - 1)
            }
        }else{
            if(numberOfQuestions < 10001){
                setnumberOfQuestions(numberOfQuestions + 1)
            }
        }
    }


  const [content, setContent] = useState('');


  const [QuizId, setQuizId] = useState('');
  const [QuizIdIsError, setQuizIdIsError] = useState(false);
  const [quizIdError, setQuizIdError] = useState('');

  const [quizModule, setQuizModule] = useState('');
  const [quizModuleIsError, setQuizModuleIsError] = useState(false);
  const [quizModuleError, setQuizModuleError] = useState('');

  const [quizCour, setQuizCour] = useState('');
  const [quizCourIsError, setQuizCourIsError] = useState(false);
  const [quizCourError, setQuizCourError] = useState('');

  const [quizType, setQuizType] = useState('');
  const [quizTypeIsError, setQuizTypeIsError] = useState(false);
  const [quizTypeError, setQuizTypeError] = useState('');

  
  const [quizDate, setQuizDate] = useState('');
  const [quizDateIsError, setQuizDateIsError] = useState(false);
  const [quizDateError, setQuizDateError] = useState('');

  
  const [quizYear, setQuizYear] = useState('');
  const [quizYearIsError, setQuizYearIsError] = useState(false);
  const [quizYearError, setQuizYearError] = useState('');

  
  const [quizSource, setQuizSource] = useState('');
  const [quizSourceIsError, setQuizSourceIsError] = useState(false);
  const [quizSourceError, setQuizSourceError] = useState('');

  
  const [quizEnonce, setQuizEnonce] = useState('');
  const [quizEnonceIsError, setQuizEnonceIsError] = useState(false);
  const [quizEnonceError, setQuizEnonceError] = useState('');

  
  const [quizExplication, setQuizExplication] = useState('');
  const [quizExplicationIsError, setQuizExplicationIsError] = useState(false);
  const [quizExplicationError, setQuizExplicationError] = useState('');

  useEffect(() => {
    getBaseInfo()
  }, []);

  const [loading, setLoading] = useState(true);

  const [dates, setDates] = useState([]);
  const [years, setYears] = useState([]);
  const [sources, setsources] = useState([]);
  const [modules, setModules] = useState([]);
  const [cours, setCours] = useState([]);
  const [modulesAndCours, setModulesAndCours] = useState([]);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  function getBaseInfo (){
    axios.post("/admin/getQuizzesBaseInfo")
    .then(function (response) {
        console.log(response)
        setLoading(false)
        setModulesAndCours(response.data.modules)
        
        var moduleArray = []
        for (let i = 0; i < response.data.modules.length; i++) {
            const item = response.data.modules[i];
            moduleArray.push(item.name)
        }

        setModules(moduleArray)


        var dateArray = []
        for (let i = 0; i < response.data.dates.length; i++) {
            const item = response.data.dates[i];
            dateArray.push(item.date)
        }

        var sourceArray = []
        for (let i = 0; i < response.data.sources.length; i++) {
            const item = response.data.sources[i];
            sourceArray.push(item.source)
        }

        var YearArray = []
        for (let i = 0; i < response.data.years.length; i++) {
            const item = response.data.years[i];
            YearArray.push(item.year)
        }


        setDates(dateArray)
        setYears(YearArray)
        setsources(sourceArray)
    })
    .catch(function (error) {
        // handle error
        //console.log(error)
        if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
            
        }
    })
}

function handleModuleChange (data) {
    setQuizModule(data)
    var myCours = modulesAndCours.find(item => item.name === data)
    if(myCours){
        var arrayCours = []
        for (let i = 0; i < myCours.cours.length; i++) {
            const item = myCours.cours[i];
            arrayCours.push(item.name)
        }
        setCours(arrayCours)
    }else{
        setCours([])
    }
}
const [questionsLength, setQuestionsLength] = useState(1);
const [questions, setQuestions] = useState([{id: '', idError: '', enonce: '', enonceError: '', question: '', questionError: '', type: '', typeError: '', a1: '', a1Error: '', a2: '', a2Error: '', a3: '', a4: '', a5: '', a6: '', a7: '', a8: '', a: '', aError: '', b: '', bError: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', correction: [], correctionError: '', explication: '', explicationError: ''}]);
function addQuestion() {
    console.log(questions)
    var array = questions
    array.push({id: '', idError: '', enonce: '', enonceError: '', question: '', questionError: '', type: '', typeError: '', a1: '', a1Error: '', a2: '', a2Error: '', a3: '', a4: '', a5: '', a6: '', a7: '', a8: '', a: '', aError: '', b: '', bError: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', correction: [], correctionError: '', explication: '', explicationError: ''})
    setQuestions(array)
    setQuestionsLength(array.length)
}

function deleteQuestion(index) {
    console.log(index)
    var array = questions
    array.splice(index, 1)
    setQuestions(array)
    setQuestionsLength(array.length)
}

  // Function to update a property in an object by index
  const updateObjectProperty = (index, propertyName, newValue) => {
    setQuestions(prevArray => {
      // Create a new array with the updated object at the specified index
      const newArray = [...prevArray];
      newArray[index] = { ...newArray[index], [propertyName]: newValue };
      newArray[index] = { ...newArray[index], [propertyName + 'Error']: '' };
      return newArray;
    });
  };

  const [questionErrors, setquestionErrors] = useState([]);

  function createQuiz (){
    axios.post("/admin/createQuiz", {
        QuizId, quizModule, quizCour, quizType, quizSource, quizYear, quizDate, quizEnonce, questions, quizExplication
    })
    .then(function (response) {
        console.log(response.data)
        if(response.data.response === 'error'){
            const errors = response.data.errors

            for (let i = 0; i < errors.length; i++) {
                const error = errors[i];
                console.log(error.field)
                if(error.field === 'QuizId'){
                    setQuizIdIsError(true)
                    setQuizIdError(error.error)
                }
                if(error.field === 'quizModule'){
                    console.log('module error')
                    setQuizModuleIsError(true)
                    setQuizModuleError(error.error)
                }
                if(error.field === "quizCour"){
                    setQuizCourError(error.error)
                    setQuizCourIsError(true)
                }
                if(error.field === "quizType"){
                    setQuizTypeError(error.error)
                    setQuizTypeIsError(true)
                }
                if(error.field === "quizDate"){
                    setQuizDateError(error.error)
                    setQuizDateIsError(true)
                }
                if(error.field === "quizYear"){
                    setQuizYearError(error.error)
                    setQuizYearIsError(true)
                }
                if(error.field === "quizSource"){
                    setQuizSourceError(error.error)
                    setQuizSourceIsError(true)
                }
                if(error.field === "quizEnonce"){
                    setQuizEnonceError(error.error)
                    setQuizEnonceIsError(true)
                }
            }

            for (let i = 0; i < response.data.questionErrors.length; i++) {
                const ObjectErrors = response.data.questionErrors[i];
                for (let j = 0; j < ObjectErrors.length; j++) {
                    const error = ObjectErrors[j];
                    setQuestions(prevArray => {
                        // Create a new array with the updated object at the specified index
                        const newArray = [...prevArray];
                        newArray[i] = { ...newArray[i], [error.field +  'Error']: error.error };
                        return newArray;
                    });
                }
               
            }
        }else if(response.data.response === 'done'){
           Navigate('/admin/questions/question/' + response.data.quizId, {replace: true})
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
            
        }
    })
}



  return (
    <Home className='NewQuestion'>
        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, }}}><span style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/Questions')}>Questions</span> {">"} Add a question</p>
        <Button   sx={{ textTransform: 'none' }} onClick={createQuiz}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '20px', marginBottom: '10px'}}}>Create a question</Button>
        <Container>


            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Identity Quiz</p>
            </div>

            <Form style={{width: false ? '100%' : '47%',  marginTop: '10px'}}>
                    <FormTitle>
                        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Quiz id</p>
                    </FormTitle>

                    <TextField
                        multiline
                        value={QuizId}
                        helperText={quizIdError}
                        error={QuizIdIsError}
                        onChange={(e)=> {setQuizId(e.target.value); setQuizIdError(''); setQuizIdIsError(false)}}
                        rows={1}
                        style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                        variant="outlined"
                        placeholder={"Quiz id"}
                    />

                </Form>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Module & cour</p>
                    {/* <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white, borderRadius: '7px'}}} startIcon={<FilterList />} endIcon={<KeyboardArrowDown/>}  >Select season</Button> */}
                </div>
            
        <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '-10px', display: true ? 'flex' : 'none'}}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.primary}}}>Module</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizModule}
                                options={modules}
                                onChange={(event, newValue) => {handleModuleChange(newValue); setQuizModuleError(''); setQuizModuleIsError(false)}}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) => option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizModuleError}
                                    error={quizModuleIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    onChange={(e) => setQuizModule(e.target.value)}

                                    />
                                )}
                            />
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Cour</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizCour}
                                options={cours}
                                onChange={(event, newValue) => {setQuizCour(newValue); setQuizCourError(''); setQuizCourIsError(false)}}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) =>  option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizCourError}
                                    error={quizCourIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    onChange={(e) => setQuizCour(e.target.value)}

                                    />
                                )}
                            />
        
                        </Form>
        
        
            </SubContainer>
           
       
          

           





            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Quiz information</p>
            </div>


           

            <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '-10px', display: true ? 'flex' : 'none'}}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Type</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizType}
                                options={['QCM', 'QCS', 'Cas clinique', 'Dossier clinique']}
                                onChange={(event, newValue) => {
                                    setQuizType(newValue); setQuizTypeError(''); setQuizTypeIsError(false)
                                    if(newValue === 'QCS' || newValue === 'QCM'){
                                        var question = questions[0]
                                        setQuestions([question])
                                    }
                                }}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) => option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizTypeError}
                                    error={quizTypeIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    />
                                )}
                            />
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Source</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizSource}
                                options={sources}
                                onChange={(event, newValue) => {setQuizSource(newValue); setQuizSourceError(''); setQuizSourceIsError(false)}}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) =>  option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizSourceError}
                                    error={quizSourceIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    onChange={(e) => setQuizSource(e.target.value)}
                                    />
                                )}
                            />
        
                        </Form>
        
        
            </SubContainer>
                                   
            <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none'}}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Date</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizDate}
                                options={dates}
                                onChange={(event, newValue) => {setQuizDate(newValue); setQuizDateError(''); setQuizDateIsError(false)}}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) => option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizDateError}
                                    error={quizDateIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    onChange={(e) => setQuizDate(e.target.value)}

                                    />
                                )}
                            />
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Year</p>
                            </FormTitle>
        
                            <Autocomplete
                                id="tags-standard"
                                value={quizYear}
                                options={years}
                                onChange={(event, newValue) => {setQuizYear(newValue); setQuizYearError(''); setQuizYearIsError(false)}}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) =>  option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    helperText={quizYearError}
                                    error={quizYearIsError}
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Example: 2019 => 2018-2019"}
                                    onChange={(e) => setQuizYear(e.target.value)}
                                    />
                                )}
                            />
        
                        </Form>
        
        
            </SubContainer>


     
            <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none'}}>
                <Form style={{width: true ? '100%' : '47%'}}>
                    <FormTitle>
                        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Enonce</p>
                    </FormTitle>

                    <MyQuillEditorAdvanced content={quizEnonce} setContent={setQuizEnonce}></MyQuillEditorAdvanced>
                    <p style={{...uiSettings.typos.paragraphDesktop, ...{color: "#d32f2f", marginTop: '-5px', fontSize: '15px', marginLeft: '10px'}}}>{quizEnonceError}</p>


                    {/* /*<TextField
                        multiline
                        rows={5}
                        value={quizEnonce}
                        onChange={(e) => {setQuizEnonce(e.target.value); setQuizEnonceError(''); setQuizEnonceIsError('')}}
                        helperText={quizEnonceError}
                        error={quizEnonceIsError}
                        style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                        variant="outlined"
                        placeholder={"Enonce"}
                    />*/}

                </Form>

        
            </SubContainer>


            <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none'}}>
                        <Form style={{width: true ? '100%' : '47%'}}>
                            <FormTitle style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}}>Questions</p>
                                <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white, borderRadius: '7px', display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none'}}} endIcon={<AddOutlined/>} onClick={addQuestion}  >Add question</Button>
                            </FormTitle>
                           
                           
                           
                                     {/* start question ********************************* */}
                            <div>
                                {questions.map((item, index)=>{
                                    return(
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, margin: '0px 20px' }}}
                                            >
                                            Question {quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? '#' + (index + 1) : '' } 
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '-10px'}} >
                                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Subquiz information</p>
                                                    <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.red, color: uiSettings.colors.white, borderRadius: '7px', display: index === 0 ? 'none' : 'flex'}}} endIcon={<DeleteOutlineOutlined/>} onClick={() => deleteQuestion(index)}  >Delete question</Button>
                                                </div>

                                                <Form style={{width: false ? '100%' : '47%',  marginTop: '10px', display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Quiz id</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.id}
                                                            error={item.idError.length > 0 ? true : false}
                                                            helperText={item.idError}
                                                            onChange={(e)=>updateObjectProperty(index, 'id', e.target.value)}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Quiz id"}
                                                        />

                                                </Form>

                                                {/* // enonce + question ********** */}

                                                <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '-10px', display: true ? 'flex' : 'none', flexDirection: 'column'}}>
                                                    <Form style={{width: true ? '100%' : '47%', display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Enonce</p>
                                                        </FormTitle>
                                                        
                                                        <MyQuillEditorAdvanced2 updateObjectProperty={updateObjectProperty} index={index} type={'enonce'} content={item.enonce}></MyQuillEditorAdvanced2>
                                    
                                                        {/* <TextField
                                                            multiline
                                                            error={item.enonceError.length > 0 ? true : false}
                                                            helperText={item.enonceError}
                                                            rows={5}
                                                            value={item.enonce}
                                                            onChange={(e)=>updateObjectProperty(index, 'enonce', e.target.value)}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Enonce"}
                                                        /> */}
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: true ? '100%' : '47%', }}>
                                                        <FormTitle style={{display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'flex'}}>
                                                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Question</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.question}
                                                            onChange={(e)=>updateObjectProperty(index, 'question', e.target.value)}
                                                            multiline
                                                            error={item.questionError.length > 0 ? true : false}
                                                            helperText={item.questionError}
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Question"}
                                                        />
                                    
                                                    </Form>

                                                    <Form style={{width: false ? '100%' : '47%', display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Type</p>
                                                        </FormTitle>
                                    
                                                        <Autocomplete
                                                            id="tags-standard"
                                                            value={item.type}
                                                            onChange={(event, newValue)=>updateObjectProperty(index, 'type', newValue)}
                                                            options={['QCM', 'QCS']}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            InputProps={{
                                                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                                            }}
                                                            PaperComponent={CustomPaper}
                                                            getOptionLabel={(option) => option} // Specify the label property
                                                            renderInput={(params) => (
                                                                <TextField
                                                                error={item.typeError.length > 0 ? true : false}
                                                                helperText={item.typeError}
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder={"Select"}
                                                                />
                                                            )}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>

                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Options</p>
                                                </div>

                                                <Accordion style={{marginTop: '20px'}}>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, margin: '0px 20px'}}}
                                                    >
                                                    Options
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '-30px', display: true ? 'flex' : 'none',}}>
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 1</p>
                                                                </FormTitle>
                                            
                                                                <TextField
                                                                    value={item.a1}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a1', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    error={item.a1Error.length > 0 ? true : false}
                                                                    helperText={item.a1Error}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 1"}
                                                                />
                                            
                                                            </Form>
                                            
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 2</p>
                                                                </FormTitle>

                                                                <TextField
                                                                    multiline
                                                                    value={item.a2}
                                                                    error={item.a2Error.length > 0 ? true : false}
                                                                    helperText={item.a2Error}    
                                                                    onChange={(e)=>updateObjectProperty(index, 'a2', e.target.value)}
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 2"}
                                                                />
                                            
                                                            </Form>
                                            
                                            
                                                        </SubContainer>

                                                        <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 3</p>
                                                                </FormTitle>
                                            
                                                                <TextField
                                                                    value={item.a3}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a3', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 3"}
                                                                />
                                            
                                                            </Form>
                                            
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 4</p>
                                                                </FormTitle>

                                                                <TextField
                                                                    value={item.a4}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a4', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 4"}
                                                                />
                                            
                                                            </Form>
                                            
                                            
                                                        </SubContainer>

                                                        <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 5</p>
                                                                </FormTitle>
                                            
                                                                <TextField
                                                                    value={item.a5}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a5', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 5"}
                                                                />
                                            
                                                            </Form>
                                            
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 6</p>
                                                                </FormTitle>

                                                                <TextField
                                                                    value={item.a6}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a6', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 6"}
                                                                />
                                            
                                                            </Form>
                                            
                                            
                                                        </SubContainer>

                                                        <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 7</p>
                                                                </FormTitle>
                                            
                                                                <TextField
                                                                    value={item.a7}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a7', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 7"}
                                                                />
                                            
                                                            </Form>
                                            
                                                            <Form style={{width: false ? '100%' : '47%'}}>
                                                                <FormTitle>
                                                                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Option 8</p>
                                                                </FormTitle>

                                                                <TextField
                                                                    value={item.a8}
                                                                    onChange={(e)=>updateObjectProperty(index, 'a8', e.target.value)}
                                                                    multiline
                                                                    rows={1}
                                                                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                                    variant="outlined"
                                                                    placeholder={"Option 8"}
                                                                />
                                            
                                                            </Form>
                                            
                                            
                                                        </SubContainer>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Answers</p>
                                                </div>

                                                <Accordion style={{marginTop: '20px'}}>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, margin: '0px 20px'}}}
                                                    >
                                                    Answers
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '-30px', display: true ? 'flex' : 'none',}}>
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer A</p>
                                                        </FormTitle>
                                    
                                                        <TextField
                                                            value={item.a}
                                                            onChange={(e)=>updateObjectProperty(index, 'a', e.target.value)}
                                                            multiline
                                                            error={item.aError.length > 0 ? true : false}
                                                            helperText={item.aError}
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer A"}
                                                        />
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer B</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.b}
                                                            onChange={(e)=>updateObjectProperty(index, 'b', e.target.value)}
                                                            multiline
                                                            error={item.bError.length > 0 ? true : false}
                                                            helperText={item.bError}
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer B"}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>

                                                <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer C</p>
                                                        </FormTitle>
                                    
                                                        <TextField
                                                            value={item.c}
                                                            onChange={(e)=>updateObjectProperty(index, 'c', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer C"}
                                                        />
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer D</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.d}
                                                            onChange={(e)=>updateObjectProperty(index, 'd', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer D"}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>

                                                <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer E</p>
                                                        </FormTitle>
                                    
                                                        <TextField
                                                            value={item.e}
                                                            onChange={(e)=>updateObjectProperty(index, 'e', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer E"}
                                                        />
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer F</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.f}
                                                            onChange={(e)=>updateObjectProperty(index, 'f', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer F"}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>

                                                <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer G</p>
                                                        </FormTitle>
                                    
                                                        <TextField
                                                            value={item.g}
                                                            onChange={(e)=>updateObjectProperty(index, 'g', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer G"}
                                                        />
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer H</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.h}
                                                            onChange={(e)=>updateObjectProperty(index, 'h', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer H"}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>

                                                <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'flex' : 'none',}}>
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer I</p>
                                                        </FormTitle>
                                    
                                                        <TextField
                                                            value={item.i}
                                                            onChange={(e)=>updateObjectProperty(index, 'i', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer I"}
                                                        />
                                    
                                                    </Form>
                                    
                                                    <Form style={{width: false ? '100%' : '47%'}}>
                                                        <FormTitle>
                                                            <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>Answer J</p>
                                                        </FormTitle>

                                                        <TextField
                                                            value={item.j}
                                                            onChange={(e)=>updateObjectProperty(index, 'j', e.target.value)}
                                                            multiline
                                                            rows={1}
                                                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                            variant="outlined"
                                                            placeholder={"Answer J"}
                                                        />
                                    
                                                    </Form>
                                    
                                    
                                                </SubContainer>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue}}} >Quiz Correction</p>
                                                </div>

                                                <Form style={{width: '35%'}}>
                                                    <FormTitle>
                                                        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, marginTop: '-10px'}}}>Correction</p>
                                                    </FormTitle>

                                                    <Autocomplete
                                                        multiple={true}
                                                        id="tags-standard"
                                                        options={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']}
                                                        value={item.correction}
                                                        onChange={(event, newValue)=>updateObjectProperty(index, 'correction', newValue)}
                                                        //9value={selectedTypesOfquestions}
                                                        //onChange={handleAutocompleteChange}
                                                        style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                        InputProps={{
                                                            style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                                        }}
                                                        PaperComponent={CustomPaper}
                                                        getOptionLabel={(option) => option} // Specify the label property
                                                        renderInput={(params) => (
                                                            <TextField
                                                            error={item.correctionError.length > 0 ? true : false}
                                                            helperText={item.correctionError}
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder={"Select"}
                                                            />
                                                        )}
                                                    />

                                                </Form>

                                                <Form style={{width: true ? '100%' : '47%', display: quizType === 'Cas clinique' || quizType === 'Dossier clinique' ? 'flex' : 'none'}}>
                                                    <FormTitle>
                                                        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Explication</p>
                                                    </FormTitle>
                                                    
                                                    <MyQuillEditorAdvanced2 updateObjectProperty={updateObjectProperty} index={index} type={'explication'} content={item.explication}></MyQuillEditorAdvanced2>
                                
                                                    {/* <TextField
                                                        multiline
                                                        error={item.enonceError.length > 0 ? true : false}
                                                        helperText={item.enonceError}
                                                        rows={5}
                                                        value={item.enonce}
                                                        onChange={(e)=>updateObjectProperty(index, 'enonce', e.target.value)}
                                                        style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                                        variant="outlined"
                                                        placeholder={"Enonce"}
                                                    /> */}
                                                </Form>

                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                                
                            </div>
                                     {/* end question ********************************* */}
                            


        
                        </Form>
        
        
            </SubContainer>





            <FormTitle>
                        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.blue, marginTop: '20px'}}}>Explication</p>
            </FormTitle>
            {/* <MyQuillEditor content={quizExplication} setContent={setQuizExplication}></MyQuillEditor> */}
            <MyQuillEditorAdvanced content={quizExplication} setContent={setQuizExplication}></MyQuillEditorAdvanced>
            
            {/* <MyDraftEditor></MyDraftEditor>        */}
        </Container>
        <Button   sx={{ textTransform: 'none' }} onClick={createQuiz}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '20px', marginBottom: '10px'}}}>Create a question</Button>
        <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                {AlertMessage}
            </Alert>
        </Snackbar>
    </Home>
  )
}
