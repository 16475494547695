import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import validator from 'validator';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const List = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`

const ListCircle = styled(CircleIcon)`
  font-size: 8px !important;
`

export default function GeneratePassword({ handleError, HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle}) {
  const navigate = useNavigate()
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isUppercase, setIsUppercase] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isChar, setIsChar] = useState(false);
  const [Is8Digits, setIs8Digits] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);

  function handlePasswordchange(value){
    setPassword(value)

    setIsLowercase(false)
    setIsUppercase(false)
    setIsChar(false)
    setIs8Digits(false)
    setIsNumber(false)

    // lowercase
    var lowercasePattern = /[a-z]/;

    if(lowercasePattern.test(value)){
      setIsLowercase(true)
    }

    // uppercase
    var uppercasePattern = /[A-Z]/;

    if(uppercasePattern.test(value)){
      setIsUppercase(true)
    }
    
    
    // special characters
    var specialCharsPattern = /[!@#$%^&*()+=\[\]\\';,./{}\|":<>?]/;

    if(specialCharsPattern.test(value)){
      setIsChar(true)
    }

    // numbers
    var numberPattern = /\d/;
    if(numberPattern.test(value)){
      setIsNumber(true)
    }

    // length
    if(value.length >= 8){
      setIs8Digits(true)
    }

  }

  const [submitLoading, setSubmitLoading] = useState(false);
 
  function handleSubmit(){
    setSubmitLoading(true)
    axios.post("/generate-password", {
      email: localStorage.getItem("email"), password
    })
        .then(function (response) {
          setSubmitLoading(false)
          if(response.data.response === 'email_verified'){
            navigate('/auth/full-name', { replace: true });
          }else if(response.data.response === 'email_sent'){
            navigate('/auth/check-email', { replace: true });
          }
        })
        .catch(function (error) {
          setSubmitLoading(false)
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }


  const [submitDisable, setSubmitDisable] = useState(true);
  useEffect(() => {
      if(isUppercase === true && isLowercase === true && isChar === true && Is8Digits === true && isNumber === true){
        setSubmitDisable(false)
        setButtonStyle(BtnStyle)
      }else{
        setSubmitDisable(true)
        setButtonStyle(DisabledBtnStyle)
      }
  }, [isUppercase, isLowercase, isChar, Is8Digits, isNumber]);

  return (
    <Home>
      <Button variant='standard'  onClick={()=>navigate('/auth/identifier')} style={{ ...uiSettings.typos.paragraphDesktop, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
        Back
      </Button>
      <Logo  auth={true}/>
      <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>Enter a password</h1>
      <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>Type a strong password</p>
      
      <TextField
        id=""
        placeholder={'Password *'}
        value={password}
        onchange
        variant='standard'
        type={'password'}
        InputProps={{
              style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
        }}
        onChange={(e)=>handlePasswordchange(e.target.value)}
      />

      <List>
        <p style={{...paragraphStyle, ...{color: isLowercase? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One lowercase character minimum</p>
        <p style={{...paragraphStyle, ...{color: isUppercase? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One uppercase character minimum</p>
        <p style={{...paragraphStyle, ...{color: isNumber? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One number minimum</p>
        <p style={{...paragraphStyle, ...{color: isChar? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One special character (-,/,*,_ ...)</p>
        <p style={{...paragraphStyle, ...{color: Is8Digits? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> 8 characters minimum</p>
      </List>
      <LoadingButton loading={submitLoading} loadingPosition='end' disabled={submitDisable} onClick={handleSubmit} style={{...paragraphStyle, ...ButtonStyle , ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
        Submit
      </LoadingButton>
      <p style={{...uiSettings.typos.paragraphMobile, ...{textAlign: 'center', color: uiSettings.colors.secondary, marginTop: '28px'}}}>By signing up, you agree to our <Link style={{color: uiSettings.colors.secondary}}>Terms of Service</Link>  & <Link style={{color: uiSettings.colors.secondary}}>Privacy Policy</Link>.</p>
    </Home>
  )
}
