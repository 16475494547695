import React, { useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export default function Password({ HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle, handleError}) {
  const navigate = useNavigate()
  const [password, setPassword] = useState('');
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [submitDisable, setSubmitDisable] = useState(true);
  function handlePasswordchange(value){
    setPassword(value)
    if(value.length > 7){
      setButtonStyle(BtnStyle)
      setSubmitDisable(false)
    }else{
      setButtonStyle(DisabledBtnStyle)
      setSubmitDisable(true)
    }
  }


  function handleSubmit(){
    setSubmitLoading(true)
    axios.post("/password", {
      password
    })
        .then(function (response) {
          setSubmitLoading(false)
          if(response.data.response === 'complete-profile'){
            if(response.data.step === 'add_name'){
              navigate('/auth/full-name', { replace: true });
            }else if (response.data.step === 'add_phone'){
              navigate('/auth/full-name', { replace: true });
            }else if (response.data.step === 'email_verification'){
              navigate('/auth/check-email', { replace: true });
            }
          }else{
            navigate('/dashboard', { replace: true });
          }
        })
        .catch(function (error) {
          setSubmitLoading(false)
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }


  return (
    <Home width={windowSize.width}>
      <Button variant='standard' onClick={()=>navigate('/auth/identifier')} style={{ ...paragraphStyle, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
        Back
      </Button>
      <Logo  auth={true}/>
      <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>Enter your password</h1>
      <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>enter your password to login</p>
      <TextField
        id=""
        placeholder={'Password *'}
        value={password}
        variant='standard'
        type={'password'}
        InputProps={{
              style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
        }}
        onChange={(e)=>handlePasswordchange(e.target.value)}
      />
      <Link to={'/auth/forget-password'} style={{...paragraphStyle, ...{color: uiSettings.colors.blue, textAlign: "right", textDecorationLine: "none"}}}>Forgot your password?</Link>
      <LoadingButton onClick={handleSubmit} loading={submitLoading} loadingPosition={'end'} disabled={submitDisable} style={{...paragraphStyle, ...ButtonStyle ,...{marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
        Submit
      </LoadingButton>
      {/* <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, marginTop: '38px'}}}>By signing up, you agree to our <Link style={{color: uiSettings.colors.secondary}}>Terms of Service</Link>  & <Link style={{color: uiSettings.colors.secondary}}>Privacy Policy</Link>.</p> */}
    </Home>
  )
}
