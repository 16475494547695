import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete, Paper, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AccountCircleOutlined, Comment, DeleteOutlineOutlined, DoNotDisturbAltOutlined, Error, ErrorOutlineOutlined, Favorite, FavoriteBorderOutlined, HeartBrokenOutlined, LibraryBooksOutlined, ModeEditOutlineOutlined, NoteOutlined, PeopleAltOutlined, QuizOutlined, Subscriptions } from '@mui/icons-material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import { DataGrid } from '@mui/x-data-grid'

const CustomPaper = (props) => {
  return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

const MyTab = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    ${{...uiSettings.typos.link_mid}};
    color: black;
    width: 280px;
    padding: 0px 10px;
`

const Profile = styled.div`
    width: calc(100% - 20px);
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
`

const ProfileInfo = styled.div`
    //flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0px 10px 10px 20px;
    width: calc(100% - 180px);
`

const AutomedProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`

const PersonalProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 25px;
`

const PersonalProfileContainer = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: colrowmn;
    justify-content: space-between;
    flex-wrap: wrap;
`


const AutomedProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    
`

const Quizzez = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
`

const QuizCart = styled.div`
    width: calc(50% - 20px);
    margin: 10px;
    min-width: 350px;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`

const styles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}

//antd drop image
const { Dragger } = Upload;

const props: UploadProps = {
  name: 'file',
  multiple: true,
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};


// mui tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
function a11yProps(index) {
return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
};
}



export default function User() {
  
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabSelected, setTabSelected] = useState('profile');

  const [loading, setLoading] = useState(true);

  const [student, setStudent] = useState({});
  const [sessions, setSessions] = useState([]);
  const [comments, setComments] = useState([]);
  const [offers, setOffers] = useState([]);

  //  const [changes, setChanges] = useState(0);


  function getStudent (id){
    axios.post("/admin/getStudent", {id: id.id.id})
    .then(function (response) {
        console.log(response.data.student)
        if(response.data.response === 'done'){
            setLoading(false)
            setStudent(response.data.student)
            if(response.data.student){
              setDisplayedFirstName(response.data.student.first_name ? response.data.student.first_name : '')
              setDisplayedFamilyName(response.data.student.family_name ? response.data.student.family_name : '')
              setDisplayedEmail(response.data.student.email ? response.data.student.email : '')
              setDisplayedPhoneNumber(response.data.student.phone_number ? response.data.student.phone_number : '')
              setDisplayedAddress(response.data.student.address ? response.data.student.address : '')
              setDisplayedFaculty(response.data.student.faculty ? response.data.student.faculty : '')
              setDisplayedDescription(response.data.student.description ? response.data.student.description : '')
              setImage(response.data.student.image ? response.data.student.image : '')
              setAbonnements(response.data.student.abonnements ? response.data.student.abonnements : [])
              setChanges(changes + 1)
            }
            setStatus('done')
            setSessions(response.data.sessions)
            setComments(response.data.comments)
            setOffers(response.data.offers)
        }else{
          setLoading(false)
          setStatus('notFound')
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}
const  id  = useParams();

const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const Navigate = useNavigate()

  const isMounted = useRef(true);
    
  useEffect(() => {
    return () => {
    // Cleanup function to set isMounted to false when component unmounts
    isMounted.current = false;
    };
  }, []);

  useEffect(() => {
      if (isMounted.current) {
        getStudent({id})
      }
  }, []);

  const [status, setStatus] = useState('notFound');

  const [loadingBan, setLoadingBan] = useState(false);
  const [displayedFirstName, setDisplayedFirstName ] = useState('');
  const [displayedFamilyName, setDisplayedFamilyName ] = useState('');
  const [displayedFaculty, setDisplayedFaculty ] = useState('');
  const [displayedDescription, setDisplayedDescription ] = useState('');
  const [displayedEmail, setDisplayedEmail ] = useState('');
  const [displayedPhoneNumber, setDisplayedPhoneNumber ] = useState('');
  const [displayedAddress, setDisplayedAddress ] = useState('');
  const [image, setImage] = useState('');
  
  function banStudent (){
    setLoadingBan(true)
    axios.post("/admin/banStudent", {id: student.id, action: !student.blocked})
    .then(function (response) {
        setOpenWaitModal2(false)  
        setLoadingBan(false)
        console.log(response)
        // update blocked property
        const updatedState = {
          ...student, // spread the existing state
          blocked: !student.blocked // update the desired property
        };
        setStudent(updatedState);
    })
    .catch(function (error) {
        // handle error
        setLoadingBan(false)
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}

const [openWaitModal1, setOpenWaitModal1] = useState(false);
const onCloseWaitModal1 = () => {
  // Handle cancel button click, e.g., stop the ongoing process
  setOpenWaitModal1(false);
};

const [loadingDelete, setLoadingDelete] = useState(false);

function deleteStudent (){
  setLoadingDelete(true)
  axios.post("/admin/deleteStudent", {id: student.id})
  .then(function (response) {
    setLoadingDelete(false)
      setOpenWaitModal1(false)
      setStatus('notFound')
  })
  .catch(function (error) {
      // handle error
      setLoadingDelete(false)
      if(error.response && error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
      }
  })

}

const [loadingEdit, setLoadingEdit] = useState(false);
function editStudent (){
  
  setLoadingEdit(true)
  axios.post("/admin/editStudent", {id: student.id, first_name: displayedFirstName, family_name: displayedFamilyName, address: displayedAddress, faculty: displayedFaculty, phone_number: displayedPhoneNumber, description: displayedDescription, image})
  .then(function (response) {
    if(response.data.response === 'done'){
      setLoadingEdit(false)
      setStudent(response.data.student)
      setValue(0)
    }else if (response.data.done === 'notFound'){
      setStatus('notFound')
      setLoadingEdit(false)
    }
  })
  .catch(function (error) {
      // handle error
      setLoadingEdit(false)
      if(error.response && error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
      }
  })

}

const [openWaitModal2, setOpenWaitModal2] = useState(false);
  const onCloseWaitModal2 = () => {
    // Handle cancel button click, e.g., stop the ongoing process
    setOpenWaitModal2(false);
  };


  const [selectedFile, setSelectedFile] = useState(null);
const fileInputRef = useRef(null);

const handleFileChange = async (event) => {
  setSelectedFile(event.target.files[0]);
  await uploadPhoto(event.target.files[0])
};

const handleImageLoad = (event) => {
  const img = event.target;
  if (img.naturalWidth > img.naturalHeight) {
    img.style.width = 'auto';
    img.style.height = '100%';
  } else {
    img.style.width = '100%';
    img.style.height = 'auto';
  }
};

async function uploadPhoto(selectedFile) {
  try {
      if (selectedFile){
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post('/admin/uploadUserPhoto', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setImage(response.data.image)
        setSelectedFile(null);
        // Clear the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        
      }else{
        setAlertType('error')
        setAlertMessage('No image was selected')
        setOpenAlert(true)
      }
  } catch (error) {
    if(error.response && error.response.status === 403){
      Navigate('/admin/login', {replace: 'true'})
  }else{
      console.log(error)
      setAlertType('error')
      setAlertMessage('An unexpected error has occured')
      setOpenAlert(true)
  }
  }
}

useEffect(() => {
  if(value === 4){
    setImage(student.image ? student.image : '')
    setDisplayedFaculty(student.faculty ? student.faculty : '')
    setDisplayedDescription(student.description ? student.description : '')
    setDisplayedFirstName(student.first_name ? student.first_name : '')
    setDisplayedFamilyName(student.family_name ? student.family_name : '')
    setDisplayedPhoneNumber(student.phone_number ? student.phone_number : '')
    setDisplayedAddress(student.address ? student.address : '')
  }
}, [value]);

const [abonnements, setAbonnements] = useState([
  {
      id: 1,
      title: "Offer 1",
      price: 10.99,
      admin: "Admin1",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-07T00:00:00.000Z"
  },
  {
      id: 2,
      title: "Offer 2",
      price: 15.49,
      admin: "Admin2",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-14T00:00:00.000Z"
  },
  {
      id: 3,
      title: "Offer 3",
      price: 8.99,
      admin: "Admin3",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-21T00:00:00.000Z"
  },
  {
      id: 4,
      title: "Offer 4",
      price: 20.99,
      admin: "Admin4",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-28T00:00:00.000Z"
  },
  {
      id: 5,
      title: "Offer 5",
      price: 12.99,
      admin: "Admin5",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-07-05T00:00:00.000Z"
  },
  {
      id: 6,
      title: "Offer 6",
      price: 18.49,
      admin: "Admin6",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-07-12T00:00:00.000Z"
  },
  {
      id: 7,
      title: "Offer 7",
      price: 9.99,
      admin: "Admin7",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-07-19T00:00:00.000Z"
  }
])




const columns = [
  { 
    field: 'title', 
    headerName: 'Title', 
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <span style={{color: uiSettings.colors.headingColor, fontSize: '17px'}}>{params.row.offer ? params.row.offer.title : '--'}</span> 
        </>
        
      );
    },
  },
  { 
    field: 'admin', 
    headerName: 'Added by', 
    width: 120,
    renderCell: (params) => {
      return (
        <>
          <span style={{fontSize: '15px'}}>{params.row.addedBy}</span> 
        </>
        
      );
    },
  },
  { 
    field: 'date', 
    headerName: 'Added in', 
    width: 120,
    renderCell: (params) => {
      return (
        <>
          <span style={{fontSize: '15px'}}>{ params.row.createdAt ? params.row.createdAt.split('T')[0] : '--'}</span> 
        </>
        
      );
    },
  },
  { field: 'expDate', headerName: 'Date of exp', width: 120,
    renderCell: (params) => {
      return (
        <span style={{fontSize: '15px'}}>{params.row.offer && params.row.offer.expDate ? params.row.offer.expDate.split('T')[0] : '--'}</span> 
      );
    }, },
  { 
    field: 'year', 
    headerName: 'Year', 
    width: 140,
    renderCell: (params) => {
      const colors = [{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'},{color: 'rgba(93, 34, 218, 1)', bgColor: 'rgba(93, 34, 218, 0.15)'},{color: 'rgba(251, 116, 75, 1)', bgColor: 'rgba(251, 116, 75, 0.15)'},{color: 'rgba(27, 32, 85, 1)', bgColor: 'rgba(27, 32, 85, 0.15)'},{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'}]
      const random = Math.floor(Math.random() * (4 - 0 + 1)) + 0
      var number = 1
      if(params.row.offer && params.row.offer.year && params.row.offer.year.length > 1){
        number = Number(!isNaN(params.row.offer.year[0]) ? params.row.offer.year[0] : 1 )
      }
      return (
        <>
          <span style={{padding: '8px 16px', borderRadius: '50px', backgroundColor: colors[number - 1 ].bgColor , color: colors[number - 1].color, fontSize: '16px'}}>{params.row.offer && params.row.offer.year ? params.row.offer.year : '--'}</span> 
        </>
        
      );
    },
  },
];

const [selectedOffer, setSelectedOffer] = useState();

const [loadingSettingAbonnement, setloadingSettingAbonnement] = useState();
const [changes, setChanges] = useState(0);
function settingAbonnement (){
  
  setloadingSettingAbonnement(true)
  axios.post("/admin/setAbonnement", {id: student.id, offer: selectedOffer})
  .then(function (response) {
    if(response.data.response === 'done'){
      setloadingSettingAbonnement(false)
      setStudent(response.data.student)
      setAbonnements(response.data.student.abonnements ? response.data.student.abonnements : [])
      setSelectedOffer()
      setChanges(changes + 1)
    }else{
      setAlertType('error')
      setAlertMessage(response.data.message)
      setOpenAlert(true)
    }
  })
  .catch(function (error) {
      // handle error
      setloadingSettingAbonnement(false)
      if(error.response && error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
      }
  })

}

useEffect(() => {
  // Sorting the items array based on the createdAt property from newest to oldest
  const sortedItems = [...abonnements].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  // Setting the sorted items array back to state
  setAbonnements(sortedItems);
  console.log(sortedItems)
}, [changes]);


  if(loading){
    return(
      <Home style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'none', flexDirection: 'column', background: 'none'}}>
            <CircularProgress  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px'}}}/>
            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Loading..</p>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
      </Home>
    )
  }else if(status === 'notFound'){
    return(
      <Home style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'flex', flexDirection: 'column', background: 'none'}}>
            <ErrorOutlineOutlined  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px', fontSize: '35px'}}}/>
            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Student not found</p>
      </Home>
    )
  }else{
    return (
      <Home>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
          <Breadcrumbs aria-label="breadcrumb" >
              <Link underline="hover" color="inherit" to="/admin/users" style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, textDecorationLine: "none"}}}>
                  users
              </Link>
              <Link style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, textDecorationLine: "none"}}}
                  underline="hover"
                  color="inherit"
                  href="/admin/users"
              >
                  user's details
              </Link>
              <Typography style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} color="text.primary">Profile</Typography>
          </Breadcrumbs>
  
          <Buttons>
            <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px'}}} startIcon={<ModeEditOutlineOutlined />} onClick={()=> {setValue(4); setTabSelected('profile')}} >edit profile</Button>
            <LoadingButton loadingPosition='start' loading={loadingBan} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: uiSettings.colors.red}}} startIcon={<DoNotDisturbAltOutlined />} onClick={() => setOpenWaitModal2(true)} >{student.blocked === true ? 'Unblock student' : 'Block student'}</LoadingButton>
            <Button onClick={()=> setOpenWaitModal1(true)} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '0px', color: uiSettings.colors.white, backgroundColor: uiSettings.colors.red}}} startIcon={<DeleteOutlineOutlined />} >delete user</Button>
          </Buttons>
  
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', borderRadius: '15px' }}
      style={{padding: "20px 10px"}}
      >
        <Tabs
          style={{width: '200px', alignItems: 'end'}}
          orientation="vertical"
          //variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab onClick={()=> setTabSelected('profile')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'profile' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><AccountCircleOutlined style={{marginRight: '8px'}}/> Profile</MyTab>} {...a11yProps(0)} />
          <Tab onClick={()=> setTabSelected('favorite')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'favorite' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><LibraryBooksOutlined style={{marginRight: '8px'}}/> Sessions</MyTab>} {...a11yProps(1)} />
          <Tab onClick={()=> setTabSelected('noted')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'noted' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><NoteOutlined style={{marginRight: '8px'}}/> Feedbacks</MyTab>} {...a11yProps(2)} />
          <Tab onClick={()=> setTabSelected('abonnement')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'abonnement' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><Subscriptions style={{marginRight: '8px'}}/> Abonnement</MyTab>} {...a11yProps(3)} />
        </Tabs>
        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={0}>
          <Profile>
              <div src={student.image && student.image.length > 0 ? student.image :  "/profile.png" } style={{width: '160px', height: '160px', borderRadius: '8px', backgroundImage:  student.image && student.image.length > 0  ? 'url("' + student.image + '")' :  'url("/profile.png")', backgroundSize: 'cover', backgroundPosition: 'center', color: 'transparent' }} >.</div>
              <ProfileInfo >
                  <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} >{student.first_name + ' ' + student.family_name}</p>
                  <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, margin: "10px 0px", display: student.description && student.description.length > 0 ? 'flex' : "none"}}} >{student.description}</p>
                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px'}}} >Faculty <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px'}}}>{student.faculty && student.faculty.length > 0 ?  student.faculty : '__'}</span></p>
                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px'}}} >Email <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px'}}}>{student.email && student.email.length > 0 ?  student.email : '__'}</span></p>
                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px'}}} >Phone number <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px'}}}>{student.phone_number && student.phone_number.length > 0 ?  student.phone_number : '__'}</span></p>
                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px'}}} >Adresse <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px'}}}>{student.address && student.address.length > 0 ?  student.address : '__'}</span></p>
                  {
                    student.studentAbonnement
                    ? 
                    <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px'}}} >Abonnement <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px', textDecorationLine: 'underline', cursor: 'pointer'}}} onClick={()=> Navigate('/admin/abonnement/'+ student.studentAbonnement.id )} >{student.studentAbonnement && student.studentAbonnement.title ?  student.studentAbonnement.title : '__'}  </span></p>
                    :
                    ''
                  }
              </ProfileInfo>
          </Profile>
        </TabPanel>
        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={1}>
  
          {/* // favorite **************************** */}
  
          <Quizzez>
            {
              sessions.map((session, key)=> (
                <QuizCart>
                    <QuizHeader>
                        <QuizAction>
                          <IconButton style={{color: uiSettings.colors.blue,}}> {session.like === true ? <Favorite/> : <FavoriteBorderOutlined/>} </IconButton>
                        </QuizAction>
                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, alignItems: 'center', ...styles.HCenter}}} > <QuizOutlined style={{...styles.quizIcon}}/>{session.title}</p>
                    </QuizHeader>
                    <QuizContainer>
                        <Tags>
                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, display: session.casClinique ? 'flex' : 'none' }}}>Cas clinique</Tag>
                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, display: session.qcm ? 'flex' : 'none' }}}>QCM</Tag>
                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.blue, backgroundColor: uiSettings.colors.bgblue, display: session.qcs ? 'flex' : 'none' }}}>QCS</Tag>
                        </Tags>
                        <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}><span style={{color: uiSettings.colors.headingColor}}>Description</span><br/>{session.description && session.description.length > 150 ? session.description.slice(0,2): session.description}</p>
                        <QuizBottom>
                          <div style={{...styles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...styles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/>Created by {student.first_name}</p>
                            <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{session.date.split("T")[0]}</p>
                          </div>
                        </QuizBottom>
                    </QuizContainer>
                </QuizCart>   
              ))
            }
{/*   
              <QuizCart>
                  <QuizHeader>
                    <QuizAction>
                        <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                        <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                      </QuizAction>
                      <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, alignItems: 'center', ...styles.HCenter}}} > <QuizOutlined style={{...styles.quizIcon}}/> Cardiology quiz #125</p>
                  </QuizHeader>
                  <QuizContainer>
                      <Tags>
                          <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                          <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                      </Tags>
                      <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                      <QuizBottom>
                        <div style={{...styles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...styles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                          <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                        </div>
                      </QuizBottom>
                  </QuizContainer>
              </QuizCart> */}
  
          </Quizzez>
        
        
        
        
        
        
        </TabPanel>
        
       
        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={2}>
          {
            comments.map((comment, key)=>(
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                  <div  style={{width: 'calc(100% - 40px)', padding: '10px 10px', margin: '5px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', borderRadius: '10px', boxShadow: '0px 0px 4px 2px whitesmoke'}}>
                      {/* title */}
                      <div  style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start'}}>
                        <Comment style={{marginRight: '10px', color: uiSettings.colors.blue}} />
                        <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '0px 0px'}}} >{comment.comment}</p>
                      </div>
                      <div  style={{width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start'}}>
                        <p style={{...uiSettings.typos.link, ...{color: uiSettings.colors.headingColor, padding: '2px 0px', marginRight: '5px', fontSize: '15px'}}} >Module <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px 0px 5px',fontSize: '15px'}}}>{comment.quiz && comment.quiz.module ? comment.quiz.module : '--'}</span></p>
                        <p style={{...uiSettings.typos.link, ...{color: uiSettings.colors.headingColor, padding: '2px 0px', fontSize: '15px'}}} >Cour <span style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, padding: '0px 10px 0px 5px',fontSize: '15px'}}}>{comment.quiz && comment.quiz.cour ? comment.quiz.cour : '--'}</span></p>
                        <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, padding: '2px 0px', flex: 1, textAlign: 'end', color: uiSettings.colors.yellow, }}} ><span style={{cursor: 'pointer', padding: '5px 15px', borderRadius: '5px', backgroundColor: uiSettings.colors.lightYellow}} onClick={()=> window.open('/admin/Questions/question/' + comment.quiz.id, '_blank')} >open</span>  </p>
                      </div>
                  </div>
              </div>
            ))
          }
        </TabPanel>

        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={3}>
          <AutomedProfile >
              <AutomedProfileContainer style={{width: 'calc(60% - 30px)', padding: '10px'}}>
                  <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>AutoMed abonnement</p>
                  
  
                  {/* <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Abonnement</p> */}
                  <Autocomplete
                      id="tags-standard"
                      value={selectedOffer}
                      options={offers}
                      onChange={(event, newValue) => {setSelectedOffer(newValue)}}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue, marginTop: '20px' }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.title} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                          // helperText={quizModuleError}
                          // error={quizModuleIsError}
                          {...params}
                          variant="outlined"
                          placeholder={"Select"}
                          // onChange={(e) => setQuizModule(e.target.value)}

                          />
                      )}
                  />
  
                 
              </AutomedProfileContainer>
              <AutomedProfileContainer style={{width: 'calc(40% - 30px)', padding: '10px'}}>
                  <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '50px'}}}></p>
                  
  
                <Buttons>
                    <LoadingButton onClick={()=> settingAbonnement()} loading={loadingSettingAbonnement} loadingPosition='center'  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: loadingEdit === true ? 'transparent' : uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow}}}>Select abonnement</LoadingButton>
                </Buttons>  
                 
  
                 
              </AutomedProfileContainer>
          </AutomedProfile>
  
          <PersonalProfile>
              <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Abonnement history</p>
              <div style={{ height: 'calc( 100vh - 220px)', width: '100%', marginTop: '20px'}}>
                <DataGrid
                  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
                  rows={abonnements}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 50 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  //loading={loading}
                />
              </div>  
          </PersonalProfile>
  
          
          
        </TabPanel>


        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={4}>
          <AutomedProfile>
              <AutomedProfileContainer style={{width: 'calc(60% - 30px)', padding: '10px'}}>
                  <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>AutoMed profile</p>
                  {/* <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>username</p> */}
                  {/* <TextField
                          id=""
                          placeholder={'username'}
                          onchange
                          variant='outlined'
                          defaultValue={'mohammed_de'}
                          type={'text'}
                          style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                          InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                          }}
                  /> */}
  
                  <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>faculty</p>
                  <TextField
                          id=""
                          placeholder={'Faculty'}
                          value={displayedFaculty}
                          onChange={(e)=> {setDisplayedFaculty(e.target.value)}}
                          variant='outlined'
                          type={'text'}
                          style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                          InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                          }}
                  />
  
                  <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>biography</p>
                  <TextField
                          id=""
                          placeholder={'biography'}
                          variant='outlined'
                          value={displayedDescription}
                          onChange={(e)=> {setDisplayedDescription(e.target.value)}}
                          type={'text'}
                          multiline
                          rows={5}
                          style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}}
                          InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{padding: "16px"}}
                          }}
                  />
              </AutomedProfileContainer>
              <AutomedProfileContainer style={{width: 'calc(40% - 30px)', padding: '10px',  }}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center !important', backgroundColor: uiSettings.colors.bgbluelight, border: '1px dashed ' + uiSettings.colors.bgblue3,  width: 'calc(100% - 10px)', padding: '5px', borderRadius: '12px'}}>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '15px'}} >
                      <div style={{width: "130px", height: '130px', backgroundImage: image && image.length > 0 ? 'url("' + image + '")' : 'url("/profile.png")', backgroundSize: 'cover', borderRadius: '8px'}}>.</div>
                  </div>
                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.secondary2, margin: '5px 0px 0px 0px', fontSize: '15px', textAlign: "center"}}} >change the profile picture</p>
                  <Buttons style={{justifyContent: 'center', marginBottom: '17px'}}>
                      <Button onClick={() => fileInputRef.current.click()}   sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: uiSettings.colors.blue, border: '1px solid ' + uiSettings.colors.blue}}} startIcon={<ModeEditOutlineOutlined />}  >uplaod</Button>
                      <Button  onClick={(e) => { setImage('')}} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '0px', color: uiSettings.colors.white, backgroundColor: uiSettings.colors.red}}} startIcon={<DeleteOutlineOutlined />} >delete</Button>
                      <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                      />
                  </Buttons>
              </div>
              </AutomedProfileContainer>
          </AutomedProfile>
  
          <PersonalProfile>
              <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Personal profile</p>
              <PersonalProfileContainer>
                  <div style={{width: '45%'}}>
                      <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>First name</p>
                      <TextField
                              id=""
                              placeholder={'First name'}
                              variant='outlined'
                              value={displayedFirstName}
                              onChange={(e)=> {setDisplayedFirstName(e.target.value)}}
                              type={'text'}
                              style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                              }}
                      />
                  </div> 
  
                  <div style={{width: '45%'}}>
                      <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Last name</p>
                      <TextField
                              id=""
                              placeholder={'Last name'}
                              variant='outlined'
                              value={displayedFamilyName}
                              onChange={(e)=> {setDisplayedFamilyName(e.target.value)}}
                              type={'text'}
                              style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                              }}
                      />
                  </div>  
  
                  <div style={{width: '45%'}}>
                      <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Email</p>
                      <TextField
                              id=""
                              placeholder={'Email'}
                              variant='outlined'
                              disabled
                              value={displayedEmail}
                              type={'email'}
                              style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                              }}
                      />
                  </div> 
  
                  <div style={{width: '45%'}}>
                      <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Phone number</p>
                      <TextField
                              id=""
                              placeholder={'Phone number'}
                              variant='outlined'
                              defaultValue={displayedPhoneNumber}
                              onChange={(e)=> {setDisplayedPhoneNumber(e.target.value)}}
                              type={'number'}
                              style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                              }}
                      />
                  </div>     
  
                  <div style={{width: '100%'}}>
                      <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Address</p>
                      <TextField
                              id=""
                              placeholder={'Address'}
                              variant='outlined'
                              value={displayedAddress}
                              onChange={(e)=> {setDisplayedAddress(e.target.value)}}
                              type={'text'}
                              style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                              }}
                      />
                  </div>   
  
              </PersonalProfileContainer>
          </PersonalProfile>
  
          <Buttons>
              <LoadingButton onClick={()=> editStudent()} loading={loadingEdit} loadingPosition='center'  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: loadingEdit === true ? 'transparent' : uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow}}}>Save changes</LoadingButton>
          </Buttons>
          
        </TabPanel>
        
      </Box>
  
      {/* delete student */}
      <Dialog open={openWaitModal1} fullWidth={true} maxWidth={'sm'} >
          <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Delete student</DialogTitle>
          <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
              <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Deleting a student will permanently delete that account along with all created sessions.</p>
          </DialogContent>
          <DialogActions>
              <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal1()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
              <LoadingButton loading={loadingDelete} loadingPosition='end' onClick={()=>deleteStudent()}   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Delete</LoadingButton>
          </DialogActions>
      </Dialog>

         {/* block student */}
         <Dialog open={openWaitModal2} fullWidth={true} maxWidth={'sm'} >
          <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>{student.blocked === true ? 'Unblock' : 'Block'} student</DialogTitle>
          <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
              <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>{student.blocked === true ? 'Unblocking this student will give them access to their account again' : 'Blocking a student means that the student will lose access to their account until they are unblocked again.'} </p>
          </DialogContent>
          <DialogActions>
              <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
              <LoadingButton loading={loadingBan} loadingPosition='end' onClick={()=>banStudent()}   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>{student.blocked === true ? 'Unblock' : 'Block'} </LoadingButton>
          </DialogActions>
      </Dialog>
  
      </Home>
    )
  }
}
