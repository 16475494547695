import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function RedirectToHome({handleError}) {
  const navigate = useNavigate()
  useEffect(() => {
    axios.post("/check_access")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            navigate('/dashboard', { replace: true });
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier')
          }else{
            navigate('/auth/identifier')
          }
        })

  }, []);


  
  return(
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100'}}>
      <CircularProgress/>
    </div>
  )
  
}
