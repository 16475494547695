import React from 'react'
import { Error, ErrorOutlineOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'


const Home = styled.div`
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export default function NotFound() {
  return (
    <Home>
        {/* <ErrorOutlineOutlined style={{fontSize: '40px', color: uiSettings.colors.headingColor, marginBottom: '10px'}}/> */}
        <p style={{...uiSettings.typos.heading1, ...{color: uiSettings.colors.blue}}}>404</p>
        <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.secondary2, display: 'flex', alignItems: 'center'}}}><ErrorOutlineOutlined style={{marginRight: '5px'}}/> <span>Page not found.</span> </p>
    </Home>
  )
}
