import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, CircularProgress, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, TextField, Autocomplete, Paper } from '@mui/material';
import { Add, DeleteOutlineOutlined, ErrorOutlineOutlined, Filter, FilterList, KeyboardArrowDown, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';

const CustomPaper = (props) => {
  return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}


const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const Buttons = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

const AutomedProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    
`


export default function AddAbonnement() {
  const [selectedStudent, setSelectedStudent] = useState({});



  // mui menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const [sortBy, setSortBy] = useState('All');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [offers, setOffers] = useState([]);
  const Navigate = useNavigate()
  const {id} = useParams()
  const [offer, setOffer] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [year, setYear] = useState('');
  const [expDate, setExpDate] = useState('');
  const [modules, setModules] = useState([]);
  const [price, setPrice] = useState('');
  const [availableModules, setAvailableModules] = useState([]);
  const [notFound, setNotFound] = useState(false);
  
  
  function getOffer (data){
    axios.post("/admin/getOffer", {id})
    .then(function (response) {
        console.log(response)
        setLoading(false)
        if(id === 'new'){
          setAvailableModules(response.data.modules)
        }else{
          if(response.data.response === 'done'){
              setOffer(response.data.offer)
              setAvailableModules(response.data.modules)
              setTitle(response.data.offer.title)
              setDescription(response.data.offer.description)
              setYear(response.data.offer.year)
              setPrice(response.data.offer.price)
              setExpDate(response.data.offer.expDate ? response.data.offer.expDate.split('T')[0] : '' )
              setModules(response.data.offer.modules && response.data.offer.modules.length > 0 ? JSON.parse(response.data.offer.modules) : [])
          }else{
            setNotFound(true)
          }
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
    // Cleanup function to set isMounted to false when component unmounts
    isMounted.current = false;
    };
  }, []);

  useEffect(() => {
      if (isMounted.current) {
        getOffer()
      }
  }, []);


  function saveOffer (data){
    axios.post("/admin/saveOffer", {id, title, description: description ? description : '', modules, expDate, year, price})
    .then(function (response) {
        console.log(response)
        if(response.data.response === 'done'){
          setAlertType('success')
          setAlertMessage(id === 'new' ? 'Abonnement created.' : 'Changes saved.')
          setOpenAlert(true)
          setTimeout(() => {
            Navigate('/admin/Abonnement', {replace: true})
          }, 2000);
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}





  const [loadingBan, setLoadingBan] = useState(false);


  if(loading){
    return(
      <Home style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'flex', flexDirection: 'column', background: 'none'}}>
        {/* <ErrorOutlineOutlined  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px', fontSize: '35px'}}}/> */}
        {/* <circularpr */}
        <CircularProgress/>
        {/* <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Offer not found</p> */}
      </Home>
    )
  }else if(notFound){
    return(
      <Home style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'flex', flexDirection: 'column', background: 'none'}}>
        <ErrorOutlineOutlined  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px', fontSize: '35px'}}}/>
        <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Offer not found</p>
      </Home>
    )
  }else{
    return (
      <Home>
        <p style={{ ...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '15px'}}}><span style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/Abonnement')} >Abonnement</span>  {'>'} {id === 'new' ? 'Add an abonnement' : 'Abonnement’s details'} </p>
        <Header>
          <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}></p>
          <Buttons>
            <Button  sx={{ textTransform: 'none' }} onClick={()=> {saveOffer()}} style={{ ...uiSettings.typos.adminButtonYellow}} startIcon={<Add />} >{id === 'new' ? 'Create abonnemnet' : 'Save changes'}</Button>
          </Buttons>
        </Header>
  
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', borderRadius: '15px' }} style={{padding: "20px 10px"}}>
        <AutomedProfileContainer style={{width: 'calc(60% - 30px)', padding: '10px'}}>
  
          <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Title</p>
          <TextField
            id=""
            placeholder={'Title'}
            value={title}
            onChange={(e)=> {setTitle(e.target.value)}}
            variant='outlined'
            type={'text'}
            style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
            InputProps={{
                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
            }}
          />
  
          <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Description</p>
          <TextField
            id=""
            placeholder={'Description'}
            value={description}
            onChange={(e)=> {setDescription(e.target.value)}}
            variant='outlined'
            multiline
            rows={5}
            type={'text'}
            style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
            InputProps={{
                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{padding: '16px'}}
            }}
          />
  
          <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Modules</p>
          <Autocomplete
            id="tags-standard"
            value={modules}
            multiple
            options={availableModules}
            onChange={(event, newValue) => {setModules(newValue)}}
            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
            InputProps={{
                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
            }}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option.module} // Specify the label property
            renderInput={(params) => (
                <TextField
                //helperText={quizModuleError}
                //error={quizModuleIsError}
                {...params}
                variant="outlined"
                placeholder={"Select"}
                //onChange={(e) => setQuizModule(e.target.value)}
                />
            )}
        />
  
        </AutomedProfileContainer>
        <AutomedProfileContainer style={{width: 'calc(40% - 30px)', padding: '10px'}}>
        <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Price</p>
          <TextField
            id=""
            placeholder={'Price'}
            value={price}
            onChange={(e)=> {setPrice(e.target.value)}}
            variant='outlined'
            type={'text'}
            style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
            InputProps={{
                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
            }}
          />
          <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Year</p>
          <Autocomplete
            id="tags-standard"
            value={year}
            options={['1st year', '2nd year', '3rd year', '4th year', '5th year', '6th year', '7th year',]}
            onChange={(event, newValue) => {setYear(newValue)}}
            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
            InputProps={{
                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
            }}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option} // Specify the label property
            renderInput={(params) => (
                <TextField
                //helperText={quizModuleError}
                //error={quizModuleIsError}
                {...params}
                variant="outlined"
                placeholder={"Select"}
                //onChange={(e) => setQuizModule(e.target.value)}
                />
            )}
        />
  
          <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Exp. date</p>
          <TextField
            id=""
            placeholder={'Experation date'}
            value={expDate}
            onChange={(e)=> {setExpDate(e.target.value)}}
            variant='outlined'
            type={'date'}
            style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
            InputProps={{
                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
            }}
          />    
        </AutomedProfileContainer>
  
  
      </Box>
  
  
        <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                {AlertMessage}
            </Alert>
        </Snackbar>
      </Home>
    )
  }

}
