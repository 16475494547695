import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings';


const Home = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: ${uiSettings.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 6;
`

const Container = styled.div`
    width: 586px;
    height: calc(100% - 48px);
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 7;
`

export default function Loading() {
  return (
    <Home>
        <Container>
            <Box style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        </Container>
    </Home>
  )
}
