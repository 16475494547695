import React, { useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Fullscreen } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap ;
`

const Image = styled.div`
    margin: 10px;
    width: 100px;
    height: 100px;
    background-image: ${props => props.url ?  `url('${props.url}')`  : ''} ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: "10px";
    box-shadow: #0000002c 0px 0px 5px 2px;
    position: relative
`

const MyDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right : 0;
    bottom: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0000006b;
    transition: display 0.5s ease;
`


export default function ImageGallerie({images}) {
    /* const [images, setImages] = useState(['/pics/Screenshot 2023-10-04 114822.png', '/pics/Screenshot 2023-09-07 094137.png', '/pics/Screenshot 2023-08-24 141828.png', '/pics/image_1706613999845.png']); */
  
    const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [image, setImage] = React.useState('');
    
  const handleClickOpen = (scrollType, image) => () => {
    setImage(image)
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

    return (
    <Home>
        {
            images.map((item)=>{
                return(
                    <Image className='myImage' url={item}><MyDiv className='subImage'><IconButton  onClick={handleClickOpen('body', item)} style={{backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white}}> <Fullscreen/></IconButton></MyDiv></Image>
                )
            })
        }

        <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            fullWidth={true}
            maxWidth={"lg"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            
        >
            <DialogContent  dividers={scroll === 'paper'} style={{backgroundColor: 'transparent', padding: '0px'}}>
                <img src={image} style={{width: '100%', height: 'auto'}} />                
            </DialogContent>
        </Dialog>
    </Home>
  )
}
