import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Dashboard from './panel/dashboard'
import { Alert, Snackbar } from '@mui/material'
import axios from 'axios'
import DataTable from '../admin/panel/dataGrid'

const Home = styled.div`
    height: 100vh;
    width: 100%;
`

export default function Student({windowSize}) {

    const navigate = useNavigate()

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertType, setAlertType] = React.useState('success');
    const [AlertMessage, setAlertMessage] = React.useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    function handleError(error){
        if(error.response.status === 400 && error.response.data.response === 'access_denied'){
            navigate('/auth/identifier', { replace: true });
        }else if(error.response.status === 400 && error.response.data.response === 'server_error'){
                setAlertType('error')
                setOpenAlert(true)
                setAlertMessage(error.response.data.error) 
        }else if(error.response.status === 429 ){
          // too many requests
          setAlertType('error')
          setOpenAlert(true)
          setAlertMessage('too many requests, try again later.') 
        }else if(error.response.status && error.response.statusText !== '' ){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.statusText) 
        }else{
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage('An error has occured')   
        }
      }

    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        axios.post("/check_access")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            setAuthenticated(true)
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
    }, []);

  return (
    <Home>
        <Routes>
            <Route exact path="/*" element={<Dashboard handleError={handleError} windowSize={windowSize} setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert} />}></Route>
        </Routes>

        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
            {AlertMessage}
            </Alert>
        </Snackbar>
    </Home>
  )
}
