import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import validator from 'validator';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';

const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const List = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`

const ListCircle = styled(CircleIcon)`
  font-size: 8px !important;
`

export default function ResetPassword({ handleError, HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle}) {
  const navigate = useNavigate()
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isUppercase, setIsUppercase] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isChar, setIsChar] = useState(false);
  const [Is8Digits, setIs8Digits] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);

  function handlePasswordchange(value){
    setPassword(value)

    setIsLowercase(false)
    setIsUppercase(false)
    setIsChar(false)
    setIs8Digits(false)
    setIsNumber(false)

    // lowercase
    var lowercasePattern = /[a-z]/;

    if(lowercasePattern.test(value)){
      setIsLowercase(true)
    }

    // uppercase
    var uppercasePattern = /[A-Z]/;

    if(uppercasePattern.test(value)){
      setIsUppercase(true)
    }
    
    
    // special characters
    var specialCharsPattern = /[!@#$%^&*()+=\[\]\\';,./{}\|":<>?]/;

    if(specialCharsPattern.test(value)){
      setIsChar(true)
    }

    // numbers
    var numberPattern = /\d/;
    if(numberPattern.test(value)){
      setIsNumber(true)
    }

    // length
    if(value.length >= 8){
      setIs8Digits(true)
    }

  }

  const [submitLoading, setSubmitLoading] = useState(false);
 
  function handleSubmit(){
    setSubmitLoading(true)
    axios.post("/reset-password", {
      id, code, password
    })
        .then(function (response) {
          setSubmitLoading(false)
          if(response.data.response === 'done'){
            setAlertType('success')
            setOpenAlert(true)
            setAlertMessage('Password changed')
            navigate('/auth/identifier')
          }else if(response.data.response === 'not_allowed'){
            setAllowed(false)
          }else if(response.data.response === 'password_error'){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(response.data.error)
          }
        })
        .catch(function (error) {
          setSubmitLoading(false)
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }


  const [submitDisable, setSubmitDisable] = useState(true);
  useEffect(() => {
      if(isUppercase === true && isLowercase === true && isChar === true && Is8Digits === true && isNumber === true){
        setSubmitDisable(false)
        setButtonStyle(BtnStyle)
      }else{
        setSubmitDisable(true)
        setButtonStyle(DisabledBtnStyle)
      }
  }, [isUppercase, isLowercase, isChar, Is8Digits, isNumber]);

  const [loading, setLoading] = useState(true);
  
  
  const [code, setCode] = useState('');
  const [id, setId] = useState('');
  const [getValue, setGetValue] = useState(false);

  useEffect(() => {
    // Function to parse the query string from the URL
    const getParamsFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const idParam = searchParams.get('id');
      const codeParam = searchParams.get('code');
      
      // Update state with the retrieved parameters
      setId(idParam);
      setCode(codeParam);
      setGetValue(true)
    };

    // Call the function to parse URL parameters when the component mounts
    getParamsFromURL();
  }, []);

  useEffect(() => {
    if(getValue){
      checkUrl(code, id)
    }
  }, [getValue]);

  const [allowed, setAllowed] = useState(false);

  function checkUrl(code, id){
    axios.post("/check-reset-password-link", {
      code, id
    })
    .then(function (response) {
      if(response.data.response === 'allowed'){
        setAllowed(true)
        setLoading(false)
      }else{
        setLoading(false)
        setAllowed(false)
      }
    })
    .catch(function (error) {
      console.log(error.response)
      if(error.response.status === 400){
        setAlertType('error')
        setOpenAlert(true)
        setAlertMessage(error.response.data.error)
        setTimeout(() => {
          navigate('/auth/identifier', {replace: true})
        }, 2000);
      }else{
        handleError(error)
        setTimeout(() => {
          navigate('/auth/identifier', {replace: true})
        }, 2000);
      }
    })
  }



  
 

  if(loading){
    return (
      <Home>
        <Button variant='standard'  onClick={()=>navigate('/auth/identifier')} style={{ ...uiSettings.typos.paragraphDesktop, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
          Back
        </Button>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
          <CircularProgress/>
        </div>
      </Home>
    )
  }else{
    if(allowed){
      return (
        <Home>
          <Button variant='standard'  onClick={()=>navigate('/auth/identifier')} style={{ ...uiSettings.typos.paragraphDesktop, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
            Back
          </Button>
          <Logo  auth={true}/>
          <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>Reset password</h1>
          <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>Type a strong password</p>
          
          <TextField
            id=""
            placeholder={'Password *'}
            value={password}
            onchange
            variant='standard'
            type={'password'}
            InputProps={{
                  style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
            }}
            onChange={(e)=>handlePasswordchange(e.target.value)}
          />
    
          <List>
            <p style={{...paragraphStyle, ...{color: isLowercase? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One lowercase character minimum</p>
            <p style={{...paragraphStyle, ...{color: isUppercase? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One uppercase character minimum</p>
            <p style={{...paragraphStyle, ...{color: isNumber? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One number minimum</p>
            <p style={{...paragraphStyle, ...{color: isChar? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> One special character (-,/,*,_ ...)</p>
            <p style={{...paragraphStyle, ...{color: Is8Digits? uiSettings.colors.success : uiSettings.colors.secondary}}}><ListCircle/> 8 characters minimum</p>
          </List>
          <LoadingButton loading={submitLoading} loadingPosition='end' disabled={submitDisable} onClick={handleSubmit} style={{...paragraphStyle, ...ButtonStyle , ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
            Submit
          </LoadingButton>
        </Home>
      )

    }else{
      return (
        <Home>
          <Button variant='standard'  onClick={()=>navigate('/auth/identifier')} style={{ ...uiSettings.typos.paragraphDesktop, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
            Back
          </Button>
          {/* <Logo  auth={true}/> */}
          <ErrorOutlineOutlined style={{ fontSize: '60px', color: uiSettings.colors.black,  marginTop: '50px'}} />
          <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800} }}>Invalid link</h1>
          <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>The reset url is not valid anymore, Try reset your password again.</p>
        </Home>
      )
    }
  }
}
