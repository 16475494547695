import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from './loading'

const Home = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: ${uiSettings.colors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 5;
`

const Container = styled.div`
    width: 586px;
    height: calc(100% - 48px);
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 6;
`


export default function CheckEmail({ HeadingStyle, paragraphStyle, windowSize, handleError, setOpenAlert, setAlertType, setAlertMessage,}) {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.post("/check_email_verification_step")
        .then(function (response) {
          if(response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else if(response.data.step === 'email_verification'){
            setLoading(false)
          }else if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else{
            navigate('/auth/identifier', { replace: true });
          }
        })
        .catch(function (error) {
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.response)
            navigate('/auth/identifier', { replace: true });
          }else if(error.response.status === 301){
            navigate(error.response.data.path, { replace: true });
          }else if(error.response.status === 403){
            navigate(error.response.data.path, { replace: true });
          }else{
            handleError(error)
          }
        })
   }, []);

   const [isResend, setIsResend] = useState(false);

   function SendVerificationEmail (){
    setIsResend(true)
    axios.post("/resend_verification_email")
    .then(function (response) {
        setIsResend(false)
        setAlertType('success')
        setOpenAlert(true)
        setAlertMessage("Email sent again.")
    })
    .catch(function (error) {
      if(error.response.status === 429 ){
        setIsResend(false)
        setAlertType('error')
        setOpenAlert(true)
        setAlertMessage("You can send another email after 3 minutes")
      }else{
        setIsResend(false)
        handleError(error)
      }
    })
   }

   if(loading){
    return(
      <Home style={{backgroundColor: 'white'}}>
        <Container>
          <Loading />
        </Container>
      </Home>
    )
   }else{
      return (
        <Home>
            <Container>
                <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '170px': '0px'}}></div>
                <Logo  auth={true}></Logo>
                <img style={{height: windowSize.width < uiSettings.devices.phone ? '250px': '330px', width: 'auto', margin: windowSize.width < uiSettings.devices.phone ? '20px 0px 00px 0px': '0px 5px'}} src="/mailsent.gif" alt="My Image" />
                <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary}}}>Please check your email, we have sent <br/> a verification link</p>
                <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: windowSize.width < uiSettings.devices.phone ? 'center': 'end'}}}><spam>You didn’t receive any email ? {isResend ? <span style={{color: uiSettings.colors.blue}}>Resending..</span> : <span onClick={SendVerificationEmail} style={{color: uiSettings.colors.blue, cursor: 'pointer'}}>Resend</span> } </spam> </p>

            </Container>
        </Home>
      )
   }
}
