import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import styled from 'styled-components'


import logo from './logo.svg';
import './App.css';
import Email from './components/auth/identifier';
import Password from './components/auth/password1';
import Auth from './components/auth/auth';
import Admin from './components/admin/admin';
import Student from './components/student/student';
import PlaySession from './components/playSession/playSession';
import RedirectToHome from './components/redirectToHome';
import DataTable from './components/admin/panel/dataGrid';




function App() {

  
const [windowSize, setWindowSize] = useState({
  width: window.innerWidth,
  height: window.innerHeight,
});

useEffect(() => {
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []); //

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<RedirectToHome />}></Route>
          <Route exact path="/auth/*" element={<Auth windowSize={windowSize} />}></Route>
          <Route exact path="/admin/*" element={<Admin windowSize={windowSize} />}></Route>
          <Route exact path="/dashboard/*" element={<Student windowSize={windowSize} />}></Route>
          <Route exact path="/session/*" element={<PlaySession windowSize={windowSize} />}></Route>
          <Route exact path="/*" element={<RedirectToHome />}></Route>
        </Routes>
      </Router>
    </div>
   
  );
}

export default App;
