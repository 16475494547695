import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Slide, Slider, Snackbar, TextField } from '@mui/material';
import { Add, AddOutlined, AddToPhotosOutlined, CheckCircleOutlineOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, Filter, FilterList, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, NotificationsActive, PeopleAltOutlined, QuizOutlined, RemoveOutlined, RemoveRedEyeOutlined, SearchOutlined, Sort } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Container = styled.div`
    width: 100%;
    //height: calc(100vh - 150px);
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 15px;
`

const QuizCart = styled.div`
    width: calc(33.33% - 20px);
    min-width: 250px;
    margin: 10px;
    min-width: ${props => props.width < 600 ? '80%' : '350px'} ;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: ${props => props.index === 1 ? 'url("/images/bg-blue-circles.svg")' : (props.index === 2 ? 'url("/images/bg-orange-circles.svg")' : (props.index === 3 ? 'url("/images/bg-yellow-circles.svg")' : (props.index === 4 ? 'url("/images/blue-bg-rectangle.svg")' :  'url("/images/yellow-bg-rectangle.svg")'  ) ) ) } ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`
const Mystyles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}

export default function AllQuizzes({windowSize}) {
    const Navigate = useNavigate()
    const [loading, setLoading] = useState(true);

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    const [exams, setExams] = useState([]);

    function getExams (data){
        axios.post("/admin/getAllExams", {id: data})
        .then(function (response) {
            console.log(response)
            if(response.data.response === 'done'){
                setLoading(false)
                setExams(response.data.exams)
                const numberOfGroups = Math.ceil(response.data.exams.length / 12);
                setPages(numberOfGroups)
                // setStudents(response.data.students)
            }
        })
        .catch(function (error) {
            // handle error
            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
        })
    }

    const isMounted = useRef(true);
    
    useEffect(() => {
        return () => {
        // Cleanup function to set isMounted to false when component unmounts
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            getExams()
        }
    }, []);

    const [sortBy, setSortBy] = useState('date');
    const [changes, setChanges] = useState(0);
    function sortExams(sort){
        setPage(1)
        const list = exams
        if(sort === 'date'){
            list.sort((a, b) => b.users - a.users);
        }else{
            list.sort((a, b) => new Date(b.year) - new Date(a.year));
        }
        setExams(list)
        setChanges(changes + 1)
    }

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const handleChange = (event, value) => {
        setPage(value);
        console.log('New Page:', value); // You can handle the new value here
    };

    const [openDeleteSessionModal, setOpenDeleteSessionModal] = React.useState(false);
    const handleClickOpenDeleteSessionModal = () => {
        setOpenDeleteSessionModal(true);
    };
    const handleCloseDeleteSessionModal = () => {
        setOpenDeleteSessionModal(false);
    };

    const [selectedExam, setSelectedExam] = useState('');

    const [loadingDelete, setLoadingDelete] = useState(false);
    function handleDeleteSession(){
      setLoadingDelete(true)
      axios.post("/admin/deleteExam", {id: selectedExam})
          .then(function (response) {
            setLoadingDelete(false)
            handleCloseDeleteSessionModal()
            const updatedList = exams.filter(item => item.id !== selectedExam);
            setExams(updatedList)
            setChanges(changes + 1)
            // remove the removed exam 
          })
          .catch(function (error) {
            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
          })
    } 

    if(loading){
        return(
            <Home style={{height: 'calc(100vh - 140px)', justifyContent: "center", alignItems: 'center'}}>
                <CircularProgress/>
                <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                        {AlertMessage}
                    </Alert>
                </Snackbar>
            </Home>
        )
    }else{
        return (
          <Home>
                <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                        {AlertMessage}
                    </Alert>
                </Snackbar>

              <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} ><span onClick={()=> Navigate('/admin/Exams') } style={{cursor:'pointer'}}>Exams</span> {">"} All exams </p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                  <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}} >Total: {exams.length} exams</p>
                  <div style={{flex: 1}} ></div>
                  <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.white, color: uiSettings.colors.headingColor}}} startIcon={<Sort />} onClick={()=> {sortBy === 'date' ? setSortBy('users') : setSortBy('date'); sortExams(sortBy === 'date' ? 'date' : 'users')}} >{sortBy === 'date' ? 'Sort by Popularity' : 'Sort by date'}</Button>
                  <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor}}} startIcon={<ModeEditOutlineOutlined />} onClick={()=> Navigate('/admin/Exams/NewExam')} >Create an exam</Button>
              </div>
              <Container style={{minHeight: '400px'}}>
      
                    {
                        loading === true ?
                        <div style={{width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <CircularProgress style={{justifyContent: 'center', alignSelf: 'center'}}/>
                        </div>
                        :
                        (
                            exams && exams.length === 0 ?
                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                                    <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                                    <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                                </div>
                                :
                                exams.map((item, index)=>{
                                    const types = item.types ? JSON.parse(item.types) : []
                                    if(index >( page * 12 - 12 - 1) && index < page * 12){
                                        return(
                                            <QuizCart key={index} width={windowSize.width} style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/exams/' + item.id)}>
                                                <QuizHeader>
                                                    <QuizAction>
                                                        {/* <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton> */}
                                                        <IconButton style={{color: uiSettings.colors.red}} onClick={()=> {setSelectedExam(item.id); setOpenDeleteSessionModal(true)}}><DeleteOutlineOutlined/> </IconButton>
                                                    </QuizAction>
                                                    <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                                                </QuizHeader>
                                                <QuizContainer>
                                                    <Tags>
                                                        {
                                                            types.map((type, key)=>{
                                                                return(
                                                                    <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                                                )
                                                            })
                                                        }                                        </Tags>
                                                    <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>{item.description && item.description.length > 100 ? item.description.slice(0,100) + ".." : item.description} <br/><span style={{color: uiSettings.colors.headingColor}}></span></p>
                                                    <QuizBottom>
                                                        <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> {item.users} revisions</p>
                                                            {/* <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.createdAt ? item.createdAt.slice(0,10) : ''}</p> */}
                                                            <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.year}</p>
                                                        </div>
                                                    </QuizBottom>
                                                </QuizContainer>
                                            </QuizCart>
                                        )
                                    }
                                })

                        )

                    }
      
                  
              </Container>
              <Pagination page={page} onChange={handleChange} count={pages} variant="outlined" shape="rounded" style={{alignSelf: 'center', margin: '10px', marginTop: '20px'}} />
             
              <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openDeleteSessionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDeleteSessionModal}
                    aria-describedby="alert-dialog-slide-description"
                    style={{padding: '10px 20px'}}
                >
                    {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                            <Form style={{width:'100%'}}>
                                    
                                    <FormTitle>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}}>Delete <span style={{color: uiSettings.colors.red}} >Exam</span> </p>
                                    </FormTitle> 

                                    <FormTitle>
                                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, fontWeight: 400}}}>Do you really want to delete this exam?</p>
                                    </FormTitle> 

                                    <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                        <LoadingButton   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseDeleteSessionModal}>Annuler</LoadingButton>
                                        <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.red, marginLeft: '10px'}}} onClick={()=> handleDeleteSession()}  >supprimer</LoadingButton>
                                    </DialogActions>
                            </Form>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
          </Home>
        )
    }
}

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 0px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
`

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
