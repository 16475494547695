import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useFetcher } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import { Button, IconButton } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab'
import { CheckCircleOutlineOutlined, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, VisibilityOutlined } from '@mui/icons-material'
import axios from 'axios'
import ImageGallerie from '../admin/panel/imageGallerie'
import { minHeight, width } from '@mui/system'



const Home = styled.div`
  width: calc(100% - 20px);
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
  overflow-y: auto ;
  align-items: center;
`
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: ${(props) => props.isPhone ? '100%' : '95%'} ;
    margin-top: ${(props) => props.isPhone ? '10px' : '20px'} ;
`

const MainContent = styled.div`
    width: ${(props) => props.isPhone ? '100%' : '85%'} ;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 1;
    background-color: ${uiSettings.colors.white};
`

const SideContent = styled.div`
    width: 15%;
    display: ${(props) => props.isPhone ? 'none' : 'flex'} ;
    flex-direction: column;
    justify-content: start;
    margin-top: 100px;
`
const SideItem = styled.div`
    width: ${props=> props.isPhone ? 'calc(100% - 68px) !important' : 'calc(100% - 68) !important'} ;
    padding: 12px 24px !important;
    padding-right: 44;
    border-radius: 7px !important;
    background-color: ${uiSettings.colors.yellow} !important;
    color: ${uiSettings.colors.black} !important;
    margin: 10px 0px !important;
    margin-right: ${props => props.isPhone ? '0px !important' : '-20px !important'};
    z-index: 0 !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
`
const RepetedSideItem = styled.div`
    width: ${props=> props.isPhone ? 'calc(100% - 68px) !important' : 'calc(100% - 68) !important'} ;
    padding: 12px 24px !important;
    padding-right: 44;
    border-radius: 7px !important;
    background-color: ${uiSettings.colors.bgBlue} !important;
    color: ${uiSettings.colors.blue} !important;
    margin: 10px 0px !important;
    margin-right: ${props => props.isPhone ? '0px !important' : '-20px !important'};
    z-index: 0 !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
    font-size: 16px !important;
`

const Header = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 20px) !important' : 'calc(100% - 48px) !important'};
    padding: ${(props) => props.isPhone ? '20px 10px !important' : '10px 24px !important'};
    border-radius: 15px !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
    background-color: ${uiSettings.colors.white};
    z-index: 5;
`

const QuizList = styled.div`
    width: ${(props) => props.isPhone ? ' calc(100% - 20px)' : ' calc(100% - 40px)'} ;
    padding: ${(props) => props.isPhone ? '10px' : '20px'} ;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    border-radius: 15px;
    margin-top: -25px;
    box-shadow: 0px 2px 15px 0px #0000001A;
    min-height: 200px;
`

const QuizItem = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 20px) !important' : 'calc(100% - 50px) !important'}  ;
    margin:${(props) => props.isPhone ? '5px !important' : '5px 10px !important'} ;
    padding: ${(props) => props.isPhone ? '5px 5px !important' : '5px 15px !important'}  ;
    box-shadow: 0px 2px 5px 0px #0000000D !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    border-radius: 15px;
    cursor: pointer;
    transition: 0s ease-in-out !important;
    &:hover{
        background-color: #d5d5d557;
        border-color:  ${props => props.color ? props.color + " !important" : 'transparent !important'} ;
    }
`

const QuizId = styled.p`
    padding: 4px 12px;
    margin: 0px;
    margin-right: 20px;
    border: 1px solid ${uiSettings.colors.headingColor};
    border-radius: 50px;
    transition: 0.1s ease-in-out;
`

// // mui tabs *************************************

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
// mui tabs ended ************************************

export default function CasClinique({styles, isPhone, quiz, nextQuiz, handleError, handleEditSidebareAfterAnswering, repeted, images}) {
    const [question, setQuestion] = useState(myQuestion);
    
    // init questions
    const [answers, setAnswers] = useState([]);
    const [subQuizzes, setSubQuizzes] = useState([]);

    const toggleAnswerSelection = (key, answerId) => {
        console.log(key, answerId)
        if(!correction){
            const updatedItems = subQuizzes
            for (let i = 0; i < updatedItems[key].length; i++) {
                const answer = updatedItems[key][i];
                if(answer.id === answerId){
                    answer.selected = !answer.selected
                }
            }
            setSubQuizzes(updatedItems)
            console.log('done')
        }
    }

    useEffect(() => {
        console.log('changed')
    }, [subQuizzes, setSubQuizzes]);


    const [valuechanged, setValuechanged] = useState(0);
    useEffect(() => {
        console.log('value changed')
    }, [valuechanged]);

      const [correction, setCorrection] = useState(false);


      const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    
    
    useEffect(() => {
        if(quiz.corrected === true){
            setCorrection(true)
        }
        
        var my_subQuizzes = []
        console.log('there are ', quiz.quiz.parentQuiz.length, ' subQuizzes')
        
        for (let i = 0; i < quiz.quiz.parentQuiz.length; i++) {
            const subQuiz = quiz.quiz.parentQuiz[i];
            var my_subQuiz = []
            var final_my_subQuiz = []
            
            my_subQuiz.push({id: 'A', question: subQuiz.a}, {id: 'B', question: subQuiz.b},  {id: 'C', question: subQuiz.c},  {id: 'D', question: subQuiz.d},  {id: 'E', question: subQuiz.e},  {id: 'F', question: subQuiz.f},  {id: 'G', question: subQuiz.g}, {id: 'H', question: subQuiz.h},  {id: 'I', question: subQuiz.i}, {id: 'J', question: subQuiz.j})
            var correctAnswers = subQuiz.correction.split('.')
            for (let t = 0; t < my_subQuiz.length; t++) {
                const question = my_subQuiz[t];
                var correction = false
                var alreadySelected = quiz.answers.split('.') // IMPORTANT : back to this *************
                if(question && question.question.length > 0){
                    for (let j = 0; j < correctAnswers.length; j++) {
                        const answer = correctAnswers[j];
                        if(answer === question.id){
                            correction = true
                        }
                    }
                    var selected = false
                    for (let j = 0; j < alreadySelected.length; j++) {
                        const answer = alreadySelected[j];
                        if(j === i){
                            for (let s = 0; s < answer.split(',').length; s++) {
                                const singleAnswer = answer.split(',')[s];
                                if(singleAnswer === question.id){
                                    selected = true
                                }
                            }  
                        }
                          
                    }
                    final_my_subQuiz.push({id: question.id, content: question.question, selected: selected, correction})
                    
                }
            }
            
            my_subQuizzes.push(final_my_subQuiz)
            
        }
        setSubQuizzes(my_subQuizzes)
       // console.log(my_subQuizzes)
        //console.log(my_subQuizzes.length)
        //console.log('*************')



        /*var my_answers= []
        var my_questions = []
        my_questions.push({id: 'A', question: quiz.quiz.a}, {id: 'B', question: quiz.quiz.b},  {id: 'C', question: quiz.quiz.c},  {id: 'D', question: quiz.quiz.d},  {id: 'E', question: quiz.quiz.e},  {id: 'F', question: quiz.quiz.f},  {id: 'G', question: quiz.quiz.g}, {id: 'H', question: quiz.quiz.h},  {id: 'I', question: quiz.quiz.i}, {id: 'J', question: quiz.quiz.j})
        var correctAnswers = quiz.quiz.correction.split('.')
        for (let i = 0; i < my_questions.length; i++) {
            const question = my_questions[i];
            var correction = false
            var alreadySelected = quiz.answers.split('.')
            if(question && question.question.length > 0){
                for (let j = 0; j < correctAnswers.length; j++) {
                    const answer = correctAnswers[j];
                    if(answer === question.id){
                        correction = true
                    }
                }
                var selected = false
                for (let j = 0; j < alreadySelected.length; j++) {
                    const answer = alreadySelected[j];
                    if(answer === question.id){
                        selected = true 
                    }
                }
                my_answers.push({id: question.id, content: question.question, selected: selected, correction})
            }
        }
        setAnswers(my_answers)*/
    }, []);


    const [loadingShow, setLoadingShow] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const navigate = useNavigate()

    function handleAnswering(action){
        if(correction === false){
            var selectedOptions = []
            var corrected = false
            var answeredCorrectly = null
            if(action === 'correct'){
                corrected = true
                setLoadingShow(true)
            }else{
                setLoadingNext(true)
            }

            for (let i = 0; i < subQuizzes.length; i++) {
                const subQuiz = subQuizzes[i];
                var selectedAnswers = []
                
                for (let j = 0; j < subQuiz.length; j++) {
                    const answer = subQuiz[j];
                    if(answer.selected === true){
                        selectedAnswers.push(answer.id)
                    }
                }
                selectedOptions.push(selectedAnswers)
                const quizCorrection = quiz.quiz.parentQuiz[i].correction.split('.').slice().sort();
                const userCorrection = selectedAnswers.slice().sort();
                if(i > 0){
                    answeredCorrectly += '.' + (JSON.stringify(quizCorrection) === JSON.stringify(userCorrection)).toString()
                }else{
                    answeredCorrectly = (JSON.stringify(quizCorrection) === JSON.stringify(userCorrection)).toString()
                }
            }
            var selectedOptionsString = ''
            var selectedOptionStringInsideArray = []
            for (let i = 0; i < selectedOptions.length; i++) {
                const option = selectedOptions[i];
                selectedOptionStringInsideArray.push(option.toString())
                if(i > 0){
                    selectedOptionsString += '.'+ option.toString()
                }else{
                    selectedOptionsString += option.toString()
                }
            }

            
           
            if(action === 'next' && selectedOptionsString.length ===  (selectedOptions.length - 1)){
                nextQuiz()
            }else{
                console.log('before sending selected options: ', selectedOptionsString)
                axios.post("/student/editSessionQuiz", {
                    quizId: quiz.id, answered: true, answers: selectedOptionStringInsideArray, answeredCorrectly: answeredCorrectly, corrected: corrected
                  } )
                .then(function (response) {
                if(response.data.step === 'add_name'){
                    navigate('/auth/full-name', { replace: true });
                }else if (response.data.step === 'add_phone'){
                    navigate('/auth/phone-number', { replace: true });
                }else{
                    //console.log(response.data.session)
                    // edit questions list
                    if(action === 'correct'){
                       setCorrection(true); 
                    }else{
                        nextQuiz()
                    }
                    setLoadingNext(false)
                    setLoadingShow(false)
                    handleEditSidebareAfterAnswering(quiz.id)
                }
                })
                .catch(function (error) {
                    setLoadingNext(false)
                    setLoadingShow(false)
                    if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
                        navigate('/auth/identifier', { replace: true });
                    }else{
                        handleError(error)
                    }
                })
            }

            
        }else if (correction === true && action === 'next'){
            nextQuiz()
        }
       
    }

    console.log('************************************************')
    console.log(repeted)

    const adjustImageStyles = (htmlContent) => {
        // Create a temporary div element to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
    
        // Query all image elements within the content
        const images = tempDiv.querySelectorAll('img');
    
        // Apply styles to images within the content
        images.forEach(image => {
          // Check if the image width exceeds 100%
          if (image.width > 100) {
            image.style.maxWidth = '100%';
            image.style.height = 'auto';
          }
        });
    
        // Return the modified HTML content
        return tempDiv.innerHTML;
      };
      const [isAllNumbers, setIsAllNumbers] = useState(false);
      const regex = /#([^#]+)#/g;
      const [openSideBar, setOpenSideBar] = useState(false);
  return (
    <Home className='qcmContainer'>
        <Container isPhone={isPhone}>
            <SideContent isPhone={isPhone} >
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.module.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.module}</SideItem>
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.source.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.source}</SideItem>
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.date} {quiz.quiz.year}</SideItem>
                {
                    quiz.quiz.module === '' && quiz.quiz.cour === '' ?
                    <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: 'flex', backgroundColor: 'red', fontSize: "16px"} }}>Suite de la précédente</RepetedSideItem>
                    :
                    <></>
                }
                {
                    repeted.map(item => {
                        return(
                            <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ? 'flex': 'none', backgroundColor: 'red', fontSize: "16px"} }}>{item.date}  {item.year}  {item.source === '' || (item.date === "" && item.year === "") ? '' : '-'} {item.source}</RepetedSideItem>
                        )
                    })
                }
            </SideContent>
            <MainContent isPhone={isPhone}>
                <Content isPhone={isPhone}>
                    {/* <Header style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{question.question}</Header> */}
                    <Header isPhone={isPhone} style={{...styles.navText, ...{color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'column', fontSize: '18px'}}}>
                    <span  dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{display: quiz.quiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}></span>
                        {/* <span style={{display: quiz.quiz.question.length > 0 ? 'block' : 'none'}}>{quiz.quiz.question}</span> */}
                    </Header>
                    <QuizList isPhone={isPhone}>
                        {/* tabs ***************** */}

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueTab} onChange={handleChangeTab} style={{overflow: 'none'}} 
                                //aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                TabIndicatorProps={{
                                    style: {
                                      backgroundColor: "transparent"
                                    }
                                  }}
                                >
                                {
                                    quiz.quiz.parentQuiz.map((subQuiz, index)=>{
                                        return(
                                            <Tab style={{...styles.navText, ...{color: uiSettings.colors.headingColor, boxShadow: "0px 2px 5px 0px #0000000D", padding: '4px 12px', marginRight: '10px', borderRadius: valueTab === 0 ? '7px 7px 0px 0px' : '7px' , marginBottom: valueTab === index ?  '0px': '10px', paddingBottom: valueTab === index ? '10px' : '0px', borderTop: valueTab === index ? '4px solid ' + uiSettings.colors.yellow : '4px solid ' + uiSettings.colors.white, transition: '0.2s'}}} label={index + 1} {...a11yProps(index)} />
                                        )
                                    })
                                }
                                </Tabs>
                            </Box>

                            {
                                    quiz.quiz.parentQuiz.map((subQuiz, index)=>{
                                        const myKey = index
                                        var array = []
                                        if(subQuizzes[myKey]){
                                            array = subQuizzes[myKey]
                                        }
                                        var itIsAllNumbers = true
                                        for (let s = 0; s < array.length; s++) {
                                            const mySubQuiz = array[s];
                                            if(!isNaN(mySubQuiz.content.replace(/\./g, ''))){
                                            }else{
                                                itIsAllNumbers = false
                                            }
                                        }
                                        //setIsAllNumbers(itIsAllNumbers)
                                        return(
                                            <CustomTabPanel style={{padding: '0px !important'}} value={valueTab} index={index}>
                                            <Content isPhone={isPhone}>
                                                {/* <Header style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{subQuiz.question}</Header> */}
                                                <Header isPhone={isPhone} style={{...styles.navText, ...{color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'column', fontSize: '18px'}}}>
                                                    <span  dangerouslySetInnerHTML={{ __html: adjustImageStyles(subQuiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{display: subQuiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}></span>
                                                    {/* <span style={{display: subQuiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}>{subQuiz.enonce}</span> */}
                                                    <span dangerouslySetInnerHTML={{ __html: subQuiz.question.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')}} style={{display: subQuiz.question.length > 0 ? 'block' : 'none'}}></span>
                                                    {/* <span style={{display: subQuiz.question.length > 0 ? 'block' : 'none'}}>{subQuiz.question}</span> */}
                                                    <span style={{display: subQuiz.a1 && subQuiz.a1.length > 0 ? 'block' : 'none', marginTop: '10px'}}>1- {subQuiz.a1}</span>
                                                    <span style={{display: subQuiz.a2 && subQuiz.a2.length > 0 ? 'block' : 'none', marginTop: '10px'}}>2- {subQuiz.a2}</span>
                                                    <span style={{display: subQuiz.a3 && subQuiz.a3.length > 0 ? 'block' : 'none', marginTop: '10px'}}>3- {subQuiz.a3}</span>
                                                    <span style={{display: subQuiz.a4 && subQuiz.a4.length > 0 ? 'block' : 'none', marginTop: '10px'}}>4- {subQuiz.a4}</span>
                                                    <span style={{display: subQuiz.a5 && subQuiz.a5.length > 0 ? 'block' : 'none', marginTop: '10px'}}>5- {subQuiz.a5}</span>
                                                    <span style={{display: subQuiz.a6 && subQuiz.a6.length > 0 ? 'block' : 'none', marginTop: '10px'}}>6- {subQuiz.a6}</span>
                                                    <span style={{display: subQuiz.a7 && subQuiz.a7.length > 0 ? 'block' : 'none', marginTop: '10px'}}>7- {subQuiz.a7}</span>
                                                    <span style={{display: subQuiz.a8 && subQuiz.a8.length > 0 ? 'block' : 'none', marginTop: '10px'}}>8- {subQuiz.a8}</span>

                                                </Header>
                                                <QuizList isPhone={isPhone} style={{flexDirection: itIsAllNumbers === true ? 'row' : 'column', flexWrap: 'nowrap', overflowX: itIsAllNumbers ? 'auto' : '', minHeight: itIsAllNumbers ? '70px' : '200px', paddingTop: itIsAllNumbers ? '50px' : '', zIndex: 1, backgroundColor: 'white'}}>
                                                    {
                                                        array.map((subQuiz)=>{

                                                            
                                                                var PrincipalColor = 'transparent'
                                                                var textColor = uiSettings.colors.headingColor
                                                                var borderColor = '#d5d5d514'
                                                                if(correction && subQuiz.correction ){
                                                                    PrincipalColor = 'green'
                                                                    textColor = 'green'
                                                                    borderColor = 'green'
                                                                }else if(correction && !subQuiz.correction && subQuiz.selected ){
                                                                    PrincipalColor = 'red'
                                                                    textColor = 'red'
                                                                    borderColor = 'red'
                                                                }else{
                                                                    PrincipalColor = 'white'
                                                                }
                                                                //var selected = subQuiz.selected
                                                                function handleChange(){
                                                                    console.log(subQuiz.selected)
                                                                    if(!correction){
                                                                        const updatedItems = subQuizzes
                                                                        for (let i = 0; i < updatedItems[myKey].length; i++) {
                                                                            const answer = updatedItems[myKey][i];
                                                                            if(answer.id === subQuiz.id){
                                                                                answer.selected = !answer.selected
                                                                            }
                                                                        }
                                                                        setSubQuizzes(updatedItems)
                                                                        console.log('done')
                                                                        setValuechanged(valuechanged + 1)
                                                                    }
                                                                }

                                                                return(
                                                                    <QuizItem isPhone={isPhone} color={borderColor} onClick={() => { handleChange(); }} style={{...styles.navText, ...{color: textColor, border: 'solid 2px ' + PrincipalColor, fontSize: isPhone ?  '16px' : '20px', fontWeight: 400, height: itIsAllNumbers ? 'min-content' : 'unset'}}}><QuizId style={{backgroundColor : subQuiz.selected && !correction ? uiSettings.colors.blue : 'transparent'   , borderColor : subQuiz.selected && !correction ? uiSettings.colors.blue : textColor, fontWeight: 600}}>{subQuiz.id}</QuizId>{subQuiz.content}<span style={{flexGrow: 1, display: correction && subQuiz.selected ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'end', fontWeight: 600}}><CheckCircleOutlineOutlined style={{color: uiSettings.colors.headingColor, fontSize: '22px'}}/></span></QuizItem>
                                                                ) 
                                                            })
                                                        }
                                                </QuizList>
                                                <Header style={{marginTop: '-20px', display: correction && (subQuiz.explication && subQuiz.explication.length > 0 ) ? 'block': 'none',zIndex: '0'}}>
                                                    <p style={{...styles.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', marginTop: '30px', }}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId>Explication</p>
                                                    <p  dangerouslySetInnerHTML={{ __html: adjustImageStyles(subQuiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px', width: '100%'}}}></p>
                                                </Header>
                                            </Content>
                                            </CustomTabPanel>
                                        )
                                    })
                            }
                            
                           
                           
                           
                            {/* <CustomTabPanel value={valueTab} index={1}>
                            <Content isPhone={isPhone}>
                                <Header style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{question.question}</Header>
                                <QuizList isPhone={isPhone}>
                                    {
                                        question.answers.map((answer) => {
                                            var PrincipalColor = 'transparent'
                                            var textColor = uiSettings.colors.headingColor
                                            var borderColor = '#d5d5d514'
                                            if(correction && answer.correction ){
                                                PrincipalColor = 'green'
                                                textColor = 'green'
                                                borderColor = 'green'
                                            }else if(correction && !answer.correction && answer.selected ){
                                                PrincipalColor = 'red'
                                                textColor = 'red'
                                                borderColor = 'red'
                                            }else{
                                                PrincipalColor = 'white'
                                            }


                                            return(
                                                <QuizItem isPhone={isPhone} color={borderColor} onClick={() => { toggleAnswerSelection(answer.id)}} style={{...styles.navText, ...{color: textColor, border: 'solid 3px ' + PrincipalColor }}}><QuizId style={{backgroundColor : answer.selected && !correction ? uiSettings.colors.blue : 'transparent'   , borderColor : answer.selected && !correction ? uiSettings.colors.blue : textColor}}>{answer.id}</QuizId>{answer.content}</QuizItem>
                                            )
                                        })
                                    }
                                </QuizList>
                            </Content>
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={2}>
                            <Content isPhone={isPhone}>
                                <Header style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{question.question}</Header>
                                <QuizList isPhone={isPhone}>
                                    {
                                        question.answers.map((answer) => {
                                            var PrincipalColor = 'transparent'
                                            var textColor = uiSettings.colors.headingColor
                                            var borderColor = '#d5d5d514'
                                            if(correction && answer.correction ){
                                                PrincipalColor = 'green'
                                                textColor = 'green'
                                                borderColor = 'green'
                                            }else if(correction && !answer.correction && answer.selected ){
                                                PrincipalColor = 'red'
                                                textColor = 'red'
                                                borderColor = 'red'
                                            }else{
                                                PrincipalColor = 'white'
                                            }


                                            return(
                                                <QuizItem isPhone={isPhone} color={borderColor} onClick={() => { toggleAnswerSelection(answer.id)}} style={{...styles.navText, ...{color: textColor, border: 'solid 3px ' + PrincipalColor }}}><QuizId style={{backgroundColor : answer.selected && !correction ? uiSettings.colors.blue : 'transparent'   , borderColor : answer.selected && !correction ? uiSettings.colors.blue : textColor}}>{answer.id}</QuizId>{answer.content}</QuizItem>
                                            )
                                        })
                                    }
                                </QuizList>
                            </Content>
                            </CustomTabPanel> */}
                        </Box>

                        {/* end tabs *********************** */}

                        
                    </QuizList>
                    <div    style={{display: isPhone ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width :'100%', transition: 'ease-in-out 0.2s'}}>
                        <IconButton onClick={() => setOpenSideBar(false)} style={{...{backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, display: openSideBar ? 'flex' : 'none'}}}><KeyboardArrowUp/></IconButton>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && quiz.quiz.module.length> 0 ? 'flex': 'none', fontSize: "16px", width: 'calc(100% - 68px)'} }}>{quiz.quiz.module}</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && quiz.quiz.source.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.source}</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && ( quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ) ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.date} {quiz.quiz.year}</SideItem>
                        {
                            quiz.quiz.module === '' && quiz.quiz.cour === '' ?
                            <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar ? 'flex' : 'none', backgroundColor: 'red', fontSize: "16px"} }}>Suite de la précédente</RepetedSideItem>
                            :
                            <></>
                        }
                        {
                            repeted.map(item => {
                                return(
                                    <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && ( quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ) ? 'flex': 'none', backgroundColor: 'red', fontSize: "16px", width: 'calc(100% - 68px)',} }}>{item.date}  {item.year}  {item.source === '' || (item.date === "" && item.year === "") ? '' : '-'} {item.source}</RepetedSideItem>
                                )
                            })
                        }                         
                        <IconButton onClick={() => setOpenSideBar(true)} style={{...{backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, display: openSideBar ? 'none' : 'flex'}}}><KeyboardArrowDown/></IconButton>
                    </div>
                </Content>
                <Header style={{marginTop: '20px', display: correction && (quiz.quiz.explication.length > 0 || images.length > 0) ? 'block': 'none'}}>
                    <p style={{...styles.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId>Explication</p>
                    {/* <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}>
                    {quiz.quiz.enonce}
                    </p> */}
                    <p  dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.quiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px', width: '100%'}}}>
                    </p>
                    <ImageGallerie images={images} />
                </Header>
                <div style={{flexDirection: isPhone? 'row' : 'row', justifyContent:  isPhone? 'end' : 'end', width: '100%', display: 'flex'}}>
                    <LoadingButton disabled={correction} loading={loadingShow} loadingPosition='end' onClick={() =>{  handleAnswering('correct')}} endIcon={<VisibilityOutlined/>}  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, alignSelf: 'end', marginTop: '20px', width: "max-content", fontSize : isPhone ? '15px' : '18px'}}} >La réponse</LoadingButton>
                    <LoadingButton endIcon={<KeyboardArrowRight />} loading={loadingNext} loadingPosition='end' onClick={() => handleAnswering('next')}   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, alignSelf: 'end', marginTop: '20px', width: "max-content", marginLeft:  isPhone? '10px' : '10px', fontSize : isPhone ? '15px' : '18px'}}} >Suivante</LoadingButton>
                </div>

            </MainContent>
            
        </Container>
    </Home>
  )
}


const myQuestion = {
    question: "Dans le traitement d'ulcère de jambe veineux, la contention élastique cible : 1RJ",
    answers: [
        {
            id: 'A',
            content: "Les varices des membres inférieurs",
            selected: false,
            correction: false
        },
        {
            id: 'B',
            content: "La coloration brunâtre",
            selected: false,
            correction: false
        },
        {
            id: 'C',
            content: "L'œdème du membre inférieur",
            selected: false,
            correction: true
        },
        {
            id: 'D',
            content: "La douleur du mollet",
            selected: false,
            correction: false
        }
    ]
}