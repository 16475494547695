import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import "./styles.css";
import styled from 'styled-components'
import uiSettings from "../../../assets/ui-settings";
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Paper, TextField, colors } from "@mui/material";
import {  makeStyles } from "@mui/styles";
import { Switch } from 'antd';
import { AddOutlined, AttachFile, CheckBox, CheckCircleOutlineOutlined, CheckOutlined, Close, Error, ErrorOutline, ErrorOutlineOutlined, Filter1Outlined, FilterListOutlined, Image, KeyboardArrowDown, RemoveOutlined, Search, SearchOutlined } from "@mui/icons-material";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { LoadingButton } from '@mui/lab';




export default function AbonnementDetails({styles, isPhone, isSidebareOpened, handleError, setAlertMessage, setOpenAlert, setAlertType}) {
    const navigate = useNavigate()
    const  id  = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [offer, setOffer] = useState({});
    const [payementData, setPayementData] = useState({});
    const [myOffer, setMyOffer] = useState('');
    const [notFound, setNotFound] = useState(true);
    const [modules, setModules] = useState([]);
    const [askedOffer, setAskedOffer] = useState('');
    const [code, setcode] = useState('');
    const [reason, setReason] = useState('');

    useEffect(() => {
        console.log(id.id)
        axios.post("/student/offer", {id})
            .then(function (response) {
              if(response.data.step === 'add_name'){
                navigate('/auth/full-name', { replace: true });
              }else if (response.data.step === 'add_phone'){
                navigate('/auth/phone-number', { replace: true });
              }else{
                if(response.data.response === 'done'){
                    setNotFound(false)
                    console.log(response.data)
                    setOffer(response.data.offer)
                    setReason(response.data.reason)
                    if(response.data.offer && response.data.offer.modules && response.data.offer.modules.length > 0){
                        setModules(JSON.parse(response.data.offer.modules))
                    }
                    setPayementData(response.data.payementData)
                    if(response.data.myOffer){
                        setMyOffer(response.data.myOffer)
                    }
                    if(response.data.askedOffer){
                      setAskedOffer(response.data.askedOffer.offerId)
                      setcode(response.data.askedOffer.code)
                    }
                }
                setIsLoading(false)
              }
            })
            .catch(function (error) {
              if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
                navigate('/auth/identifier', { replace: true });
              }else{
                handleError(error)
              }
            })
      }, []);

      const fileInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    //await uploadPhoto(event.target.files[0])
  };

  const [loadingFile, setLoadingFile] = useState(false);
  async function uploadPhoto(selectedFile) {
    try {
      if(uploadedFiles.length < 10){
        if (selectedFile){
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('name', 'mohamed');
          setLoadingFile(true)
          const response = await axios.post('/mailing/uploadFile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          setLoadingFile(false)
          console.log(response)
          if(response.data.response === 'done'){
            var data = uploadedFiles
            data.push(response.data.file)
            setUploadedFiles(data)
          }else{
            setOpenAlert(true)
            setAlertMessage(response.data.error)
            setAlertType("error")
          }
          //setImage(response.data.image)
          setSelectedFile(null);
          // Clear the file input
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }else{
          setLoadingFile(false)
          setOpenAlert(true)
          setAlertMessage('There is no selected file')
          setAlertType("error")
        }
      }else{
        setLoadingFile(false)
        setOpenAlert(true)
        setAlertMessage('Cannot uplaod more than 10 files')
        setAlertType("error")
      }
    } catch (error) {
      setLoadingFile(false)
      setOpenAlert(true)
      setAlertMessage('internal server error')
      setAlertType("error")
    }
  }

  const [loadingDemand, setLoadingDemand] = useState(false);
  async function demanderAbonnement() {
    try {
        if (selectedFile){
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('offer', offer.id);
          setLoadingDemand(true)
          const response = await axios.post('/student/askForOffer', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          setcode(response.data.code)
          setAskedOffer(offer.id)
          setOpenAlert(true)
          setAlertMessage(response.data.message)
          setAlertType("success")
          setLoadingDemand(false)
          setSelectedFile({})
          console.log(response)
        }else{
          setLoadingDemand(false)
          setOpenAlert(true)
          setAlertMessage('There is no selected file')
          setAlertType("error")
        }
    } catch (error) {
      setLoadingFile(false)
      setOpenAlert(true)
      if(error.response && error.response.data && error.response.data.error){
        setAlertMessage(error.response.data.error)
      }else{
        setAlertMessage('internal server error')
      }
      setAlertType("error")
    }
  }

  async function handleDeleteFile(fileRef) {
    try {
        if (fileRef){
          var data = uploadedFiles
          data = data.filter((item) => item.ref !== fileRef)
          setUploadedFiles(data)
          const response = await axios.post('/mailing/deleteFile', {fileRef});
        }else{
          setOpenAlert(true)
          setAlertMessage('There is no selected file')
          setAlertType("error")
        }
    } catch (error) {
      setOpenAlert(true)
      setAlertMessage('internal server error')
      setAlertType("error")
    }
  }
  
    if(isLoading){
        return (
            <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} style={{height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                <CircularProgress></CircularProgress>
            </Home>
        ) 
    }else if (notFound){
        return (
            <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} style={{height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                <ErrorOutlineOutlined style={{fontSize: '30px', color: uiSettings.colors.blue}}></ErrorOutlineOutlined>
                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginBottom: '20px', marginTop: '10px', flexDirection: 'row', display: 'flex'}}}>abonnement introuvable.</p>
            </Home>
        )  
    }else{
        return (
        <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
            <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '20px'}}}> <span style={{cursor: 'pointer'}} onClick={()=> navigate('/dashboard/Abonnements/')} >Abonnements</span>  {">"} détails</p>
            <div style={{width: 'calc(100% - 20px)', padding: '10px', backgroundColor: uiSettings.colors.lightYellow, color: uiSettings.colors.red, display: reason && reason.length > 0 && !myOffer && askedOffer !== offer.id ? 'flex'  : 'none', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', borderRadius: '8px', marginBottom: '10px'}}>
              <ErrorOutline/>
              <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.red, marginBottom: '0px', marginTop: '0px', flexDirection: 'row', display: 'flex', flex: 1, padding: '0px 10px'}}}><strong style={{marginRight: '10px'}}>Échec de la validation: </strong> {reason}</p>
            </div>
            <Container>
                <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginBottom: '20px', marginTop: '10px', flexDirection: 'row', display: 'flex'}}}><BarIcon>.</BarIcon>{offer.title}<span style={{...uiSettings.typos.paragraphDesktop, ...{borderLeft: '1px solid ' +  uiSettings.colors.lightGray, paddingLeft: '10px', marginLeft: '10px', color: uiSettings.colors.secondary}}}>Expired in <span style={{color: uiSettings.colors.secondary}}>{ offer.expDate ? offer.expDate.split('T')[0] : '' }</span></span></p>
                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary,}}}>{offer.description}</p>
                {/* <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '10px'}}}>Modules</p> */}
                {/* <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '10px'}}}>Expired in <span style={{color: uiSettings.colors.secondary}}>11/06/2024</span></p> */}
                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '5px'}}}>Year <span style={{color: uiSettings.colors.secondary}}>{offer.year}</span></p>
                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '5px'}}}>Price <span style={{color: uiSettings.colors.secondary}}>{offer.price}.00 DZD</span></p>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px'}}>
                   {
                    modules.map((module, index)=> {
                        return(
                            <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, marginLeft: '0px', padding: '5px 10px', borderRadius: '8px', backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.blue, marginRight: '10px', marginBottom: '10px'}}}>{module.module}</p>
                        )
                    })
                   }
                </div>
                {
                    (myOffer.offerId) ?
                    
                    <div style={{width: 'calc(100% - 20px)', padding: '10px', backgroundColor: uiSettings.colors.lightYellow, color: uiSettings.colors.orange, display: offer.id === myOffer.offerId ? 'flex'  : 'none', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', borderRadius: '8px'}}>
                        <CheckCircleOutlineOutlined/>
                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.orange, marginBottom: '0px', marginTop: '0px', flexDirection: 'row', display: 'flex', flex: 1, padding: '0px 10px'}}}>Abonnement actif actuel.</p>
                    </div>
                    :
                    <span>
                      {
                        askedOffer && askedOffer.length > 0 ?
                        <></>
                        :
                        <>
                          <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginBottom: '20px', marginTop: '10px', flexDirection: 'row', display: 'flex'}}}>Comment commander cet abonnement ?</p>
                          <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary,}}}>Pour souscrire à l'abonnement, veuillez effectuer le paiement du montant de l'abonnement par voie postale à l'adresse indiquée. Une fois le paiement effectué, envoyez-nous le reçu et nos administrateurs activeront votre abonnement. </p>
                          <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '5px'}}}>CCP <span style={{color: uiSettings.colors.secondary}}>{payementData.ccp}</span></p>
                          <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '5px'}}}>Clé <span style={{color: uiSettings.colors.secondary}}>{payementData.key}</span></p>
                          <LoadingButton loading={loadingFile}   sx={{ textTransform: 'none' }} endIcon={<AttachFile/>} style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.bgblue, marginTop: isPhone ? '10px': '10px'}}}  onClick={() => fileInputRef.current.click()}  >Sélectionner le reçu</LoadingButton>           
                          <input
                              type="file"
                              accept=".jpeg, .jpg, .png"
                              onChange={handleFileChange}
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                          />
                          {selectedFile && selectedFile.name ?
                              <p key={0} style={{margin: '5px 5px', color: uiSettings.colors.secondary, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start'}}><AttachFile style={{marginRight: '5px'}} /> {selectedFile.name} <IconButton onClick={()=> setSelectedFile()} style={{padding: '3px', marginLeft: '5px'}}><Close style={{cursor: 'pointer', marginLeft: '0px', color: 'red', padding: '0px'}} /></IconButton>  </p>
                              :
                              ''
                          }
                        </>
                      }
                       
                    </span>
                }
            </Container>
            
            <div style={{width: '100%', display: myOffer.offerId || (askedOffer.length > 0 && offer.id != askedOffer) ? 'none' : 'flex', flexDirection: 'rows', justifyContent: 'end', }}>
                <Button   sx={{ textTransform: 'none' }} disabled={offer.id === askedOffer ? true : false} onClick={()=> demanderAbonnement()} style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, marginTop: isPhone ? '10px': '10px', width: 'max-content', marginRight: '5px'}}}  >{offer.id === askedOffer ? 'Traitement de la  demande ' + code : 'Demander'} </Button>           
            </div>
            
        </Home>
        )
    }
}


const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`

const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
    position: relative;
    align-items: center;
`

const Container = styled.div`
    margin-top: 20px;
    width: calc(100% - 42px);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    align-content: start;
    flex-wrap: wrap;
    box-shadow: 0px 2px 15px 0px #0000001A;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 16px;

`
const BarIcon = styled.div`
    width: 16px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const OfferBox = styled.div`
    width: 340px;
    min-width: calc(33% - 30px);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    margin: 5px;
`
const OfferYear = styled.div`
    width: max-content;
    padding: 5px 10px;
    border-radius: 8px;
    color: ${props => props.selected === true ? uiSettings.colors.white : uiSettings.colors.blue};
    box-shadow: ${props => props.selected === false ? '0px 2px 5px 0px #0000001A' : 'none'};
    background-color: ${props => props.selected === true ? uiSettings.colors.blue : uiSettings.colors.white};
    margin-left: 20px;
    z-index: 10;
`

const OfferSubContainer = styled.div`
    width: calc(100% - 30px);
    border-radius: 15px;
    margin-top: -20px;
    padding: 10px 15px;
    border: ${uiSettings.colors.lightGray} 1px solid;
    padding-top: 30px;
`

const OfferFooter = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
