import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import styled from 'styled-components'


const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
`


export default function Test({windowSize, isSidebareOpened}) {
  return (
    <Home>
      404 - Not Found
    </Home>
  )
}
