import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from './loading';
import {phone} from 'phone';
import { LoadingButton } from '@mui/lab';


const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export default function Phone({handleError, HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle}) {
  const navigate = useNavigate()

  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false);
  
   useEffect(() => {
    axios.post("/check_access")
        .then(function (response) {
          if(response.data.step === 'add_phone'){
            setLoading(false)
          }else if(response.data.step === 'email_verification'){
            navigate('/auth/identifier', { replace: true });
          }else if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if(response.data.step === 'completed'){
            navigate('/dashboard', { replace: true });
          }else{
            navigate('/auth/identifier', { replace: true });
          }
        })
        .catch(function (error) {
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.response)
            navigate('/auth/identifier', { replace: true });
          }else if(error.response.status === 301){
            navigate(error.response.data.path, { replace: true });
          }else if(error.response.status === 403){
            navigate(error.response.data.path, { replace: true });
          }else{
            handleError(error)
          }
        })
   }, []);

   function handleChangePhoneNumber(value){
    if(value.length < 11){
      setPhoneNumber(value)
      const test = phone('+213' + value)
      setError(false)
      if(test.isValid){
        setButtonStyle(BtnStyle)
        setDisabledBtn(false)
      }else{
        setButtonStyle(DisabledBtnStyle)
        setDisabledBtn(true)
        if(value.length > 9){
          setError(true)
        }
      }
    }
    
   }

   const [validatePhone , setvalidatePhone ] = useState();

   function handleSubmit(){
      setLoadingButton(true)
      axios.post("/add_phone", {
        phoneNumber
      })
          .then(function (response) {
            setLoadingButton(false)
            navigate('/auth/phone-number-verification', { replace: false });
          })
          .catch(function (error) {
            console.log(error.response)
            setLoadingButton(false)
            if(error.response.status === 400){
              setAlertType('error')
              setOpenAlert(true)
              setAlertMessage(error.response.data.error)
            }else{
              handleError(error)
            }
          })
   }

   if(loading){
    return(
      <Home style={{backgroundColor: 'white'}}>
        <Loading />
      </Home>
    )
   }else{
    return (
      <Home width={windowSize.width}>
        <Button variant='standard' onClick={()=> navigate('/auth/full-name')} style={{ ...paragraphStyle, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '170px': '28px'}}}>
          Back
        </Button>
        <Logo  auth={true}/>
        <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>Verify you Phone Number</h1>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>Enter your phone number to verify it </p>
        <TextField
          id=""
          placeholder={'Phone Number *'}
          value={phoneNumber}
          error={error}
          variant='standard'
          type={'number'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>handleChangePhoneNumber(e.target.value)}
        />
        <LoadingButton loading={loadingButton} loadingPosition='end' disabled={disabledBtn} onClick={handleSubmit} style={{...paragraphStyle, ...ButtonStyle, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
          Submit
        </LoadingButton>
      </Home>
    )
   }
 
}
