import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Slider, Snackbar, TextField } from '@mui/material';
import { Add, AddOutlined, AddToPhotosOutlined, CheckCircleOutlineOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, Filter, FilterList, FilterListOutlined, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, PeopleAltOutlined, QuizOutlined, RemoveOutlined, RemoveRedEyeOutlined, SearchOutlined } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import axios from 'axios';

const CustomPaper = (props) => {
    return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

function valuetext(value) {
    return `${value}°C`;
}

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Container = styled.div`
    width: calc(100% - 24px);
    padding: 12px;
    border-radius: 15px;
    background-color: white;
    min-height: 50px;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: 0px;
`

const OptionList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10px;
    padding: 5px 0px;
    border-radius: 7px !important;
    overflow: auto;
    height: 300px;
    background-color: ${uiSettings.colors.bgblue};
    padding-bottom: 10px;

`

const OptionItem = styled.div`
    width: calc(100% - 30px);
    padding: 15px !important;
    background-color: ${uiSettings.colors.bgblue};
    color: ${uiSettings.colors.secondary};
    border-bottom: 1px solid ${uiSettings.colors.bgBlue};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px !important;
`
const OptionStatus = styled.div`
    background-color: ${uiSettings.colors.yellow} !important;
    color: black !important;
    font-size: 15px !important ;
    padding: 0px 10px !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`



export default function NewQuiz() {
    const Navigate = useNavigate()
    const [selectedTypesOfquestions, setSelectedTypesOfquestions] = useState([]); // State to store selected options
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedTypesOfquestions(newValue);
    };

    const [selectedsources, setSelectedsources] = useState([]); // State to store selected options
    const handleSourceChange = (event, newValue) => {
        setSelectedsources(newValue);
    };

    const [isAllDates, setIsAllDates] = useState(true);
    const [value, setValue] = React.useState([2004, 2013]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChange1 = (event, newValue) => {
        setValueBeta(newValue);
      };

    const [numberOfQuestions, setnumberOfQuestions] = useState(120);
    function handleChangeNumberOfQuestions(type, number){
        if(type === 'new'){
            if(number >= 0 && number < 10001){
                setnumberOfQuestions( parseInt(number))
            }
        }
        else if(type === 'remove'){
            if(numberOfQuestions > 0){
                setnumberOfQuestions(numberOfQuestions - 1)
            }
        }else{
            if(numberOfQuestions < 10001){
                setnumberOfQuestions(numberOfQuestions + 1)
            }
        }
    }


    const [modules, setModules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [displayedModules, setdisplayedModules] = useState([]);
    const [cours, setCours] = useState([]);
    const [moduleId, setModuleId] = useState('');
    const [years, setYears] = useState([]);
    const [sources, setSources] = useState([]);

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');

    const handleClickAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenAlert(false);
    };

    const isMounted = useRef(true);
    
    useEffect(() => {
        return () => {
        // Cleanup function to set isMounted to false when component unmounts
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            axios.post("/admin/getBaseInfoToCreateSession")
            .then(function (response) {
                setLoading(false)
                console.log(response.data)
                if(response.data.modules && response.data.modules.length > 0){
                    setModules(response.data.modules)
                    const sortedModules = response.data.modules.sort((a, b) => a.name.localeCompare(b.name));
                    setdisplayedModules(sortedModules)
                    setCours(response.data.modules[0].cours)
                    setModuleId(response.data.modules[0].id)
                }
                setYears(response.data.years)
                setSources(response.data.sources)
            })
            .catch(function (error) {
                if(error.response && error.response.status === 403){
                    Navigate('/admin/login', {replace: 'true'})
                }else{
                    console.log(error)
                    setAlertType('error')
                    setAlertMessage('An unexpected error has occured')
                    setOpenAlert(true)
                }
            })
        }
    }, []);

    const [loading , setLoading] = useState(true);

    const [moduleSearch, setModuleSearch] = useState('');
    function searchModule(item){
        setModuleSearch(item)
        if(item === ''){
            const sortedModules = [...modules].sort((a, b) => a.name.localeCompare(b.name));
            setdisplayedModules([...sortedModules])
            setCours(modules[0].cours);
            setModuleId(modules[0].id);

        }else{
            var testModules = []
            let i = 0 
            for (let index = 0; index < modules.length; index++) {
                const module = modules[index];
                var lowercaseModule = module.name.toLocaleLowerCase()
                var lowercaseKeyword = item.toLocaleLowerCase()
                if(lowercaseModule.includes(lowercaseKeyword)){
                    testModules.push(module)
                    i++
                }
            }
            if(i === 0) {
                setdisplayedModules([...[]]);
                setCours([...[]]);
                setModuleId('');
            }else{
                const sortedModules = [...testModules].sort((a, b) => a.name.localeCompare(b.name));
                setdisplayedModules(sortedModules)
                setCours([...testModules[0].cours]);
                setModuleId(testModules[0].id);
            }
            
        }
        
    }

    function handleSelectAllModules(action){
        const testModules = modules
        var select = false
        if(action === 'select'){
            select = true
        }
        for (let index = 0; index < testModules.length; index++) {
            const module = testModules[index];
            var lowercaseModule = module.name.toLocaleLowerCase()
            var lowercaseKeyword = moduleSearch.toLocaleLowerCase()
            if(lowercaseModule.includes(lowercaseKeyword)){
                for (let j = 0; j < module.cours.length; j++) {
                    const cour = module.cours[j];
                    cour.selected = select
                }
            }
            
        }
        setModules([...testModules])
    }
    const [moduleName, setModuleName] = useState('');
    const [courSearch, setCourSearch] = useState('');

    function handlecour(item, name){
        var TestModules = displayedModules
        for (let i = 0; i < TestModules.length; i++) {
            if(TestModules[i].id === item ){
                if(name === 'select'){
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                            var lowercaseCour = TestModules[i].cours[j].name.toLocaleLowerCase()
                            var lowercaseKeyword = courSearch.toLocaleLowerCase()
                            if(lowercaseCour.includes(lowercaseKeyword)){
                            // if(TestModules[i].cours[j].name.includes(courSearch)){
                                TestModules[i].cours[j].selected = true
                                const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));
                                setdisplayedModules([...TestModules])
                                setModules([...TestModules])
                                setCours(TestModules[i].cours)
                                setModuleId(TestModules[i].id) 
                            }
                    }
                }else if(name === 'remove'){
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                        var lowercaseCour1 = TestModules[i].cours[j].name.toLocaleLowerCase()
                        var lowercaseKeyword1 = courSearch.toLocaleLowerCase()
                        if(lowercaseCour1.includes(lowercaseKeyword1)){
                        // if(TestModules[i].cours[j].name.includes(courSearch)){
                            TestModules[i].cours[j].selected = false
                            setModules([...TestModules])
                            const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));

                            setdisplayedModules([...TestModules])
                            setCours(TestModules[i].cours)
                            setModuleId(TestModules[i].id)
                        }
                    }
                }else{
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                        if(TestModules[i].cours[j].name === name){
                            console.log('name: ', TestModules[i].cours[j].name)
                            console.log('before: ', TestModules[i].cours[j].selected)
                            TestModules[i].cours[j].selected = !TestModules[i].cours[j].selected
                            const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));


                            setdisplayedModules([...sortedModules])
                            setModules([...TestModules])
                            setCours(TestModules[i].cours)
                            setModuleId(TestModules[i].id)
                            console.log('after: ', TestModules[i].cours[j].selected)
                        }
                    }
                }
                
            }
        }
       
    }

    const [openFilter, setOpenFilter] = useState(true);
    const [openSessionTitle, setOpenSessionTitle] = useState(false);

const typesOfQuestions = [ 'QCM', "QCS", 'Cas clinique']


    function handleChangeNumberOfQuestions(type, number){
        if(type === 'new'){
            if(number >= 0 && number < 10001){
                setnumberOfQuestions( parseInt(number))
            }
        }
        else if(type === 'remove'){
            if(numberOfQuestions > 0){
                setnumberOfQuestions(numberOfQuestions - 1)
            }
        }else{
            if(numberOfQuestions < 10001){
                setnumberOfQuestions(numberOfQuestions + 1)
            }
        }

    }

    const [valueBeta, setValueBeta] = React.useState([2004, 2013]);


    const handleSliderChangeCommitted = (event, newValue) => {
        // Your logic here, e.g., updating the state
        console.log('done: ', newValue)
        setValue(newValue)
        handleChange(event, newValue);
    }

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    function createSession(){
        axios.post("/admin/CreateSession", {
            title: title,
            cours: modules,
            description: description,   
            types: selectedTypesOfquestions,
            sources: selectedsources,
            quantity: numberOfQuestions, 
            range: value,
            allDates: isAllDates,
            filter: openFilter
        })
        .then(function (response) {
            Navigate('/admin/Exams', { replace: false });
        })
        .catch(function (error) {

            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else if(error.response.status === 400 && error.response.data.response === 'invalid_session_params'){
                if(error.response.data.errors[0]){
                    setAlertMessage(error.response.data.errors[0].message)
                    setAlertType('error')
                    setOpenAlert(true)
                    setTimeout(function() {
                        setOpenAlert(false)
                    }, 3000);
                }
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }               
        })
    }

    const [loadingNumber, setLoadingNumber] = useState(false);
    const [questionsNumber, setQuestionsNumber] = useState(0);

    function getQuestionsNumber(){
        setLoadingNumber(true)
        axios.post("/admin/getQuestionsNumber", {
            cours: modules,
            types: selectedTypesOfquestions,
            sources: selectedsources,
            range: value,
            allDates: isAllDates,
            filter: openFilter
        })
        .then(function (response) {
            setLoadingNumber(false)
            setQuestionsNumber(response.data.number)
            console.log(response.data.number)
        })
        .catch(function (error) {
            setLoadingNumber(false)
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        })
    }

    useEffect(() => {
        getQuestionsNumber()
    }, [modules, selectedTypesOfquestions, selectedsources, value, isAllDates]);



    if(loading){
        return(
          <Home style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'none', flexDirection: 'column', background: 'none'}}>
                <CircularProgress  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px'}}}/>
                <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Loading..</p>
                <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                        {AlertMessage}
                    </Alert>
                </Snackbar>
          </Home>
        )
    }else{
        return (
          <Home>
              <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, }}}><span style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/Quizzes')}>Quizzes</span> {">"} Add a quiz</p>
              <Button onClick={()=> createSession()}   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '20px', marginBottom: '10px'}}}>Create a quiz</Button>
              <Container>
                    <Form style={{width: '60%'}}>
                      {/* title ************ */}
                      <FormTitle style={{marginTop: '0px'}}>
                          <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px'}}}>Title</p>
                      </FormTitle>
                      <TextField
                          id=""
                          placeholder={'Enter a title, like “Dermatologie - Cours : Psoriasis + Alopécies”'}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          variant='outlined'
                          type={'text'}
                          style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                          InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}}
                          }}
                      />
                  </Form>
      
                  {/* description ******* */}
      
                  <Form style={{width: '60%'}}>
                      <FormTitle>
                          <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>DESCRIPTION (optional)</p>
                      </FormTitle>
                      <TextField
                          id=""
                          placeholder={'Add a description...'}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          variant='outlined'
                          type={'text'}
                          multiline
                          rows={3}
                          style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px' }}
                          InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{padding: "16px"}}
                          }}
                      />
                  </Form>


                {/* select cours */} 
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                      <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} >Quiz  in which module?</p>
                      {/* <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white, borderRadius: '7px'}}} startIcon={<FilterList />} endIcon={<KeyboardArrowDown/>}  >Select season</Button> */}
                </div>
                
                <Container className="selectQuestions">
                    <SubContainer isPhone={false}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>MODULES</p>
                            </FormTitle>
        
                            <TextField
                                id=""
                                value={moduleSearch}
                                placeholder={'Search'}
                                onChange={(e) => searchModule(e.target.value)}
                                variant='outlined'
                                type={'text'}
                                style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                InputProps={{
                                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                        endAdornment: (
                                            <InputAdornment style={{marginRight: '10px'}} position="end">
                                                { <SearchOutlined />}
                                            </InputAdornment>
                                        ),
                                }}
                            />
        
                            <OptionList className="selectQuestions">
                                {
                                    [''].map((item) => {
                                        var message = 'Clear all'
                                        var action = 'remove'
                                        for (let index = 0; index < displayedModules.length; index++) {
                                            const module = displayedModules[index];
                                            for (let index = 0; index < module.cours.length; index++) {
                                                const cour = module.cours[index];
                                                if(cour.selected === false){
                                                    message = "Select all"
                                                    action = 'select'
                                                }
                                                return (
                                                    <OptionItem onClick={() => handleSelectAllModules(action)} style={{cursor: 'pointer'}}>{message}</OptionItem>
                                                )
                                            }
                                        }
                                    })
                                }
                                {
                                    displayedModules.map((myModule) => { 
                                        var count = 0
                                        for (let index = 0; index < myModule.cours.length; index++) {
                                            const cour = myModule.cours[index];
                                            if(cour.selected === true) {
                                                count = count + 1
                                            }
                                        }
                                        
                                        return(
                                            <OptionItem style={{backgroundColor: myModule.name === moduleName ? uiSettings.colors.bgblue4 : 'unset', cursor: 'pointer' }} onClick={()=> {setCours(myModule.cours); setModuleId(myModule.id); setModuleName(myModule.name)}} >{myModule.name}<OptionStatus style={{display: count > 0 ? 'flex' : 'none'}}>{count}</OptionStatus> </OptionItem> 
                                        ) 
                                        
                                    })
                                }
                            </OptionList>
        
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%', marginTop: false ? '30px' : '20px'}}>
                                <FormTitle>
                                    <BarIcon>.</BarIcon>
                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>COURSES</p>
                                </FormTitle>
        
                                <TextField
                                    id=""
                                    placeholder={'Search'}
                                    value={courSearch}
                                    onChange={(e)=>{setCourSearch(e.target.value)}}
                                    variant='outlined'
                                    type={'text'}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                            endAdornment: (
                                                <InputAdornment style={{marginRight: '10px'}} position="end">
                                                    { <SearchOutlined />}
                                                </InputAdornment>
                                            ),
                                    }}
                                />
        
                                <OptionList className="selectQuestions">
                                    
                                        {
                                            [''].map((item)=>{
                                                if(displayedModules.length > 0 && cours.length>0){
                                                    var message = 'Clear all'
                                                    var action = 'remove'
                                                    var found = false
                                                    for (let index = 0; index < cours.length; index++) {
                                                        const cour = cours[index];
                                                        var lowercaseCour = cour.name.toLocaleLowerCase()
                                                        var lowercaseKeyword = courSearch.toLocaleLowerCase()
                                                        if(lowercaseCour.includes(lowercaseKeyword)){
                                                        // if(cour.name.includes(courSearch)){
                                                            found = true
                                                            if(cour.selected === false){
                                                                message = 'Select all'
                                                                action = 'select'
                                                            }} 
                                                        }
                                                    if(found === true){
                                                       return (
                                                            <OptionItem onClick={() => handlecour(moduleId, action)} style={{cursor: 'pointer'}}>{message}</OptionItem>
                                                        )  
                                                    }
                                                   
                                                }
                                                
                                                
                                            })
                                        }
                                        {

                                            cours.map((cour)=>{
                                                var lowercaseCour = cour.name.toLocaleLowerCase()
                                                var lowercaseKeyword = courSearch.toLocaleLowerCase()
                                                if(lowercaseCour.includes(lowercaseKeyword)){
                                                // if(cour.name.includes(courSearch)){
                                                   return(
                                                        <OptionItem onClick={() => handlecour(moduleId, cour.name)} style={{backgroundColor: cour.selected? uiSettings.colors.bgblue4 :'inherit', cursor: 'pointer'}}>{cour.name} <CheckCircleOutlineOutlined style={{display: cour.selected ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                                    )  
                                                }
                                            })
                                        }
                                </OptionList>
        
        
                        </Form>
                    </SubContainer>
                </Container>


                 
      
                 
      
                  {/* modules ******** */}
                  <SubContainer style={{display: 'none'}} isPhone={false} >
                      <Form style={{width:'47%'}}>
                          <FormTitle>
                              <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>MODULES</p>
                          </FormTitle>
      
                          <TextField
                              id=""
                              //value={moduleSearch}
                              placeholder={'Recherche'}
                              //onChange={(e) => searchModule(e.target.value)}
                              variant='outlined'
                              type={'text'}
                              style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                              InputProps={{
                                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                      endAdornment: (
                                          <InputAdornment style={{marginRight: '10px'}} position="end">
                                              { <SearchOutlined />}
                                          </InputAdornment>
                                      ),
                              }}
                          />
      
                          <OptionList className="selectQuestions">
                              {/*
                                  [''].map((item) => {
                                      var message = 'Effacer tout'
                                      var action = 'remove'
                                      for (let index = 0; index < displayedModules.length; index++) {
                                          const module = displayedModules[index];
                                          for (let index = 0; index < module.cours.length; index++) {
                                              const cour = module.cours[index];
                                              if(cour.selected === false){
                                                  message = "Sélectionner tout"
                                                  action = 'select'
                                              }
                                              return (
                                                  <OptionItem onClick={() => handleSelectAllModules(action)}>{message}</OptionItem>
                                              )
                                          }
                                      }
                                  })
                              */}
                              {/*
                                  displayedModules.map((myModule) => { 
                                      var count = 0
                                      for (let index = 0; index < myModule.cours.length; index++) {
                                          const cour = myModule.cours[index];
                                          if(cour.selected === true) {
                                              count = count + 1
                                          }
                                      }
                                      
                                      return(
                                          <OptionItem style={{backgroundColor: myModule.name === moduleName ? uiSettings.colors.bgblue4 : 'unset' }} onClick={()=> {setCours(myModule.cours); setModuleId(myModule.id); setModuleName(myModule.name)}} >{myModule.name}<OptionStatus style={{display: count > 0 ? 'flex' : 'none'}}>{count}</OptionStatus> </OptionItem> 
                                      ) 
                                      
                                  })
                              */}
                              <OptionItem>Select all</OptionItem>
                              <OptionItem style={{backgroundColor: 'unset' }} >Analogie<OptionStatus style={{display: 0 > 0 ? 'flex' : 'none'}}>{0}</OptionStatus> </OptionItem> 
                              <OptionItem style={{backgroundColor: 'unset' }} >Analogie<OptionStatus style={{display: 0 > 0 ? 'flex' : 'none'}}>{0}</OptionStatus> </OptionItem> 
                              <OptionItem style={{backgroundColor: 'unset' }} >Analogie<OptionStatus style={{display: 2 > 0 ? 'flex' : 'none'}}>{2}</OptionStatus> </OptionItem> 
                              <OptionItem style={{backgroundColor: 'unset' }} >Analogie<OptionStatus style={{display: 0 > 0 ? 'flex' : 'none'}}>{0}</OptionStatus> </OptionItem> 
      
                          </OptionList>
      
      
                      </Form>
      
                      <Form style={{width: '47%', marginTop: '20px'}}>
                              <FormTitle>
                                  <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor}}}>COURS</p>
                              </FormTitle>
      
                              <TextField
                                  id=""
                                  placeholder={'Recherche'}
                                  //value={courSearch}
                                  //onChange={(e)=>{setCourSearch(e.target.value)}}
                                  variant='outlined'
                                  type={'text'}
                                  style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                  InputProps={{
                                          style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                          endAdornment: (
                                              <InputAdornment style={{marginRight: '10px'}} position="end">
                                                  { <SearchOutlined />}
                                              </InputAdornment>
                                          ),
                                  }}
                              />
      
                              <OptionList className="selectQuestions">
                                  
                                      {/*
                                          [''].map((item)=>{
                                              if(displayedModules.length > 0 && cours.length>0){
                                                  var message = 'Effacer tout'
                                                  var action = 'remove'
                                                  var found = false
                                                  for (let index = 0; index < cours.length; index++) {
                                                      const cour = cours[index];
                                                      if(cour.name.includes(courSearch)){
                                                          found = true
                                                          if(cour.selected === false){
                                                              message = 'Sélectionner tout'
                                                              action = 'select'
                                                          }} 
                                                      }
                                                  if(found === true){
                                                      return (
                                                          <OptionItem onClick={() => handlecour(moduleId, action)} >{message}</OptionItem>
                                                      )  
                                                  }
                                                  
                                              }
                                              
                                              
                                          })
                                      */}
                                      {/*
      
                                          cours.map((cour)=>{
                                              if(cour.name.includes(courSearch)){
                                                  return(
                                                      <OptionItem onClick={() => handlecour(moduleId, cour.name)} style={{backgroundColor: cour.selected? uiSettings.colors.bgblue4 :'inherit'}}>{cour.name} <CheckCircleOutlineOutlined style={{display: cour.selected ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                                  )  
                                              }
                                          })
                                      */}
                                      <OptionItem  >{'Select all'}</OptionItem>
                                      <OptionItem  style={{backgroundColor:  uiSettings.colors.bgblue4 }}>Darmatologie <CheckCircleOutlineOutlined style={{display: true ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: false ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: true ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: false ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: false ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: false ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                      <OptionItem  style={{backgroundColor: 'inherit'}}>Darmatologie <CheckCircleOutlineOutlined style={{display: false ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
      
      
                              </OptionList>
      
      
                      </Form>
                  </SubContainer>
      
      
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                      <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} >Filter the quiz’s Questions</p>
                  </div>

                  {/* questions filter ********* */}
                <Container>
                    {/* <Header isPhone={false} style={{alignItems: false? 'start': 'center'}}>
                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}}>Comment souhaitez-vous les questions de votre session ?</p>
                        <Button onClick={()=> setOpenFilter(!openFilter)}   sx={{ textTransform: 'none' }} startIcon={<FilterListOutlined/>} endIcon={<KeyboardArrowDown/>}  style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, marginTop: false ? '10px' : '0px'}}} >Sélectionnez un filtre</Button>
                    </Header> */}
        
                    <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: openFilter ? 'flex' : 'none'}}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Types of question</p>
                            </FormTitle>
        
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={typesOfQuestions}
                                value={selectedTypesOfquestions}
                                onChange={handleAutocompleteChange}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) => option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    />
                                )}
                            />
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Sources</p>
                            </FormTitle>
        
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={sources}
                                value={selectedsources}
                                onChange={handleSourceChange}
                                style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) =>  option.source} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                    />
                                )}
                            />
        
                        </Form>
        
        
                    </SubContainer>
        
        
                    <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '20px', display: openFilter ? 'flex' : 'none', transition: '1s'}}>
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Year</p>
                            </FormTitle>
        
                           <Box style={{marginTop: "30px"}}>
                                <Slider
                                    disabled={isAllDates}
                                    min={years[years.length - 1] ? parseInt(years[0].year) : 2013 }
                                    step={1}
                                    max={parseInt(years[years.length - 1] ? years[years.length - 1].year : 2000) }
                                    getAriaLabel={() => 'Temperature range'}
                                    value={valueBeta}
                                    onChange={handleChange1}
                                    onChangeCommitted={handleSliderChangeCommitted}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                />
                                <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>All years</p>} />
                            </Box>
        
                        </Form>
        
                        <Form style={{width: false ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Number of questions</p>
                            </FormTitle>
        
                            <div style={{...uiSettings.typos.link_mid, ...{width: "calc(100% -32px)", backgroundColor: uiSettings.colors.bgblue, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 16px', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)'}}}>
                                <IconButton  style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('remove')}><RemoveOutlined/></IconButton>
                                {/* <p style={{...styles.link_mid, ...{color: uiSettings.colors.secondary}}}>{numberOfQuestions}</p> */}
                                <TextField
                                    className="numberTextField"
                                    id=""
                                    placeholder={'0'}
                                    value={numberOfQuestions}
                                    onChange={(e) => handleChangeNumberOfQuestions('new', e.target.value)}
                                    variant='outlined'
                                    type={'number'}
                                    max={5}
                                    style={{ backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', textAlign: 'center !important', border: 'none' }}
                                    InputProps={{
                                        maxLength: 3,
                                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px', textAlign: 'center', border: 'none'}},
                                    }}
                                />
                                <IconButton  style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('add')}><AddOutlined/></IconButton>
                            </div>
                            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px'}}}>Available questions: <span style={{color: uiSettings.colors.blue}} >{loadingNumber? 'calcule..' : questionsNumber}</span></p>
        
                            
        
                        </Form>
        
        
                    </SubContainer>
                   
                </Container>
      
      
                  <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '0px', display: true ? 'none' : 'none'}}>
                              <Form style={{width: false ? '100%' : '47%'}}>
                                  <FormTitle>
                                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Types de question</p>
                                  </FormTitle>
              
                                  <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={['QCM', 'QCS', 'CasClinique']}
                                      value={selectedTypesOfquestions}
                                      onChange={handleAutocompleteChange}
                                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                      InputProps={{
                                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                      }}
                                      PaperComponent={CustomPaper}
                                      getOptionLabel={(option) => option} // Specify the label property
                                      renderInput={(params) => (
                                          <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder={"Sélectionner"}
                                          />
                                      )}
                                  />
              
                              </Form>
              
                              <Form style={{width: false ? '100%' : '47%'}}>
                                  <FormTitle>
                                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Sources</p>
                                  </FormTitle>
              
                                  <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={['Alger', 'Blida', 'Oran', 'Annaba']}
                                      value={selectedsources}
                                      onChange={handleSourceChange}
                                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                      InputProps={{
                                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                                      }}
                                      PaperComponent={CustomPaper}
                                      getOptionLabel={(option) =>  option.source} // Specify the label property
                                      renderInput={(params) => (
                                          <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder={"Sélectionner"}
                                          />
                                      )}
                                  />
              
                              </Form>
              
              
                  </SubContainer>
      
      
                  <SubContainer className="animation1"  isPhone={false} style={{ marginTop: '20px', display: true ? 'none' : 'none', transition: '1s'}}>
                              <Form style={{width: false ? '100%' : '47%'}}>
                                  <FormTitle>
                                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Année</p>
                                  </FormTitle>
              
                                 <Box style={{marginTop: "30px"}}>
                                      <Slider
                                          disabled={isAllDates}
                                          min={years[years.length - 1] ? parseInt(years[0]) :2007 }
                                          step={1}
                                          max={parseInt(years[years.length - 1] ? years[years.length - 1] : 2002) }
                                          getAriaLabel={() => 'Temperature range'}
                                          value={value}
                                          onChange={handleChange}
                                          valueLabelDisplay="auto"
                                          getAriaValueText={valuetext}
                                      />
                                      <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                                  </Box>
              
                              </Form>
              
                              <Form style={{width: false ? '100%' : '47%'}}>
                                  <FormTitle>
                                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Nombre de questions</p>
                                  </FormTitle>
              
                                  <div style={{...uiSettings.typos.link_mid, ...{width: "calc(100% -32px)", backgroundColor: uiSettings.colors.bgblue, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 16px', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)'}}}>
                                      <IconButton  style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('remove')}><RemoveOutlined/></IconButton>
                                      {/* <p style={{...styles.link_mid, ...{color: uiSettings.colors.secondary}}}>{numberOfQuestions}</p> */}
                                      <TextField
                                          className="numberTextField"
                                          id=""
                                          placeholder={'0'}
                                          value={numberOfQuestions}
                                          onChange={(e) => handleChangeNumberOfQuestions('new', e.target.value)}
                                          variant='outlined'
                                          type={'number'}
                                          max={5}
                                          style={{ backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', textAlign: 'center !important', border: 'none' }}
                                          InputProps={{
                                              maxLength: 3,
                                              style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px', textAlign: 'center', border: 'none'}},
                                          }}
                                      />
                                      <IconButton  style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('add')}><AddOutlined/></IconButton>
                                  </div>
                                  <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px'}}}>Questions disponibles: <span style={{color: uiSettings.colors.blue}} >10</span></p>
              
                                  
              
                              </Form>
              
              
                  </SubContainer>
      
              </Container>
              <Button onClick={()=> createSession()} sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '10px', marginBottom: '20px'}}}>Create a quiz</Button>
              <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                  <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                      {AlertMessage}
                  </Alert>
              </Snackbar>
          </Home>
        )
    }

    

}

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'} ;
    justify-content: space-between;
    align-items: center;
`
