import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import "./styles.css";
import styled from 'styled-components'
import uiSettings from "../../../assets/ui-settings";
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Paper, TextField, colors } from "@mui/material";
import {  makeStyles } from "@mui/styles";
import { Switch } from 'antd';
import { AddOutlined, CheckBox, CheckCircleOutlineOutlined, CheckOutlined, ErrorOutline, Filter1Outlined, FilterListOutlined, KeyboardArrowDown, RemoveOutlined, Search, SearchOutlined } from "@mui/icons-material";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";



export default function Abonnements({styles, isPhone, isSidebareOpened, handleError, setAlertMessage, setOpenAlert, setAlertType}) {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    const [displayedOffers, setDisplayedOffers] = useState([]);
    const [myOffer, setMyOffer] = useState('');
    const [askedOffer, setAskedOffer] = useState('');
    useEffect(() => {
        axios.post("/student/offers")
            .then(function (response) {
              if(response.data.step === 'add_name'){
                navigate('/auth/full-name', { replace: true });
              }else if (response.data.step === 'add_phone'){
                navigate('/auth/phone-number', { replace: true });
              }else{
                setIsLoading(false)
                console.log(response.data)
                setOffers(response.data.offers)
                setDisplayedOffers(response.data.offers)
                setKeyword('')
                if(response.data.myOffer){
                    setMyOffer(response.data.myOffer)
                }
                if(response.data.askedOffer){
                    setAskedOffer(response.data.askedOffer.offerId)
                }
              }
            })
            .catch(function (error) {
              if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
                // if (error.response.data.message && error.response.data.message.length > 0){
                //     setAlertMessage(error.response.data.message)
                //     setAlertType('error')
                //     setOpenAlert(true)
                // }
                // setTimeout(() => {
                //     console.log('redirected')
                // }, 3000);
                navigate('/auth/identifier', { replace: true });
              }else{
                handleError(error)
              }
            })
      }, []);

      const [keyword, setKeyword] = useState('');

      useEffect(() => {
        var data = offers
        if(keyword === ''){
            setDisplayedOffers(offers)
        }else{
            var filteredData = []
            for (let i = 0; i < data.length; i++) {
                const offer = data[i];
                if( (offer.title && offer.title.toLowerCase().includes(keyword.toLowerCase())) || (offer.description && offer.description.toLowerCase().includes(keyword.toLowerCase())) || (offer.modules && offer.modules.toLowerCase().includes(keyword.toLowerCase()))){
                    filteredData.push(offer)
                }
            }
            setDisplayedOffers(filteredData)
        }
      }, [keyword]);

    const [changes, setChanges] = useState(0);
      function orderOffers(){
        var data = displayedOffers
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setDisplayedOffers(data)
        setChanges(changes + 1)
      }
  
    return (
    <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor, marginBottom: '20px'}}}>Découvrez <span style={{color: uiSettings.colors.blue}}> nos abonnements</span></p>
        <div style={{width: 'calc(100% - 20px)', padding: '10px', borderRadius: '7px', backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', border: '1px solid ' + uiSettings.colors.blue}}>
            <ErrorOutline style={{color: uiSettings.colors.headingColor, fontSize: '25px', marginRight: '10px'}}/>
            <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>All the abonnement offers are free in the beta version 🥳🔥</p>

        </div>
        <Header isPhone={isPhone}>
            <NavbarSearch style={{width: '534px', maxWidth: '100%'}}>
                <TextField
                    id=""
                    placeholder={'Search'}
                    variant='outlined'
                    type={'text'}
                    value={keyword}
                    onChange={(e)=> setKeyword(e.target.value)}
                    style={{width: "100%", backgroundColor: uiSettings.colors.beige1, borderRadius: '8px 0px 0px 8px', }}
                    InputProps={{
                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px', paddingRight: '50px'}, ...{}}
                    }}
                />
                <Search style={{padding: '9px 9px', fontSize: '26px', backgroundColor: 'transparent', color:'#0000005c', borderRadius: '7px 7px 7px 7px', marginLeft: '-52px', zIndex: '10', }}/>
            </NavbarSearch>            
            <Button onClick={()=>orderOffers()}  sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowDown/>} style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.bgblue, marginTop: isPhone ? '10px': '0px'}}}  >Le plus récent</Button>
        </Header>
        <Container>
            {
                isLoading ?
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '100px 0px 0px 0px'}}>
                    <CircularProgress/>
                </div>
                :
                displayedOffers.map((offer, index)=>{
                    return(
                        <OfferBox key={index}>
                            <OfferYear selected={offer.id === myOffer.offerId} asked={offer.id === askedOffer}>
                                <p style={{...uiSettings.typos.navText, ...{color: offer.id === myOffer.offerId || offer.id === askedOffer ? uiSettings.colors.white : uiSettings.colors.headingColor}}}>{offer.year}</p>
                            </OfferYear>
                            <OfferSubContainer selected={offer.id === myOffer.offerId}  asked={offer.id === askedOffer}>
                                <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginBottom: '10px', marginTop: '10px'}}}>{offer.title}</p>
                                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>{offer.description && offer.description.length > 85 ? offer.description.slice(0,84) + '..' : offer.description}</p>
                                <OfferFooter>
                                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>{offer.price}.00 DZD</p>
                                    <Button   sx={{ textTransform: 'none' }} onClick={()=> navigate('/dashboard/Abonnements/'+ offer.id)}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow}}}  >{offer.id === askedOffer ? 'En Cours' : (offer.id === myOffer.offerId ? 'Consulter' : 'Consulter')} </Button>
                                </OfferFooter>
                            </OfferSubContainer>
                        </OfferBox>
                    )
                })
            }

            
        </Container>
        
    </Home>
  )
}


const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`

const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
    position: relative;
    align-items: center;
`

const Container = styled.div`
    margin-top: 20px;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    align-content: start;
    flex-wrap: wrap;
`

const OfferBox = styled.div`
    width: 340px;
    min-width: calc(33% - 30px);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    margin: 5px;
`
const OfferYear = styled.div`
    width: max-content;
    padding: 5px 10px;
    border-radius: 8px;
    color: ${props => props.selected === true || props.asked === true ? uiSettings.colors.white : uiSettings.colors.blue};
    box-shadow: ${props => props.selected === false ? '0px 2px 5px 0px #0000001A' : 'none'};
    background-color: ${props => props.selected === true ? uiSettings.colors.blue : (props.asked === true ? uiSettings.colors.yellow : uiSettings.colors.white)};
    margin-left: 20px;
    z-index: 10;
`

const OfferSubContainer = styled.div`
    width: calc(100% - 30px);
    border-radius: 15px;
    margin-top: -20px;
    padding: 10px 15px;
    border: ${props => props.selected === true ? uiSettings.colors.blue : (props.asked === true ? uiSettings.colors.yellow : uiSettings.colors.lightGray)} 2px solid;
    padding-top: 30px;
`

const OfferFooter = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
