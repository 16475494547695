import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import "./styles.css";
import styled from 'styled-components'
import uiSettings from "../../../assets/ui-settings";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { BorderColorOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, FavoriteOutlined, KeyboardArrowDown, KeyboardArrowRight, PlayArrowOutlined, PlayCircleOutlineOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Paper } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Slide from '@mui/material/Slide';
import { LoadingButton } from "@mui/lab";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  height: 100%;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`

const Progress = styled.div`
    height: 16px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background-color: ${uiSettings.colors.bgBlue};
    z-index: 0;
    position: relative;
`

const ProgressGreen = styled.div`
    height: 16px;
    width: ${props => props.percent ? props.percent + '%' : '0%'} ;
    border-radius: 16px;
    overflow: hidden;
    background-color: ${uiSettings.colors.success};
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
`

const ProgressRed = styled.div`
    height: 16px;
    width: ${props => props.percent ? props.percent + '%' : '0%'} ;
    border-radius: 16px;
    overflow: hidden;
    background-color: ${uiSettings.colors.fail};
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
`

const AccordingDetailes = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border-top: 1px solid ;
`
const AccordingDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 7px 0px;
`


const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 0px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

  
  
  
const sessions = [];
const itemsPerPage = 4;




export default function AllSessions({styles, isPhone, isSidebareOpened, handleError, setAlertMessage, setOpenAlert, setAlertType}) {

  const [openEditSessionModal, setOpenEditSessionModal] = React.useState(false);
  const handleClickOpenEditSessionModal = () => {
    setOpenEditSessionModal(true);
  };
  const handleCloseEditSessionModal = () => {
    setLoadingEdit(false)
    setOpenEditSessionModal(false);
  };


  const [openDeleteSessionModal, setOpenDeleteSessionModal] = React.useState(false);
  const handleClickOpenDeleteSessionModal = () => {
    setOpenDeleteSessionModal(true);
  };
  const handleCloseDeleteSessionModal = () => {
    setOpenDeleteSessionModal(false);
  };

  const [displayedSessions, setDisplayedSessions] = useState([]);

  const columns = [
    { 
      field: 'title', 
      headerName: (<div onClick={(e) => e.stopPropagation()}  onMouseOver={(e) => e.stopPropagation()}   style={{marginLeft: '50px', textAlign: 'start'}}>Session</div> ),
      //headerName: 'Session',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span style={{color: uiSettings.colors.secondary, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap '}}>{params.row.title}</span> 
          </>
        );
      },
    },
    // { 
    //     field: 'date', 
    //     headerName: (<div onClick={(e) => e.stopPropagation()} onMouseOver={(e) => e.stopPropagation()}  style={{width: '180px', textAlign: 'center'}}>Date</div> ),
    //     width: 200,
    //     renderCell: (params) => {
    //       var mydate = params.row.date
    //       var date = mydate.split('T')[0]
    //       var time = (parseInt(mydate.split('T')[1].split(':')[0])) + ':' + mydate.split('T')[1].split(':')[1]
          
    //       return (
    //         <>
    //           <span style={{color: uiSettings.colors.secondary, width: '220px', textAlign: 'center'}}>{date} {time} </span> 
    //         </>
    //       );
    //     },
    //   },
      { 
        field: 'Type', 
        headerName: (<div onClick={(e) => e.stopPropagation()}  onMouseOver={(e) => e.stopPropagation()}  style={{width: '180px', textAlign: 'center', }}>Type</div> ), 
        width: 250,
        renderCell: (params) => {
          if(params.row.type === 'exam'){
            return(
              <div style={{width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'center'}}>
                <Tag style={{display: 'unset', color: uiSettings.colors.white, backgroundColor: uiSettings.colors.blue, fontSize: '15px'}}>Examen</Tag> 
              </div>
            )
          }else{
            return (
              <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '240px',}}>
                <Tag style={{display: params.row.qcm ? 'unset' : 'none', color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, fontSize: '15px'}}>QCM</Tag> 
                <Tag style={{display: params.row.qcs ? 'unset' : 'none', color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, fontSize: '15px'}}>QCS</Tag> 
                <Tag style={{display: params.row.casClinique ? 'unset' : 'none', color: uiSettings.colors.blue, backgroundColor: uiSettings.colors.bgBlue, fontSize: '15px'}}>Cas clinique</Tag> 
              </div>
            );
          }
        },
      },
      { 
        field: 'Progress', 
        headerName: (<div onClick={(e) => e.stopPropagation()}  onMouseOver={(e) => e.stopPropagation()}   style={{width: '250px', textAlign: 'center'}}>Progress</div> ), 
        width: 250,
        renderCell: (params) => {
          return (
            <>
              <Progress>
                {/* success */}
                <ProgressGreen percent={params.row.correct}></ProgressGreen>
                {/* success + failed */}
                <ProgressRed percent={params.row.correct + params.row.nonCorrect} ></ProgressRed>
              </Progress>
            </>
          );
        },
      },
      { 
        field: 'Actions', 
        headerName: (<div onClick={(e) => e.stopPropagation()}  onMouseOver={(e) => e.stopPropagation()}   style={{width: '220px', textAlign: 'center'}}>Actions</div> ), 
        width: 240,
        renderCell: (params) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '240px'}}>
                <IconButton onClick={()=> window.location.href = '/session/' + params.row.id} style={{color: uiSettings.colors.secondary}}><PlayCircleOutlineOutlined/></IconButton> 
                <IconButton onClick={()=> handleLike(params.row.id, !params.row.like)}  style={{color: uiSettings.colors.secondary}}><FavoriteBorderOutlined style={{display: params.row.like ? 'none' : 'block'}} /><FavoriteOutlined  style={{display: params.row.like ? 'block' : 'none', color: uiSettings.colors.blue}} /></IconButton>
                <IconButton onClick={()=>{setSelectedSession(params.row); setOpenEditSessionModal(true)}}  style={{color: uiSettings.colors.secondary}}><BorderColorOutlined/></IconButton>
                <IconButton onClick={()=> {setSelectedSession(params.row); setOpenDeleteSessionModal(true)}}  style={{color: uiSettings.colors.secondary}}><DeleteOutlineOutlined/></IconButton>
            </div>
          );
        },
      },
    
  ];

  const [sortModelByDate, setSortModelByDate] = useState([{ field: 'date', sort: 'desc' }]);
  const [sortModelByTitle, setSortModelByTitle] = useState([{ field: 'title', sort: 'asc' }]);
  const [sortByNewest, setSortByNewest] = useState(true);

  const [selectedSession, setSelectedSession] = useState({});

  const [test, setTest] = useState(0);
  function handleLike(sessionId, value){
    axios.post("/student/handleLikeSession", {sessionId, value})
        .then(function (response) {
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            var mySessions = sessions
            for (let i = 0; i < mySessions.length; i++) {
              const mySession = mySessions[i];
              if(mySession.id === sessionId){
                mySession.like = value
              }
            }
            setsessions(mySessions)
            setTest(test + 1)
            console.log('hayaaa')
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 

  const [loadingEdit, setLoadingEdit] = useState(false);
  function handleEditSession(){
    setLoadingEdit(true)
    axios.post("/student/handleEditSession", {selectedSession})
        .then(function (response) {
          setLoadingEdit(false)
          handleCloseEditSessionModal()
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            var mySessions = sessions
            for (let i = 0; i < mySessions.length; i++) {
              const mySession = mySessions[i];
              if(mySession.id === selectedSession.id){
                mySession.title = selectedSession.title
                mySession.description = selectedSession.description
              }
            }
            setsessions(mySessions)
            setTest(test + 1)
            console.log('hayaaa')
          }
        })
        .catch(function (error) {
          setLoadingEdit(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 

  const [loadingDelete, setLoadingDelete] = useState(false);
  function handleDeleteSession(){
    setLoadingDelete(true)
    axios.post("/student/handleDeleteSession", {selectedSession})
        .then(function (response) {
          setLoadingDelete(false)
          handleCloseDeleteSessionModal()
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            setsessions(response.data.sessions)
          }
        })
        .catch(function (error) {
          setLoadingDelete(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 
  


    const [page, setPage] = useState(1);
    const [sessions, setsessions] = useState([]);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

   
  
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedStudents = sessions.slice(startIndex, endIndex);

    const navigate = useNavigate()


    const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        axios.post("/student/getAllSessions")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            setIsLoading(false)
            console.log(response.data)
            setsessions(response.data.sessions)
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [filterType, setFilterType] = useState('Toutes');

    useEffect(() => {
      var data = []
      if(filterType === 'Toutes'){
        data = sessions
      }else if (filterType === 'Sessions'){
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          if(session.type === 'session'){
            data.push(session)
          }
        }
      }else if (filterType === 'Examens'){
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          if(session.type === 'exam'){
            data.push(session)
          }
        }
      }
      setDisplayedSessions(data)
    }, [sessions, filterType]);

    if(isLoading){
      return(
          <Home style={{justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)'}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
              <CircularProgress />
          </Home> 
      )
  }else{
    return (
      <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
          <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>Historique de <span style={{color: uiSettings.colors.blue}}> toutes les sessions</span></p>
          <Header>
              <Button
                onClick={handleClick}
                size="small"
                // sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowDown/>} style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.bgblue}}}  >{filterType}</Button>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      // transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => {handleClose(); setFilterType('Toutes')}}>
                  <ListItemIcon>
                    <KeyboardArrowRight fontSize="small" />
                  </ListItemIcon>
                  Toutes
                </MenuItem>
                <MenuItem onClick={()=>{handleClose(); setFilterType('Sessions')}}>
                  <ListItemIcon>
                    <KeyboardArrowRight fontSize="small" />
                  </ListItemIcon>
                  Sessions
                </MenuItem>
                <MenuItem onClick={()=>{handleClose(); setFilterType('Examens')}}>
                  <ListItemIcon>
                    <KeyboardArrowRight fontSize="small" />
                  </ListItemIcon>
                  Examens
                </MenuItem>
              </Menu>
              <Button   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow}}}  onClick={() => navigate('/dashboard/createSession')} >Créer une session</Button>
          </Header>
  
          <div style={{ height: 400, width: '100%', marginTop: '10px', display: isPhone ? 'none' : 'block' }}>
          <DataGrid
            style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, backgroundColor: uiSettings.colors.white}}}
            rows={displayedSessions}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            // sortModel={sortByNewest ? sortModelByDate : sortModelByTitle} 
            // onSortModelChange={sortByNewest ? sortModelByDate : sortModelByTitle}
  
              />
          </div> 
  
          <div style={{marginTop: '40px', display: isPhone ? 'block' : 'none', flexGrow: 1 }}>
          {sessions.map((session) => {
            var mydate = session.date
            var date = mydate.split('T')[0]
            var time = (parseInt(mydate.split('T')[1].split(':')[0])) + ':' + mydate.split('T')[1].split(':')[1]
              return (
                <Accordion key={session.id} style={{boxShadow: '0px 0px 4px 2px whitesmoke'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel" + session.id + 'a-content' } 
                      id={'panel' + session.id + 'a-header'}
                    >
                    <Typography style={{...styles.link_font, ...{color: uiSettings.colors.headingColor}}}>{session.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <AccordingDetailes>
                            <AccordingDetail>
                                <p style={{...styles.link_mid, ...{color: uiSettings.colors.headingColor, marginRight: "10px"}}} >Date</p>
                                <p style={{...styles.link_mid, ...{color: uiSettings.colors.secondary}}} >{date} {time} </p>
                            </AccordingDetail>
                            <AccordingDetail>
                                <p style={{...styles.link_mid, ...{color: uiSettings.colors.headingColor, marginRight: "10px"}}} >Type</p>
                                <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', width: '240px',}}>
                                  <Tag style={{display: session.qcm ? 'unset' : 'none', color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, fontSize: '15px'}}>QCM</Tag> 
                                  <Tag style={{display: session.qcs ? 'unset' : 'none', color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, fontSize: '15px'}}>QCS</Tag> 
                                  <Tag style={{display: session.casClinique ? 'unset' : 'none', color: uiSettings.colors.blue, backgroundColor: uiSettings.colors.bgBlue, fontSize: '15px'}}>Cas clinique</Tag> 
                                </div>
                            </AccordingDetail>
                            <AccordingDetail>
                                <p style={{...styles.link_mid, ...{color: uiSettings.colors.headingColor, marginRight: "10px"}}} >Progress</p>
                                <Progress>
                                  {/* success */}
                                  <ProgressGreen percent={session.correct}></ProgressGreen>
                                  {/* success + failed */}
                                  <ProgressRed percent={session.correct + session.nonCorrect} ></ProgressRed>
                                </Progress>
                            </AccordingDetail>
                            <AccordingDetail>
                                <p style={{...styles.link_mid, ...{color: uiSettings.colors.headingColor, marginRight: "10px"}}} >Actions</p>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', width: 'max-content'}}>
                                  <IconButton onClick={()=> window.location.href = '/session/' + session.id} style={{color: uiSettings.colors.secondary}}><PlayCircleOutlineOutlined/></IconButton> 
                                  <IconButton onClick={()=> handleLike(session.id, !session.like)}  style={{color: uiSettings.colors.secondary}}><FavoriteBorderOutlined style={{display: session.like ? 'none' : 'block'}} /><FavoriteOutlined  style={{display: session.like ? 'block' : 'none', color: uiSettings.colors.blue}} /></IconButton>
                                  <IconButton onClick={()=>{setSelectedSession(session); setOpenEditSessionModal(true)}}  style={{color: uiSettings.colors.secondary}}><BorderColorOutlined/></IconButton>
                                  <IconButton onClick={()=> {setSelectedSession(session); setOpenDeleteSessionModal(true)}}  style={{color: uiSettings.colors.secondary}}><DeleteOutlineOutlined/></IconButton>
                                </div>
                            </AccordingDetail>
                        </AccordingDetailes>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            )
          }

          
         
          
          )}
  
          
          </div>
  
          <Box style={{display: isPhone ? 'flex' : "none"}} justifyContent="center" mt={2}>
              <Pagination
              classes={{
                  root: 'custom-pagination-root', // Add your custom class here
                  ul: 'custom-pagination-ul', // Add your custom class here
                  item: 'custom-pagination-item', // Add your custom class here
                  selected: 'custom-pagination-selected', // Add your custom class here
                  outlined: 'custom-pagination-outlined', // Add your custom class here
              }}
              count={Math.ceil(sessions.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
              //color="primary"
              variant="outlined" shape="rounded" 
              />
          </Box>


          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openEditSessionModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseEditSessionModal}
            aria-describedby="alert-dialog-slide-description"
            style={{padding: '10px 20px'}}
          >
            {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                    <Form style={{width: isPhone ? '100%' : '100%'}}>
                              
                              <FormTitle>
                                  <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor}}}>Modifier <span style={{color: uiSettings.colors.blue}} >la session</span> </p>
                              </FormTitle> 

                              <FormTitle style={{marginBottom: '10px'}}>
                                  <BarIcon>.</BarIcon>
                                  <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Titre</p>
                              </FormTitle>  
                              <TextField
                                      id=""
                                      placeholder={'Session title'}
                                      value={selectedSession.title}
                                      onChange={(e) =>{
                                        var parmanentSession = selectedSession
                                        parmanentSession.title = e.target.value
                                        setSelectedSession(parmanentSession)
                                        setTest(test+1)
                                      }}
                                      variant='outlined'
                                      type={'text'}
                                      rows={1}
                                      style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', marginBottom: '10px' }}
                                      InputProps={{
                                              style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}}
                                      }}
                                  />

                                  <FormTitle style={{marginBottom: '10px'}}>
                                    <BarIcon>.</BarIcon>
                                    <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Description</p>
                                  </FormTitle> 
                                  <TextField
                                      id=""
                                      placeholder={'Session Description'}
                                      value={selectedSession.description}
                                      onChange={(e) =>{
                                        var parmanentSession = selectedSession
                                        parmanentSession.description = e.target.value
                                        setSelectedSession(parmanentSession)
                                        setTest(test+1)
                                      }}
                                      variant='outlined'
                                      multiline
                                      type={'text'}
                                      rows={5}
                                      style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px' }}
                                      InputProps={{
                                              style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{padding: "16px"}}
                                      }}
                                  />
                            
                                <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                  <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseEditSessionModal}>Annuler</LoadingButton>
                                  <LoadingButton loading={loadingEdit} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, marginLeft: '10px'}}} onClick={()=> handleEditSession()}  >Sauvegarder</LoadingButton>
                                </DialogActions>
                      </Form>
              </DialogContentText>
            </DialogContent>
          </Dialog>


          <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={openDeleteSessionModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDeleteSessionModal}
            aria-describedby="alert-dialog-slide-description"
            style={{padding: '10px 20px'}}
          >
            {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                    <Form style={{width: isPhone ? '100%' : '100%'}}>
                              
                              <FormTitle>
                                  <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor}}}>Supprimer <span style={{color: uiSettings.colors.red}} >la session</span> </p>
                              </FormTitle> 

                              <FormTitle>
                                  <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, fontWeight: 400}}}>voulez-vous vraiment supprimer cette session ?</p>
                              </FormTitle> 

                                <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                  <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseDeleteSessionModal}>Annuler</LoadingButton>
                                  <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.red, marginLeft: '10px'}}} onClick={()=> handleDeleteSession()}  >supprimer</LoadingButton>
                                </DialogActions>
                      </Form>
              </DialogContentText>
            </DialogContent>
          </Dialog>
      </Home>
    )
  }
 
}
