import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Loading from './loading';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export default function FullName({handleError, HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle}) {
  const navigate = useNavigate()

  const location = useLocation();

  // Get the search parameters from the URL
  const searchParams = new URLSearchParams(location.search);

  // Read the firstName and familyName from the search parameters
  const e_firstName = searchParams.get('firstName');
  const e_familyName = searchParams.get('familyName');

  const [firstName, setFirstName] = useState(e_firstName ? e_firstName :  '');
  const [lastName, setLastName] = useState(e_familyName ? e_familyName : '');
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isLoadingBtn, setisLoadingBtn] = useState(false);


  // handle submit 
  function handleSubmit() {
    setisLoadingBtn(true)
    axios.post("/add_name", {
      firstName, lastName
    })
        .then(function (response) {
          setisLoadingBtn(false)
          navigate('/auth/phone-number', { replace: true });
        })
        .catch(function (error) {
          console.log(error.response)
          setisLoadingBtn(false)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }


  // protected link must verified the access
  const [loading, setLoading] = useState(true);
   useEffect(() => {
    axios.post("/check_access")
        .then(function (response) {
          if(response.data.step === 'add_name' || response.data.step == 'add_phone'){
            setLoading(false)
          }else if(response.data.step === 'email_verification'){
            navigate('/auth/identifier', { replace: true });
          }else if(response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            navigate('/auth/identifier', { replace: true });
          }
        })
        .catch(function (error) {
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
            navigate('/auth/identifier', { replace: true });
          }else if(error.response.status === 301){
            navigate(error.response.data.path, { replace: true });
          }else if(error.response.status === 403){
            navigate(error.response.data.path, { replace: true });
          }else{
            handleError(error)
            
          }
        })
   }, []);

   // Button styles
   useEffect(() => {
      if(firstName.length > 0 && lastName.length > 0){
        setButtonStyle(BtnStyle)
        setDisabledBtn(false)
      }else{
        setButtonStyle(DisabledBtnStyle)
        setDisabledBtn(true)
      }
   }, [lastName, firstName]);

   if(loading){
    return(
      <Home style={{backgroundColor: 'white'}}>
        <Loading />
      </Home>
    )
   }else{
    return (
      <Home width={windowSize.width}>
        <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '100px': '0px'}}></div>
        <Logo  auth={true}/>
        <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>How Can We Call You</h1>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>Enter your  full name </p>
        <TextField
          id=""
          placeholder={'First name'}
          value={firstName}
          //onchange
          variant='standard'
          type={'text'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>setFirstName(e.target.value)}
        />
        <div style={{height:'28px'}}></div>
        <TextField
          id=""
          placeholder={'Last name'}
          value={lastName}
          //onchange
          variant='standard'
          type={'text'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>setLastName(e.target.value)}
        />
        <LoadingButton onClick={handleSubmit} loading={isLoadingBtn} loadingPosition='end' disabled={disabledBtn} style={{...paragraphStyle, ...ButtonStyle, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
          Submit
        </LoadingButton>
      </Home>
    )
   }
  
}
