import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Slide, Snackbar, Tooltip } from '@mui/material';
import { Add, AddToPhotosOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, Filter, FilterList, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, NotificationsActive, PeopleAltOutlined, QuizOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { deepOrange, lightBlue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Header = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`
const BarIcon = styled.div`
    width: 16px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`
const HeaderDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const MyButton = styled.div`
    width: min-content;
    height: min-content;
    padding: 5px 7px;
    margin-left: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${uiSettings.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 15px 0px #0000001A;
`

const QuizCart = styled.div`
    width: calc(50% - 20px);
    margin: 10px;
    min-width: ${props => props.width < 600 ? '80%' : '350px'} ;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: ${props => props.index === 1 ? 'url("/images/bg-blue-circles.svg")' : (props.index === 2 ? 'url("/images/bg-orange-circles.svg")' : (props.index === 3 ? 'url("/images/bg-yellow-circles.svg")' : (props.index === 4 ? 'url("/images/blue-bg-rectangle.svg")' :  'url("/images/yellow-bg-rectangle.svg")'  ) ) ) } ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`
const Mystyles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}

export default function Quizzes({windowSize, isSidebareOpened, isPhone}) {
    const [slidesPerView, setSlidesPerView] = useState(1);
    const [spaceBetween, setSpaceBetween] = useState(20);
    const [swiperWidth, setSwiperWidth] = useState('500px');
    const [styles, setStyles] = useState({...uiSettings.typos});

    useEffect(() => {
        // Define breakpoints and corresponding slidesPerView and spaceBetween values
        const breakpoints = {
            320: {
                slidesPerView: 1,
                spaceBetween: -50,
                width: "300px"
            },
           
            580: {
                slidesPerView: 1,
                spaceBetween: -150,
                width: "300px"
            },
            680: {
                slidesPerView: 1,
                spaceBetween: -300,
                width: "300px"
            },
            720: {
                slidesPerView: 2,
                spaceBetween: 0,
                width: "300px"
            },
            800: {
                slidesPerView: 2,
                spaceBetween: -50,
                width: "400px"
    
            },
            900: {
                slidesPerView: 2,
                spaceBetween: -200,
                width: "600px"
    
            },
            1050: {
                slidesPerView: 3,
                spaceBetween: 0,
                width: "160vh"
            },
            1150: {
                slidesPerView: 3,
                spaceBetween: -60,
                width: "160vh"
            },
            1250: {
                slidesPerView: 4,
                spaceBetween: 150,
                width: "180vh"
            },
            1300: {
                slidesPerView: 4,
                spaceBetween: 180,
                width: "200vh"
            },
            1400: {
                slidesPerView: 4,
                spaceBetween: 150,
                width: "200vh"
            },
        };
    
        // Get the current screen width
        var screenWidth = 0
        if(isSidebareOpened){
            screenWidth = window.innerWidth - 350;
        }else{
            screenWidth = window.innerWidth - 150;
        }
        
    
        // Find the appropriate breakpoint
        let breakpoint = 320; // Default breakpoint if none matches
        for (const width in breakpoints) {
          if (screenWidth >= parseInt(width, 10)) {
            breakpoint = parseInt(width, 10);
          }
        }
    
        // Set the Swiper properties based on the breakpoint
            setSlidesPerView(breakpoints[breakpoint].slidesPerView);
            setSpaceBetween(breakpoints[breakpoint].spaceBetween);
            if(isSidebareOpened === true){          
    
                if(windowSize.width < 600){
                    setSwiperWidth((windowSize.width - 30 )+ 'px');
                }else{
                    setSwiperWidth((windowSize.width - 350 )+ 'px');
                }
                
            }else{
                if(windowSize.width < 600){
                    setSwiperWidth((windowSize.width - 30 )+ 'px');
                }else{
                    setSwiperWidth((windowSize.width - 100 )+ 'px');
                }
                
            }
        
      }, [isSidebareOpened, windowSize]);

      const Navigate = useNavigate()

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };


    const [loading, setLoading] = useState(true);
    const [exams, setExams] = useState([]);
    const [recentExam, setRecentExam] = useState([]);
    const [mostRevisedExams, setMostRevisedExams] = useState([]);
    const [totalExams, setTotalExams] = useState(0);

    function getExams (data){
        axios.post("/admin/getExams", {id: data})
        .then(function (response) {
            console.log(response)
            if(response.data.response === 'done'){
                setLoading(false)
                setExams(response.data.exams)
                setRecentExam(response.data.recentExams)
                setMostRevisedExams(response.data.mostRevisedExams)
                setTotalExams(response.data.totalExams)
                // setStudents(response.data.students)
            }
        })
        .catch(function (error) {
            // handle error
            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
        })
    }

    const isMounted = useRef(true);
    
    useEffect(() => {
        return () => {
        // Cleanup function to set isMounted to false when component unmounts
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            getExams()
        }
    }, []);

    const [openDeleteSessionModal, setOpenDeleteSessionModal] = React.useState(false);
    const handleClickOpenDeleteSessionModal = () => {
        setOpenDeleteSessionModal(true);
    };
    const handleCloseDeleteSessionModal = () => {
        setOpenDeleteSessionModal(false);
    };

    const [selectedExam, setSelectedExam] = useState('');
    const [changes, setChanges] = useState(0);
    const [loadingDelete, setLoadingDelete] = useState(false);
    function handleDeleteSession(){
      setLoadingDelete(true)
      axios.post("/admin/deleteExam", {id: selectedExam})
          .then(function (response) {
            setLoadingDelete(false)
            handleCloseDeleteSessionModal()
            const updatedList = recentExam.filter(item => item.id !== selectedExam);
            setRecentExam(updatedList)
            const updatedList1 = mostRevisedExams.filter(item => item.id !== selectedExam);
            setMostRevisedExams(updatedList1)
            setChanges(changes + 1)
            // remove the removed exam 
          })
          .catch(function (error) {
            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
          })
    } 

    if(loading === true){
        return(
            <Home style={{height: 'calc(100vh - 130px)', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
                <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                        {AlertMessage}
                    </Alert>
                </Snackbar>
            </Home>
        )
    }else{
        return (
          <Home>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>

              <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}} >Exams</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                  <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}} >Total: {totalExams} exams</p>
                  <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor}}} startIcon={<ModeEditOutlineOutlined />} onClick={()=> Navigate('/admin/Exams/NewExam')} >Create an exam</Button>
              </div>
      
              {/* Recent quizzes ********************* */}
              
              <Header>
                  <HeaderDetails>
                      <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor}}}>Recent exams</p>
                  </HeaderDetails>
                  <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                      <Button  sx={{ textTransform: 'none' }} style={{  ...uiSettings.typos.link_mid, ...{marginRight: '10px', color: uiSettings.colors.secondary,  padding: '8px 16px', borderRadius: '10px'}}} variant='text' endIcon={<KeyboardArrowRight />} onClick={()=> Navigate('/admin/Exams/AllExams')} >Show all</Button>
      
                      {/* <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary}}}>Show more</p> */}
                      {/* <MyButton className="swiper-button image-swiper-button-prev1"><KeyboardArrowLeft/></MyButton>
                      <MyButton className="swiper-button image-swiper-button-next1"><KeyboardArrowRight/></MyButton> */}
                  </HeaderDetails>
              </Header>
      
      
              
      
              <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={spaceBetween}
                  navigation={{
                  nextEl: ".image-swiper-button-next1",
                  prevEl: ".image-swiper-button-prev1",
                  disabledClass: "swiper-button-disabled1"
                  }}
                  modules={[Navigation]}
                  className="mySwiper1"
                  style={{width: swiperWidth}}
              >
              
                  
                 {
                    recentExam && recentExam.length === 0 ?
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '180px'}}>
                        <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                    </div>
                    :
                    recentExam.map((item, index)=>{
                        const types = item.types ? JSON.parse(item.types) : []
                        console.log(types)
                        return(
                            <SwiperSlide  key={index}  >
                                <QuizCart style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/exams/' + item.id)} width={windowSize.width}>
                                    <QuizHeader>
                                        <QuizAction>
                                            {/* <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton> */}
                                            <IconButton style={{color: uiSettings.colors.red}} onClick={()=> {setSelectedExam(item.id); setOpenDeleteSessionModal(true)}} ><DeleteOutlineOutlined/> </IconButton>
                                        </QuizAction>
                                        <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                                    </QuizHeader>
                                    <QuizContainer>
                                        <Tags>
                                            {
                                                types.map((type, key)=>{
                                                    return(
                                                        <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                                    )
                                                })
                                            }
                                            {/* <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag> */}
                                        </Tags>
                                        <Tooltip title={item.description}>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>{item.description && item.description.length > 100 ? item.description.slice(0,100) + ".." : item.description} <br/><span style={{color: uiSettings.colors.headingColor}}></span></p>
                                        </Tooltip> 
                                        <QuizBottom>
                                            <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                                <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> {item.users} revisions</p>
                                                <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.year}</p>
                                                {/* <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.createdAt ? item.createdAt.slice(0,10) : ''}</p> */}
                                            </div>
                                        </QuizBottom>
                                    </QuizContainer>
                                </QuizCart>
                            </SwiperSlide>
                        )
                    })
                 } 
                  
      
      
      
              {/* <SwiperSlide >
                  <QuizCart width={windowSize.width}>
                      <QuizHeader index={0}>
                          <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                          </QuizAction>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}>
                      <QuizHeader index={1}>
                          <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                          </QuizAction>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}>
                      <QuizHeader index={2}>
                          <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                          </QuizAction>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}>
                      <QuizHeader index={0}>
                          <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                          </QuizAction>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide> */}
      
      
      
              </Swiper>
      
      
      
              {/* Most revised quizzes ********************* */}
      
      
              <Header>
                  <HeaderDetails>
                      <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor}}}>Most Popular exams</p>
                  </HeaderDetails>
                  <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                      <Button  sx={{ textTransform: 'none' }} style={{  ...uiSettings.typos.link_mid, ...{marginRight: '10px', color: uiSettings.colors.secondary,  padding: '8px 16px', borderRadius: '10px'}}} variant='text' endIcon={<KeyboardArrowRight />} onClick={()=> Navigate('/admin/Exams/AllExams')} >Show all</Button>
      
                      {/* <MyButton className="swiper-button image-swiper-button-prev2"><KeyboardArrowLeft/></MyButton>
                      <MyButton className="swiper-button image-swiper-button-next2"><KeyboardArrowRight/></MyButton> */}
                  </HeaderDetails>
              </Header>
      
      
              <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={spaceBetween}
                  navigation={{
                  nextEl: ".image-swiper-button-next2",
                  prevEl: ".image-swiper-button-prev2",
                  disabledClass: "swiper-button-disabled2"
                  }}
                  modules={[Navigation]}
                  className="mySwiper2"
                  style={{width: swiperWidth}}
              >
              

              { mostRevisedExams && mostRevisedExams.length === 0 ?
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '180px'}}>
                    <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                    <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                </div>
                :
                mostRevisedExams.map((item, index)=>{
                    const types = item.types ? JSON.parse(item.types) : []
                    return(
                        <SwiperSlide  key={index}  >
                        <QuizCart  width={windowSize.width} onClick={()=> Navigate('/admin/exams/' + item.id)}  style={{height: '190px', cursor: 'pointer'}}>
                        <QuizHeader index={2}   style={{height: '120px'}}>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                        </QuizHeader>
                        <QuizContainer style={{height: '70px'}}>
                            <Tags>
                            {
                                types.map((type, key)=>{
                                    return(
                                        <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                    )
                                })
                            }
                            </Tags>
                            <QuizBottom>
                                <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> {item.users} revisions</p>
                                {/* <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.createdAt ? item.createdAt.slice(0,10) : ''}</p> */}
                                <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.year}</p>
                                </div>
                            </QuizBottom>
                        </QuizContainer>
                        </QuizCart>
                        </SwiperSlide>
                    )
                })
              }
                  
                  
              {/* <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={1}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={2}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={1}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide> */}
      
      
      
              </Swiper>
      
      
      
               {/* Most revised quizzes ********************* */}
      
      
               {/* <Header>
                  <HeaderDetails>
                      <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor}}}>New quizzes by users</p>
                  </HeaderDetails>
                  <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                      <Button  sx={{ textTransform: 'none' }} style={{  ...uiSettings.typos.link_mid, ...{marginRight: '10px', color: uiSettings.colors.secondary,  padding: '8px 16px', borderRadius: '10px'}}} variant='text' endIcon={<KeyboardArrowRight />} onClick={()=> Navigate('/admin/Quizzes/AllQuizzesByUsers')} >Show more</Button>
      
                  </HeaderDetails>
              </Header> */}
      
      
              {/* <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={spaceBetween}
                  navigation={{
                  nextEl: ".image-swiper-button-next4",
                  prevEl: ".image-swiper-button-prev4",
                  disabledClass: "swiper-button-disabled4"
                  }}
                  modules={[Navigation]}
                  className="mySwiper4"
                  style={{width: swiperWidth}}
              >
              
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={0}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
                  
                  
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={1}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={2}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
              <SwiperSlide >
                  <QuizCart width={windowSize.width}   style={{height: '190px'}}>
                      <QuizHeader index={1}   style={{height: '120px'}}>
                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                      </QuizHeader>
                      <QuizContainer style={{height: '70px'}}>
                          <Tags>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                              <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                          </Tags>
                          <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                              <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                          </QuizBottom>
                      </QuizContainer>
                  </QuizCart>
              </SwiperSlide>
      
      
      
              </Swiper> */}
      
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openDeleteSessionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDeleteSessionModal}
                    aria-describedby="alert-dialog-slide-description"
                    style={{padding: '10px 20px'}}
                >
                    {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                            <Form style={{width:'100%'}}>
                                    
                                    <FormTitle>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor}}}>Delete <span style={{color: uiSettings.colors.red}} >Exam</span> </p>
                                    </FormTitle> 

                                    <FormTitle>
                                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, fontWeight: 400}}}>Do you really want to delete this exam?</p>
                                    </FormTitle> 

                                    <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                        <LoadingButton   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseDeleteSessionModal}>Annuler</LoadingButton>
                                        <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.red, marginLeft: '10px'}}} onClick={()=> handleDeleteSession()}  >supprimer</LoadingButton>
                                    </DialogActions>
                            </Form>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
      
      
          </Home>
        )
    }
}


const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 0px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
`

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });