import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import Identifier from './identifier';
import Password from './password';
import GeneratePassword from './generatePassword';
import CheckEmail from './checkEmail';
import FullName from './name';
import Phone from './phone';
import ConfirmPhone from './confirmPhone';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckEmailLink from './checkEmailLink';
import Google from './google';
import RedirectToIdentifier from './redirectToIdentifier';
import ForgetPassword from './forgetPassword';
import ResetPassword from './resetPassword';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const fadeImages = [
  {
    url: '/images/p3.png',
    caption: 'Plus de 50 000 QCM',
    id: 0
  },
  {
    url: '/images/p3.png',
    caption: 'Externat Alger ,  Residanat Alger , ECN , HyperQCm',
    id: 1
  },
  {
    url: '/images/p3.png',
    caption: 'Toutes les années 1996-2024',
    id: 2
  },
];


// styled components
const Home = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: ${uiSettings.colors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
`

const Container = styled.div`
    width: ${props => (props.width < 800 ? '600px' : '800px')};
    //height: 80%;
    height: ${props => (props.width < 800 ? 'max-content' : 'max-content')} ;
    min-height: ${props => (props.width < 800 ? 'calc(100vh - 68px)' : 'max-content')};
    //min-height: 580px;
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position:  ${props => (props.position === 'unset' ? 'unset' : 'relative')};
    overflow: hidden;
    margin: ${props => (props.width < 800 ? '10px !important' : '10px')};

`

const FormContainer = styled.div`
  width: ${props => (props.width < 800 ? '100%' : '70%')};
  height: 100%;
`
const ShowContainer = styled.div`
  width: 100%;
  height: max-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${uiSettings.colors.blue};
  border-radius: 20px;
`
const ShowImage = styled.div`
  min-height: 400px;
  max-height: 800px;
  width: 100%;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;

`
const ShowText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 96%;
  //height: 26%;
  height: 100px;
  padding: 2%;
  color: white !important;
`



export default function Auth({windowSize}) {
  const [HeadingStyle, setHeadingStyle] = useState(uiSettings.typos.heading1);
  const [paragraphStyle, setParagraphStyle] = useState(uiSettings.typos.paragraphDesktop);
  const [BtnStyle, setBtnStyle] = useState({color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow});
  const [DisabledBtnStyle, setDisabledBtnStyle] = useState({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary});
  
  const [position, setPosition] = useState("relative");
  useEffect(() => {
    // Update the pathname state when the component mounts
    console.log('path', window.location.pathname)
    if(window.location.pathname === '/auth/phone-number-verification'){
      setPosition("unset")
    }else{
      setPosition('relative')
    }
  }, []);

  // Alert config 

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertType, setAlertType] = React.useState('success');
  const [AlertMessage, setAlertMessage] = React.useState('');

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  // end Alert config


  const [email, setEmail] = useState('');



  useEffect(() => {
    if(windowSize.width > uiSettings.devices.phone){
      setHeadingStyle(uiSettings.typos.heading1)
      setParagraphStyle(uiSettings.typos.paragraphDesktop)
    }else if(windowSize.width < uiSettings.devices.phone){
      setHeadingStyle(uiSettings.typos.heading1mobile)
      setParagraphStyle(uiSettings.typos.paragraphMobile)
    }
  }, [windowSize]);

    const navigate = useNavigate()
  function handleError(error){
    if(error.response.status === 400 && error.response.data.response === 'access_denied'){
        navigate(error.response.data.path, {replace: true})
    }else if(error.response.status === 400 && error.response.data.response === 'server_error'){
        setAlertMessage('An error has occured.')
        setAlertType('error')
        setOpenAlert(true)
        setInterval(() => {
            setOpenAlert(false)
        }, 3000);
    }else if(error.response.status === 429 ){
      // too many requests
      setAlertMessage(error.response.data.message ? error.response.data.message : 'Too many requests.')
      setAlertType('error')
      setOpenAlert(true)
      setInterval(() => {
          setOpenAlert(false)
      }, 3000);  
    }else if(error.response.status && error.response.statusText !== '' ){
        setAlertMessage('An error has occured.')
        setAlertType('error')
        setOpenAlert(true)
        setInterval(() => {
            setOpenAlert(false)
        }, 3000);    
    }else{
        setAlertMessage('An error has occured.')
        setAlertType('error')
        setOpenAlert(true)
        setInterval(() => {
            setOpenAlert(false)
        }, 3000);    
    }
  }


  return (
    <Home>
      <Container position={position} width={windowSize.width}>
        <FormContainer width={windowSize.width}>
          <Routes>
              <Route exact path="/" element={<RedirectToIdentifier />}></Route>
              <Route exact path="/identifier" element={<Identifier email={email} setEmail={setEmail} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize} BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} />}></Route>
              <Route exact path="/google-OAuth-failed" element={<Google email={email} setEmail={setEmail} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize} BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} />}></Route>
              <Route exact path="/password" element={<Password BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} email={email} setEmail={setEmail} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/forget-password" element={<ForgetPassword BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} email={email} setEmail={setEmail} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/reset-password" element={<ResetPassword BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} email={email} setEmail={setEmail} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/password-generate" element={<GeneratePassword setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} email={email} setEmail={setEmail} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/check-email" element={<CheckEmail handleError={handleError} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/check-email-link" element={<CheckEmailLink setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/full-name" element={<FullName BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/phone-number" element={<Phone BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
              <Route exact path="/phone-number-verification" element={<ConfirmPhone setPosition={setPosition} BtnStyle={BtnStyle} DisabledBtnStyle={DisabledBtnStyle} setAlertMessage={setAlertMessage} setOpenAlert={setOpenAlert} setAlertType={setAlertType} handleError={handleError} HeadingStyle={HeadingStyle} paragraphStyle={paragraphStyle} windowSize={windowSize}/>}></Route>
          </Routes>
        </FormContainer>
        <div className="slide-container" style={{flex: 1, height: '100%', maxHeight: '750px', width: '35%', display: windowSize.width < '800' ? 'none' : 'block', alignSelf: 'center' }}>
        <Fade>
          {fadeImages.map((fadeImage, index) =>{
            if(fadeImage.id === 0){
              return(
                <ShowContainer key={index}>
                  <ShowImage className='zzz' image={fadeImage.url} />
                  <ShowText>
                    <p style={{...uiSettings.typos.link, ...{textAlign: 'center',}}} >{fadeImage.caption}</p>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{...uiSettings.typos.largeDot}} >.</div>
                      <div style={{...uiSettings.typos.dot}} >.</div>
                      <p style={{...uiSettings.typos.dot}} >.</p>
                    </div>
                  </ShowText>
                </ShowContainer>
              )
            }else if(fadeImage.id === 1){
              return (
                <ShowContainer key={index}>
                <ShowImage image={fadeImage.url} />
                <ShowText>
                  <p style={{...uiSettings.typos.link, ...{textAlign: 'center'}}} >{fadeImage.caption}</p>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{...uiSettings.typos.dot}} >.</div>
                      <div style={{...uiSettings.typos.largeDot}} >.</div>
                      <p style={{...uiSettings.typos.dot}} >.</p>
                  </div>
                </ShowText>
              </ShowContainer>
              )
            }else {
              return(
                 <ShowContainer key={index}>
                <ShowImage image={fadeImage.url} />
                <ShowText>
                  <p style={{...uiSettings.typos.link, ...{textAlign: 'center'}}} >{fadeImage.caption}</p>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{...uiSettings.typos.dot}} >.</div>
                      <div style={{...uiSettings.typos.dot}} >.</div>
                      <p style={{...uiSettings.typos.largeDot}} >.</p>
                  </div>
                </ShowText>
              </ShowContainer>
              )
             
            }
          })}
        </Fade>
      </div>    
      </Container>

      {/* // my ALERT */}
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
    </Home>
  )
}
