import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Login from './login'
import { Alert, Snackbar } from '@mui/material'
import Panel from './panel/panel'
import DataTable from './panel/dataGrid'
import axios from 'axios'
import ResetPassword from './resetPassword'

const Home = styled.div`
    height: 100vh;
    width: 100%;
`




export default function Admin({windowSize}) {

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertType, setAlertType] = React.useState('success');
    const [AlertMessage, setAlertMessage] = React.useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };


    

  return (
    <Home>
        <Routes>
            <Route exact path="/login" element={<Login windowSize={windowSize} setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert} />}></Route>
            <Route exact path="/reset-password" element={<ResetPassword windowSize={windowSize} setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert} />}></Route>
            <Route exact path="/*" element={<Panel windowSize={windowSize} setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert} />}></Route>
        </Routes>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
            {AlertMessage}
            </Alert>
      </Snackbar>
    </Home>
  )
}
