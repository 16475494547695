import React from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import { ErrorOutlineOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const Home = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: ${uiSettings.colors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 5;
`

const Container = styled.div`
    width: 586px;
    height: calc(100% - 48px);
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 6;
`

export default function Google({ HeadingStyle, paragraphStyle, windowSize}) {
  return (
    <Home>
        <Container>
            <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '170px': '0px'}}></div>
            <Logo></Logo>
            <ErrorOutlineOutlined style={{height: windowSize.width < uiSettings.devices.phone ? '100px': '150px', width: 'auto', margin: windowSize.width < uiSettings.devices.phone ? '40px 0px 20px 0px': '100px 5px 50px 5px'}}/>
            <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary}}}> Failed to connect using Google<br/>There's something wrong</p>
            <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: windowSize.width < uiSettings.devices.phone ? 'center': 'end'}}}><span>Do you want to  <Link onClick={()=> window.location.reload()} style={{color: uiSettings.colors.blue, textDecorationLine: 'none'}}>Try again</Link> ?</span> </p>

        </Container>
    </Home>
  )
}
