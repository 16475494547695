import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar } from '@mui/material';
import { Add, DeleteOutlineOutlined, Filter, FilterList, KeyboardArrowDown, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const Buttons = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`



const CustomHeader = ({ column }) => {
  // Define a custom header component that does not include the menu icon
  return (
    <div>
      {column.headerName} ..
    </div>
  );
};

const CustomRowMenu = ({ row, setOpenWaitModal2, setOpenWaitModal1, setSelectedStudent, setSelectedOffer}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedStudent(row)
    setSelectedOffer(row.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleOpenMenu}>
        {/* Icon for the menu */}
        {/* You can replace this with your desired icon */}
        <MoreVert/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={()=> navigate('/admin/Abonnement/'+ row.id)}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
         <RemoveRedEyeOutlined  style={{marginRight: '10px'}}/> View abonnement
        </MenuItem>
{/* 
        <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
         <ModeEditOutlineOutlined style={{marginRight: '10px'}}/> edit student profile
        </MenuItem> */}

        

        <MenuItem  onClick={() => { setOpenWaitModal1(true); handleCloseMenu()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '5px 16px'}}}>
         <DeleteOutlineOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> Delete abonnement
        </MenuItem>
        
        {/* Add more menu items as needed */}
      </Menu>
    </div>
  );
};





const userData = [
  {
    id: 1,
    username: "user1",
    email: "user1@example.com",
    university: "University A",
    score: 85,
    year: 2022,
  },
  {
    id: 2,
    username: "user2",
    email: "user2@example.com",
    university: "University B",
    score: 92,
    year: 2023,
  },
  {
    id: 3,
    username: "user3",
    email: "user3@example.com",
    university: "University C",
    score: 78,
    year: 2022,
  },
  {
    id: 4,
    username: "user4",
    email: "user4@example.com",
    university: "University A",
    score: 95,
    year: 2023,
  },
  // Add more user objects as needed
];

// You can now use the userData array with id values for each user



export default function Abonnement() {
  const [selectedStudent, setSelectedStudent] = useState({});
  const columns = [
    { 
      field: 'title', 
      headerName: 'Title', 
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span style={{color: uiSettings.colors.headingColor, fontSize: '17px'}}>{params.row.title}</span> 
          </>
          
        );
      },
    },
    { field: 'expDate', headerName: 'Date of exp', width: 200,
      renderCell: (params) => {
        return (
            <span style={{fontSize: '15px'}}>{params.row.expDate? params.row.expDate.split('T')[0] : '--'}</span> 
        );
      }, },
    {
      field: 'price',
      headerName: 'Price',
      width: 200,  
      renderCell: (params) => {
        return (
            <span style={{fontSize: '16px'}}>{params.row.price? params.row.price : '0'}.00 DZD</span> 
        );
      },  
    },
    { 
      field: 'year', 
      headerName: 'Year', 
      width: 150,
      renderCell: (params) => {
        const colors = [{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'},{color: 'rgba(93, 34, 218, 1)', bgColor: 'rgba(93, 34, 218, 0.15)'},{color: 'rgba(251, 116, 75, 1)', bgColor: 'rgba(251, 116, 75, 0.15)'},{color: 'rgba(27, 32, 85, 1)', bgColor: 'rgba(27, 32, 85, 0.15)'},{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'}]
        const random = Math.floor(Math.random() * (4 - 0 + 1)) + 0
        var myNumber = random
        if(params.row.year && !isNaN(params.row.year.slice(0,1))){
          myNumber = Number(params.row.year.slice(0,1)) - 1
        }
        return (
          <>
            <span style={{padding: '8px 16px', borderRadius: '50px', backgroundColor: colors[myNumber].bgColor , color: colors[myNumber].color, fontSize: '16px'}}>{params.row.year}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'Actions', 
      headerName: 'Actions', 
      width:  100,
      // columnMenu: false,
      // sortable: false,
      // menubar: false,
      // headerComponent: CustomHeader,
      renderCell: (params) => {
        return (
          <CustomRowMenu onClick={(e) => e.stopPropagation()} setSelectedOffer={setSelectedOffer} row={params.row} setOpenWaitModal2={setOpenWaitModal2} setOpenWaitModal1={setOpenWaitModal1} setSelectedStudent={setSelectedStudent} />
        );
      },
    },
    
  ];
  

  // mui menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const [sortBy, setSortBy] = useState('All');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [offers, setOffers] = useState([]);
  const Navigate = useNavigate()

  function getStudents (data){
    axios.post("/admin/getAbonnements", {id: data})
    .then(function (response) {
        console.log(response)
        if(response.data.response === 'done'){
            setLoading(false)
            setOffers(response.data.offers)
            // setStudents(response.data.students)
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}

  const isMounted = useRef(true);
    
  useEffect(() => {
    return () => {
    // Cleanup function to set isMounted to false when component unmounts
    isMounted.current = false;
    };
  }, []);

  useEffect(() => {
      if (isMounted.current) {
        getStudents()
      }
  }, []);

  const [orderType, setOrderType] = useState('Alphabetically');

  useEffect(() => {
    if(orderType === 'Alphabetically'){
      function orderByFirstName(arr) {
        return arr.slice().sort((a, b) => {
          const firstNameA = a.first_name ? a.first_name.toLowerCase() : '';
          const firstNameB = b.first_name ? b.first_name.toLowerCase() : '';
      
          if (firstNameA === '' && firstNameB !== '') {
            return 1;
          }
          if (firstNameB === '' && firstNameA !== '') {
            return -1;
          }
      
          if (firstNameA < firstNameB) {
            return -1;
          }
          if (firstNameA > firstNameB) {
            return 1;
          }
          return 0;
        });
      }
      const sortedStudents = orderByFirstName(students);
      console.log(sortedStudents)
      setStudents(sortedStudents)
    }else if (orderType === 'Sessions created'){
      function orderByStudentSessionLength(arr) {
        return arr.slice().sort((a, b) => {
          const sessionLengthA = a.studentSession ? a.studentSession.length : 0;
          const sessionLengthB = b.studentSession ? b.studentSession.length : 0;
      
          return sessionLengthB - sessionLengthA; // Sort in descending order
        });
      }
      const sortedStudents = orderByStudentSessionLength(students);
      console.log(sortedStudents)
      setStudents(sortedStudents)
    }
  }, [orderType]);

  const [openWaitModal2, setOpenWaitModal2] = useState(false);
  const onCloseWaitModal2 = () => {
    // Handle cancel button click, e.g., stop the ongoing process
    setOpenWaitModal2(false);
  };

  const [loadingBan, setLoadingBan] = useState(false);

  function banStudent (){
    setLoadingBan(true)
    axios.post("/admin/banStudent", {id: selectedStudent.id, action: !selectedStudent.blocked})
    .then(function (response) {
        setLoadingBan(false)
        setOpenWaitModal2(false)
        console.log(response)
        const updateItem = (id, blocked) => {
          // Find the index of the object with the given ID
          const index = students.findIndex(item => item.id === id);
          if (index === -1) {
            // If the ID is not found, return without updating
            return;
          }
      
          // Create a new array with the updated object at the found index
          const updatedItems = [...students];
          updatedItems[index] = { ...updatedItems[index], blocked: blocked };
      
          // Update the state with the new array
          setStudents(updatedItems);
        };
        updateItem(selectedStudent.id, !selectedStudent.blocked)
    })
    .catch(function (error) {
        // handle error
        setLoadingBan(false)
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}


const [openWaitModal1, setOpenWaitModal1] = useState(false);
const onCloseWaitModal1 = () => {
  // Handle cancel button click, e.g., stop the ongoing process
  setOpenWaitModal1(false);
};

const [loadingDelete, setLoadingDelete] = useState(false);

const [selectedOffer, setSelectedOffer] = useState("");

function deleteSubscription (){
  setLoadingDelete(true)
  axios.post("/admin/deleteSubscription", {id: selectedOffer})
  .then(function (response) {
    setLoadingDelete(false)
    setOpenWaitModal1(false)
    if(response.data.response === 'done'){
      setLoading(false)
      setOffers(response.data.offers)
    }
  })
  .catch(function (error) {
      // handle error
      setLoadingDelete(false)
      if(error.response && error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
      }
  })
}

  return (
    <Home>
      <p style={{ ...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '15px'}}}>Abonnement</p>
      <Header>
        <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}>Total: {offers.length} abonnemnets</p>
        <Buttons>
          <Button  sx={{ textTransform: 'none' }} onClick={()=> {Navigate('/admin/Abonnement/new')}} style={{ ...uiSettings.typos.adminButtonYellow}} startIcon={<Add />} >Add an abonnemnet</Button>
        </Buttons>
      </Header>
      <div style={{ height: 'calc( 100vh - 220px)', width: '100%' }}>
        <DataGrid
          style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
          rows={offers}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[5, 10]}
          //checkboxSelection
          disableRowSelectionOnClick
          loading={loading}

        />
      </div>  

          {/* block student */}
      <Dialog open={openWaitModal2} fullWidth={true} maxWidth={'sm'} >
          <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>{selectedStudent.blocked === true ? 'Unblock' : 'Block'} student</DialogTitle>
          <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
              <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>{selectedStudent.blocked === true ? 'Unblocking this student will give them access to their account again' : 'Blocking a student means that the student will lose access to their account until they are unblocked again.'} </p>
          </DialogContent>
          <DialogActions>
              <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
              <LoadingButton loading={loadingBan} loadingPosition='end' onClick={()=>banStudent()}   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>{selectedStudent.blocked === true ? 'Unblock' : 'Block'} </LoadingButton>
          </DialogActions>
      </Dialog>

          {/* delete student */}
          <Dialog open={openWaitModal1} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Delete subscription</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>do you really want to cancel this subscription</p>
            </DialogContent>
            <DialogActions>
                <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal1()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
                <LoadingButton loading={false} loadingPosition='end' onClick={()=>deleteSubscription()}   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Delete</LoadingButton>
            </DialogActions>
        </Dialog>

      <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
              {AlertMessage}
          </Alert>
      </Snackbar>
    </Home>
  )
}
