import React, { useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import validator from 'validator';

const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const Container = styled.div`
    width: 586px;
    height: calc(100% - 48px);
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 6;
`

export default function ForgetPassword({ HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle, handleError}) {
  const navigate = useNavigate()
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [submitDisable, setSubmitDisable] = useState(true);
  function handleEmailchange(value){
    setEmail(value)
    if(validator.isEmail(value)){
      setButtonStyle(BtnStyle)
      setSubmitDisable(false)
    }else{
      setButtonStyle(DisabledBtnStyle)
      setSubmitDisable(true)
    }
  }

  const [emailSent, setEmailSent] = useState(false);

  function handleSubmit(){
    setSubmitLoading(true)
    axios.post("/forget-password", {
      email
    })
        .then(function (response) {
          setSubmitLoading(false)
           setEmailSent(true) 
        })
        .catch(function (error) {
          setSubmitLoading(false)
          console.log(error.response)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }

  if(emailSent){
    return (
      <Home>
            <Button variant='standard' onClick={()=>navigate('/auth/identifier')} style={{ ...paragraphStyle, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
              Back
            </Button>
            <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '170px': '0px'}}></div>
            <Logo  auth={true}></Logo>
            <img style={{height: windowSize.width < uiSettings.devices.phone ? '250px': '330px', width: 'auto', margin: windowSize.width < uiSettings.devices.phone ? '20px 0px 00px 0px': '0px 5px'}} src="/mailsent.gif" alt="My Image" />
            <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary}}}>Please check your email, we have sent a verification link</p>
      </Home>
    )
  }else{
    return (
      <Home width={windowSize.width}>
        <Button variant='standard' onClick={()=>navigate('/auth/identifier')} style={{ ...paragraphStyle, ...{backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, width: 'min-content', marginBottom: windowSize.width < uiSettings.devices.phone ? '70px': '28px'}}}>
          Back
        </Button>
        <Logo  auth={true}/>
        <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px'} }}>Forgot your password</h1>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px'}}}>enter your email to reset your password</p>
        <TextField
          id=""
          placeholder={'Email *'}
          value={email}
          variant='standard'
          type={'email'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>handleEmailchange(e.target.value)}
        />
        {/* <Link style={{...paragraphStyle, ...{color: uiSettings.colors.blue, textAlign: "right", textDecorationLine: "none"}}}>Forgot your password?</Link> */}
        <LoadingButton onClick={handleSubmit} loading={submitLoading} loadingPosition={'end'} disabled={submitDisable} style={{...paragraphStyle, ...ButtonStyle ,...{marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
          Submit
        </LoadingButton>
        {/* <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, marginTop: '38px'}}}>By signing up, you agree to our <Link style={{color: uiSettings.colors.secondary}}>Terms of Service</Link>  & <Link style={{color: uiSettings.colors.secondary}}>Privacy Policy</Link>.</p> */}
      </Home>
    )
  }

  
}
