import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

const Home = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${uiSettings.colors.blue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow: scroll;
    padding: 20px 0px !important;
`

const Container = styled.div`
    width: ${props => (props.width < 800 ? 'calc(100% - 78px)' : '430px ')} !important;
    height:  max-content !important;
    //min-height: ${props => (props.width < 800 ? 'calc(100vh - 68px)' : '80%')};
    padding: 24px 24px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 5;
    margin: 18px 0px;
    margin-top: 18px !important;
`

export default function ConfirmPhone({handleError, setPosition, HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, DisabledBtnStyle, BtnStyle}) {
  const navigate = useNavigate()
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [otp, setOtp] = useState('');
  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  function handleChangeOtp(otp){
    setOtp(otp)
    if(otp.length > 0){
      setIsDisabledBtn(false)
      setButtonStyle(BtnStyle)
    }else{
      setIsDisabledBtn(true)
      setButtonStyle(DisabledBtnStyle)
    }
  }


  function handleSubmit(){
    setIsLoadingBtn(true)
    axios.post("/check_otp", {
      otp
    })
        .then(function (response) {
          setIsLoadingBtn(false)
          navigate('/dashboard/', { replace: true });
        })
        .catch(function (error) {
          console.log(error.response)
          setIsLoadingBtn(false)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }

  const [loadingResendCode, setLoadingResendCode] = useState(false);
  function resendCode(){
    setLoadingResendCode(true)
    axios.post("/resend_code", {
      otp
    })
        .then(function (response) {
          setLoadingResendCode(false)
          setAlertType('info')
          setOpenAlert(true)
          setAlertMessage("New code sent.")
        })
        .catch(function (error) {
          console.log(error.response)
          setLoadingResendCode(false)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }

  useEffect(() => {
      setPosition("unset")
  }, []);

  return (
    <Home>
      <Container width={windowSize.width}>    
        <Logo  auth={true}/>
        <img style={{height: windowSize.width < uiSettings.devices.phone ? '170px': '210px' , width: 'auto', margin: windowSize.width < uiSettings.devices.phone ? '80px 0px 20px 0px': '0px 5px', alignSelf: 'center'}} src="/mailsent.gif" alt="My Image" />
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px', textAlign: 'center'}}}>Please enter the verification code we just sent to your phone number </p>
        <TextField
          id=""
          placeholder={'Enter verification code'}
          value={otp}
          variant='standard'
          type={'number'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>handleChangeOtp(e.target.value)}
        />
        <LoadingButton loading={isLoadingBtn} loadingPosition='end' disabled={isDisabledBtn} onClick={handleSubmit} style={{...paragraphStyle, ...ButtonStyle, ...{marginTop: '30px', padding: '8px', borderRadius: '12px'}}}>
          Submit
        </LoadingButton>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginTop: '45px', textAlign: 'center'}}}>You didn't receive any code ?</p>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginTop: '18px', textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: "wrap"}}}><LoadingButton loading={loadingResendCode} onClick={resendCode} loadingPosition='end' style={{ ...BtnStyle , ...{color: uiSettings.colors.blue, paddingRight: '30px', paddingLeft: '30px' , backgroundColor: uiSettings.colors.white, textDecorationLine: 'none'}}}>Resend code </LoadingButton><LoadingButton onClick={() => navigate('/auth/phone-number')} style={{ ...BtnStyle , ...{color: uiSettings.colors.blue , backgroundColor: uiSettings.colors.white, textDecorationLine: 'none'}}}>Edit your phone number</LoadingButton></p>
      </Container>
    </Home>
  )
}
