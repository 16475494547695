import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Slider, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Add, AddOutlined, AddToPhotosOutlined, CheckCircleOutlineOutlined, DeleteOutlineOutlined, EditOutlined, ErrorOutline, FavoriteBorderOutlined, Filter, FilterList, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, PeopleAltOutlined, QuizOutlined, RemoveOutlined, RemoveRedEyeOutlined, SearchOutlined } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import MyQuillEditor from './textEditor';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import PropTypes from 'prop-types';
import ImageGallerie from './imageGallerie';




const CustomPaper = (props) => {
    return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

function valuetext(value) {
    return `${value}°C`;
}

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Container = styled.div`
    width: calc(100% - 24px);
    padding: 12px;
    border-radius: 15px;
    background-color: white;
    min-height: 50px;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: 0px;
`

const OptionList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10px;
    padding: 5px 0px;
    border-radius: 7px !important;
    overflow: auto;
    height: 300px;
    background-color: ${uiSettings.colors.bgblue};
    padding-bottom: 10px;

`

const OptionItem = styled.div`
    width: calc(100% - 30px);
    padding: 15px !important;
    background-color: ${uiSettings.colors.bgblue};
    color: ${uiSettings.colors.secondary};
    border-bottom: 1px solid ${uiSettings.colors.bgBlue};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px !important;
`
const OptionStatus = styled.div`
    background-color: ${uiSettings.colors.yellow} !important;
    color: black !important;
    font-size: 15px !important ;
    padding: 0px 10px !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 1;
    background-color: ${uiSettings.colors.white};
    align-self: center;
`

const Header = styled.div`
    width: calc(100% - 48px) !important;
    padding: 32px 24px !important;
    border-radius: 15px !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
    background-color: ${uiSettings.colors.white};
    z-index: 5;
`


const QuizList = styled.div`
    width: ${(props) => props.isPhone ? ' calc(100% - 20px)' : ' calc(100% - 40px)'} ;
    padding: ${(props) => props.isPhone ? '10px' : '20px'} ;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    border-radius: 15px;
    margin-top: -20px;
    box-shadow: 0px 2px 15px 0px #0000001A;
    min-height: 200px;
`

const QuizItem = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 44px) !important' : 'calc(100% - 68px) !important'}  ;
    margin: 10px !important;
    padding: ${(props) => props.isPhone ? '10px 12px !important' : '20px 24px !important'}  ;
    box-shadow: 0px 2px 5px 0px #0000000D !important;
    display: flex ;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:hover{
        background-color: #d5d5d557;
        border-color:  ${props => props.color ? props.color + " !important" : 'transparent !important'} ;
    }
`

const QuizId = styled.p`
    padding: 4px 12px;
    margin: 0px;
    margin-right: 20px;
    border: 1px solid ${uiSettings.colors.headingColor};
    border-radius: 50px;
    transition: 0.1s ease-in-out;
`


export default function QuestionDetails() {
    const Navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const id = window.location.pathname.split('/').pop();
    const [quiz, setQuiz] = useState(emptyQuiz);
    
    useEffect(() => {
        getQuestion(id)
    }, []);

    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');
  
    const handleClickAlert = () => {
      setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
    };

    const [correction, setCorrection] = useState([]);
    const [images, setImages] = useState([]);
    function getQuestion (data){
        axios.post("/admin/getQuiz", {id: data})
        .then(function (response) {
            console.log(response)
            if(response.data.response === 'done'){
                setLoading(false)
                setQuiz(response.data.quiz)
                //console.log(JSON.parse(response.data.quiz.files))
                if(response.data.quiz.files){
                    setImages(JSON.parse(response.data.quiz.files))
                }
                if(response.data.quiz.correction){
                    setCorrection(response.data.quiz.correction.split('.'))
                }
            }else if(response.data.response === 'notFound'){
                setLoading(false)
                setNotFound(true)
            }
        })
        .catch(function (error) {
            // handle error
            if(error.response && error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                console.log(error)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
                
            }
        })
    }

    function isCorrect(correction, data){
        var array = correction.split('.')
        const exist = array.find(item => item === data)
        if(exist){
            return true
        }else{
            return false
        }
    }

    const [quizDeleted, setQuizDeleted] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    function deleteQuiz (){
        setLoadingDelete(true)
        setOpenWaitModal2(false)
        axios.post("/admin/deleteQuiz", {id})
        .then(function (response) {
            setLoadingDelete(false)
            if(response.data.response === 'done'){
                setQuizDeleted(true)
            }
        })
        .catch(function (error) {
            // handle error
            if(error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                setLoadingDelete(false)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
        })
    }

    const [openWaitModal2, setOpenWaitModal2] = useState(false);
    const [loadingFetchDuplicatesByQuestion, setLoadingFetchDuplicatesByQuestion] = useState(false);
    const onCloseWaitModal2 = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setOpenWaitModal2(false);
    };

    const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const adjustImageStyles = (htmlContent) => {
        // Create a temporary div element to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
    
        // Query all image elements within the content
        const images = tempDiv.querySelectorAll('img');
    
        // Apply styles to images within the content
        images.forEach(image => {
          // Check if the image width exceeds 100%
          if (image.width > 100) {
            image.style.maxWidth = '100%';
            image.style.height = 'auto';
          }
        });
    
        // Return the modified HTML content
        return tempDiv.innerHTML;
      };

      const regex = /#([^#]+)#/g;

  return (
    <Home>
        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, }}}><span style={{cursor: 'pointer'}} onClick={()=> Navigate('/admin/Questions')}>Questions</span> {">"} Question details</p>
        <div style={{display: loading === true || notFound === true || quizDeleted === true ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'end'}}>
            <Button   sx={{ textTransform: 'none' }} onClick={()=> Navigate('edit')}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, display:'flex', alignSelf: 'end', width: 'max-content', marginTop: '20px', marginBottom: '10px'}}} startIcon={<EditOutlined/>}>Edit</Button>
            <LoadingButton  loading={loadingDelete} loadingPosition='start'  sx={{ textTransform: 'none' }} onClick={()=> setOpenWaitModal2(true)}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, display:'flex', alignSelf: 'end', width: 'max-content', marginTop: '20px', marginBottom: '10px'}}} startIcon={<DeleteOutlineOutlined/>}>Delete</LoadingButton>
        </div>
        <Container style={{height: 'calc(100vh - 180px)', justifyContent: 'center', alignItems: 'center', display: loading ?  'flex' : 'none', flexDirection: 'column', background: 'none'}}>
            <CircularProgress  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px'}}}/>
            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Loading..</p>
        </Container>
        <Container style={{height: 'calc(100vh - 300px)', justifyContent: 'center', alignItems: 'center', display: loading === false && notFound === true ?  'flex' : 'none', flexDirection: 'column', background: 'none'}}>
            <ErrorOutline  style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px', fontSize: '50px'}}}/>
            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Quiz not found</p>
        </Container>
        <Container style={{height: 'calc(100vh - 300px)', justifyContent: 'center', alignItems: 'center', display: loading === false && notFound === false && quizDeleted === true  ?  'flex' : 'none', flexDirection: 'column', background: 'none'}}>
            <DeleteOutlineOutlined  style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.blue, marginTop: '0px', paddingTop: '0px', fontSize: '50px'}}}/>
            <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px', paddingTop: '0px'}}}>Quiz deleted successfully</p>
        </Container>
        {
            [''].map(item => {
                console.log('quizType', quiz.type)
                if(quiz.type === "QCM" || quiz.type === "QCS"){
                    return(
                        <Container style={{display: loading === false && notFound === false && quizDeleted === false ? 'block' : 'none'}}>
                            {/* <MyQuillEditor content={content} setContent={setContent}></MyQuillEditor> */}
                            <SubContainer>
                                <Form style={{width: '63%'}}>
                                    {/* title ************ */}
                                    <FormTitle style={{marginTop: '0px'}}>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px'}}}>TITLE</p>
                                    </FormTitle>
                                <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}></p>
                                    <p dangerouslySetInnerHTML={{ __html: quiz.question }} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}></p>

                                </Form>

                                <Form style={{width: '35%'}}>
                                
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                        style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '12px', marginBottom: '0px'}}}
                                        expandIcon={<ExpandMoreIcon style={{color: uiSettings.colors.headingColor}} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        >
                                        Question information
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Module <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}>{quiz.module}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Cour <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.cour}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Type <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.type}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Source <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.source}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Date <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.date}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px", display: quiz.year.length > 0 ? 'flex' : "none"}}}>Year <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.year.length > 0 ? quiz.year - 1 + " - " : ""}  {quiz.year}</span> </p>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                </div>


                                </Form>
                            </SubContainer>
                            
                            <SubContainer>
                                <Form style={{width: '100%'}}>
                                    {/* title ************ */}
                                    <FormTitle style={{marginTop: '0px'}}>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px'}}}>Question preview</p>
                                    </FormTitle>

                                    <Content>
                                        <Header>
                                            {/* <p dangerouslySetInnerHTML={{ __html: quiz.explication }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p> */}

                                            <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }}  style={{...uiSettings.typos.navText, ...{display: quiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}}></p>
                                            <p  dangerouslySetInnerHTML={{ __html: quiz.question.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>') }} style={{...uiSettings.typos.navText, ...{display: quiz.question.length > 0 ? 'block' : 'none'}}}></p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a1 && quiz.a1.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>1- {quiz.a1}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a2 && quiz.a2.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>2- {quiz.a2}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a3 && quiz.a3.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>3- {quiz.a3}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a4 && quiz.a4.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>4- {quiz.a4}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a5 && quiz.a5.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>5- {quiz.a5}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a6 && quiz.a6.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>6- {quiz.a6}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a7 && quiz.a7.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>7- {quiz.a7}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a8 && quiz.a8.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>8- {quiz.a8}</p>
                                        </Header> 

                                        <QuizList  isPhone={false}>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'A') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'A') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'A') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.a.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'A') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'A') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'A') ? 'white' : uiSettings.colors.headingColor }}>A</QuizId>{quiz.a}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'B') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'B') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'B') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.b.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'B') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'B') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'B') ? 'white' : uiSettings.colors.headingColor }}>B</QuizId>{quiz.b}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'C') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'C') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'C') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.c.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'C') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'C') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'C') ? 'white' : uiSettings.colors.headingColor }}>C</QuizId>{quiz.c}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'D') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'D') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'D') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.d.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'D') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'D') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'D') ? 'white' : uiSettings.colors.headingColor }}>D</QuizId>{quiz.d}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'E') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'E') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'E') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.e.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'E') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'E') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'E') ? 'white' : uiSettings.colors.headingColor }}>E</QuizId>{quiz.e}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'F') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'F') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'F') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.f.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'F') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'F') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'F') ? 'white' : uiSettings.colors.headingColor }}>F</QuizId>{quiz.f}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'G') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'G') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'G') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.g.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'G') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'G') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'G') ? 'white' : uiSettings.colors.headingColor }}>G</QuizId>{quiz.g}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'H') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'H') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'H') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.h.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'H') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'H') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'H') ? 'white' : uiSettings.colors.headingColor }}>H</QuizId>{quiz.h}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'I') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'I') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.i.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'I') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'I') ? 'white' : uiSettings.colors.headingColor }}>I</QuizId>{quiz.i}</QuizItem>
                                            <QuizItem isPhone={false} color={isCorrect(quiz.correction, 'J') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(quiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(quiz.correction, 'J') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: quiz.j.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(quiz.correction, 'J') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(quiz.correction, 'J') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(quiz.correction, 'J') ? 'white' : uiSettings.colors.headingColor }}>J</QuizId>{quiz.j}</QuizItem>
                                        </QuizList>
                                        <Header style={{marginTop: '20px', display: quiz.explication.length > 0 || images.length > 0 ? 'block' : 'none'}}>
                                            <p style={{...uiSettings.typos.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId> Explication</p>
                                            {/* <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}>
                                            {quiz.explication}
                                            </p> */}
                                            <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p>

                                            <ImageGallerie images={images} />

                                        </Header>
                                    </Content>

                                </Form>
                            </SubContainer>

                        


                        </Container>
                    )
                }else if( quiz.type === "Cas clinique" || quiz.type === "Dossier clinique" ){
                    return(
                        <Container style={{display: loading === false && notFound === false && quizDeleted === false ? 'block' : 'none'}}>
                        {/* <Container style={{display: loading === false && notFound === false && quizDeleted === false ? 'block' : 'none'}}> */}
                            {/* <MyQuillEditor content={content} setContent={setContent}></MyQuillEditor> */}
                            <SubContainer>
                                <Form style={{width: '63%'}}>
                                    {/* title ************ */}
                                    <FormTitle style={{marginTop: '0px'}}>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px'}}}>TITLE</p>
                                    </FormTitle>
                                    <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}></p>
                                    <p dangerouslySetInnerHTML={{ __html: quiz.question }} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}></p>

                                </Form>

                                <Form style={{width: '35%'}}>
                                
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                        style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '12px', marginBottom: '0px'}}}
                                        expandIcon={<ExpandMoreIcon style={{color: uiSettings.colors.headingColor}} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        >
                                        Question information
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Module <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}>{quiz.module}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Cour <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.cour}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Type <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.type}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Source <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.source}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px"}}}>Date <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.date}</span> </p>
                                                <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px', marginBottom: "10px", display: quiz.year.length > 0 ? 'flex' : "none"}}}>Year <span style={{color: uiSettings.colors.secondary, marginLeft: '10px'}}> {quiz.year.length > 0 ? quiz.year - 1 + " - " : ""}  {quiz.year}</span> </p>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                </div>


                                </Form>
                            </SubContainer>
                            
                            <SubContainer>
                                <Form style={{width: '100%'}}>
                                    {/* title ************ */}
                                    <FormTitle style={{marginTop: '0px'}}>
                                        <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginTop: '0px', paddingTop: '0px'}}}>Question preview</p>
                                    </FormTitle>

                                    <Content>
                                        <Header>
                                            <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }}  style={{...uiSettings.typos.navText, ...{display: quiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}}></p>
                                            <p dangerouslySetInnerHTML={{ __html: quiz.question.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>') }} style={{...uiSettings.typos.navText, ...{display: quiz.question && quiz.question.length > 0 ? 'block' : 'none'}}}></p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a1 && quiz.a1.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>1- {quiz.a1}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a2 && quiz.a2.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>2- {quiz.a2}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a3 && quiz.a3.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>3- {quiz.a3}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a4 && quiz.a4.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>4- {quiz.a4}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a5 && quiz.a5.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>5- {quiz.a5}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a6 && quiz.a6.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>6- {quiz.a6}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a7 && quiz.a7.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>7- {quiz.a7}</p>
                                            <p  style={{...uiSettings.typos.navText, ...{display: quiz.a8 && quiz.a8.length > 0 ? 'block' : 'none', marginTop: '10px'}}}>8- {quiz.a8}</p>
                                        </Header> 

                                        <QuizList  isPhone={false}>

                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={valueTab} onChange={handleChangeTab} style={{overflow: 'none'}} 
                                                //aria-label="basic tabs example"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                                TabIndicatorProps={{
                                                    style: {
                                                    backgroundColor: "transparent"
                                                    }
                                                }}
                                                >
                                                {
                                                    quiz.parentQuiz.map((subQuiz, index)=>{
                                                        return(
                                                            <Tab style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, boxShadow: "0px 2px 5px 0px #0000000D", padding: '4px 12px', marginRight: '10px', borderRadius: valueTab === 0 ? '7px 7px 0px 0px' : '7px' , marginBottom: valueTab === index ?  '0px': '10px', paddingBottom: valueTab === index ? '10px' : '0px', borderTop: valueTab === index ? '4px solid ' + uiSettings.colors.yellow : '4px solid ' + uiSettings.colors.white, transition: '0.2s'}}} label={index + 1} {...a11yProps(index)} />
                                                        )
                                                    })
                                                }
                                                </Tabs>
                                            </Box>

                                            {
                                                quiz.parentQuiz.map((subQuiz, index)=>{
                                                    const myKey = index
                                                    var array = []
                                                    if(quiz.parentQuiz[myKey]){
                                                        array = quiz.parentQuiz[myKey]
                                                    }
                                                    return(
                                                        <CustomTabPanel style={{padding: '0px !important'}} value={valueTab} index={index}>
                                                        <Content isPhone={false}>
                                                            {/* <Header style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{subQuiz.question}</Header> */}
                                                            <Header style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'column'}}}>
                                                                <span dangerouslySetInnerHTML={{ __html: adjustImageStyles(subQuiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{display: subQuiz.enonce.length > 0 ? 'block' : 'none', marginBottom: '15px'}}></span>
                                                                <span dangerouslySetInnerHTML={{ __html: subQuiz.question.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>') }} style={{display: subQuiz.question.length > 0 ? 'block' : 'none'}}></span>
                                                                <span style={{display: subQuiz.a1 && subQuiz.a1.length > 0 ? 'block' : 'none', marginTop: '10px'}}>1- {subQuiz.a1}</span>
                                                                <span style={{display: subQuiz.a2 && subQuiz.a2.length > 0 ? 'block' : 'none', marginTop: '10px'}}>2- {subQuiz.a2}</span>
                                                                <span style={{display: subQuiz.a3 && subQuiz.a3.length > 0 ? 'block' : 'none', marginTop: '10px'}}>3- {subQuiz.a3}</span>
                                                                <span style={{display: subQuiz.a4 && subQuiz.a4.length > 0 ? 'block' : 'none', marginTop: '10px'}}>4- {subQuiz.a4}</span>
                                                                <span style={{display: subQuiz.a5 && subQuiz.a5.length > 0 ? 'block' : 'none', marginTop: '10px'}}>5- {subQuiz.a5}</span>
                                                                <span style={{display: subQuiz.a6 && subQuiz.a6.length > 0 ? 'block' : 'none', marginTop: '10px'}}>6- {subQuiz.a6}</span>
                                                                <span style={{display: subQuiz.a7 && subQuiz.a7.length > 0 ? 'block' : 'none', marginTop: '10px'}}>7- {subQuiz.a7}</span>
                                                                <span style={{display: subQuiz.a8 && subQuiz.a8.length > 0 ? 'block' : 'none', marginTop: '10px'}}>8- {subQuiz.a8}</span>

                                                            </Header>
                                                            <QuizList isPhone={false}>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'A') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'A') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'A') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.a.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'A') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'A') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'A') ? 'white' : uiSettings.colors.headingColor }}>A</QuizId>{subQuiz.a}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'B') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'B') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'B') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.b.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'B') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'B') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'B') ? 'white' : uiSettings.colors.headingColor }}>B</QuizId>{subQuiz.b}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'C') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'C') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'C') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.c.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'C') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'C') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'C') ? 'white' : uiSettings.colors.headingColor }}>C</QuizId>{subQuiz.c}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'D') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'D') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'D') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.d.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'D') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'D') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'D') ? 'white' : uiSettings.colors.headingColor }}>D</QuizId>{subQuiz.d}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'E') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'E') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'E') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.e.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'E') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'E') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'E') ? 'white' : uiSettings.colors.headingColor }}>E</QuizId>{subQuiz.e}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'F') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'F') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'F') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.f.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'F') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'F') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'F') ? 'white' : uiSettings.colors.headingColor }}>F</QuizId>{subQuiz.f}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'G') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'G') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'G') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.g.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'G') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'G') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'G') ? 'white' : uiSettings.colors.headingColor }}>G</QuizId>{subQuiz.g}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'H') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'H') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'H') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.h.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'H') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'H') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'H') ? 'white' : uiSettings.colors.headingColor }}>H</QuizId>{subQuiz.h}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'I') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'I') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.i.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'I') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'I') ? 'white' : uiSettings.colors.headingColor }}>I</QuizId>{subQuiz.i}</QuizItem>
                                                                <QuizItem isPhone={false} color={isCorrect(subQuiz.correction, 'J') ? uiSettings.colors.success : '#d5d5d514'}  style={{...uiSettings.typos.navText, ...{color:isCorrect(subQuiz.correction, 'I') ? uiSettings.colors.success : uiSettings.colors.headingColor, border: isCorrect(subQuiz.correction, 'J') ? ' solid 3px ' + uiSettings.colors.success : 'solid 3px transparent', display: subQuiz.j.length > 0 ? 'flex' : 'none'  }}}><QuizId style={{backgroundColor : isCorrect(subQuiz.correction, 'J') ? uiSettings.colors.success : 'transparent'   , borderColor : isCorrect(subQuiz.correction, 'J') ? uiSettings.colors.success : uiSettings.colors.headingColor, color: isCorrect(subQuiz.correction, 'J') ? 'white' : uiSettings.colors.headingColor }}>J</QuizId>{subQuiz.j}</QuizItem>

                                                                {/*
                                                                    array.map((subQuiz)=>{
                                                                            var PrincipalColor = 'transparent'
                                                                            var textColor = uiSettings.colors.headingColor
                                                                            var borderColor = '#d5d5d514'
                                                                            if(correction && subQuiz.correction ){
                                                                                PrincipalColor = 'green'
                                                                                textColor = 'green'
                                                                                borderColor = 'green'
                                                                            }else if(correction && !subQuiz.correction && subQuiz.selected ){
                                                                                PrincipalColor = 'red'
                                                                                textColor = 'red'
                                                                                borderColor = 'red'
                                                                            }else{
                                                                                PrincipalColor = 'white'
                                                                            }
                                                                            //var selected = subQuiz.selected

                                                                            return(
                                                                                <QuizItem isPhone={false} color={borderColor} style={{...uiSettings.typos.navText, ...{color: textColor, border: 'solid 3px ' + PrincipalColor }}}><QuizId style={{backgroundColor : subQuiz.selected && !correction ? uiSettings.colors.blue : 'transparent'   , borderColor : subQuiz.selected && !correction ? uiSettings.colors.blue : textColor}}>{subQuiz.id}</QuizId>{subQuiz.content}<span style={{flexGrow: 1, display: correction && subQuiz.selected ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'end'}}><CheckCircleOutlineOutlined style={{color: uiSettings.colors.headingColor, fontSize: '22px'}}/></span></QuizItem>
                                                                            ) 
                                                                        })
                                                                */}
                                                                <Header style={{marginTop: '20px', display: subQuiz.explication.length > 0 ? 'block' : 'none'}}>
                                                                    <p style={{...uiSettings.typos.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId> Explication</p>
                                                                    <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(subQuiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p>
                                                                    {/* <p dangerouslySetInnerHTML={{ __html: quiz.explication }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p> */}                                                                
                                                                </Header>
                                                            </QuizList>
                                                        </Content>
                                                        </CustomTabPanel>
                                                    )
                                                })
                                            }
                            
                                            
                                        </Box>
                                            
                                        </QuizList>
                                        <Header style={{marginTop: '20px', display: quiz.explication.length > 0 || images.length > 0 ? 'block' : 'none'}}>
                                            <p style={{...uiSettings.typos.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId> Explication</p>
                                            <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p>
                                            {/* <p dangerouslySetInnerHTML={{ __html: quiz.explication }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p> */}
                                            
                                            <ImageGallerie images={images} />
                                        </Header>
                                    </Content>

                                </Form>
                            </SubContainer>

                        </Container>
                    )
                }
            })
        }
        

        

        <Dialog open={openWaitModal2} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Delete quiz</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Deleting quiz parmanently ?</p>
            </DialogContent>
            <DialogActions>
                <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
                <Button   sx={{ textTransform: 'none' }} onClick={()=> {deleteQuiz()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Delete</Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                {AlertMessage}
            </Alert>
        </Snackbar>
    </Home>
  )
}


const emptyQuiz = {
    id: '',
    quiz_id: '',
    type: '',
    parent: '',
    year: '',
    module: '',
    cour: '',
    source: '',
    date: '',
    enonce: '',
    question: "",
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    f: '',
    g: '',
    h: '',
    i: '',
    j: '',
    a1: '',
    a2: '',
    a3: '',
    a4: '',
    a5: '',
    a6: '',
    a7: '',
    a8: '',
    correction: '',
    explication: '',
    note: '',
    createdAt: '2024-01-18T13:47:09.000Z',
    updatedAt: '2024-01-18T13:47:09.000Z'
  }

const quizs = {
    id: 'e38ce7a1-be57-407a-9ace-35ad2a81d73a',
    quiz_id: 'Q001564',
    type: 'QCM',
    parent: '',
    year: '2021',
    module: 'Anatomie (1ère année)',
    cour: 'Arthrologie du membre supérieur',
    source: 'Externat Alger',
    date: 'S1',
    enonce: '',
    question: "Concernant l'articulation scapulo-humérale :",
    a: 'Articulation synoviale à 3 degrés de liberté',
    b: 'Articulation mobile mais stable',
    c: 'La tête regarde en haut, en arrière, et en dedans',
    d: 'Les ligaments gléno-huméraux renforcent la capsule en arrière',
    e: 'Elle est traversée en haut par le tendon du long biceps',
    f: '',
    g: '',
    h: '',
    i: '',
    j: '',
    a1: '',
    a2: '',
    a3: '',
    a4: '',
    a5: '',
    a6: '',
    a7: '',
    a8: '',
    correction: 'A.C',
    explication: 'test',
    note: null,
    createdAt: '2024-01-18T13:47:09.000Z',
    updatedAt: '2024-01-18T13:47:09.000Z'
  }