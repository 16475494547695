import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import TextField from '@mui/material/TextField';
import Input from '../reusableComponents/input';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import Separator from '../reusableComponents/separator';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import validator from 'validator';
import { useNavigate  } from 'react-router-dom';
import Google from './google';
import Loading from './loading';


const Home = styled.div`
  width: ${props => (props.width < uiSettings.devices.phone ? '90%' : '85%')};
  padding: 0px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.width < uiSettings.devices.phone ? 'start' : 'start')} ;
`

export default function Identifier({ email, setEmail, HeadingStyle, paragraphStyle, windowSize, DisabledBtnStyle, BtnStyle, handleError, setOpenAlert, setAlertType, setAlertMessage}) {

  const navigate = useNavigate()

  const [ButtonStyle, setButtonStyle] = useState(DisabledBtnStyle);
  const [ButtonDisabled, setButtonDisabled] = useState(true);

  const [emailError, setEmailError] = useState(false);

  function handleEmailchange (value) {
    setEmail(value)
    if(value.length > 0){
      setButtonStyle(BtnStyle)
      setButtonDisabled(false)
    }else{
      setButtonStyle(DisabledBtnStyle)
      setButtonDisabled(true)
    }
  }

  const [submitLoading, setSubmitLoading] = useState(false);
  function handleSubmit(){
    setSubmitLoading(true)
    axios.post("/indentifier", {
      email
    })
        .then(function (response) {
          setSubmitLoading(false)
          localStorage.setItem('email', email)
          if(response.data.response === 'login'){
            navigate('/auth/password', { replace: false });
          }else{
            navigate('/auth/password-generate', { replace: false });
          }
        })
        .catch(function (error) {
          setSubmitLoading(false)
          console.log(error.response.status)
          if(error.response.status === 400){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }

  const queryParams = new URLSearchParams(window.location.search)
  const failed = queryParams.get("failed")
  useEffect(() => {
    if (failed === 'google_oauth') {
      setGoogleError(true)
      navigate('/auth/identifier', {replace: true})
    }else{
      setGoogleError(false)
    }

    handleEmailchange (email)
  }, []);  

  const domainName = window.location.hostname;
  console.log(domainName)

  // check access
  useEffect(() => {
    axios.post("/check_access")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            navigate('/dashboard', { replace: true });
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            console.log(error.response.data)
            if(error.response.data.message){
              setAlertMessage(error.response.data.message)
              setAlertType('error')
              setOpenAlert(true)
            }
            setLoading(false)
          }else{
            handleError(error)
          }
        })

  }, []);

  const [googleError, setGoogleError] = useState(false);
  const [loading, setLoading] = useState(false);
  if(loading){
    return(
      <Loading />
    )
  }
  else if(googleError){
    return(
      <Google windowSize={windowSize}/>
    )
  }else{
    return (
      <Home width={windowSize.width}>
        <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '100px': '0px'}}></div>
        <Logo auth={true} style={{width: '50px', height: 'auto'}}  />
        <h1 style={{...HeadingStyle, ...{color: uiSettings.colors.black, fontWeight: 800, marginTop: '28px '} }}>Welcome to Automed</h1>
        <p style={{...paragraphStyle, ...{color: uiSettings.colors.secondary, marginBottom: '28px', marginTop: '4px'}}}>Login to continue the adventure</p>
        <TextField
          id=""
          placeholder={'Enter your email *'}
          value={email}
          //onchange
          error={emailError}
          variant='standard'
          type={'text'}
          InputProps={{
                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black}}
          }}
          onChange={(e)=>handleEmailchange(e.target.value)}
        />
        {/* <Link to={'/auth/forget-password'} style={{...paragraphStyle, ...{color: uiSettings.colors.blue, textAlign: "right", textDecorationLine: "none"}}}>Forgot your password?</Link> */}
        <Separator style={{marginTop: "28px !important"}}></Separator>
        <Button onClick={()=> window.open( 'https://' +domainName + "/auth/google", "_self")} style={{borderColor: uiSettings.colors.secondary, color: uiSettings.colors.black, marginTop: '28px', borderRadius: '0px', textAlign: 'left', position: 'relative'}} variant="outlined">
        {/* <Button onClick={()=> window.open("https://my.auto-med.app/auth/google", "_self")} style={{borderColor: uiSettings.colors.secondary, color: uiSettings.colors.black, marginTop: '28px', borderRadius: '0px', textAlign: 'left', position: 'relative'}} variant="outlined"> */}
          <GoogleIcon style={{position: "absolute", left: '10', color: uiSettings.colors.black,}}></GoogleIcon>
          <p style={{ ...paragraphStyle, ...{color: uiSettings.colors.secondary}}}>Continue with <span style={{color: uiSettings.colors.black}}>Google</span></p>
        </Button>
        {/* <LoadingButton onClick={() => navigate('/dashboard/')} loading={submitLoading}  loadingPosition='end' disabled={ButtonDisabled} style={{...paragraphStyle, ...ButtonStyle, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}> */}
        <LoadingButton onClick={handleSubmit} loading={submitLoading}  loadingPosition='end' disabled={ButtonDisabled} style={{...paragraphStyle, ...ButtonStyle, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}}}>
          Submit
        </LoadingButton>

        {/* <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, marginTop: '30px'}}}>By signing up, you agree to our <Link style={{color: uiSettings.colors.secondary}}>Terms of Service</Link>  & <Link style={{color: uiSettings.colors.secondary}}>Privacy Policy</Link>.</p> */}
      </Home>
    )
  }
  
}
