import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'           
import { Alert, CircularProgress, IconButton, Menu, Snackbar, Stack, TextField, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { AddBoxOutlined, AddCommentOutlined, BorderColorOutlined, CollectionsBookmarkOutlined, CreditCardOutlined, DriveFileRenameOutlineOutlined, ErrorOutlineOutlined, FavoriteBorderOutlined, FavoriteOutlined, FullscreenOutlined, HelpOutlineOutlined, HighlightOffOutlined, HomeMaxOutlined, KeyboardArrowDown, KeyboardArrowUp, ListAltOutlined, LogoutOutlined, ModeNightOutlined, Note, NoteAddOutlined, NotificationsNoneOutlined, PauseCircle, PauseCircleOutlineOutlined, PendingActionsOutlined, PersonOutlineOutlined, PlaceOutlined, QuizOutlined, Search, SettingsOutlined, StyleOutlined, SupportOutlined, TimerOutlined, TrendingUpOutlined } from '@mui/icons-material'
import Logo from '../reusableComponents/logo'
import { Avatar, Badge } from 'antd'
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import QCM from './qcm'
import CasClinique from './casClinic'
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { LoadingButton } from '@mui/lab'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
  ];


const Home = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: start;
  background-color: ${uiSettings.colors.blue};
  overflow: ${props => props.isPhone === true ? 'hidden' : 'unset'} ;
  overflow: hidden;
`

const SideBar = styled.div`
  width:  'max-content !important' ;
  height: calc(100vh - 10px);
  margin: 5px 0px 0px 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: max-content !important;
`

const Container = styled.div`
  width:${props => props.isSidebareOpened === true ? 'calc(80% - 5px)' : '100%'} ;
  height: calc(100vh - 10px);
  margin: 5px 5px 5px 0px;
  padding: 0px;
  background-color: white;
  border-radius: 15px 15px 15px 0px;
  overflow: hidden;
`

const SubContainer = styled.div`
  width: calc(100% );
  padding: 10px 10px 0px 0px;
  height: calc(100vh - 115px);
  overflow-y: auto;
  overflow-x: hidden;
`


const SidebareLinks = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  //height: calc(100vh - 50px);
  overflow-y: auto;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 0px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const SidebareSubLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${uiSettings.colors.bgblue3};
`

const SidebareLink = styled.div`
    width:  ${props => props.isSidebareOpened === true ? 'cacl(100% - 80px) !important' : 'min-content !important'} ;
  border-left: 8px solid ${uiSettings.colors.yellow};
  padding-left: 10px;
  margin: 2px 0px;
  transition: 100ms ease-in-out;
`

const SidebareSubLink = styled.div`
  width:  ${props => props.isSidebareOpened === true ? 'cacl(100% - 80px) !important' : 'min-content !important'} ;
  border-left: 8px solid ${uiSettings.colors.yellow};
  padding-left: 10px;
  margin: 0px 0px;
  transition: 100ms ease-in-out !important;
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SidebareLinkToClick = styled.div`
  width: cacl(100% - 80px) !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 7px 3px !important;
  border-radius: 7px;
  cursor: pointer;
  transition: 100ms ease-in-out;
  font-size: 17px !important;
  transition: 0.9s ease-in-out;

  /* &:hover{
    background-color: ${uiSettings.colors.bgblue3};
  } */
`

const SidebareSubLinkToClick = styled.div`
  width:  cacl(100% - 80px) !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 7px 5px !important;
  border-radius: 7px;
  cursor: pointer;
  transition: 100ms ease-in-out;
  background-color: ${uiSettings.colors.bgblue3};
  transition: 100ms ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px !important;
  height: 30px !important;

`

const LinkArrowIcon = styled.span`
  color: ${uiSettings.colors.white};
  flex-grow: 1;
  flex-direction: row;
  justify-content: end;
  align-items: end;
`

const Navbar = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 2px 10px 0px #00000026;

`

const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
    position: relative;
    align-items: center;
`

const NavbarInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
`




export default function PlaySession({windowSize}) {

  const [openCommentModal, setOpenCommentModal] = React.useState(false);
  const handleClickOpenCommentModal = () => {
    setOpenCommentModal(true);
  };
  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
  };


  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const handleClickOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };
  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };


  const [isSidebareOpened, setIsSidebareOpened] = useState(false);
  const [tab, setTab] = useState('home');
  const [questionLink, setQuestionLink] = useState(false);
  const [flashcardLink, setFlashcardLink] = useState(false);
  const [examenLink, setExamenLink] = useState(false);

  const Navigate = useNavigate()
  const [like, setLike] = useState();
  const [feedback, setFeedback] = useState('');
  const [note, setNote] = useState('');

  const [noteLoading, setNoteLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);


  function handleSmallChanges(action){
    var value
    if(action === 'like'){
      value = !quizLiked
    }else if(action === 'note'){
      value = note
      setNoteLoading(true)
    }else if(action === 'feedback'){
      value = feedback
      setFeedbackLoading(true)
    }

    axios.post("/student/editSmallChangesInQuiz", {
      action, value, quizId: quiz.id
    } )
        .then(function (response) {
          setFeedbackLoading(false)
          setNoteLoading(false)
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            // handle some edits
            if(action === 'like'){
              setQuizLiked(value)
            }
            handleCloseCommentModal()
          }
        })
        .catch(function (error) {
          console.log(error)
          setFeedback(false)
          setNoteLoading(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  }

  const [quiz, setQuiz] = useState({});
  const [notFoundQuestion, setNotFoundQuestion] = useState(false);
  const [images, setImages] = useState([]);
  const [repeted, setRepeted] = useState([]);
  function handleSidebareChange(item, index){
    //console.log(tab)
    setTab(item.id)
    setCurrentQuiz(index + 1)
    if(isPhone){
      setIsSidebareOpened(false)
    }
    setQuizLiked(false)
    setNote('')
    setFeedback('')
    setQuestionLoading(true)
    axios.post("/student/getQuiz", {
      sessionId: id, quizId: item.id
    } )
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            console.log(response.data)
            if(response.data.response === 'session_not_found'){
              setNotFound(true)
              setPageLoading(false)
            }else{
              if(response.data.response === 'quiz_not_found'){
                setPageLoading(false)
                setQuestionLoading(false)
                setNotFound(true)
                setQuiz({})
                setNotFoundQuestion(true)
              }else{
                if(response.data.quiz && response.data.quiz.quiz){
                  setQuizType(response.data.quiz.quiz.type)
                  if(response.data.quiz.quiz.note == null){
                    setRepeted([])
                  }else{
                    setRepeted(JSON.parse(response.data.quiz.quiz.note))
                  }
                }
                setPageLoading(false)
                setQuestionLoading(false)
                setNotFound(false)
                setQuiz(response.data.quiz)
                if(response.data.quiz.quiz.files && response.data.quiz.quiz.files.length > 0 ){
                  setImages(JSON.parse(response.data.quiz.quiz.files))
                }else{
                  setImages([])
                }
                setNotFoundQuestion(false)
                setQuizLiked(response.data.quiz.liked)
                setNote(response.data.quiz.note)
                setFeedback(response.data.quiz.comment)
              }
              
              
            }
            //setPageLoading(false)
          }
        })
        .catch(function (error) {
          console.log(error)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })

  }
  const [modalContent, setModalContent] = useState('');


  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertType, setAlertType] = React.useState('success');
  const [AlertMessage, setAlertMessage] = React.useState('');
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenAlert(false);
  };  
  
  const [comment, setComment] = useState("");

  const [isPhone, setIsPhone] = useState(false);
  const [styles, setStyles] = useState({...uiSettings.typos});

  useEffect(() => {
  if(windowSize.width < 600) {
    setIsPhone(true)
    setStyles(uiSettings.typosMobile)
  }else{
    setIsPhone(false)
    setStyles(uiSettings.typos)
  }
  }, [windowSize]);
  
  if(isPhone){
   // setStyles(uiSettings.typosMobile)
  }

  function handleError(error){
    if(error.response.status === 400 && error.response.data.response === 'access_denied'){
        navigate('/auth/identifier', { replace: true });
    }else if(error.response.status === 400 && error.response.data.response === 'server_error'){
            setAlertType('error')
            setOpenAlert(true)
            setAlertMessage(error.response.data.error) 
    }else if(error.response.status === 429 ){
      // too many requests
      setAlertType('error')
      setOpenAlert(true)
      setAlertMessage('too many requests, try again later.') 
    }else if(error.response.status && error.response.statusText !== '' ){
        setAlertType('error')
        setOpenAlert(true)
        setAlertMessage(error.response.statusText) 
    }else{
        setAlertType('error')
        setOpenAlert(true)
        setAlertMessage('An error has occured')   
    }
  }

  const [pageLoading, setPageLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(true);
  const navigate = useNavigate()
  const [sessionId, setSessionId] = useState();
  const id = window.location.pathname.split('/').pop();
  //setSessionId(id)
  const [quizTitle, setQuizTitle] = useState("");
  const [quizLength, setQuizLength] = useState(0);
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [quizType, setQuizType] = useState('');
  const [quizTime, setQuizTime] = useState();
  const [questions, setQuestions] = useState([]);
  const [quizLiked, setQuizLiked] = useState(false);


  useEffect(() => {
    console.log(id)
    axios.post("/student/getSession", {
      sessionId: id
    } )
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            console.log(response.data)
            if(response.data.response === 'session_not_found'){
              setNotFound(true)
              setPageLoading(false)
            }else{
              setPageLoading(false)
              setNotFound(false)
              setQuestions(response.data.OrderedQuiz)
              setQuizTitle(response.data.session.name)
              setQuizLength(response.data.OrderedQuiz.length)
              setQuizTime(parseInt(response.data.session.time))
              setQuizTime(parseInt(response.data.session.time))
              setCurrentQuiz(1)
              handleSidebareChange(response.data.OrderedQuiz[0], 1)
            }
            //setPageLoading(false)
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  }, []);



  function updateQuizeTime(){
    axios.post("/student/updateSessionTimer", {
      sessionId: id, quizTime
    } )
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  }
 

  const [backUpTimer, setBackUpTimer] = useState(0);
  const [timePaused, setTimePaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if(timePaused === false){
        setQuizTime(prevTime => prevTime + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timePaused]);


  useEffect(() => {
        console.log( 'backUpTimer : ', backUpTimer)
        console.log( 'quizTime : ', quizTime)
        if(backUpTimer % 20 === 0){
          updateQuizeTime()
        }
        setBackUpTimer(backUpTimer + 1)
  }, [setQuizTime, quizTime]);


  function handleEditSidebareAfterAnswering(id){
    const updatedItems = questions.map(item => {
      if (item.id === id) {
        // Update the variable in the object
        return { ...item, answered: true };
      }
      return item;
    });
    setQuestions(updatedItems)

  }

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  function nextQuiz(){
    
    handleSidebareChange(questions[currentQuiz], currentQuiz )
  }

  const [numberOfnsweredQuizzes, setNumberOfnsweredQuizzes] = useState(0);
  
  function exitSession(){
    var corrected = 0
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      if(question.answered === true){
        corrected++
      }
    }
    setNumberOfnsweredQuizzes(corrected)
    setOpenLogoutModal(true)
  }

  const hours = Math.floor(quizTime / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((quizTime % 3600) / 60).toString().padStart(2, '0');
  const seconds = (quizTime % 60).toString().padStart(2, '0');

  if(pageLoading){
    return(
      <Home style={{justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', backgroundColor: uiSettings.colors.white}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
          <CircularProgress />
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
              {AlertMessage}
            </Alert>
          </Snackbar>
      </Home> 
    )
  }else if(notFound){
    return(
      <Home style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', backgroundColor: uiSettings.colors.white}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
          <ErrorOutlineOutlined style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px', fontSize: '30px'}}}/>
          <p style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px'}}} >Session not found</p>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
              {AlertMessage}
            </Alert>
          </Snackbar>
      </Home> 
    )
  }
  else{
    return (
      <Home isPhone={isPhone}>
  
        {/* sidebar ********************* */}
        <SideBar style={{display: (isPhone && !isSidebareOpened) ? 'none': 'flex' }} isSidebareOpened={isSidebareOpened}>
          <div style={{width: '100%', height: '60px', display: isPhone ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'center',}}>
            <QuizOutlined style={{color: uiSettings.colors.white, fontSize: '40px', padding: isSidebareOpened === true ? '0px 28px 0px 20px' : '0px 10px 0px 20px' }} />
          </div>
  
          <SidebareLinks style={{flexGrow: 1, marginBottom: '5px',}} className="scroll-container3">
  
            {/* questions ********* */}
            {
              questions.map((question, index) => {
                  var item = 'CasClinique'
                  if(index % 2 === 0){
                      item = 'qcm'
                  }
                  return(
                      <SidebareLink  isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === question.id ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
                          <SidebareLinkToClick onClick={() => handleSidebareChange( question, index)} style={{...uiSettings.typos.heading5, ...{color: question.answered ? '#fbfcfd77' : uiSettings.colors.white}}}>Q{index + 1} </SidebareLinkToClick>
                      </SidebareLink>
                  )
              })
            }
          </SidebareLinks>
          <div    style={{height: 'max-content', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width: 'calc(100% - 5px)', backgroundColor: uiSettings.colors.white, color: uiSettings.colors.headingColor, marginLeft: '5px', borderRadius: '15px 0px 0px 15px', padding: '5px 0px'}}>
              <Tooltip title="Ajouter un commentaire" placement="right"><IconButton onClick={(e) => { setModalContent('feedback'); handleClickOpenCommentModal()} }><AddCommentOutlined/></IconButton></Tooltip>
              <Tooltip title="Aimer" placement="right"><IconButton onClick={()=> handleSmallChanges('like')}><FavoriteBorderOutlined style={{display : quizLiked ? 'none' : 'block'}}/><FavoriteOutlined style={{display : !quizLiked ? 'none' : 'block', color: uiSettings.colors.blue}}/></IconButton></Tooltip>
              <Tooltip title="Ajouter une note" placement="right"><IconButton onClick={(e) => { setModalContent('note'); handleClickOpenCommentModal()} }><BorderColorOutlined/></IconButton></Tooltip>
              <Tooltip title="Ouvrir en plein écran" placement="right"><IconButton  onClick={handleFullScreen}><FullscreenOutlined style={{color: isFullScreen ? uiSettings.colors.blue : 'unset'}}/></IconButton></Tooltip>
              <Tooltip title="Pause" placement="right"><IconButton onClick={()=> setTimePaused(!timePaused)}><PauseCircleOutlineOutlined style={{color: timePaused ? uiSettings.colors.blue : 'unset'}}/></IconButton></Tooltip>
              <Tooltip title="Sortie" placement="right"><IconButton onClick={() => exitSession()} ><HighlightOffOutlined/></IconButton></Tooltip>
              {/* <Tooltip title="Exit" placement="right"><IconButton onClick={() => navigate('/dashboard/allSessions')} ><HighlightOffOutlined/></IconButton></Tooltip> */}
          </div>
  
          
  
  
        </SideBar>
       
       
        <Container onClick={() => {if(isPhone && isSidebareOpened) {setIsSidebareOpened(false)}}}>
          
          {/* navbar desktop ********** */}
          <Navbar style={{display: isPhone? 'none' : 'flex'}}>
            <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '180px'}}}><TimerOutlined style={{marginRight: '10px', color: uiSettings.colors.blue}}/>{hours}:{minutes}:{seconds} </p>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}> {currentQuiz} / <span style={{color: uiSettings.colors.blue}}> {quizLength}</span></p>
              <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>{quizTitle}</p>
            </div>
            <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, minWidth: '150px'}}}><span style={{color: uiSettings.colors.blue}}>type: </span> {quizType}</p>
          </Navbar>
  
          {/* navbar mobile ********** */}
          <Navbar style={{display: !isPhone ? 'none' : 'flex'}}>
            
  
            <NavbarInfo>
              <IconButton onClick={()=> setIsSidebareOpened(true)} style={{ fontSize: '26px', color: uiSettings.colors.black,   zIndex: '10', marginRight: '30px'}}><MenuIcon /></IconButton>
              <Logo style={{ }}></Logo>
            </NavbarInfo>
  
            <NavbarInfo>
              
               <IconButton disabled><Search style={{ fontSize: '26px', color: "#00000034",   zIndex: '10', }}/></IconButton> 
  
              <Stack spacing={2} direction="row">
                <Badge badgeContent={5} style={{margin: "0px 45px 0px 0px"}}  sx={{"& .MuiBadge-badge": {color: uiSettings.colors.black ,backgroundColor: uiSettings.colors.yellow }}}>
                    <IconButton disabled><NotificationsNoneOutlined style={{color: "#00000034", }} /></IconButton> 
                </Badge>
              </ Stack>
            </NavbarInfo>
          </Navbar>
  
  
          {
            [''].map(()=>{
              if(questionLoading){
                return(
                  <SubContainer style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                    <CircularProgress />
                  </SubContainer>
                )
              }else if(notFoundQuestion){
                return(
                  <Home style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', backgroundColor: uiSettings.colors.white}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                      <ErrorOutlineOutlined style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px', fontSize: '30px'}}}/>
                      <p style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px'}}} >Quiz not found</p>
                      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                        <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                          {AlertMessage}
                        </Alert>
                      </Snackbar>
                  </Home> 
                )
              }else{
                if(quiz && quiz.quiz){
                  if(quiz.quiz.type === 'QCM' || quiz.quiz.type === 'QCS'){
                    return(
                      <QCM images={images} repeted={repeted} handleEditSidebareAfterAnswering={handleEditSidebareAfterAnswering} handleError={handleError}  nextQuiz={nextQuiz} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone} quiz={quiz}/>
                    )
                  }else if(quiz.quiz.type === 'Dossier clinique' || quiz.quiz.type === 'Cas clinique'){
                    return(
                      <CasClinique images={images} repeted={repeted} handleEditSidebareAfterAnswering={handleEditSidebareAfterAnswering}  handleError={handleError}  nextQuiz={nextQuiz}  windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone} quiz={quiz}/>
                    )
                  }else{
                    return(
                      <Home style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', backgroundColor: uiSettings.colors.white}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                        <ErrorOutlineOutlined style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px', fontSize: '30px'}}}/>
                        <p style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px'}}} >An error has occured</p>
                      </Home> 
                    )
                  }
                }else{
                  return(
                    <Home style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', backgroundColor: uiSettings.colors.white}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                      <ErrorOutlineOutlined style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px', fontSize: '30px'}}}/>
                      <p style={{...uiSettings.typos.heading3, ...{ color: uiSettings.colors.headingColor, marginTop: '10px'}}} >An error has occured in this quiz</p>
                    </Home> 
                  )
                }
                
              }
            })
          }
          
          
                          
  
         
  
  
        </Container>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
              {AlertMessage}
            </Alert>
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={openLogoutModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseLogoutModal}
          aria-describedby="alert-dialog-slide-description"
          style={{padding: '10px 20px'}}
        >
          {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                   <Form style={{width: isPhone ? '100%' : '100%'}}>
                            <FormTitle style={{marginBottom: '10px'}}>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Sortie <span style={{color: uiSettings.colors.blue}}>{quizTitle}</span></p>
                            </FormTitle>
                            <FormTitle style={{marginBottom: '0px'}}>
                                <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor, fontWeight: '400', display: questions.length > numberOfnsweredQuizzes ? 'block' : 'none'}}}>Il y a <span style={{color: uiSettings.colors.blue, paddingLeft: '5px', paddingRight: '5px'}}> { questions.length - numberOfnsweredQuizzes } </span> quiz auxquels on n’a pas encore répondu.</p>
                                <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor, fontWeight: '400', display: questions.length === numberOfnsweredQuizzes ? 'block' : 'none'}}}>Vous avez répondu à <span style={{color: uiSettings.colors.blue, paddingLeft: '5px', paddingRight: '5px'}}>tous</span> les quiz de cette session.</p>
                            </FormTitle>
                          
                              <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px', fontSize: isPhone ? '16px' : '18px'}}}  onClick={handleCloseLogoutModal}>Annuler</LoadingButton>
                                <LoadingButton loading={feedbackLoading} loadingPosition='end'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, marginLeft: '10px',  fontSize: isPhone ? '16px' : '18px'}}}   onClick={()=> navigate('/dashboard/allSessions')}>Sortir  {questions.length > numberOfnsweredQuizzes ? (isPhone ? '' : 'quand même') : ''}</LoadingButton>
                              </DialogActions>
                    </Form>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={openCommentModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseCommentModal}
          aria-describedby="alert-dialog-slide-description"
          style={{padding: '10px 20px'}}
        >
          {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                   <Form style={{width: isPhone ? '100%' : '100%'}}>
                            <FormTitle style={{marginBottom: '10px'}}>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>{modalContent === 'feedback'? 'Envoyer un ' : 'Ajouter une '} {modalContent}</p>
                            </FormTitle>
                            <FormTitle>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor, fontWeight: '400', display: modalContent === 'feedback'? 'block' : 'none'}}}>Si vous avez un feedback que vous souhaiteriez partager avec les administrateurs de la plateforme, n'hésitez pas à l'envoyer. Les administrateurs liront vos commentaires et les prendront en considération.</p>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor, fontWeight: '400', display: modalContent === 'note'? 'block' : 'none'}}}>Enregistrer une note pour ce quiz peut être un excellent moyen de garder une trace des informations et des idées importantes.</p>
                            </FormTitle>
                            <TextField
                                    id=""
                                    placeholder={'Add a ' + modalContent}
                                    value={modalContent === 'feedback' ? feedback : note }
                                    onChange={(e) => modalContent === 'feedback' ? setFeedback(e.target.value) : setNote(e.target.value)}
                                    variant='outlined'
                                    type={'text'}
                                    multiline
                                    rows={5}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px' }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{padding: "16px"}}
                                    }}
                                />
                          
                              <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px',  fontSize: isPhone ? '16px' : '18px'}}}  onClick={handleCloseCommentModal}>Annuler</LoadingButton>
                                <LoadingButton loading={feedbackLoading} loadingPosition='end'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display: modalContent === 'feedback' ? 'flex' : 'none', marginLeft: '10px', fontSize: isPhone ? '16px' : '18px'}}}   onClick={()=> handleSmallChanges('feedback')}>Envoyer</LoadingButton>
                                <LoadingButton loading={noteLoading} loadingPosition='end'    sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display: modalContent === 'note' ? 'flex' : 'none', marginLeft: '10px', fontSize: isPhone ? '16px' : '18px'}}}   onClick={()=> handleSmallChanges('note')}>Sauvegarder</LoadingButton>
                              </DialogActions>
                    </Form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Home>
    )
  }
  
}



