import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AccountCircleOutlined, AddCircleOutline, Comment, DeleteOutlineOutlined, DoNotDisturbAltOutlined, Error, ErrorOutlineOutlined, Favorite, FavoriteBorderOutlined, HeartBrokenOutlined, LibraryBooksOutlined, ModeEditOutlineOutlined, NoteOutlined, PeopleAltOutlined, QuizOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

const MyTab = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    ${{...uiSettings.typos.link_mid}};
    color: black;
    width: 280px;
    padding: 0px 10px;
`

const Profile = styled.div`
    width: calc(100% - 20px);
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
`

const ProfileInfo = styled.div`
    //flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0px 10px 10px 20px;
    width: calc(100% - 180px);
`

const AutomedProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`

const PersonalProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 25px;
`

const PersonalProfileContainer = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: colrowmn;
    justify-content: space-between;
    flex-wrap: wrap;
`


const AutomedProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    
`

const Quizzez = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
`

const QuizCart = styled.div`
    width: calc(50% - 20px);
    margin: 10px;
    min-width: 350px;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`

const styles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}

//antd drop image
const { Dragger } = Upload;

const props: UploadProps = {
  name: 'file',
  multiple: true,
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};


// mui tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
function a11yProps(index) {
return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
};
}



export default function AddUser() {
  


const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const Navigate = useNavigate()






  const [loadingBan, setLoadingBan] = useState(false);
  const [displayedFirstName, setDisplayedFirstName ] = useState('');
  const [displayedFamilyName, setDisplayedFamilyName ] = useState('');
  const [displayedFaculty, setDisplayedFaculty ] = useState('');
  const [displayedDescription, setDisplayedDescription ] = useState('');
  const [displayedEmail, setDisplayedEmail ] = useState('');
  const [displayedPassword, setDisplayedPassword ] = useState('');
  const [displayedPhoneNumber, setDisplayedPhoneNumber ] = useState('');
  const [displayedAddress, setDisplayedAddress ] = useState('');
  const [image, setImage] = useState('');
  const [sendNotif, setSendNotif] = useState(false);

  const [errors, setErrors] = useState({first_name: '', family_name: '', faculty: '', description: '', email: '', phone_number: '', password: ''});
  

  const updateError = (propertyName, value) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [propertyName]: value
    }));
  };

const [loadingCreate, setLoadingCreate] = useState(false);

function createStudent (){
  
  setLoadingCreate(true)
  axios.post("/admin/createStudent", {first_name: displayedFirstName, family_name: displayedFamilyName, address: displayedAddress, faculty: displayedFaculty, phone_number: displayedPhoneNumber, description: displayedDescription, image, sendNotif, email: displayedEmail, password: displayedPassword})
  .then(function (response) {
    if(response.data.response === 'done'){
      setLoadingCreate(false)
      Navigate('/admin/users/user/' + response.data.studentId)
    }
  })
  .catch(function (error) {
      // handle error
      setLoadingCreate(false)
      if(error.response && error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else if(error.response && error.response.status === 400 && error.response.data && error.response.data.response === 'invalidParams'){
        setAlertType('error')
        setAlertMessage('Invalid params, check your data.')
        setOpenAlert(true)
        for (let i = 0; i < error.response.data.errors.length; i++) {
          const errorObj = error.response.data.errors[i];
          updateError(errorObj.field, errorObj.message)
        }  
      }else{
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
      }
  })

}




  const [selectedFile, setSelectedFile] = useState(null);
const fileInputRef = useRef(null);

const handleFileChange = async (event) => {
  setSelectedFile(event.target.files[0]);
  await uploadPhoto(event.target.files[0])
};

const handleImageLoad = (event) => {
  const img = event.target;
  if (img.naturalWidth > img.naturalHeight) {
    img.style.width = 'auto';
    img.style.height = '100%';
  } else {
    img.style.width = '100%';
    img.style.height = 'auto';
  }
};

async function uploadPhoto(selectedFile) {
  try {
      if (selectedFile){
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post('/admin/uploadUserPhoto', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setImage(response.data.image)
        setSelectedFile(null);
        // Clear the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        
      }else{
        setAlertType('error')
        setAlertMessage('No image was selected')
        setOpenAlert(true)
      }
  } catch (error) {
    if(error.response && error.response.status === 403){
      Navigate('/admin/login', {replace: 'true'})
  }else{
      console.log(error)
      setAlertType('error')
      setAlertMessage('An unexpected error has occured')
      setOpenAlert(true)
  }
  }
}



  return (
    <Home>
          <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
              <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                  {AlertMessage}
              </Alert>
          </Snackbar>
        <Breadcrumbs aria-label="breadcrumb" >
            <Link underline="hover" color="inherit" to="/admin/users" style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, textDecorationLine: "none"}}}>
                users
            </Link>
            <Link style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, textDecorationLine: "none"}}}
                underline="hover"
                color="inherit"
                href="/admin/users"
            >
                new user
            </Link>
        </Breadcrumbs>
  
          <Buttons>
            <LoadingButton onClick={createStudent} loadingPosition='start' loading={loadingCreate} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow}}} startIcon={<AddCircleOutline />} >Add user</LoadingButton>
          </Buttons>
        
  
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', borderRadius: '15px' }} style={{padding: "20px 10px"}}>
      <div style={{width: '100%'}}>
        <AutomedProfile>
            <AutomedProfileContainer style={{width: 'calc(60% - 30px)', padding: '10px'}}>
                <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>AutoMed profile</p>
  
                <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>faculty</p>
                <TextField
                        id=""
                        placeholder={'Faculty'}
                        value={displayedFaculty}
                        onChange={(e)=> {setDisplayedFaculty(e.target.value)}}
                        variant='outlined'
                        type={'text'}
                        style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}
                        InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
                        }}
                />
  
                <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>biography</p>
                <TextField
                        id=""
                        placeholder={'biography'}
                        variant='outlined'
                        value={displayedDescription}
                        onChange={(e)=> {setDisplayedDescription(e.target.value)}}
                        type={'text'}
                        multiline
                        rows={5}
                        style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '4px 4px 4px 4px', }}}
                        InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{padding: "16px"}}
                        }}
                />
            </AutomedProfileContainer>
            <AutomedProfileContainer style={{width: 'calc(40% - 30px)', padding: '10px',  }}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center !important', backgroundColor: uiSettings.colors.bgbluelight, border: '1px dashed ' + uiSettings.colors.bgblue3,  width: 'calc(100% - 10px)', padding: '5px', borderRadius: '12px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '15px'}} >
                    <div style={{width: "130px", height: '130px', color: 'transparent', backgroundImage: image && image.length > 0 ? 'url("' + image + '")' : 'url("/profile.png")', backgroundSize: 'cover', borderRadius: '8px'}}>.</div>
                </div>
                <p style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.secondary2, margin: '5px 0px 0px 0px', fontSize: '15px', textAlign: "center"}}} >change the profile picture</p>
                <Buttons style={{justifyContent: 'center', marginBottom: '17px'}}>
                    <Button onClick={() => fileInputRef.current.click()}   sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: uiSettings.colors.blue, border: '1px solid ' + uiSettings.colors.blue}}} startIcon={<ModeEditOutlineOutlined />}  >uplaod</Button>
                    <Button  onClick={(e) => { setImage('')}} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '0px', color: uiSettings.colors.white, backgroundColor: uiSettings.colors.red}}} startIcon={<DeleteOutlineOutlined />} >delete</Button>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                </Buttons>
            </div>
            </AutomedProfileContainer>
        </AutomedProfile>
  
        <PersonalProfile>
            <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Personal profile</p>
            <PersonalProfileContainer>
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>First name</p>
                    <TextField
                            id=""
                            placeholder={'First name'}
                            variant='outlined'
                            value={displayedFirstName}
                            onChange={(e)=> {setDisplayedFirstName(e.target.value); updateError('first_name', '')}}
                            error={errors.first_name.length > 0 ? true : false}
                            helperText={errors.first_name}
                            type={'text'}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue,}, ...{}}
                            }}
                    />
                </div> 
  
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Last name</p>
                    <TextField
                            id=""
                            placeholder={'Last name'}
                            variant='outlined'
                            value={displayedFamilyName}
                            onChange={(e)=> {setDisplayedFamilyName(e.target.value); updateError('family_name', '')}}
                            error={errors.family_name.length > 0 ? true : false}
                            helperText={errors.family_name}
                            type={'text'}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                            }}
                    />
                </div>  
  
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Phone number</p>
                    <TextField
                            id=""
                            placeholder={'Phone number'}
                            variant='outlined'
                            defaultValue={displayedPhoneNumber}
                            onChange={(e)=> {setDisplayedPhoneNumber(e.target.value); updateError('phone_number', '')}}
                            type={'number'}
                            error={errors.phone_number.length > 0 ? true : false}
                            helperText={errors.phone_number}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                            }}
                    />
                </div>     
  
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Address</p>
                    <TextField
                            id=""
                            placeholder={'Address'}
                            variant='outlined'
                            value={displayedAddress}
                            onChange={(e)=> {setDisplayedAddress(e.target.value)}}
                            type={'text'}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                            }}
                    />
                </div>   
  
            </PersonalProfileContainer>
        </PersonalProfile>

        <PersonalProfile>
            <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Login credentials</p>
            <PersonalProfileContainer>
  
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Email *</p>
                    <TextField
                            id=""
                            placeholder={'Email'}
                            variant='outlined'
                            value={displayedEmail}
                            error={errors.email.length > 0 ? true : false}
                            helperText={errors.email}
                            type={'email'}
                            onChange={(e)=> {setDisplayedEmail(e.target.value); updateError('email', '')}}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                            }}
                    />
                </div> 
  
                <div style={{width: '45%'}}>
                    <p style={{ ...uiSettings.typos.heading5, ...{color: uiSettings.colors.headingColor, margin: '14px 0px 5px 0px'}}}>Password *</p>
                    <TextField
                            id=""
                            placeholder={'Password'}
                            variant='outlined'
                            value={displayedPassword}
                            error={errors.password.length > 0 ? true : false}
                            helperText={errors.password}
                            type={'text'}
                            onChange={(e)=> {setDisplayedPassword(e.target.value); updateError('password', '')}}
                            style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', }}
                            InputProps={{
                                    style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue}, ...{}}
                            }}
                    />
                </div>     

                <div style={{width: '45%', marginTop: '20px'}}>
                    <FormControlLabel
                      value="end"
                      control={<Switch color="primary" checked={sendNotif} onChange={(e)=> setSendNotif(e.target.checked)} />}
                      label="Send email with login credentials to the new user."
                      labelPlacement="end"
                    />
                </div>

    
                
            </PersonalProfileContainer>
        </PersonalProfile>
        
      </div>
      
    </Box>
  
    <Buttons>
      <LoadingButton onClick={()=> createStudent()}  loadingPosition='start' loading={loadingCreate} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow}}} startIcon={<AddCircleOutline />} >Add user</LoadingButton>
    </Buttons>
  
    </Home>
  )
}
