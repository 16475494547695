import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import { CircularProgress, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'


const Home = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${uiSettings.colors.bgBlue2};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Container = styled.div`
    width: 60%;
    min-width: ${props => (props.width < 800 ? 'calc(100% - 20px)' : '60%')};
    height: ${props => (props.width < 800 ? 'calc(100% - 20px)' : '80vh')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${uiSettings.colors.white};
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px #0000000D;
`

const ContainerImage = styled.div`
    height: 80vh;
    width: 40%;
    background-image: url('/bg1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: ${props => (props.width < 800 ? 'none' : 'block')};
`

const ContainerForm = styled.div`
    height: 80vh;
    width: 60%;
    background-color: ${uiSettings.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Form = styled.div`
    width: calc(100% - 150px) ;
    min-width: 300px;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
`

export default function ResetPassword({windowSize, setOpenAlert, setAlertType, setAlertMessage}) {
    
    const [password, setPassword] = useState('');


    const [buttonStyle, setButtonStyle] = useState({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary});
    
    const [buttonDisabled, setButtonDisabled] = useState(true);
    
    const [buttonLoading, setButtonLoading] = useState(false);

 



    useEffect(() => {
        if( password.length > 8){
            setButtonStyle({color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow})
            setButtonDisabled(false)
        } else {
            setButtonStyle({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary})
            setButtonDisabled(true)
        }
    }, [password]);



    

    const navigate = useNavigate()

   

    function handleSubmit(){
        setButtonLoading(true)
        axios.post("/admin/reset_password", {
            password, code, id
          })
              .then(function (response) {
                setButtonDisabled(false)
                setButtonLoading(false)
                if (response.data.response === 'done'){
                    setAlertType('success')
                    setAlertMessage('Password changed.')
                    setOpenAlert(true)
                    setTimeout(() => {
                        navigate('/admin/login', {replace: true})
                    }, 500);
                }else if(response.data.response === 'error'){
                    setAlertType('error')
                    setAlertMessage(response.data.error)
                    setOpenAlert(true) 
                }
              })
              .catch(function (error) {
                setButtonLoading(false)
                setButtonDisabled(false)
                console.log(error.response)
                //handleError(error)
                setAlertType('error')
                setAlertMessage('An error has occured.')
                setOpenAlert(true)
              })
    }


     // check login

     const [authenticated, setAuthenticated] = useState(false);
     const [loading, setLoading] = useState(true);
     const [type, setType] = useState('Login');
     const [code, setCode] = useState('');
     const [id, setId] = useState('');
     const [getValue, setGetValue] = useState(false);
   
     useEffect(() => {
       // Function to parse the query string from the URL
       const getParamsFromURL = () => {
         const searchParams = new URLSearchParams(window.location.search);
         const idParam = searchParams.get('id');
         const codeParam = searchParams.get('code');
         
         // Update state with the retrieved parameters
         setId(idParam);
         setCode(codeParam);
         setGetValue(true)
       };
   
       // Call the function to parse URL parameters when the component mounts
       getParamsFromURL();
     }, []);
 
     useEffect(() => {
        if(getValue){
            axios.post("/admin/check_reset_password_link", { code ,  id})
            .then(function (response) {
                console.log(response.data)
               if(response.data.response === 'allowed'){
                   setAllowed(true)
                   setLoading(false)
                 }else{
                   setLoading(false)
                   setAllowed(false)
                 }
            })
            .catch(function (error) {
                   setAlertType('error')
                   setOpenAlert(true)
                   setAlertMessage('An error has occured.')
                   setTimeout(() => {
                     navigate('/admin/login', {replace: true})
                   }, 2000);
            })
        }
     }, [getValue]);


     const [allowed, setAllowed] = useState(false);

  return (
    <Home>
        {
            [''].map(item => {
                if(loading){
                    return(
                        //<Container width={windowSize.width}>
                            <CircularProgress style={{alignSelf: 'center'}}/>
                        //</Container>
                    )
                }else{
                    if(allowed){
                        return(
                            <Container width={windowSize.width}>
                                <ContainerImage width={windowSize.width} />
                                <ContainerForm>
                                    <Form>
                                        <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', borderBottom: '2px solid ' + uiSettings.colors.yellow, width: 'max-content', alignSelf: 'center', marginBottom: '44px'} }}>Reset password</p>
                                        <TextField
                                            id=""
                                            placeholder={'New password *'}
                                            variant='standard'
                                            type={'password'}
                                            value={password}
                                            onChange={(e) => {setPassword(e.target.value)}}
                                            InputProps={{
                                                    style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, }}
                                            }}
                                        />
    
                                        <LoadingButton loading={buttonLoading} loadingPosition='end' onClick={handleSubmit} style={{...uiSettings.typos.paragraphDesktop, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}, ...buttonStyle}} disabled={buttonDisabled} >
                                            Submit
                                        </LoadingButton>
    
                                        <p onClick={()=> navigate('/admin/login', {replace: true})} style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px', display: type === 'Signup' ? 'none' : 'unset', cursor: 'pointer'}}}>
                                            Back to login
                                        </p>
    
                                    </Form>
                                </ContainerForm>
                            </Container>
                        )
                    }else{
                        return(
                            <Container width={windowSize.width}>
                                <ContainerImage width={windowSize.width} />
                                <ContainerForm>
                                    <Form>
                                        <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', width: 'max-content', alignSelf: 'center', marginBottom: '0px'} }}>Invalid link !</p>
                                        <p style={{...uiSettings.typos.paragraphDesktop, ...{textAlign: 'center', padding: '0px 8px', width: 'max-content', alignSelf: 'center', marginBottom: '50px', color: uiSettings.colors.secondary} }}>This link is not valid anymore.</p>
                                    
                                        <p onClick={()=> navigate('/admin/login', {replace: true})} style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px', display: type === 'Signup' ? 'none' : 'unset', cursor: 'pointer'}}}>
                                            Back to login
                                        </p>
    
                                    </Form>
                                </ContainerForm>
                            </Container>
                        )
                    }
                }
            })
        }
        {/* <Container width={windowSize.width}>
            <ContainerImage width={windowSize.width} />
            <ContainerForm>
                <Form>
                    <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', borderBottom: '2px solid ' + uiSettings.colors.yellow, width: 'min-content', alignSelf: 'center', marginBottom: '44px'} }}>Login</p>
                    <TextField
                        id=""
                        placeholder={'Email'}
                        variant='standard'
                        type={'email'}
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); handlechange('email')}}
                        InputProps={{
                                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, }}
                        }}
                        error={emailError.error}
                        helperText={emailError.text}
                    />
                    <TextField
                        id=""
                        placeholder={'Password'}
                        onChange={(e)=> {setPassword(e.target.value); handlechange('password')}}
                        value={password}
                        variant='standard'
                        type={'password'}
                        InputProps={{
                                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, marginTop: '20px' }}
                        }}
                        error={passwordError.error}
                        helperText={passwordError.text}
                    />

                    <LoadingButton onClick={handleSubmit} style={{...uiSettings.typos.paragraphDesktop, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}, ...buttonStyle}} disabled={buttonDisabled} >
                        Submit
                    </LoadingButton>

                    <p style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px'}}}>
                        Forgot your password?
                    </p>

                </Form>
            </ContainerForm>
        </Container> */}
    </Home>
  )
}
