import { ErrorOutline, KeyboardArrowRight, NotificationsActive, PeopleAltOutlined, QuizOutlined, Sort } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Button, CircularProgress, IconButton, Pagination, Snackbar, Tooltip } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

export default function Exams({isPhone, isSidebareOpened, handleError, windowSize}) {
    const Navigate = useNavigate()
    const [exams, setExams] = useState([]);
    const [sortBy, setSortBy] = useState('date');
    const [changes, setChanges] = useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [AlertType, setAlertType] = React.useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    function sortExams(sort){
        setPage(1)
        const list = exams
        if(sort === 'date'){
            list.sort((a, b) => b.users - a.users);
        }else{
            list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        setExams(list)
        setChanges(changes + 1)
    }

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const handleChange = (event, value) => {
        setPage(value);
        console.log('New Page:', value); // You can handle the new value here
    };
    const [loading, setLoading] = useState(true);

    function getExams (data){
        axios.post("/student/getAllExams", {id: data})
        .then(function (response) {
            console.log(response)
            if(response.data.step === 'add_name'){
                Navigate('/auth/full-name', { replace: true });
            }else if (response.data.step === 'add_phone'){
                Navigate('/auth/phone-number', { replace: true });
            }else{
                setLoading(false)
                setExams(response.data.exams)
                const numberOfGroups = Math.ceil(response.data.exams.length / 12);
                setPages(numberOfGroups)
            }
           
        })
        .catch(function (error) {
            // handle error
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        })
    }

    const isMounted = useRef(true);
    
    useEffect(() => {
        return () => {
        // Cleanup function to set isMounted to false when component unmounts
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            getExams()
        }
    }, []);

    const [loadingExam, setLoadingExam] = useState('');

  function createSessionExam(examId){
    setLoadingExam(examId)
    axios.post("/student/createSessionExam", {id: examId})
        .then(function (response) {
          console.log('changed')
          if(response.data.step === 'add_name'){
            Navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            Navigate('/auth/phone-number', { replace: true });
          }else{
            Navigate('/dashboard/allSessions'); 
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            Navigate('/auth/identifier', { replace: true });
          }else{
            console.log(error.response)
            setLoadingExam('')
            handleError(error)
          }
        })
  } 

    if(loading){
        return(
            <Home style={{justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', height: 'calc(100vh - 140px)'}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                <CircularProgress />
            </Home> 
        )
    }else{
        return (
          <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened}>
              <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                  <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
                      {AlertMessage}
                  </Alert>
              </Snackbar>
      
              <p style={{...uiSettings.typos.heading1, ...{color: uiSettings.colors.headingColor, marginBottom: '20px'}}}>tous <span style={{color: uiSettings.colors.blue}}> les examens</span></p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}} >
                  <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}} >Total: {exams.length} examens</p>
                  <div style={{flex: 1}} ></div>
                <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px', backgroundColor:  uiSettings.colors.bgblue, color: uiSettings.colors.headingColor}}} startIcon={<Sort />} onClick={()=> {sortBy === 'date' ? setSortBy('users') : setSortBy('date'); sortExams(sortBy === 'date' ? 'date' : 'users')}} >{sortBy === 'date' ? 'Trier par popularité' : 'Trier par date'}</Button>
              </div>
      
              <Container style={{minHeight: '400px'}}>
            
                          {
                              loading === true ?
                              <div style={{width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                  <CircularProgress style={{justifyContent: 'center', alignSelf: 'center'}}/>
                              </div>
                              :
                              (
                                  exams && exams.length === 0 ?
                                  <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                                      <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                                      <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                                  </div>
                                  :
                                  exams.map((item, index)=>{
                                      const types = item.types ? JSON.parse(item.types) : []
                                      if(index >( page * 12 - 12 - 1) && index < page * 12){
                                          return(
                                              <QuizCart key={index} width={windowSize.width}>
                                                  <QuizHeader1 index={index % 3 + 1}>
                                                      <QuizAction>
                                                          {/* <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.createdAt ? item.createdAt.slice(0,10) : ''}</p> */}
                                                          <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>{item.year}</p>
          
                                                          {/* <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton> */}
                                                          {/* <IconButton style={{color: uiSettings.colors.red}} onClick={()=> {setSelectedExam(item.id); setOpenDeleteSessionModal(true)}}><DeleteOutlineOutlined/> </IconButton> */}
                                                      </QuizAction>
                                                      <Tooltip title={item.name} placement="top">
                                                          <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                                                      </Tooltip>
          
                                                  </QuizHeader1>
                                                  <QuizContainer>
                                                      <Tags>
                                                          {
                                                              types.map((type, key)=>{
                                                                  return(
                                                                      <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                                                  )
                                                              })
                                                          }  
                                                          
                                                      </Tags>
                                                      <Tooltip title={item.description} placement="top">
                                                          <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>{item.description && item.description.length > 100 ? item.description.slice(0,100) + ".." : item.description} <br/><span style={{color: uiSettings.colors.headingColor}}></span></p>
                                                      </Tooltip>                                     
                                                      <QuizBottom>
                                                          <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                                              <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> {item.users} révisions</p>
                                                              <LoadingButton sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowRight />} style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow, padding: '5px 10px'}}}  onClick={()=> createSessionExam(item.id)} loading={loadingExam === item.id ? true : false} loadingPosition="end" disabled={loadingExam === '' ? false :  true} >Réviser</LoadingButton>
                                                          </div>
                                                      </QuizBottom>
                                                  </QuizContainer>
                                              </QuizCart>
                                          )
                                      }
                                  })
      
                              )
      
                          }
            
                        
                    </Container>
                    <Pagination page={page} onChange={handleChange} count={pages} variant="outlined" shape="rounded" style={{alignSelf: 'center', margin: '10px', marginTop: '20px'}} />
                   
          </Home>
        )
    }
}


const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
`

const Container = styled.div`
    width: 100%;
    //height: calc(100vh - 150px);
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 15px;
`

const QuizCart = styled.div`
    width: calc(33.33% - 20px);
    min-width: 250px;
    margin: 10px;
    min-width: ${props => props.width < 600 ? '80%' : '350px'} ;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: ${props => props.index === 1 ? 'url("/images/bg-blue-circles.svg")' : (props.index === 2 ? 'url("/images/bg-orange-circles.svg")' : (props.index === 3 ? 'url("/images/bg-yellow-circles.svg")' : (props.index === 4 ? 'url("/images/blue-bg-rectangle.svg")' :  'url("/images/yellow-bg-rectangle.svg")'  ) ) ) } ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizHeader1 = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: ${props => props.index === 1 ? 'url("/images/yellow-bg-rectangle.svg")' : (props.index === 2 ? 'url("/images/yellow-bg-rectangle.svg")' : (props.index === 3 ? 'url("/images/yellow-bg-rectangle.svg")' : (props.index === 4 ? 'url("/images/yellow-bg-rectangle.svg")' :  'url("/images/yellow-bg-rectangle.svg")'  ) ) ) } ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`

const Mystyles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}