import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { Add, Cancel, CancelOutlined, Check, CheckCircleOutlineOutlined, CheckOutlined, Close, DeleteOutlineOutlined, Error, Filter, FilterList, History, Image, KeyboardArrowDown, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, OpenInNew, RemoveRedEyeOutlined, RequestPage, Subscriptions } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const Buttons = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`



const MyTab = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    ${{...uiSettings.typos.link_mid}};
    color: black;
    width: 280px;
    padding: 0px 10px;
`

const Profile = styled.div`
    width: calc(100% - 20px);
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
`

const ProfileInfo = styled.div`
    //flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0px 10px 10px 20px;
    width: calc(100% - 180px);
`

const AutomedProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`

const PersonalProfile = styled.div`
    width: calc(100% - 20px) !important;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 0px;
`

const PersonalProfileContainer = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: colrowmn;
    justify-content: space-between;
    flex-wrap: wrap;
`


const AutomedProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    
`









const userData = [
  {
    id: 1,
    username: "user1",
    email: "user1@example.com",
    university: "University A",
    score: 85,
    year: 2022,
  },
  {
    id: 2,
    username: "user2",
    email: "user2@example.com",
    university: "University B",
    score: 92,
    year: 2023,
  },
  {
    id: 3,
    username: "user3",
    email: "user3@example.com",
    university: "University C",
    score: 78,
    year: 2022,
  },
  {
    id: 4,
    username: "user4",
    email: "user4@example.com",
    university: "University A",
    score: 95,
    year: 2023,
  },
  // Add more user objects as needed
];

// You can now use the userData array with id values for each user


  // mui tabs
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
  }



export default function AbonnementRequests() {
  const [selectedStudent, setSelectedStudent] = useState({});
  const navigate = useNavigate()

  const columns = [
    { field: 'code', headerName: 'Reference', width: 120,
    renderCell: (params) => {
      return (
          <span style={{fontSize: '15px', color: uiSettings.colors.headingColor,}}>{params.row.code? params.row.code : '--'}</span> 
      );
    }, },
    { 
      field: 'student', 
      headerName: 'Student', 
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span style={{ fontSize: '15px'}}>{params.row.student ? params.row.student.family_name + ' ' + params.row.student.first_name : ''}</span> 
            <IconButton onClick={()=> window.open('/admin/users/user/' + (params.row.student ? params.row.student.id : ''), '_blank')} style={{fontSize: '20px', padding: '5px', marginLeft: '5px', color: uiSettings.colors.blue}}><OpenInNew style={{fontSize: '19px'}}/></IconButton>
          </>
          
        );
      },
    },
    { field: 'offer', headerName: 'Offer', width: 180,
      renderCell: (params) => {
        return (
            <span style={{fontSize: '15px'}}>{params.row.offer? params.row.offer.title : '--'} <IconButton onClick={()=> window.open('/admin/Abonnement/' + (params.row.offer ? params.row.offer.id : ''), '_blank')} style={{fontSize: '15px', padding: '5px', marginLeft: '0px', color: uiSettings.colors.blue}}><OpenInNew style={{fontSize: '19px'}} /></IconButton></span> 
        );
      }, },
    {
      field: 'price',
      headerName: 'Price',
      width: 130,  
      renderCell: (params) => {
        return (
            <span style={{fontSize: '16px'}}>{params.row.offer? params.row.offer.price : '0'}.00 DZD</span> 
        );
      },  
    },
    { 
      field: 'receipt', 
      headerName: '', 
      width:  50,
      // columnMenu: false,
      // sortable: false,
      // menubar: false,
      // headerComponent: CustomHeader,
      renderCell: (params) => {
        return (
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <IconButton onClick={()=> handleClickOpenShowReceipt(params.row.receipt)}  variant='contained' style={{color: uiSettings.colors.white, backgroundColor: uiSettings.colors.blue, padding: '5px'}}><Image/></IconButton>
          </div>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 120,  
      renderCell: (params) => {
        return (
            <span style={{fontSize: '16px'}}>{params.row.createdAt? params.row.createdAt.split('T')[0] : '--'}</span> 
        );
      },  
    },
    
    { 
      field: 'Actions', 
      headerName: '', 
      width:  150,
      // columnMenu: false,
      // sortable: false,
      // menubar: false,
      // headerComponent: CustomHeader,
      renderCell: (params) => {
        return (
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <LoadingButton loadingPosition='end' loading={loadingRequests.includes(params.row.id)} onClick={()=> approveRequest(params.row.id)} endIcon={<CheckCircleOutlineOutlined />} style={{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow,}}>Approve</LoadingButton>
            <IconButton onClick={()=>{setSelectedRequest(params.row.id); setOpenRefuseRequest(true)}} style={{padding: '5px'}}><Close style={{fontSize: '20px'}} /></IconButton>
          </div>
        );
      },
    },
    
  ];

  const columnsHistories = [
    { 
      field: 'student', 
      headerName: 'Student', 
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span style={{color: uiSettings.colors.headingColor, fontSize: '17px'}}>{params.row.student ? params.row.student.family_name + ' ' + params.row.student.first_name : ''}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'title', 
      headerName: 'Title', 
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <span style={{color: uiSettings.colors.secondary, fontSize: '15px'}}>{params.row.offer ? params.row.offer.title : '--'}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'admin', 
      headerName: 'Added by', 
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <span style={{fontSize: '15px'}}>{params.row.addedBy}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'date', 
      headerName: 'Added in', 
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <span style={{fontSize: '15px'}}>{ params.row.createdAt ? params.row.createdAt.split('T')[0] : '--'}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'year', 
      headerName: 'Year', 
      width: 140,
      renderCell: (params) => {
        const colors = [{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'},{color: 'rgba(93, 34, 218, 1)', bgColor: 'rgba(93, 34, 218, 0.15)'},{color: 'rgba(251, 116, 75, 1)', bgColor: 'rgba(251, 116, 75, 0.15)'},{color: 'rgba(27, 32, 85, 1)', bgColor: 'rgba(27, 32, 85, 0.15)'},{color: 'rgba(255, 201, 37, 1)', bgColor: 'rgba(255, 193, 8, 0.15)'},{color: 'rgba(111, 207, 151, 1)', bgColor: 'rgba(111, 207, 151, 0.15)'}]
        const random = Math.floor(Math.random() * (4 - 0 + 1)) + 0
        var number = 1
        if(params.row.offer && params.row.offer.year && params.row.offer.year.length > 1){
          number = Number(!isNaN(params.row.offer.year[0]) ? params.row.offer.year[0] : 1 )
        }
        return (
          <>
            <span style={{padding: '8px 16px', borderRadius: '50px', backgroundColor: colors[number - 1].bgColor , color: colors[number - 1].color, fontSize: '16px'}}>{params.row.offer && params.row.offer.year ? params.row.offer.year : '--'}</span> 
          </>
          
        );
      },
    },
  ];
  
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabSelected, setTabSelected] = useState('request');

  const [abonnements, setAbonnements] = useState([
    {
      id: 11,
      title: "Offer 1",
      price: 10.99,
      admin: "Admin1",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-07T00:00:00.000Z"
  },
  {
      id: 21,
      title: "Offer 2",
      price: 15.49,
      admin: "Admin2",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-14T00:00:00.000Z"
  },
  {
      id: 31,
      title: "Offer 3",
      price: 8.99,
      admin: "Admin3",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-21T00:00:00.000Z"
  },
  {
      id: 41,
      title: "Offer 4",
      price: 20.99,
      admin: "Admin4",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-06-28T00:00:00.000Z"
  },
  {
      id: 51,
      title: "Offer 5",
      price: 12.99,
      admin: "Admin5",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-07-05T00:00:00.000Z"
  },
  {
      id: 16,
      title: "Offer 6",
      price: 18.49,
      admin: "Admin6",
      year: 2024,
      createdAt: "2024-05-07T00:00:00.000Z",
      expDate: "2024-07-12T00:00:00.000Z"
  },
    {
        id: 1,
        title: "Offer 1",
        price: 10.99,
        admin: "Admin1",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-06-07T00:00:00.000Z"
    },
    {
        id: 2,
        title: "Offer 2",
        price: 15.49,
        admin: "Admin2",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-06-14T00:00:00.000Z"
    },
    {
        id: 3,
        title: "Offer 3",
        price: 8.99,
        admin: "Admin3",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-06-21T00:00:00.000Z"
    },
    {
        id: 4,
        title: "Offer 4",
        price: 20.99,
        admin: "Admin4",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-06-28T00:00:00.000Z"
    },
    {
        id: 5,
        title: "Offer 5",
        price: 12.99,
        admin: "Admin5",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-07-05T00:00:00.000Z"
    },
    {
        id: 6,
        title: "Offer 6",
        price: 18.49,
        admin: "Admin6",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-07-12T00:00:00.000Z"
    },
    {
        id: 7,
        title: "Offer 7",
        price: 9.99,
        admin: "Admin7",
        year: 2024,
        createdAt: "2024-05-07T00:00:00.000Z",
        expDate: "2024-07-19T00:00:00.000Z"
    }
  ])

  // mui menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const [sortBy, setSortBy] = useState('All');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [offers, setOffers] = useState([]);
  const Navigate = useNavigate()
  const [requests, setRequests] = useState([]);
  const [histories, setHistories] = useState([]);
  
  function getStudents (data){
    axios.post("/admin/getAbonnementRequests", {id: data})
    .then(function (response) {
        console.log(response.data)
        if(response.data.response === 'done'){
            setLoading(false)
            setRequests(response.data.requests)
            setHistories(response.data.histories)
            // setStudents(response.data.students)
        }
    })
    .catch(function (error) {
        // handle error
        if(error.response && error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            console.log(error)
            setAlertType('error')
            setAlertMessage('An unexpected error has occured')
            setOpenAlert(true)
        }
    })
}

    const isMounted = useRef(true);
      
    useEffect(() => {
      return () => {
      // Cleanup function to set isMounted to false when component unmounts
      isMounted.current = false;
      };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
          getStudents()
        }
    }, []);

  const [orderType, setOrderType] = useState('Alphabetically');

  useEffect(() => {
    if(orderType === 'Alphabetically'){
      function orderByFirstName(arr) {
        return arr.slice().sort((a, b) => {
          const firstNameA = a.first_name ? a.first_name.toLowerCase() : '';
          const firstNameB = b.first_name ? b.first_name.toLowerCase() : '';
      
          if (firstNameA === '' && firstNameB !== '') {
            return 1;
          }
          if (firstNameB === '' && firstNameA !== '') {
            return -1;
          }
      
          if (firstNameA < firstNameB) {
            return -1;
          }
          if (firstNameA > firstNameB) {
            return 1;
          }
          return 0;
        });
      }
      const sortedStudents = orderByFirstName(students);
      console.log(sortedStudents)
      setStudents(sortedStudents)
    }else if (orderType === 'Sessions created'){
      function orderByStudentSessionLength(arr) {
        return arr.slice().sort((a, b) => {
          const sessionLengthA = a.studentSession ? a.studentSession.length : 0;
          const sessionLengthB = b.studentSession ? b.studentSession.length : 0;
      
          return sessionLengthB - sessionLengthA; // Sort in descending order
        });
      }
      const sortedStudents = orderByStudentSessionLength(students);
      console.log(sortedStudents)
      setStudents(sortedStudents)
    }
  }, [orderType]);



const [openShowReceipt, setOpenShowReceipt] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [image, setImage] = React.useState('');
    
  function handleClickOpenShowReceipt(image) {
    setImage(image)
    setOpenShowReceipt(true);
    setScroll("body");
  };

  const handleCloseShowReceipt = () => {
    setOpenShowReceipt(false);
  };

  const [openRefuseRequest, setOpenRefuseRequest] = useState(false);
    const onCloseRefuseRequest = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setReason('')
      setOpenRefuseRequest(false);
    };

    const [loadingCancelRequest, setLoadingCancelRequest] = useState(false);
    const [changes, setChanges] = useState(0);
    function cancelRequest() {
      setLoadingCancelRequest(true)
      axios.post("/admin/cancelRequest", {id: selectedRequest, reason})
       .then(function (response) {
         if(response.data.response === 'done'){
           // remove the request from the list
            var data = requests
            data = data.filter((item) => item.id !== selectedRequest)
            setRequests(data)
            setChanges(changes + 1)
          }else if (response.data.response === 'params_error'){
            setAlertMessage(response.data.message)
            setAlertType('error')
            setOpenAlert(true)
          }
          setLoadingCancelRequest(false)
          setOpenRefuseRequest(false)
       })
       .catch(function (error) {
            setLoadingCancelRequest(false)
            console.log(error)
            // handleError(error)
            setAlertMessage('An unexpected error has occured.')
            setAlertType('error')
            setOpenAlert(true)
       })
    }


    const [loadingRequests, setLoadingRequests] = useState([]);

    function approveRequest(id) {
      var data = loadingRequests
      data.push(id)
      setLoadingRequests(data)
      setChanges(changes + 1)

      axios.post("/admin/approveRequest", {id})
       .then(function (response) {
        console.log(response)
         if(response.data.response === 'done'){
            // remove the request from the list
            var data = requests
            data = data.filter((item) => item.id !== id)
            setRequests(data)
            setChanges(changes + 1)
          }else if (response.data.response === 'notFound'){
            var data = loadingRequests
            data = data.filter((item) => item !== id)
            setLoadingRequests(data)
            setChanges(changes + 1)
            setAlertMessage('Request not found.')
            setAlertType('error')
            setOpenAlert(true)
          }
       })
       .catch(function (error) {
            setLoadingCancelRequest(false)
            console.log(error)
            // handleError(error)
            data = data.filter((item) => item !== id)
            setLoadingRequests(data)
            setChanges(changes + 1)
            setAlertMessage('An unexpected error has occured.')
            setAlertType('error')
            setOpenAlert(true)
       })
    }

    const [selectedRequest, setSelectedRequest] = useState('');
    const [reason, setReason] = useState('');

  return (
    <Home>
      <p style={{ ...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '15px'}}}>Abonnement requests</p>
      <Header>
        <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}>Total: {requests.length} requests</p>
        <Buttons>
          <Button  sx={{ textTransform: 'none' }} onClick={()=> {Navigate('/admin/Abonnement/new')}} style={{ ...uiSettings.typos.adminButtonYellow}} startIcon={<Add />} >Add an abonnemnet</Button>
        </Buttons>
      </Header>
      

      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', borderRadius: '15px' }}
      style={{padding: "20px 10px"}}
      >
        <Tabs
          style={{width: '200px', alignItems: 'end'}}
          orientation="vertical"
          //variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab onClick={()=> setTabSelected('request')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'request' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><Subscriptions style={{marginRight: '8px'}}/> Requests</MyTab>} {...a11yProps(0)} />
          <Tab onClick={()=> setTabSelected('history')} sx={{ textTransform: 'none' }} label={<MyTab style={{color: tabSelected === 'history' ? uiSettings.colors.headingColor : uiSettings.colors.secondary, width: '200px'}}><History style={{marginRight: '8px'}}/> History</MyTab>} {...a11yProps(1)} />
        </Tabs>
      
        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={0}>
         
  
          <PersonalProfile>
              {/* <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Abonnement requests</p> */}
              <div style={{ height: 'calc( 100vh - 260px)', width: '100%', marginTop: '0px'}}>
                <DataGrid
                  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
                  rows={requests}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 50 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection={false}
                  rowSelection={false}
                  disableRowSelectionOnClick
                  //loading={loading}
                />
              </div>  
          </PersonalProfile>
  
          
          
        </TabPanel>

        <TabPanel style={{width: 'calc(100% - 200px)'}} value={value} index={1}>
         
  
        <PersonalProfile>
              {/* <p style={{ ...uiSettings.typos.heading5, ...{paddingLeft: '15px', borderLeft: 'solid 4px ' + uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginBottom: '10px'}}}>Abonnement requests</p> */}
              <div style={{ height: 'calc( 100vh - 260px)', width: '100%', marginTop: '0px'}}>
                <DataGrid
                  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
                  rows={histories}
                  columns={columnsHistories}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 50 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  //checkboxSelection
                  disableRowSelectionOnClick
                  rowSelection={false}
                  //loading={loading}
                />
              </div>  
          </PersonalProfile>
 
         
         
       </TabPanel>


        
      </Box>

      <Dialog
          open={openShowReceipt}
          onClose={handleCloseShowReceipt}
          scroll={scroll}
          fullWidth={true}
          maxWidth={"lg"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          
      >
          <DialogContent  dividers={scroll === 'paper'} style={{backgroundColor: 'transparent', padding: '0px'}}>
              <img src={"/admin/getReceipt/" + image} style={{width: '100%', height: 'auto'}} />                
          </DialogContent>
      </Dialog>


        <Dialog open={openRefuseRequest} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Rejection of the request</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Do you want to reject this request?</p>
                <TextField
                      id=""
                      placeholder={'Reason for rejection'}
                      variant='outlined'
                      value={reason}
                      onChange={(e)=> {setReason(e.target.value)}}
                      type={'text'}
                      style={{width: "100%", backgroundColor: 'white', borderRadius: '4px 4px 4px 4px', marginTop: '10px', }}
                      InputProps={{
                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, backgroundColor: uiSettings.colors.bgblue,}, ...{}}
                      }}
                />
            </DialogContent>

            <DialogActions>
                <LoadingButton   sx={{ textTransform: 'none' }} onClick={()=> {onCloseRefuseRequest()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</LoadingButton>
                <LoadingButton loading={loadingCancelRequest} loadingPosition='end'   sx={{ textTransform: 'none' }} onClick={()=> {cancelRequest()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Reject</LoadingButton>
            </DialogActions>
        </Dialog>

        

      <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
              {AlertMessage}
          </Alert>
      </Snackbar>
    </Home>
  )
}
