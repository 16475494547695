import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import { CircularProgress, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'


const Home = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${uiSettings.colors.bgBlue2};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Container = styled.div`
    width: 60%;
    min-width: ${props => (props.width < 800 ? 'calc(100% - 20px)' : '60%')};
    height: ${props => (props.width < 800 ? 'calc(100% - 20px)' : '80vh')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${uiSettings.colors.white};
    align-self: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px #0000000D;
`

const ContainerImage = styled.div`
    height: 80vh;
    width: 40%;
    background-image: url('/bg1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: ${props => (props.width < 800 ? 'none' : 'block')};
`

const ContainerForm = styled.div`
    height: 80vh;
    width: 60%;
    background-color: ${uiSettings.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Form = styled.div`
    width: calc(100% - 150px) ;
    min-width: 300px;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
`

export default function Login({windowSize, setOpenAlert, setAlertType, setAlertMessage}) {
    
    const [email, setEmail] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState({error: false, text: ''});
    const [resetEmailError, setResetEmailError] = useState({error: false, text: ''});
    const [passwordError, setPasswordError] = useState({error: false, text: ''});

    const [buttonStyle, setButtonStyle] = useState({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary});
    const [resetButtonStyle, setResetButtonStyle] = useState({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary});
    
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [resetButtonDisabled, setResetButtonDisabled] = useState(true);
    
    const [buttonLoading, setButtonLoading] = useState(false);
    const [resetButtonLoading, setResetButtonLoading] = useState(false);

    useEffect(() => {
        if(validator.isEmail(resetEmail)){
            setResetButtonStyle({color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow})
            setResetButtonDisabled(false)
        } else {
            setResetButtonStyle({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary})
            setResetButtonDisabled(true)
        }
    }, [resetEmail]);


    function handlechange(item){
        if(item === 'email'){
            setEmailError({error: false, text: ''})
        }else if(item === 'password'){
            setPasswordError({error: false, text: ''})
        }
        
    }

    useEffect(() => {
        if(email.length > 0 && password.length > 0){
            setButtonStyle({color: uiSettings.colors.black, backgroundColor: uiSettings.colors.yellow})
            setButtonDisabled(false)
        } else {
            setButtonStyle({color: uiSettings.colors.white, backgroundColor: uiSettings.colors.secondary})
            setButtonDisabled(true)
        }
    }, [email, password]);



    

    const navigate = useNavigate()
    const [ResetEmailSent, setResetEmailSent] = useState(false);

    function handleSubmitReset(){
        setResetButtonLoading(true)
        console.log('here')
        axios.post("/admin/forget-password", {
            email: resetEmail
          })
              .then(function (response) {
                setResetButtonDisabled(false)
                setResetButtonLoading(false)
                if(response.data.response === 'done'){
                    setResetEmailSent(true)
                }else{
                    setAlertType('error')
                    setAlertMessage(response.data.error)
                    setOpenAlert(true)
                }
              })
              .catch(function (error) {
                console.log(error)
                setResetButtonDisabled(false)
                setResetButtonLoading(false)
                  setAlertType('error')
                  setAlertMessage('An error has occured4')
                  setOpenAlert(true)
              })
    }

    function handleSubmit(){
        setButtonLoading(true)
        axios.post("/admin/" + type, {
            email, password
          })
              .then(function (response) {
                if(type === 'Signup'){
                    setAlertMessage('New admin account created.')
                    setAlertType('success')
                    setOpenAlert(true)
                    setButtonLoading(false)
                    setTimeout(() => {
                        navigate('/admin/Dashboard', {replace: true})
                    }, 2000);
                }else{
                    navigate('/admin/Dashboard', {replace: true})
                }
              })
              .catch(function (error) {
                console.log(error)
                setButtonLoading(false)
                console.log(error.response)
                if(error.response.status === 400 && error.response.data.response === 'invalid_params' ){
                  const errors = error.response.data.errors 
                  for (let index = 0; index < errors.length; index++) {
                    const error = errors[index];
                    if(error.item === 'email'){
                        setEmailError({error: 'true', text: error.text})
                    }else if(error.item === 'password'){
                        setPasswordError({error: 'true', text: error.text})
                    }
                  }
                }else{
                  //handleError(error)
                  setAlertType('error')
                  setAlertMessage('An error has occured')
                  setOpenAlert(true)
                }
              })
    }


     // check login

     const [authenticated, setAuthenticated] = useState(false);
     const [loading, setLoading] = useState(true);
     const [type, setType] = useState('Login');
 
     useEffect(() => {
         axios.post("/check_admin_access")
         .then(function (response) {
            navigate('/admin/Dashboard', { replace: true });
         })
         .catch(function (error) {
            console.log(error)
            if(error.response.status === 403){
                setLoading(false)
                if(error.response.data.count ===  0){
                    setType('Signup')
                }
            }else{
                // handleError(error)
                setAlertMessage('An unexpected error has occured.')
                setAlertType('error')
                setOpenAlert(true)
            }
         })
     }, []);


     const [resetPassword, setresetPassword] = useState(false);
     

  return (
    <Home>
        {
            [''].map(item => {
                if(loading){
                    return(
                        //<Container width={windowSize.width}>
                            <CircularProgress style={{alignSelf: 'center'}}/>
                        //</Container>
                    )
                }else{
                    if(resetPassword){
                        if(ResetEmailSent){
                            return(
                                <Container width={windowSize.width}>
                                    <ContainerImage width={windowSize.width} />
                                    <ContainerForm>
                                        <Form>
                                            <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', width: 'max-content', alignSelf: 'center', marginBottom: '0px'} }}>Email sent!</p>
                                            <p style={{...uiSettings.typos.paragraphDesktop, ...{textAlign: 'center', padding: '0px 8px', width: 'max-content', alignSelf: 'center', marginBottom: '50px', color: uiSettings.colors.secondary} }}>Check your email to reset your password.</p>
                                           
                                            <p onClick={()=> setresetPassword(false)} style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px', display: type === 'Signup' ? 'none' : 'unset', cursor: 'pointer'}}}>
                                                Back to login
                                            </p>
        
                                        </Form>
                                    </ContainerForm>
                                </Container>
                            )
                        }else{
                            return(
                                <Container width={windowSize.width}>
                                    <ContainerImage width={windowSize.width} />
                                    <ContainerForm>
                                        <Form>
                                            <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', borderBottom: '2px solid ' + uiSettings.colors.yellow, width: 'max-content', alignSelf: 'center', marginBottom: '44px'} }}>Forgot password</p>
                                            <TextField
                                                id=""
                                                placeholder={'Email'}
                                                variant='standard'
                                                type={'email'}
                                                value={resetEmail}
                                                onChange={(e) => {setResetEmail(e.target.value)}}
                                                InputProps={{
                                                        style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, }}
                                                }}
                                                // error={emailError.error}
                                                // helperText={emailError.text}
                                            />
        
                                            <LoadingButton loading={resetButtonLoading} loadingPosition='end' onClick={handleSubmitReset} style={{...uiSettings.typos.paragraphDesktop, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}, ...resetButtonStyle}} disabled={resetButtonDisabled} >
                                                Submit
                                            </LoadingButton>
        
                                            <p onClick={()=> setresetPassword(false)} style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px', display: type === 'Signup' ? 'none' : 'unset', cursor: 'pointer'}}}>
                                                Back to login
                                            </p>
        
                                        </Form>
                                    </ContainerForm>
                                </Container>
                            )
                        }
                    }else{
                        return(
                            <Container width={windowSize.width}>
                                <ContainerImage width={windowSize.width} />
                                <ContainerForm>
                                    <Form>
                                        <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', borderBottom: '2px solid ' + uiSettings.colors.yellow, width: 'min-content', alignSelf: 'center', marginBottom: '44px'} }}>{type}</p>
                                        <TextField
                                            id=""
                                            placeholder={'Email'}
                                            variant='standard'
                                            type={'email'}
                                            value={email}
                                            onChange={(e) => {setEmail(e.target.value); handlechange('email')}}
                                            InputProps={{
                                                    style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, }}
                                            }}
                                            error={emailError.error}
                                            helperText={emailError.text}
                                        />
                                        <TextField
                                            id=""
                                            placeholder={'Password'}
                                            onChange={(e)=> {setPassword(e.target.value); handlechange('password')}}
                                            value={password}
                                            variant='standard'
                                            type={'password'}
                                            InputProps={{
                                                    style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, marginTop: '20px' }}
                                            }}
                                            error={passwordError.error}
                                            helperText={passwordError.text}
                                        />
    
                                        <LoadingButton loading={buttonLoading} loadingPosition='end' onClick={handleSubmit} style={{...uiSettings.typos.paragraphDesktop, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}, ...buttonStyle}} disabled={buttonDisabled} >
                                            Submit
                                        </LoadingButton>
    
                                        <p onClick={()=> setresetPassword(true)} style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px', display: type === 'Signup' ? 'none' : 'unset', cursor: 'pointer'}}}>
                                            Forgot your password?
                                        </p>
    
                                    </Form>
                                </ContainerForm>
                            </Container>
                        )
                    }
                }
            })
        }
        {/* <Container width={windowSize.width}>
            <ContainerImage width={windowSize.width} />
            <ContainerForm>
                <Form>
                    <p style={{...uiSettings.typos.heading3, ...{textAlign: 'center', padding: '16px 8px', borderBottom: '2px solid ' + uiSettings.colors.yellow, width: 'min-content', alignSelf: 'center', marginBottom: '44px'} }}>Login</p>
                    <TextField
                        id=""
                        placeholder={'Email'}
                        variant='standard'
                        type={'email'}
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); handlechange('email')}}
                        InputProps={{
                                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, }}
                        }}
                        error={emailError.error}
                        helperText={emailError.text}
                    />
                    <TextField
                        id=""
                        placeholder={'Password'}
                        onChange={(e)=> {setPassword(e.target.value); handlechange('password')}}
                        value={password}
                        variant='standard'
                        type={'password'}
                        InputProps={{
                                style: {...uiSettings.styles.input, ...{color: uiSettings.colors.black, marginTop: '20px' }}
                        }}
                        error={passwordError.error}
                        helperText={passwordError.text}
                    />

                    <LoadingButton onClick={handleSubmit} style={{...uiSettings.typos.paragraphDesktop, ...{ marginTop: '28px', padding: '8px', borderRadius: '12px'}, ...buttonStyle}} disabled={buttonDisabled} >
                        Submit
                    </LoadingButton>

                    <p style={{...uiSettings.typos.link, ...{alignSelf: 'center', color: uiSettings.colors.blue, marginTop: '34px'}}}>
                        Forgot your password?
                    </p>

                </Form>
            </ContainerForm>
        </Container> */}
    </Home>
  )
}
