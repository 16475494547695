import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import Logo from '../reusableComponents/logo'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Home = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: ${uiSettings.colors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 5;
`

const Container = styled.div`
    width: 586px;
    height: calc(100% - 48px);
    padding: 24px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 6;
`

export default function CheckEmailLink({ HeadingStyle, paragraphStyle, windowSize, setOpenAlert, setAlertType, setAlertMessage, handleError}) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);

  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("id")
  const code = queryParams.get("code")

  // check it
  const [errorMessage, setErrorMessage] = useState('');
  function handleCheck(){
    axios.post("/check-email-link", {
      id, code
    })
        .then(function (response) {
          navigate('/auth/full-name', { replace: true });
        })
        .catch(function (error) {
          if(error.response.status === 400 && error.response.data.response === 'invalid_link'){
            setLoading(false)
            setErrorMessage(error.response.data.error)
          }else{
            handleError(error)
          }
        })
  }

  useEffect(() => {
    handleCheck()
  }, []);


  if(loading){
    return (
      <Home>
        <Container>
          <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '170px': '0px'}}></div>
          <Logo auth={true}></Logo>
          <Box style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
          <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, margin: "20px 0px 0px 0px"}}}>the link is being verified, please be patient</p>
        </Container>
      </Home>
    ) 
  }else{
    return(
      <Home>
      <Container>
          <div style={{marginTop: windowSize.width < uiSettings.devices.phone ? '170px': '0px'}}></div>
          <Logo></Logo>
          <Box style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} sx={{ display: 'flex' }}>
            <ErrorOutlineOutlinedIcon style={{ fontSize: '60px', color: uiSettings.colors.secondary}} />
            <p style={{...paragraphStyle, ...{textAlign: 'center', color: uiSettings.colors.secondary, margin: "20px 0px 0px 0px"}}}>{errorMessage}</p>
          </Box>
        </Container>
    </Home>
    )
  }
  
}
