import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./styles.css";
import { Navigation } from "swiper/modules";
import { AddToPhotosOutlined, DeleteOutlineOutlined, FavoriteBorderOutlined, FavoriteOutlined, KeyboardArrowLeft, KeyboardArrowRight, MyLocationOutlined, NotificationsActive, PeopleAltOutlined, PeopleOutline, QuizOutlined, TimerOutlined } from "@mui/icons-material";
import uiSettings from "../../../assets/ui-settings";
import { Button, CircularProgress, Slide } from "@mui/material";
import { IconButton } from "@mui/material";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from "@mui/lab";
import Tooltip from '@mui/material/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
  `

const Header = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`
const BarIcon = styled.div`
    width: 16px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`
const HeaderDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const MyButton = styled.div`
    width: min-content;
    height: min-content;
    padding: 5px 7px;
    margin-left: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${uiSettings.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 15px 0px #0000001A;
`

const QuizCart = styled.div`
    width: calc(50% - 20px);
    margin: 10px;
    min-width: ${props => props.width < 600 ? '80%' : '350px'} ;
    height: 319px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 2px whitesmoke;
`
const QuizHeader = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: ${props => props.index === 1 ? 'url("/images/bg-blue-circles.svg")' : (props.index === 2 ? 'url("/images/bg-orange-circles.svg")' : (props.index === 3 ? 'url("/images/bg-yellow-circles.svg")' : (props.index === 4 ? 'url("/images/blue-bg-rectangle.svg")' :  'url("/images/yellow-bg-rectangle.svg")'  ) ) ) } ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizHeader1 = styled.div`
    width: calc(100% - 20px);
    height: calc(45% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: #1268e9c4;
    background-image: url("/images/yellow-bg-rectangle.svg") ;
    background-position: center ;
    background-size: contain;
    background-size: auto;
    position: relative;
`

const QuizAction = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 3px 6px;
  border-radius: 4px 0px 0px 4px ;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(100% - 20px);
    height: calc(55% - 10px);
    padding: 10px 10px 0px 10px;
    background-color: white;
`

const QuizBottom = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const Tag = styled.div`
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-right: 10px !important;
`

const Champions = styled.div`
    width: 100%;
    min-height: ${props => props.isPhone === true ? '400px' : '600px'} ;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    background-image: url("/images/bg-confetti.png");
    background-position: center;
    background-size: cover;
`
const Champion1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: start;
    align-items: center;
    background-color: white;

`

const Champion2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
    align-items: center;
    background-color: white;
    

`

const Champion3 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
    margin-top: 40px;
    align-items: center;
    background-color: white;

`



const Champion1Avatar = styled.div`
    width: ${props => props.isPhone === true ? '80px' : '200px'}  ;
    height: ${props => props.isPhone === true ? '80px' : '200px'} ;
    border-radius: 25px;
    color: transparent;
    /* background-color: #DFD6FBA6; */
    background-image: url("/images/user-orange.png");
    background-position: center;
    background-size: contain;
`

const Champion2Avatar = styled.div`
    width:  ${props => props.isPhone === true ? '70px' : '150px'}  ;
    height: ${props => props.isPhone === true ? '70px' : '150px'}  ;;
    border-radius: 15px;
    color: transparent;
    /* background-color:#FFAB3C59; */
    background-image: url("/images/user-yellow.png");
    background-position: center;
    background-size: contain;
`

const Champion3Avatar = styled.div`
    width: ${props => props.isPhone === true ? '70px' : '120px'}  ;
    height: ${props => props.isPhone === true ? '70px' : '120px'}  ;
    border-radius: 25px;
    color: transparent;
    /* background-color: #CDEDFDA6; */
    background-image: url("/images/user-blue.png");
    background-position: center;
    background-size: contain;
`

const ChampionDisc = styled.div`
    padding: 10px 20px !important;
    background-color: #15BDFF59 !important;
    border-radius: 20px !important;
    text-align: center !important;
;
`


const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 0px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
`



  
  
  


const Mystyles = { 
    quizIcon: {padding: '8px', backgroundColor: uiSettings.colors.white, fontSize: '24px', color: '#1268e9c4', marginRight: '10px', borderRadius: '4px'},
    HCenter: {display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}
}

export default function Main({windowSize, isSidebareOpened, styles, isPhone, handleError, setAlertMessage, setOpenAlert, setAlertType}) {

  const [slidesPerView, setSlidesPerView] = useState(1);
  const [spaceBetween, setSpaceBetween] = useState(20);
  const [swiperWidth, setSwiperWidth] = useState('500px');

  useEffect(() => {
    // Define breakpoints and corresponding slidesPerView and spaceBetween values
    const breakpoints = {
        320: {
            slidesPerView: 1,
            spaceBetween: -50,
            width: "300px"
        },
       
        580: {
            slidesPerView: 1,
            spaceBetween: -150,
            width: "300px"
        },
        680: {
            slidesPerView: 1,
            spaceBetween: -300,
            width: "300px"
        },
        720: {
            slidesPerView: 2,
            spaceBetween: 0,
            width: "300px"
        },
        800: {
            slidesPerView: 2,
            spaceBetween: -50,
            width: "400px"

        },
        900: {
            slidesPerView: 2,
            spaceBetween: -200,
            width: "600px"

        },
        1050: {
            slidesPerView: 3,
            spaceBetween: 0,
            width: "160vh"
        },
        1150: {
            slidesPerView: 3,
            spaceBetween: -60,
            width: "160vh"
        },
        1250: {
            slidesPerView: 4,
            spaceBetween: 150,
            width: "180vh"
        },
        1300: {
            slidesPerView: 4,
            spaceBetween: 180,
            width: "200vh"
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 150,
            width: "200vh"
        },
    };

    // Get the current screen width
    var screenWidth = 0
    if(isSidebareOpened){
        screenWidth = window.innerWidth - 350;
    }else{
        screenWidth = window.innerWidth - 150;
    }
    

    // Find the appropriate breakpoint
    let breakpoint = 320; // Default breakpoint if none matches
    for (const width in breakpoints) {
      if (screenWidth >= parseInt(width, 10)) {
        breakpoint = parseInt(width, 10);
      }
    }

    // Set the Swiper properties based on the breakpoint
        setSlidesPerView(breakpoints[breakpoint].slidesPerView);
        setSpaceBetween(breakpoints[breakpoint].spaceBetween);
        if(isSidebareOpened === true){
            
            

            if(windowSize.width < 600){
                setSwiperWidth((windowSize.width - 30 )+ 'px');
            }else{
                setSwiperWidth((windowSize.width - 280 )+ 'px');
            }
            
        }else{
            if(windowSize.width < 600){
                setSwiperWidth((windowSize.width - 30 )+ 'px');
            }else{
                setSwiperWidth((windowSize.width - 100 )+ 'px');
            }
            
        }
    
  }, [isSidebareOpened, windowSize]);

  const navigate = useNavigate()

  const [openDeleteSessionModal, setOpenDeleteSessionModal] = React.useState(false);
  const handleClickOpenDeleteSessionModal = () => {
    setOpenDeleteSessionModal(true);
  };
  const handleCloseDeleteSessionModal = () => {
    setOpenDeleteSessionModal(false);
  };


  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedSession, setSelectedSession] = useState({});
  function handleDeleteSession(){
    setLoadingDelete(true)
    axios.post("/student/handleDeleteSession", {selectedSession})
        .then(function (response) {
          setLoadingDelete(false)
          handleCloseDeleteSessionModal()
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            setRecently(response.data.sessions)
          }
        })
        .catch(function (error) {
          setLoadingDelete(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 


  const [isLoading, setIsLoading] = useState(true);
  const [recently, setRecently] = useState([]);
  const [newExams, setNewExams] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [champions, setchampions] = useState([]);

  const [test, setTest] = useState(0);
  function handleLike(sessionId, value){
    axios.post("/student/handleLikeSession", {sessionId, value})
        .then(function (response) {
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            var mySessions = recently
            for (let i = 0; i < mySessions.length; i++) {
              const mySession = mySessions[i];
              if(mySession.id === sessionId){
                mySession.like = value
              }
            }
            setRecently(mySessions)
            setTest(test + 1)
            
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 
  const [exams, setExams] = useState([]);
  const [recentExams, setRecentExams] = useState([]);
  useEffect(() => {
    axios.post("/student/home")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            setIsLoading(false)
            console.log(response.data)
            setRecently(response.data.recently)
            setchampions(response.data.champions)
            setExams(response.data.exams)
            setRecentExams(response.data.recentExams)
            //setNewExams(response.data.newExams)
            //setSuggestions(response.data.suggestions)
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            // if (error.response.data.message && error.response.data.message.length > 0){
            //     setAlertMessage(error.response.data.message)
            //     setAlertType('error')
            //     setOpenAlert(true)
            // }
            // setTimeout(() => {
            //     console.log('redirected')
            // }, 3000);
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  }, []);

  const [loadingExam, setLoadingExam] = useState('');

  function createSessionExam(examId){
    setLoadingExam(examId)
    axios.post("/student/createSessionExam", {id: examId})
        .then(function (response) {
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            navigate('/dashboard/allSessions'); 
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            console.log(error.response)
            setLoadingExam('')
            handleError(error)
          }
        })
  } 




  if(isLoading){
    return(
        <Home style={{justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)', height: 'calc(100vh - 140px)'}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
            <CircularProgress />
        </Home> 
    )
    }else{
        return (
            <Home  isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
        
        
                {/* most revised ******************* */}
                <Header>
                    <HeaderDetails>
                        <BarIcon>.</BarIcon>
                        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>Récemment révisé</p>
                    </HeaderDetails>
                    <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                        <MyButton className="swiper-button image-swiper-button-prev"><KeyboardArrowLeft/></MyButton>
                        <MyButton className="swiper-button image-swiper-button-next"><KeyboardArrowRight/></MyButton>
                    </HeaderDetails>
                </Header>
        
                
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled"
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                    style={{width: swiperWidth}}
                >
                
                {
                    [''].map(()=>{
                        if(recently.length === 0){
                            return(
                                 <SwiperSlide >
                                    <QuizCart width={windowSize.width}>
                                        <QuizHeader  index={1}>
                                            <QuizAction>
                                            <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                                            <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                                            </QuizAction>
                                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                                        </QuizHeader>
                                        <QuizContainer >
                                            <Tags>
                                                <Tag  style={{...uiSettings.typos.link_mid, ...{color: '#fb744b8e', backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                                                <Tag style={{...uiSettings.typos.link_mid, ...{color: '#fac21969', backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                                            </Tags>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: '#9a9a9a74', margin: '10px 0px'}}}>Season 05, course, module created by</p>
                                            <QuizBottom>
                                            <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                                <p style={{...uiSettings.typos.link_mid, ...{color: '#9a9a9a74', margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                                                <p style={{...uiSettings.typos.link_bold, ...{color: '#fac21971'}}}>medium</p>
                                            </div>
                                            </QuizBottom>
                                        </QuizContainer>
                                    </QuizCart>
                                </SwiperSlide> 
                            )
                        }
                    })
                }

                {
                     recently.map((item, index)=>{
        
                        const hours = Math.floor( parseInt(item.time ) / 3600).toString().padStart(2, '0');
                        const minutes = Math.floor((parseInt(item.time ) % 3600) / 60).toString().padStart(2, '0');
                        const seconds = (parseInt(item.time ) % 60).toString().padStart(2, '0');
                        return(
                            <SwiperSlide>
                                <QuizCart width={windowSize.width}>
                                    <QuizHeader index={index % 3 + 1}>
                                        <QuizAction>
                                        <IconButton onClick={()=> {handleLike(item.id, !item.like)}} style={{color: uiSettings.colors.blue}}><FavoriteBorderOutlined style={{display: item.like ? 'none' : 'block'}} /><FavoriteOutlined  style={{display: item.like ? 'block' : 'none', color: uiSettings.colors.blue}} /></IconButton>
                                        <IconButton onClick={()=> {setSelectedSession(item); setOpenDeleteSessionModal(true)}} style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                                        </QuizAction>
                                        <Tooltip title={item.title} placement="top">
                                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.title}</span> </p>
                                        </Tooltip>
                                    </QuizHeader>
                                    <QuizContainer>
                                        <Tags>
                                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, display : item.casClinique ? 'block' : 'none' }}}>Cas clinique</Tag>
                                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, display : item.qcm ? 'block' : 'none' }}}>QCM</Tag>
                                            <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.blue, backgroundColor: uiSettings.colors.bgblue, display : item.qcs ? 'block' : 'none' }}}>QCS</Tag>
                                        </Tags>
                                        <Tooltip title={item.description} placement="top">
                                            <p className="truncated-text" style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}> {item.description && item.description.length > 111 ? (isPhone ? item.description.slice(0,80) + '..' : item.description.slice(0,110) + '..' )  : item.description }</p>
                                        </Tooltip>
                                        <QuizBottom>
                                        <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%', marginBottom: '7px'}}}>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><TimerOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/>{hours}:{minutes}:{seconds} </p>
                                            <Button sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowRight />} style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow, padding: '5px 10px'}}}  onClick={()=> window.location.href = '/session/' + item.id} >Continuer</Button>
                                        </div>
                                        </QuizBottom>
                                    </QuizContainer>
                                </QuizCart>
                            </SwiperSlide>
                    
                        )
                    })
                }
                
                {/* <SwiperSlide >
                    <QuizCart width={windowSize.width}>
                        <QuizHeader>
                            <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                        </QuizHeader>
                        <QuizContainer>
                            <Tags>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                            </Tags>
                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                            <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                                <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                            </QuizBottom>
                        </QuizContainer>
                    </QuizCart>
                </SwiperSlide> */}
                   
                  
                </Swiper>
                
                
                <div style={{width: '100%', background: 'linear-gradient(0deg, rgba(0, 179, 250, 0.395) 0%, rgba(0, 179, 250, 0.208) 40%, rgba(0,179,250,0) 100%)', height: '200px', bottom: '0', marginTop: '-200px', zIndex: '1000', display: recently.length === 0 ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '6px'}} >
                    <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, marginBottom: '10px', width: '100%', textAlign: 'center'}}} >il n'y a pas de <span style={{color: uiSettings.colors.blue}} >sessions</span> récemment révisées</p>
                    <Button   sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowRight/>}  style={{...styles.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue}}}  onClick={() => navigate('/dashboard/createSession')} >Creer une session</Button>
                </div>
        
                {/* new exams *********** */}
        
                <Header>
                    <HeaderDetails>
                        <BarIcon>.</BarIcon>
                        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>les examens les plus révisés</p>
                    </HeaderDetails>
                    <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                        <Button   sx={{ textTransform: 'none' }} type="text" style={{...styles.heading5, ...{color: uiSettings.colors.headingColor, padding: "7px 16px"}}} endIcon={<KeyboardArrowRight/>} onClick={()=> navigate('/dashboard/Exams')} >Découvrir</Button>
                    </HeaderDetails>
                </Header>

                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled"
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                    style={{width: swiperWidth}}
                >
                

                {   exams && exams.length === 0 ?
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '180px'}}>
                        <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                    </div>
                    :
                    exams.map((item, index)=>{
        
                        const types = item.types ? JSON.parse(item.types) : []
                        return(
                            <SwiperSlide>
                                <QuizCart width={windowSize.width} style={{height: "230px"}}>
                                    <QuizHeader1  style={{height: "130px"}} index={index % 3 + 1}>
                                        <QuizAction>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, margin: '10px 0px', ...uiSettings.typos.paragraphDesktop}}}>{item.year}</p>

                                        {/* <IconButton onClick={()=> {handleLike(item.id, !item.like)}} style={{color: uiSettings.colors.blue}}><FavoriteBorderOutlined style={{display: item.like ? 'none' : 'block'}} /><FavoriteOutlined  style={{display: item.like ? 'block' : 'none', color: uiSettings.colors.blue}} /></IconButton> */}
                                        {/* <IconButton onClick={()=> {setSelectedSession(item); setOpenDeleteSessionModal(true)}} style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton> */}
                                        </QuizAction>
                                        <Tooltip title={item.name} placement="top">
                                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                                        </Tooltip>

                                    </QuizHeader1>
                                    <QuizContainer style={{height: "100px"}}>
                                        <Tags>
                                            {
                                                types.map((type, key)=>{
                                                    return(
                                                        <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                                    )
                                                })
                                            }                                         </Tags>
                                        <Tooltip title={item.description} placement="top">
                                            {/* <p className="truncated-text" style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}> {item.description && item.description.length > 111 ? item.description.slice(0,110) + '..' : item.description }</p> */}
                                        </Tooltip>
                                        <QuizBottom>
                                        <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%', marginBottom: '7px'}}}>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/>{item.users} révisions </p>
                                            <LoadingButton sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowRight />} style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow, padding: '5px 10px'}}}  onClick={()=> createSessionExam(item.id)} loading={loadingExam === item.id ? true : false} loadingPosition="end" disabled={loadingExam === '' ? false :  true} >Réviser</LoadingButton>
                                        </div>
                                        </QuizBottom>
                                    </QuizContainer>
                                </QuizCart>
                            </SwiperSlide>
                    
                        )
                    })
                }
                
                {/* <SwiperSlide >
                    <QuizCart width={windowSize.width}>
                        <QuizHeader>
                            <QuizAction>
                              <IconButton style={{color: uiSettings.colors.blue}}> <FavoriteBorderOutlined/></IconButton>
                              <IconButton style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiology quiz #125</span> </p>
                        </QuizHeader>
                        <QuizContainer>
                            <Tags>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                            </Tags>
                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}>Season 05, course, module created by <br/><span style={{color: uiSettings.colors.headingColor}}>Mohammed Ali</span></p>
                            <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 215 users</p>
                                <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                            </QuizBottom>
                        </QuizContainer>
                    </QuizCart>
                </SwiperSlide> */}
                   
                  
                </Swiper>
        
                {/* <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    navigation={{
                    nextEl: ".image-swiper-button-next1",
                    prevEl: ".image-swiper-button-prev1",
                    disabledClass: "swiper-button-disabled1"
                    }}
                    modules={[Navigation]}
                    className="mySwiper1"
                    style={{width: swiperWidth}}
                >
                <SwiperSlide>
                    <QuizCart style={{height: '200px'}} width={windowSize.width}>
                        <QuizHeader style={{height: '200px', padding: '30px 10px'}}  index={4}>
                            <QuizAction style={{top: "30px"}}>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor}}}>Bientôt disponible ✨</p>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <AddToPhotosOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Cardiologie 2023</span> </p>
                        </QuizHeader>
                    </QuizCart>
                </SwiperSlide>
                   
                  
                  
                <SwiperSlide>
                    <QuizCart style={{height: '200px'}} width={windowSize.width}>
                        <QuizHeader style={{height: '200px', padding: '30px 10px'}}  index={5}>
                            <QuizAction style={{top: "30px"}}>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor}}}>Bientôt disponible ✨</p>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <AddToPhotosOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Anatomie (1ère année) </span> </p>
                        </QuizHeader>
                    </QuizCart>
                </SwiperSlide>
                
                <SwiperSlide>
                    <QuizCart style={{height: '200px'}} width={windowSize.width}>
                        <QuizHeader style={{height: '200px', padding: '30px 10px'}}  index={4}>
                            <QuizAction style={{top: "30px"}}>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor}}}>Bientôt disponible ✨</p>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <AddToPhotosOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Immunologie (2ème année)</span> </p>
                        </QuizHeader>
                    </QuizCart>
                </SwiperSlide>
        
                <SwiperSlide>
                    <QuizCart style={{height: '200px'}} width={windowSize.width}>
                        <QuizHeader style={{height: '200px', padding: '30px 10px'}}  index={5}>
                            <QuizAction style={{top: "30px"}}>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor}}}>Bientôt disponible ✨</p>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <AddToPhotosOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Pharmacologie</span> </p>
                        </QuizHeader>
                    </QuizCart>
                </SwiperSlide>
        
                <SwiperSlide style={{display: isPhone ? 'unset': 'none'}}>
                    <QuizCart style={{height: '200px', justifyContent: 'center', boxShadow: 'none', alignItems: 'center'}}>
                        <Button variant="outlined" style={{...styles.heading5, ...{color: uiSettings.colors.headingColor, padding: "10px ", borderColor: uiSettings.colors.headingColor, width: 'min-content'}}} endIcon={<KeyboardArrowRight/>} >Discover</Button>
                    </QuizCart>
                </SwiperSlide>
        
        
        
                </Swiper> */}
        
        
        
                {/* Suggestions for you *********** */}
        
        
                <Header>
                    <HeaderDetails>
                        <BarIcon>.</BarIcon>
                        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>Les examens plus récents</p>
                    </HeaderDetails>
                    <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                        <MyButton className="swiper-button2 image-swiper-button-prev2"><KeyboardArrowLeft/></MyButton>
                        <MyButton className="swiper-button2 image-swiper-button-next2"><KeyboardArrowRight/></MyButton>
                    </HeaderDetails>
                </Header>
        
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    navigation={{
                    nextEl: ".image-swiper-button-next2",
                    prevEl: ".image-swiper-button-prev2",
                    disabledClass: "swiper-button-disabled2"
                    }}
                    modules={[Navigation]}
                    className="mySwiper2"
                    style={{width: swiperWidth}}
                >
                {   recentExams && recentExams.length === 0 ?
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '180px'}}>
                        <NotificationsActive style={{color: uiSettings.colors.blue, marginBottom: '10px'}}/>
                        <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor}}}>il n'y a pas d'examen maintenant</p>
                    </div>
                    :
                    recentExams.map((item, index)=>{
        
                        const types = item.types ? JSON.parse(item.types) : []
                        return(
                            <SwiperSlide>
                                <QuizCart width={windowSize.width} style={{height: "230px"}}>
                                    <QuizHeader1  style={{height: "130px"}} index={index % 3 + 1}>
                                        <QuizAction>
                                        {/* <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, margin: '10px 0px', ...uiSettings.typos.paragraphDesktop}}}>{item.createdAt.slice(0,10)}</p> */}
                                        <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, margin: '10px 0px', ...uiSettings.typos.paragraphDesktop}}}>{item.year}</p>
                                        {/* <IconButton onClick={()=> {setSelectedSession(item); setOpenDeleteSessionModal(true)}} style={{color: uiSettings.colors.red}}><DeleteOutlineOutlined/> </IconButton> */}
                                        </QuizAction>
                                        <Tooltip title={item.name} placement="top">
                                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >{item.name}</span> </p>
                                        </Tooltip>

                                    </QuizHeader1>
                                    <QuizContainer style={{height: "100px"}}>
                                        <Tags>
                                            {
                                                types.map((type, key)=>{
                                                    return(
                                                        <Tag key={key} style={{...uiSettings.typos.link_mid, ...{color: type === 'QCM' ? uiSettings.colors.orange : (type === 'QCS' ? uiSettings.colors.yellow : uiSettings.colors.blue), backgroundColor: type === 'QCM' ? uiSettings.colors.lightOrange : (type === 'QCS' ? uiSettings.colors.lightYellow : uiSettings.colors.bgblue), }}}>{type}</Tag>
                                                    )
                                                })
                                            }                                         </Tags>
                                        <Tooltip title={item.description} placement="top">
                                            {/* <p className="truncated-text" style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px'}}}> {item.description && item.description.length > 111 ? item.description.slice(0,110) + '..' : item.description }</p> */}
                                        </Tooltip>
                                        <QuizBottom>
                                        <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%', marginBottom: '7px'}}}>
                                            <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/>{item.users} révisions </p>
                                            <LoadingButton sx={{ textTransform: 'none' }} endIcon={<KeyboardArrowRight />} style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow, padding: '5px 10px'}}}  onClick={()=> createSessionExam(item.id)} loading={loadingExam === item.id ? true : false} loadingPosition="end" disabled={loadingExam === '' ? false :  true} >Réviser</LoadingButton>
                                        </div>
                                        </QuizBottom>
                                    </QuizContainer>
                                </QuizCart>
                            </SwiperSlide>
                    
                        )
                    })
                }
        
        
                {/* <SwiperSlide>
                    <QuizCart width={windowSize.width} style={{height: "230px"}}>
                        <QuizHeader  style={{height: "130px"}}  index={1}>
                            <QuizAction style={{top: "30px"}}>
                                <p style={{...styles.heading5, ...{color: uiSettings.colors.headingColor}}}>Bientôt disponible ✨</p>
                            </QuizAction>
                            <p style={{...uiSettings.typos.heading3, ...Mystyles.HCenter, ...{color: uiSettings.colors.headingColor, alignItems: 'center', }}} > <QuizOutlined style={{...Mystyles.quizIcon}}/> <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }} >Asthme bronchique</span> </p>
                        </QuizHeader>
                        <QuizContainer  style={{height: "100px"}}>
                            <Tags>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, }}}>Cas clinique</Tag>
                                <Tag style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, }}}>QCM</Tag>
                            </Tags>
                            <QuizBottom>
                              <div style={{...Mystyles.HCenter, ...{justifyContent: 'space-between', width: '100%'}}}>
                                <p style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary, margin: '10px 0px', ...Mystyles.HCenter}}}><PeopleAltOutlined style={{color: uiSettings.colors.blue, marginRight: '8px'}}/> 0 users</p>
                                <p style={{...uiSettings.typos.link_bold, ...{color: uiSettings.colors.yellow}}}>medium</p>
                              </div>
                            </QuizBottom>
                        </QuizContainer>
                    </QuizCart>
                </SwiperSlide> */}
                   
                  
                
        
                </Swiper>
        
        
                {/* Champions of the week 🏆 ***************** */}
                <Header style={{marginTop: '80px'}}>
                    <HeaderDetails>
                        <BarIcon>.</BarIcon>
                        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>Champions de la semaine 🏆</p>
                    </HeaderDetails>
                    <HeaderDetails  style={{justifyContent: 'end', display: isPhone ?  'none' : 'flex'}}>
                        
                    </HeaderDetails>
                </Header>
                
                <Champions  isPhone={isPhone} >
                    <Champion2>
                        <Champion2Avatar isPhone={isPhone} ></Champion2Avatar>
                        <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor, margin: "10px 0px", textAlign: 'center'}}} >{champions[1] ? champions[1].student.first_name + ' #2' : 'Player  #2' }</p>
                        <ChampionDisc style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>{champions[1] ? (champions[1].score > 0 ? champions[1].score : '0') : '0' } ✅</ChampionDisc>
                    </Champion2>
        
                    <Champion1>
                        <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor, margin: "0px 0px", textAlign: 'center'}}} >👑</p>
                        <Champion1Avatar isPhone={isPhone} ></Champion1Avatar>
                        <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor, margin: "10px 0px", textAlign: 'center'}}} >{champions[0] ? champions[0].student.first_name + ' #1' : 'Player  #1' }</p>
                        <ChampionDisc style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>{champions[0] ? (champions[0].score > 0 ? champions[0].score : '0') : '0' } ✅</ChampionDisc>
                    </Champion1>
        
                    <Champion3>
                        <Champion3Avatar isPhone={isPhone} ></Champion3Avatar>
                        <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor, margin: "10px 0px", textAlign: 'center'}}} >{champions[2] ? champions[2].student.first_name + ' #1' : 'Player  #3' }</p>
                        <ChampionDisc style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>{champions[2] ? (champions[2].score > 0 ? champions[2].score : '0') : '0' } ✅</ChampionDisc>
                    </Champion3>
                    
                </Champions>


                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openDeleteSessionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDeleteSessionModal}
                    aria-describedby="alert-dialog-slide-description"
                    style={{padding: '10px 20px'}}
                >
                    {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                            <Form style={{width: isPhone ? '100%' : '100%'}}>
                                    
                                    <FormTitle>
                                        <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor}}}>Supprimer  <span style={{color: uiSettings.colors.red}} >la session</span> </p>
                                    </FormTitle> 

                                    <FormTitle>
                                        <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, fontWeight: 400}}}>voulez-vous vraiment supprimer cette session ?</p>
                                    </FormTitle> 

                                        <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                                        <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseDeleteSessionModal}>Annuler</LoadingButton>
                                        <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.red, marginLeft: '10px'}}} onClick={()=> handleDeleteSession()}  >Supprimer</LoadingButton>
                                        </DialogActions>
                            </Form>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
                
            </Home>
            
          );
    }
 
}
