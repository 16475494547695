const uiSettings = {
    colors: {
        blue: '#00b3fa',
        success: '#1EF072',
        yellow: '#fac119',
        white: 'white',
        primary: 'black',
        secondary: '#9A9A9A',
        secondary2: '#BCBCBC',
        black: "black",
        bgBlue: '#D9F5FE',
        bgBlue2: '#EDFAFF',
        beige1: '#F6F6F6',
        headingColor: '#13303F',
        lightGray: '#C9C9C9',
        lightYellow: '#FBCF4B1A',
        orange: '#FB744B',
        lightOrange: '#FB744B12',
        red: '#EB5757',
        bgblue: '#F6F9FF',
        bgblue3: '#43c8fc',
        bgblue4: '#f1f0f0',
        bgbluelight: '#F6F9FF73',
        fail: '#FF6459',




    },
    devices: {
        desktop: '100%',
        tablet: '800px',
        phone: '750'
    },
    typos: {
        link: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'white', fontWeight: 400},
        link_mid: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'white', fontWeight: 500},
        link_font: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'white', fontWeight: 500},
        link_bold: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'white', fontWeight: 700},
        heading1: {fontFamily: 'Poppins', fontSize: '48px', padding: '0px', margin: '0px', color: 'white', fontWeight: 800},
        heading2: {fontFamily: 'Poppins', fontSize: '36px', padding: '0px', margin: '0px', color: 'white', fontWeight: 800},
        heading3: {fontFamily: 'Poppins', fontSize: '24px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading4: {fontFamily: 'Poppins', fontSize: '20px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading5: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'black', fontWeight: 600},
        navText: {fontFamily: 'Poppins', fontSize: '20px', padding: '0px', margin: '0px', color: 'black', fontWeight: 600},
        link_semiBold: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading1mobile: {fontFamily: 'Poppins', fontSize: '32px', padding: '0px', margin: '0px', color: 'white', fontWeight: 700},
        paragraphDesktop: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', fontWeight: 400},
        paragraphMobile: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', fontWeight: 400},
        dot: {hight: '6px', fontSize:'6px', width: '7px', margin: '3px', padding: '0px', backgroundColor: 'white', borderRadius: '50px'},
        largeDot: {hight: '6px', fontSize:'6px', width: '20px', margin: '3px', padding: '0px', backgroundColor: 'white', color: 'white', borderRadius: '50px'},
        adminButton: {color: '#13303F', backgroundColor: 'white', fontFamily: 'Poppins', fontSize: '16px', borderRadius: '10px', padding: '8px 16px'},
        adminButtonYellow: {color: 'black', backgroundColor: '#fac119', fontFamily: 'Poppins', fontSize: '16px', borderRadius: '10px', padding: '8px 16px'}
    },
    typosMobile: {
        link: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'white', fontWeight: 400},
        link_mid: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'white', fontWeight: 500},
        link_font: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'white', fontWeight: 500},
        link_bold: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'white', fontWeight: 700},
        heading1: {fontFamily: 'Poppins', fontSize: '28px', padding: '0px', margin: '0px', color: 'white', fontWeight: 800},
        heading2: {fontFamily: 'Poppins', fontSize: '20px', padding: '0px', margin: '0px', color: 'white', fontWeight: 800},
        heading3: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading4: {fontFamily: 'Poppins', fontSize: '20px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading5: {fontFamily: 'Poppins', fontSize: '14px', padding: '0px', margin: '0px', color: 'black', fontWeight: 600},
        navText: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'black', fontWeight: 600},
        link_semiBold: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', color: 'black', fontWeight: 700},
        heading1mobile: {fontFamily: 'Poppins', fontSize: '32px', padding: '0px', margin: '0px', color: 'white', fontWeight: 700},
        paragraphDesktop: {fontFamily: 'Poppins', fontSize: '18px', padding: '0px', margin: '0px', fontWeight: 400},
        paragraphMobile: {fontFamily: 'Poppins', fontSize: '16px', padding: '0px', margin: '0px', fontWeight: 400},
        dot: {hight: '6px', fontSize:'6px', width: '7px', margin: '3px', padding: '0px', backgroundColor: 'white', borderRadius: '50px'},
        largeDot: {hight: '6px', fontSize:'6px', width: '20px', margin: '3px', padding: '0px', backgroundColor: 'white', color: 'white', borderRadius: '50px'},
        adminButton: {color: '#13303F', backgroundColor: 'white', fontFamily: 'Poppins', fontSize: '16px', borderRadius: '10px', padding: '8px 16px'},
        adminButtonYellow: {color: 'black', backgroundColor: '#fac119', fontFamily: 'Poppins', fontSize: '16px', borderRadius: '10px', padding: '8px 16px'}
    },
    styles: {
        input: { fontFamily: 'Poppins', fontSize: '17px', fontWeight: 400 },
    }
}

export default uiSettings



