import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function RedirectToIdentifier() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/auth/identifier')
  }, []);
  return(
    <div></div>
  )
  
}
