import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { IconButton, Menu, Slide, Stack, TextField } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { AddBoxOutlined, CreditCard, CreditCardOutlined, DriveFileRenameOutlineOutlined, HomeMaxOutlined, KeyboardArrowDown, KeyboardArrowUp, ListAltOutlined, LogoutOutlined, NotificationsNoneOutlined, PendingActionsOutlined, Person, PersonOutlineOutlined, PlaceOutlined, QuizOutlined, Search, SettingsOutlined, StyleOutlined, SupportOutlined, TrendingUpOutlined } from '@mui/icons-material'
import Logo from '../../reusableComponents/logo'
import { Avatar, Badge } from 'antd'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';




import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Main from './main'
import Test from './test'
import AllSessions from './allSessions'
import CreateSession from './createSession'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import Logo1 from '../../reusableComponents/logo1'
import Abonnements from './abonnements'
import AbonnementDetails from './abonnementDetails'
import Exams from './exams'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: start;
  background-color: ${uiSettings.colors.blue};
  overflow: ${props => props.isPhone === true ? 'hidden' : 'unset'} ;
  overflow: hidden;
`

const SideBar = styled.div`
  width: ${props => props.isSidebareOpened === true ? '20%' : 'max-content !important'} ;
  height: calc(100vh - 10px);
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: ${props => props.isSidebareOpened === true ? '250px !important' : 'max-content !important'};
`

const Container = styled.div`
  width:${props => props.isSidebareOpened === true ? 'calc(80% - 30px)' : '100%'} ;
  height: calc(100vh - 30px);
  margin: 5px;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
`

const SubContainer = styled.div`
  width: calc(100% );
  padding: 10px 10px 0px 0px;
  height: calc(100vh - 115px);
  overflow-y: auto;
  overflow-x: hidden;
`


const SidebareLinks = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: calc(100vh - 50px);
  overflow-y: auto;
`

const SidebareSubLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${uiSettings.colors.bgblue3};
`

const SidebareLink = styled.div`
    width:  ${props => props.isSidebareOpened === true ? 'cacl(100% - 80px) !important' : 'min-content !important'} ;
  border-left: 8px solid ${uiSettings.colors.yellow};
  padding-left: 10px;
  margin: 2px 0px;
  transition: 100ms ease-in-out;
`

const SidebareSubLink = styled.div`
  width:  ${props => props.isSidebareOpened === true ? 'cacl(100% - 80px) !important' : 'min-content !important'} ;
  border-left: 8px solid ${uiSettings.colors.yellow};
  padding-left: 10px;
  margin: 0px 0px;
  transition: 100ms ease-in-out !important;
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SidebareLinkToClick = styled.div`
  width: cacl(100% - 80px) !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 7px 3px !important;
  border-radius: 7px;
  cursor: pointer;
  transition: 100ms ease-in-out;
  font-size: 17px !important;
  transition: 0.9s ease-in-out;

  /* &:hover{
    background-color: ${uiSettings.colors.bgblue3};
  } */
`

const SidebareSubLinkToClick = styled.div`
  width:  cacl(100% - 80px) !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 7px 5px !important;
  border-radius: 7px;
  cursor: pointer;
  transition: 100ms ease-in-out;
  background-color: ${uiSettings.colors.bgblue3};
  transition: 100ms ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px !important;
  height: 30px !important;

`

const LinkArrowIcon = styled.span`
  color: ${uiSettings.colors.white};
  flex-grow: 1;
  flex-direction: row;
  justify-content: end;
  align-items: end;
`

const Navbar = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
    position: relative;
    align-items: center;
`

const NavbarInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
`

 const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  margin-top: 0px;
`

const FormTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
`



export default function Dashboard({windowSize, handleError, setAlertMessage, setOpenAlert, setAlertType}) {

  const [anchorElUserMenu, setAnchorElUserMenu] = React.useState(null);
  const openUserMenu = Boolean(anchorElUserMenu);
  const handleClickUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };

  const [loadingLogout, setLoadingLogout] = useState(false);
  function handleLogoutSession(){
    setLoadingLogout(true)
    axios.post("/logoutAndClearSession")
        .then(function (response) {
          setLoadingLogout(false)
          //handleCloseDeleteSessionModal()
          console.log('changed')
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            navigate('/auth/identifier', { replace: true });
          }
        })
        .catch(function (error) {
          setLoadingLogout(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
  } 


  const [anchorElNotifications, setAnchorElNotifications] = React.useState(null);
  const openNotifications = Boolean(anchorElNotifications);
  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };


  const [isSidebareOpened, setIsSidebareOpened] = useState(true);
  const [tab, setTab] = useState('home');
  const [questionLink, setQuestionLink] = useState(false);
  const [flashcardLink, setFlashcardLink] = useState(false);
  const [examenLink, setExamenLink] = useState(false);

  const Navigate = useNavigate()
  function handleSidebareLinks(path){
    //console.log(path)
    //Navigate('/dashboard/' + path)
  }


  function handleSidebareChange(tab){
    //console.log(tab)
    setTab(tab)
    if(tab === 'home'){
      Navigate('/dashboard' )
    }else{
      Navigate('/dashboard/' + tab)
    }
    if(isPhone){
      setIsSidebareOpened(false)
    }
  }
  
  const navigate = useNavigate()

  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const handleClickOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };
  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };

 


  const [isPhone, setIsPhone] = useState(false);
  const [styles, setStyles] = useState({...uiSettings.typos});

  const [path, setPath] = useState('');
  const location = useLocation();
  const myPath = location.pathname;
  const dashboardPath = "/dashboard";
  const dashboardIndex = myPath.indexOf(dashboardPath);
  var result = myPath.substring(dashboardIndex + dashboardPath.length)
  if (result.startsWith("/")) {
    result = result.substring(1);
  }
  

  useEffect(() => {
    if(result === ''){
      setTab('home')
    }else{
      setTab(result)
    }
  }, [result]);

  useEffect(() => {
  if(windowSize.width < 600) {
    setIsPhone(true)
    setStyles(uiSettings.typosMobile)
  }else{
    setIsPhone(false)
    setStyles(uiSettings.typos)
  }
  }, [windowSize]);

  useEffect(() => {
    if(isPhone === true){
      setIsSidebareOpened(false)
    }
  }, [isPhone]);
  
  if(isPhone){
   // setStyles(uiSettings.typosMobile)
  }
  return (
    <Home isPhone={isPhone}>

      {/* sidebar ********************* */}
      <SideBar style={{display: (isPhone && !isSidebareOpened) ? 'none': 'flex' }} isSidebareOpened={isSidebareOpened}>
        <div style={{width: '100%', height: '60px', display: isPhone ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'center',}}>
          <MenuIcon onClick={()=> setIsSidebareOpened(!isSidebareOpened)} style={{color: uiSettings.colors.white, fontSize: '40px', padding: isSidebareOpened === true ? '0px 28px 0px 20px' : '0px 10px 0px 20px' }} />
        </div>

        <div style={{width: 'calc(100% - 48px)', height: '60px', display: !isPhone ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'center', padding:  '0px 28px 0px 20px'}}>
          <Logo1 auth={true}  style={{color: uiSettings.colors.white, fontSize: '40px', }} />
        </div>

        <SidebareLinks className="scroll-container">

          {/* home ********* */}
          <SidebareLink onClick={() => console.log('sssss')} isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'home' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick onClick={() => handleSidebareChange('home')} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white}}}><HomeMaxOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Acceuil..</span> </SidebareLinkToClick>
          </SidebareLink>
          {/* questions ******* */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: uiSettings.colors.blue}}>
            <SidebareLinkToClick onClick={() => setQuestionLink(!questionLink)} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white,}}}><QuizOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Questions</span> <LinkArrowIcon style={{display: questionLink === false && isSidebareOpened === true ? 'flex': 'none'}}><KeyboardArrowDown  /></LinkArrowIcon>   <LinkArrowIcon style={{ display: questionLink === true  && isSidebareOpened === true ? 'flex': 'none'}}><KeyboardArrowUp  /></LinkArrowIcon>  </SidebareLinkToClick>
          </SidebareLink>
          <SidebareSubLink onClick={() => handleSidebareLinks('createSession')} isSidebareOpened={isSidebareOpened} style={{display: questionLink === true ? 'block': "none", borderColor: tab === 'createSession' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick onClick={() => handleSidebareChange('createSession')} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white, borderRadius: ' 7px 7px 0 0 '}}}><DriveFileRenameOutlineOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Creer une session</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          <SidebareSubLink  onClick={() => handleSidebareLinks('allSessions')} isSidebareOpened={isSidebareOpened} style={{display: questionLink === true ? 'block': "none", borderColor: tab === 'allSessions' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick onClick={() => handleSidebareChange('allSessions')} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white, borderRadius: ' 0 0 7px 7px'}}}><ListAltOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Toutes les sessions test</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          {/* flash cards ******* */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: uiSettings.colors.blue}}>
            <SidebareLinkToClick /*onClick={() => setFlashcardLink(!flashcardLink)}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5',}}}><StyleOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Flashcards ✨</span> <LinkArrowIcon style={{display: flashcardLink === false && isSidebareOpened === true ? 'flex': 'none',}}><KeyboardArrowDown  /></LinkArrowIcon>   <LinkArrowIcon style={{ display: flashcardLink === true  && isSidebareOpened === true ? 'flex': 'none'}}><KeyboardArrowUp  /></LinkArrowIcon>  </SidebareLinkToClick>
          </SidebareLink>
          <SidebareSubLink isSidebareOpened={isSidebareOpened} style={{display: flashcardLink === true ? 'block': "none", borderColor: tab === 'createEns' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick /*onClick={() => handleSidebareChange('createEns')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5', borderRadius: ' 7px 7px 0 0 '}}}><AddBoxOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Creer un ensemble</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          <SidebareSubLink isSidebareOpened={isSidebareOpened} style={{display: flashcardLink === true ? 'block': "none", borderColor: tab === 'flashcardAllSessions' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick /*onClick={() => handleSidebareChange('flashcardAllSessions')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5', borderRadius: ' 0 0 7px 7px'}}}><ListAltOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Toutes les sessions</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          {/* examens ******* */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: uiSettings.colors.blue, display: 'none'}}>
            <SidebareLinkToClick  style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5',}}}><PendingActionsOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Examens ✨</span> <LinkArrowIcon style={{display: examenLink === false && isSidebareOpened === true ? 'flex': 'none',}}><KeyboardArrowDown  /></LinkArrowIcon>   <LinkArrowIcon style={{ display: examenLink === true && isSidebareOpened === true ? 'flex': 'none'}}><KeyboardArrowUp  /></LinkArrowIcon>  </SidebareLinkToClick>
          </SidebareLink>
          <SidebareSubLink isSidebareOpened={isSidebareOpened} style={{display: examenLink === true ? 'block': "none", borderColor: tab === 'exam1' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick /*onClick={() => handleSidebareChange('exam1')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5', borderRadius: ' 7px 7px 0 0 '}}}><QuizOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Examens 1</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          <SidebareSubLink isSidebareOpened={isSidebareOpened} style={{display: examenLink === true ? 'block': "none", borderColor: tab === 'exam2' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareSubLinkToClick /*onClick={() => handleSidebareChange('exam2')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5', borderRadius: ' 0 0 7px 7px'}}}><QuizOutlined style={{padding:'0px 10px'}} />  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: isSidebareOpened ? 'block' : "none"}}>Examens 2</span> </SidebareSubLinkToClick>
          </SidebareSubLink>
          {/* exam ***** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Exams' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick onClick={() => handleSidebareChange('Exams')} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white}}}><PendingActionsOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>examens</span> </SidebareLinkToClick>
          </SidebareLink>
          {/* abonnement ***** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Abonnements' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick onClick={() => handleSidebareChange('Abonnements')} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white}}}><CreditCardOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Abonnements</span> </SidebareLinkToClick>
          </SidebareLink>
          {/* points de vente ********** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'POS' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick /*onClick={() => handleSidebareChange('POS')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5'}}}><PlaceOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Points de vente ✨</span> </SidebareLinkToClick>
          </SidebareLink>
          {/* stats ********** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Statistiques' ? uiSettings.colors.yellow : uiSettings.colors.blue, marginBottom: '30px'}}>
            <SidebareLinkToClick /*onClick={() => handleSidebareChange('Statistiques')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5'}}}><TrendingUpOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Statistiques ✨</span> </SidebareLinkToClick>
          </SidebareLink>


          {/* stats ********** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: uiSettings.colors.blue, flexGrow: '1'}}>
          </SidebareLink>

          {/* Profile ********** */}
          <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Profile' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick /*onClick={() => handleSidebareChange('Profile')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5'}}}><PersonOutlineOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Profile ✨</span> </SidebareLinkToClick>
          </SidebareLink>
           {/* Parametres ********** */}
           <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Parametres' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick /*onClick={() => handleSidebareChange('Parametres')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5'}}}><SettingsOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Parametres ✨</span> </SidebareLinkToClick>
          </SidebareLink>
           {/* Support ********** */}
           <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Support' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick /*onClick={() => handleSidebareChange('Support')}*/ style={{...uiSettings.typos.heading5, ...{color: '#fffefeb5'}}}><SupportOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Support ✨</span> </SidebareLinkToClick>
          </SidebareLink>
           {/* Deconnexion ********** */}
           <SidebareLink isSidebareOpened={isSidebareOpened} style={{ borderColor: tab === 'Deconnexion' ? uiSettings.colors.yellow : uiSettings.colors.blue}}>
            <SidebareLinkToClick onClick={() => setOpenLogoutModal(true)} style={{...uiSettings.typos.heading5, ...{color: uiSettings.colors.white}}}><LogoutOutlined style={{padding:'0px 10px'}} />  <span style={{display: isSidebareOpened ? 'block' : "none" }}>Deconnexion</span> </SidebareLinkToClick>
          </SidebareLink>


        </SidebareLinks>
      </SideBar>
      <Container onClick={() => {if(isPhone && isSidebareOpened) {setIsSidebareOpened(false)}}}>
        {/* navbar desktop ********** */}
        <Navbar style={{display: isPhone? 'none' : 'flex'}}>
          <Logo style={{ }}></Logo>
          <NavbarSearch style={{width: '534px'}}>
                    <TextField
                        id=""
                        placeholder={'Search ✨'}
                        value={''}
                        variant='outlined'
                        type={'text'}
                        style={{width: "100%", backgroundColor: uiSettings.colors.beige1, borderRadius: '8px 0px 0px 8px', }}
                        InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px', paddingRight: '50px'}, ...{}}
                        }}
                    />
                    <Search style={{padding: '9px 9px', fontSize: '26px', backgroundColor: '#fac21983', color:'#0000005c', borderRadius: '7px 7px 7px 7px', marginLeft: '-52px', zIndex: '10', }}/>
          </NavbarSearch>

          <NavbarInfo>
            <Stack
            style={{marginLeft: '20px'}}
            onClick={handleClickNotifications}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openNotifications ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openNotifications ? 'true' : undefined}            
            spacing={2} direction="row">
              <Badge badgeContent={5} style={{margin: "0px 45px 0px 0px"}}  sx={{"& .MuiBadge-badge": {color: uiSettings.colors.black ,backgroundColor: uiSettings.colors.yellow }}}>
                <IconButton
                  style={{marginLeft: '20px'}}
                  onClick={handleClickNotifications}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openNotifications ? 'notification-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNotifications ? 'true' : undefined}
                >
                  <NotificationsNoneOutlined color="action" />
                </IconButton>
                  
              </Badge>
            </ Stack>
            
            <Avatar onClick={handleClickUserMenu}  style={{width: '40px', height: 'auto', marginLeft: '10px', boxShadow: '0px 0px 5px 2px #9b9a9a17', cursor: 'pointer'}} alt="Mohammed dellihr" src="/images/user-orange.png" />
            <KeyboardArrowDown style={{color: uiSettings.colors.headingColor , cursor: 'pointer'}} onClick={handleClickUserMenu}/>
            
            <Menu
              anchorEl={anchorElUserMenu}
              id="account-menu"
              open={openUserMenu}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem disabled onClick={handleCloseUserMenu}  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, width: '100%', padding: '5px 50px 5px 10px', fontWeight: 400}}}>
                <ListItemIcon>
                  <Person style={{color: uiSettings.colors.headingColor}} fontSize="small" />
                </ListItemIcon>
                Profile ✨
              </MenuItem>
              <MenuItem disabled onClick={handleCloseUserMenu} style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, width: '100%', padding: '5px 50px 5px 10px', fontWeight: 400}}}>
                <ListItemIcon>
                  <CreditCard style={{color: uiSettings.colors.headingColor}} fontSize="small" />
                </ListItemIcon>
                Abonnements ✨
              </MenuItem>
              <Divider />
              <MenuItem disabled onClick={handleCloseUserMenu}  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, width: '100%', padding: '5px 20px 5px 10px', fontWeight: 400}}}>
                <ListItemIcon>
                  <Settings style={{color: uiSettings.colors.headingColor}} fontSize="small" />
                </ListItemIcon>
                Parametres ✨
              </MenuItem>
              <MenuItem  onClick={() => setOpenLogoutModal(true)}  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor, width: '100%', padding: '5px 20px 5px 10px', fontWeight: 400}}}>
                <ListItemIcon>
                  <Logout style={{color: uiSettings.colors.headingColor}} fontSize="small" />
                </ListItemIcon>
                Deconexion
              </MenuItem>
            </Menu>

            <Menu
              anchorEl={anchorElNotifications}
              id="notification-menu"
              open={openNotifications}
              onClose={handleCloseNotifications}
              onClick={handleCloseNotifications}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              style={{height: '300px'}}
            >
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '330px',minHeight: '500px'}}>
                <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.yellow, margin: '0px 10px 20px 10px ', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', width: '300px', fontWeight: 400}}} >Notifications </p>
                <Divider />
                <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', width: '300px', flexWrap: 'nowrap', alignItems: 'center', padding: "10px", }} disabled  >
                  <NotificationsNoneOutlined style={{color: uiSettings.colors.blue,  width: 'min-content'}} fontSize="small" />
                  <span style={{...uiSettings.typos.heading5, ...{flexGrow: 1, padding: '5px 10px', color: uiSettings.colors.headingColor, fontWeight: 400}}} >Bienvenue sur la plateforme</span>
                  
                </div >
                <Divider />
              </div>
              
              
            </Menu>


          </NavbarInfo>
        </Navbar>

        {/* navbar mobile ********** */}
        <Navbar style={{display: !isPhone ? 'none' : 'flex'}}>
          

          <NavbarInfo>
            <IconButton onClick={()=> setIsSidebareOpened(true)} style={{ fontSize: '26px', color: uiSettings.colors.black,   zIndex: '10', marginRight: '30px'}}><MenuIcon /></IconButton>
            <Logo style={{ }}></Logo>
          </NavbarInfo>

          <NavbarInfo>
            
             <IconButton disabled><Search style={{ fontSize: '26px', color: "#00000034",   zIndex: '10', }}/></IconButton> 

            <Stack spacing={2} direction="row">
              <Badge badgeContent={5} style={{margin: "0px 45px 0px 0px"}}  sx={{"& .MuiBadge-badge": {color: "#00000034" ,backgroundColor: uiSettings.colors.yellow }}}>
                  <IconButton onClick={handleClickNotifications}><NotificationsNoneOutlined style={{color: "#000000", }} /></IconButton> 
              </Badge>
            </ Stack>
          </NavbarInfo>
        </Navbar>


        <SubContainer className='scroll-container2'>
          <Routes>
              <Route exact path="/" element={<Main handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/allSessions" element={<AllSessions handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/createSession" element={<CreateSession handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/Exams" element={<Exams handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/Abonnements" element={<Abonnements handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/Abonnements/:id" element={<AbonnementDetails handleError={handleError} windowSize={windowSize} isSidebareOpened={isSidebareOpened}  styles={styles} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert}/>}></Route>
              <Route exact path="/*" element={<Test handleError={handleError} styles={styles}  windowSize={windowSize} isSidebareOpened={isSidebareOpened} isPhone={isPhone}  setAlertMessage={setAlertMessage} setAlertType={setAlertType} setOpenAlert={setOpenAlert} />}></Route>
          </Routes>
        </SubContainer>
        
                        

       


      </Container>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openLogoutModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseLogoutModal}
        aria-describedby="alert-dialog-slide-description"
        style={{padding: '10px 20px'}}
    >
        {/* <DialogTitle style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, padding: '10px 20px'}}}>{"Add comment"}</DialogTitle> */}
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
                <Form style={{width: isPhone ? '100%' : '100%'}}>
                        
                        <FormTitle>
                            <p style={{...styles.heading2, ...{color: uiSettings.colors.headingColor}}}>Déconnexion </p>
                        </FormTitle> 

                        <FormTitle>
                            <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor, fontWeight: 400}}}>Êtes-vous sûr de vouloir vous déconnecter ?</p>
                        </FormTitle> 

                            <DialogActions style={{margin: '20px 0px 0px 0px', padding: '0px'}}>
                            <LoadingButton   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', backgroundColor: 'transparent', marginLeft: '10px'}}}  onClick={handleCloseLogoutModal}>Annuler</LoadingButton>
                            <LoadingButton loading={loadingLogout} loadingPosition='center'  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white,  padding: '7px 16px', backgroundColor: uiSettings.colors.red, marginLeft: '10px'}}} onClick={()=> handleLogoutSession()}  >Déconnecter</LoadingButton>
                            </DialogActions>
                </Form>
        </DialogContentText>
        </DialogContent>
    </Dialog>
    </Home>
  )
}
