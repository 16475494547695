import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../assets/ui-settings'
import { Button, IconButton } from '@mui/material'
import { CheckCircle, CheckCircleOutlineOutlined, Circle, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, VisibilityOutlined } from '@mui/icons-material'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import ImageGallerie from '../admin/panel/imageGallerie'

const Home = styled.div`
  width: calc(100% - 20px);
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
  overflow-y: auto ;
  align-items: center;
  transition: 0.5s !important;
  position: relative;
`
const Container = styled.div`
    display: flex;
    flex-direction:${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: start;
    align-items: start;
    width: ${(props) => props.isPhone ? '100%' : '95%'} ;
    margin-top: ${(props) => props.isPhone ? '10px' : '20px'} ;
`

const MainContent = styled.div`
    width: ${(props) => props.isPhone ? '100%' : '85%'} ;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 1;
    background-color: ${uiSettings.colors.white};
`

const SideContent = styled.div`
    width: 15%;
    display: ${(props) => props.isPhone ? 'none' : 'flex'} ;
    flex-direction: column;
    justify-content: start;
    margin-top: 100px;
`
const SideItem = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 48px) !important' : 'calc(100% - 28px) !important'}  ;
    padding: 12px 24px !important;
    padding-right: 44px;
    border-radius: 7px !important;
    background-color: ${uiSettings.colors.yellow} !important;
    color: ${uiSettings.colors.black} !important;
    margin: 10px 0px !important;
    margin-right:${(props) => props.isPhone ? '0px !important' : '-20px !important'} ;
    z-index: 0 !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
`
const RepetedSideItem = styled.div`
    width: ${props=> props.isPhone ? 'calc(100% - 55px) !important' : 'calc(100% - 68) !important'} ;
    padding: 12px 24px !important;
    padding-right: 44;
    border-radius: 7px !important;
    background-color: ${uiSettings.colors.bgBlue} !important;
    color: ${uiSettings.colors.blue} !important;
    margin: 10px 0px !important;
    margin-right: ${props => props.isPhone ? '0px !important' : '-20px !important'};
    z-index: 0 !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
    font-size: 16px !important;
`

const Header = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 20px) !important' : 'calc(100% - 48px) !important'};
    padding: ${(props) => props.isPhone ? '20px 10px !important' : '10px 24px !important'};
    border-radius: 15px !important;
    box-shadow: 0px 2px 15px 0px #0000001A !important;
    background-color: ${uiSettings.colors.white};
    z-index: 5;
`

const QuizList = styled.div`
    width: ${(props) => props.isPhone ? ' calc(100% - 20px)' : ' calc(100% - 40px)'} ;
    padding: ${(props) => props.isPhone ? '10px' : '20px'} ;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    border-radius: 15px;
    margin-top: -25px;
    box-shadow: 0px 2px 15px 0px #0000001A;
    min-height: 200px;
`

const QuizItem = styled.div`
    width: ${(props) => props.isPhone ? 'calc(100% - 20px) !important' : 'calc(100% - 50px) !important'}  ;
    margin: ${(props) => props.isPhone ? '5px !important' : '5px 10px !important'};
    padding: ${(props) => props.isPhone ? '5px 5px !important' : '5px 15px !important'}  ;
    box-shadow: 0px 2px 5px 0px #0000000D !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:hover{
        background-color: #d5d5d557;
        border-color:  ${props => props.color ? props.color + " !important" : 'transparent !important'} ;
    }
`

const QuizId = styled.p`
    padding: 4px 12px;
    margin: 0px;
    margin-right: 20px;
    border: 1px solid ${uiSettings.colors.headingColor};
    border-radius: 50px;
    transition: 0.1s ease-in-out;
`

export default function QCM({styles, isPhone, quiz, nextQuiz, handleError, handleEditSidebareAfterAnswering, repeted, images}) {
    const [question, setQuestion] = useState(myQuestion);
    const [answers, setAnswers] = useState([]);
    useEffect(() => {
        if(quiz.corrected === true){
            setCorrection(true)
        }
        var my_answers= []
        var my_questions = []
        my_questions.push({id: 'A', question: quiz.quiz.a}, {id: 'B', question: quiz.quiz.b},  {id: 'C', question: quiz.quiz.c},  {id: 'D', question: quiz.quiz.d},  {id: 'E', question: quiz.quiz.e},  {id: 'F', question: quiz.quiz.f},  {id: 'G', question: quiz.quiz.g}, {id: 'H', question: quiz.quiz.h},  {id: 'I', question: quiz.quiz.i}, {id: 'J', question: quiz.quiz.j})
        var correctAnswers = quiz.quiz.correction.split('.')
        for (let i = 0; i < my_questions.length; i++) {
            const question = my_questions[i];
            var correction = false
            var alreadySelected = quiz.answers.split('.')
            if(question && question.question.length > 0){
                for (let j = 0; j < correctAnswers.length; j++) {
                    const answer = correctAnswers[j];
                    if(answer === question.id){
                        correction = true
                    }
                }
                var selected = false
                for (let j = 0; j < alreadySelected.length; j++) {
                    const answer = alreadySelected[j];
                    if(answer === question.id){
                        selected = true 
                    }
                }
                my_answers.push({id: question.id, content: question.question, selected: selected, correction})
            }
        }
        setAnswers(my_answers)
    }, []);

    const navigate = useNavigate()

    const [loadingShow, setLoadingShow] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);

    const [isAllNumber, setIsAllNumber] = useState(false);
    useEffect(() => {
        var data = true
        for (let i = 0; i < answers.length; i++) {
            const answer = answers[i].content;
            if(!isNaN(answer.replace(/\./g, ''))){
            }else{
                data = false
            }
            setIsAllNumber(data)
        }
    }, [answers]);
    
    function handleAnswering(action){
        
        if(correction === false){

            var selectedOptions = []
            var corrected = false
            var answeredCorrectly = null
            if(action === 'correct'){
                corrected = true
                setLoadingShow(true)
            }else{
                setLoadingNext(true)
            }
            for (let i = 0; i < answers.length; i++) {
                const answer = answers[i];
                if(answer.selected === true){
                    selectedOptions.push(answer.id)
                }
                
                const quizCorrection = quiz.quiz.correction.split('.').slice().sort();
                const userCorrection = selectedOptions.slice().sort();
                answeredCorrectly = JSON.stringify(quizCorrection) === JSON.stringify(userCorrection)                
            }

            if(action === 'next' && !selectedOptions.length > 0){
                nextQuiz()
            }else{
                axios.post("/student/editSessionQuiz", {
                    quizId: quiz.id, answered: true, answers: selectedOptions, answeredCorrectly: answeredCorrectly, corrected: corrected
                  } )
                .then(function (response) {
                if(response.data.step === 'add_name'){
                    navigate('/auth/full-name', { replace: true });
                }else if (response.data.step === 'add_phone'){
                    navigate('/auth/phone-number', { replace: true });
                }else{
                    console.log(response.data.session)
                    // edit questions list
                    if(action === 'correct'){
                       setCorrection(true); 
                    }else{
                        nextQuiz()
                    }
                    setLoadingNext(false)
                    setLoadingShow(false)
                    handleEditSidebareAfterAnswering(quiz.id)
                }
                })
                .catch(function (error) {
                    setLoadingNext(false)
                    setLoadingShow(false)
                    if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
                        navigate('/auth/identifier', { replace: true });
                    }else{
                        handleError(error)
                    }
                })
            }

            
        }else if (correction === true && action === 'next'){
            nextQuiz()
        }
       
    }

    const toggleAnswerSelection = (answerId) => {
        if(!correction){

            const updatedItems = answers.map(item => {
                if (item.id === answerId) {
                  // Update the variable in the object
                  return { ...item, selected: ! item.selected };
                }else{
                    if(quiz.quiz.type === 'QCS'){
                        return { ...item, selected: false }; 
                    }
                }
                return item;
              });
            setAnswers(updatedItems)

            /*setAnswers((prevData) => ({
                //...prevData,
                answers: prevData.map((answer) =>
                  answer.id === answerId
                    ? { ...answer, selected: !answer.selected }
                    : answer
                )
              }));*/
        }
      };
      const [correction, setCorrection] = useState(false);

      const [openSideBar, setOpenSideBar] = useState(false);

      const adjustImageStyles = (htmlContent) => {
        // Create a temporary div element to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
    
        // Query all image elements within the content
        const images = tempDiv.querySelectorAll('img');
    
        // Apply styles to images within the content
        images.forEach(image => {
          // Check if the image width exceeds 100%
          if (image.width > 100) {
            image.style.maxWidth = '100%';
            image.style.height = 'auto';
          }
        });
    
        // Return the modified HTML content
        return tempDiv.innerHTML;
      };

      const regex = /#([^#]+)#/g;

  return (
    <Home className='qcmContainer'>
        <Container isPhone={isPhone}>
            <SideContent isPhone={isPhone} >
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.module.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.module}</SideItem>
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.source.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.source}</SideItem>
                <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.date} {quiz.quiz.year}</SideItem>
                {
                    quiz.quiz.module === '' && quiz.quiz.cour === '' ?
                    <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: 'flex', backgroundColor: 'red', fontSize: "16px"} }}>Suite de la précédente</RepetedSideItem>
                    :
                    <></>
                }
                {
                    repeted.map(item => {
                        return(
                            <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ? 'flex': 'none', backgroundColor: 'red', fontSize: "16px"} }}>{item.date}  {item.year}  {item.source === '' || (item.date === "" && item.year === "") ? '' : '-'} {item.source}</RepetedSideItem>
                        )
                    })
                }          
            </SideContent>
            
            <MainContent isPhone={isPhone} >
                <Content>
                    <Header isPhone={isPhone} style={{...styles.navText, ...{color: uiSettings.colors.headingColor, display: 'flex', flexDirection: 'column', fontSize: '18px'}}}>
                        <span  dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.quiz.enonce.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{display: quiz.quiz.enonce.length > 0 ? 'block' : 'none', marginTop: '0px', }}></span>
                        <span dangerouslySetInnerHTML={{ __html: quiz.quiz.question.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')}} style={{display: quiz.quiz.question.length > 0 ? 'block' : 'none'}}></span>
                        <span style={{display: quiz.quiz.a1 && quiz.quiz.a1.length > 0 ? 'block' : 'none', marginTop: '10px'}}>1- {quiz.quiz.a1}</span>
                        <span style={{display: quiz.quiz.a2 && quiz.quiz.a2.length > 0 ? 'block' : 'none', marginTop: '10px'}}>2- {quiz.quiz.a2}</span>
                        <span style={{display: quiz.quiz.a3 && quiz.quiz.a3.length > 0 ? 'block' : 'none', marginTop: '10px'}}>3- {quiz.quiz.a3}</span>
                        <span style={{display: quiz.quiz.a4 && quiz.quiz.a4.length > 0 ? 'block' : 'none', marginTop: '10px'}}>4- {quiz.quiz.a4}</span>
                        <span style={{display: quiz.quiz.a5 && quiz.quiz.a5.length > 0 ? 'block' : 'none', marginTop: '10px'}}>5- {quiz.quiz.a5}</span>
                        <span style={{display: quiz.quiz.a6 && quiz.quiz.a6.length > 0 ? 'block' : 'none', marginTop: '10px'}}>6- {quiz.quiz.a6}</span>
                        <span style={{display: quiz.quiz.a7 && quiz.quiz.a7.length > 0 ? 'block' : 'none', marginTop: '10px'}}>7- {quiz.quiz.a7}</span>
                        <span style={{display: quiz.quiz.a8 && quiz.quiz.a8.length > 0 ? 'block' : 'none', marginTop: '10px'}}>8- {quiz.quiz.a8}</span>
                    </Header>
                    <QuizList style={{flexDirection: isAllNumber === true ? 'row' : 'column', flexWrap: 'nowrap', overflowX: isAllNumber ? 'auto' : '', minHeight: isAllNumber ? '70px' : '200px', paddingTop: isAllNumber ? '50px' : ''}}  isPhone={isPhone}>
                        {
                            answers.map((answer) => {
                                var PrincipalColor = 'transparent'
                                var textColor = uiSettings.colors.headingColor
                                var borderColor = '#d5d5d514'
                                if(correction && answer.correction ){
                                    PrincipalColor = 'green'
                                    textColor = 'green'
                                    borderColor = 'green'
                                }else if(correction && !answer.correction && answer.selected ){
                                    PrincipalColor = 'red'
                                    textColor = 'red'
                                    borderColor = 'red'
                                }else{
                                    PrincipalColor = 'white'
                                }


                                return(
                                    <QuizItem isPhone={isPhone} color={borderColor} onClick={() => { toggleAnswerSelection(answer.id)}} style={{...styles.navText, ...{color: textColor, border: 'solid 2px ' + PrincipalColor, fontSize: isPhone ?  '16px' : '18px', fontWeight: 400 }}}><QuizId style={{backgroundColor : answer.selected && !correction ? uiSettings.colors.blue : 'transparent'   , borderColor : answer.selected && !correction ? uiSettings.colors.blue : textColor, fontWeight: 600}}>{answer.id}</QuizId>{answer.content} <span style={{flexGrow: 1, display: correction && answer.selected ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'end', fontWeight: 600}}><CheckCircleOutlineOutlined style={{color: uiSettings.colors.headingColor, fontSize: '22px'}}/></span> </QuizItem>
                                )
                            })
                        }
                    </QuizList>
                    <div    style={{display: isPhone ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width :'100%', transition: 'ease-in-out 0.2s'}}>
                        <IconButton onClick={() => setOpenSideBar(false)} style={{...{backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, display: openSideBar ? 'flex' : 'none'}}}><KeyboardArrowUp/></IconButton>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && quiz.quiz.module.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.module}</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && quiz.quiz.source.length> 0 ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.source}</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && ( quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ) ? 'flex': 'none', fontSize: "16px"} }}>{quiz.quiz.date} {quiz.quiz.year}</SideItem>
                        {
                            quiz.quiz.module === '' && quiz.quiz.cour === '' ?
                            <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar ? 'flex' : 'none', backgroundColor: 'red', fontSize: "16px"} }}>Suite de la précédente</RepetedSideItem>
                            :
                            <></>
                        }
                        {
                            repeted.map(item => {
                                return(
                                    <RepetedSideItem  isPhone={isPhone} style={{...styles.navText, ...{display: openSideBar && ( quiz.quiz.date.length> 0 || quiz.quiz.year.length> 0 ) ? 'flex': 'none', backgroundColor: 'red', fontSize: "16px"} }}>{item.date}  {item.year}  {item.source === '' || (item.date === "" && item.year === "") ? '' : '-'} {item.source}</RepetedSideItem>
                                )
                            })
                        }                         
                        {/* <SideItem  isPhone={isPhone} style={{...styles.navText, ...{padding: '12px 24px', display: openSideBar ? 'flex' : 'none', fontSize: "16px"}}}>Dermatologie</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{padding: '12px 24px', display: openSideBar ? 'flex' : 'none', fontSize: "16px"}}}>Externat Alger</SideItem>
                        <SideItem  isPhone={isPhone} style={{...styles.navText, ...{padding: '12px 24px', display: openSideBar ? 'flex' : 'none', fontSize: "16px"}}}>P2 2021</SideItem> */}
                        <IconButton onClick={() => setOpenSideBar(true)} style={{...{backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, display: openSideBar ? 'none' : 'flex'}}}><KeyboardArrowDown/></IconButton>
                    </div>
                </Content>
                <Header style={{marginTop: '20px', display: correction && (quiz.quiz.explication.length > 0 || images.length > 0) ? 'block' : 'none'}}>
                    <p style={{...styles.navText, ...{ color: uiSettings.colors.yellow, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}}><QuizId style={{width: "28px", height: '28px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderColor: uiSettings.colors.yellow}}>i</QuizId>Explication</p>
                    {/* <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}>
                    {quiz.quiz.explication}
                    </p> */}
                    <p dangerouslySetInnerHTML={{ __html: adjustImageStyles(quiz.quiz.explication.replace(regex, '<span style="background-color: #00b3fa !important; color: white !important; padding: 0px 5px; border-radius: 5px;">$1</span>')) }} style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginTop: '20px'}}}></p>
                    <ImageGallerie images={images} />
                </Header>
                <div style={{flexDirection: isPhone? 'row' : 'row', justifyContent:  isPhone? 'end' : 'end', width: '100%', display: 'flex'}}>
                    <LoadingButton disabled={correction} loading={loadingShow} loadingPosition='end' onClick={() =>{  handleAnswering('correct')}} endIcon={<VisibilityOutlined/>}  sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, alignSelf: 'end', marginTop: '20px', width: "max-content", fontSize : isPhone ? '15px' : '18px'}}} >La réponse</LoadingButton>
                    <LoadingButton endIcon={<KeyboardArrowRight />} loading={loadingNext} loadingPosition='end' onClick={() => handleAnswering('next')}   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, alignSelf: 'end', marginTop: '20px', width: "max-content", marginLeft:  isPhone? '10px' : '10px', fontSize : isPhone ? '15px' : '18px'}}} >Suivante</LoadingButton>
                </div>
                
            </MainContent>
            
        </Container>
    </Home>
  )
}


const myQuestion = {
    question: "Dans le traitement d'ulcère de jambe veineux, la contention élastique cible : 1RJ",
    answers: [
        {
            id: 'A',
            content: "Les varices des membres inférieurs",
            selected: false,
            correction: false
        },
        {
            id: 'B',
            content: "La coloration brunâtre",
            selected: false,
            correction: false
        },
        {
            id: 'C',
            content: "L'œdème du membre inférieur",
            selected: true,
            correction: true
        },
        {
            id: 'D',
            content: "La douleur du mollet",
            selected: false,
            correction: false
        }
    ]
}