import React from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { ArrowRight, KeyboardArrowRight, ListAltOutlined, PeopleAltOutlined } from '@mui/icons-material'
import { Avatar, Button, IconButton } from '@mui/material'
import { deepOrange } from '@mui/material/colors'


const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    margin: 0px 10px;
    height: 80vh;
`

const Cartes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`

const Carte = styled.div`
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px 15px !important;
    background-color: ${uiSettings.colors.white};
    border-radius: 15px;
`
const CarteTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const Subs = styled.div`
    width: calc(100% - 30px);
    border-radius: 15px;
    background-color: ${uiSettings.colors.white};
    padding: 10px 15px;
    margin-top: 15px;
`

const SubTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const SubsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 15px;
`
const Subabonnement = styled.div`
    width: max-content;
    border-radius: 50px;
    text-align: center;
    padding: 4px 16px !important;
    
`

const ChampionUsers = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 5px;
`

const styles = {
    icon: {borderRadius: '4px', padding: '8px', fontSize: '25px', color: uiSettings.colors.yellow, backgroundColor: uiSettings.colors.lightYellow, marginRight: '15px'},
    username: {width: '33% ', marginRight: '20px',  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'},
    email: {width: '38% ', marginRight: '20px',   overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', },
    abonnement: {width: '28% ',  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',},
}

export default function Dashboard() {
  return (
    <Home>
        <Container style={{width: '60%',}}>
            <p style={{ ...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '15px'}}}>Overview</p>
            {/* // cartes */}
            <Cartes>
                <Carte>
                    <CarteTitle>
                        <PeopleAltOutlined style={{...styles.icon, }}/>
                        <p style={{ ...uiSettings.typos.link_semiBold, ...{ color: uiSettings.colors.lightGray}}}>Total of users</p>
                    </CarteTitle>
                    <p style={{ ...uiSettings.typos.heading2, ...{color: uiSettings.colors.headingColor, margin: '15px 0px', borderBottom: '3px solid ' + uiSettings.colors.yellow, width: 'min-content'}}}>256</p>
                </Carte>

                <Carte>
                    <CarteTitle>
                        <ListAltOutlined style={{...styles.icon, ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange}}}/>
                        <p style={{ ...uiSettings.typos.link_semiBold, ...{ color: uiSettings.colors.lightGray}}}>Total of quizzes</p>
                    </CarteTitle>
                    <p style={{ ...uiSettings.typos.heading2, ...{color: uiSettings.colors.headingColor, margin: '15px 0px', borderBottom: '3px solid ' + uiSettings.colors.orange, width: 'min-content'}}}>9865</p>
                </Carte>


            </Cartes>   

            {/* subscribers ****** */}
            <Subs>
                <SubTitle >
                    <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor}}}>New subscribers</p>
                    <Button endIcon={<KeyboardArrowRight/>} style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary}}} >more</Button>
                </SubTitle>

                {/* header */}
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.username}}>Username</p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Email</p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.abonnement}}>Abonnement</p>
                </SubsRow>
                <hr style={{color: uiSettings.colors.secondary, height: '1px'}}/>
                {/* // content */}
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar> <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px'}}>Mohammeddeeded</span> </p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Dellihrmoahmmed44@gmail.com</p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar> <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px'}}>Mohammeddeeded</span> </p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Dellihrmoahmmed44@gmail.com</p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar> <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px'}}>Mohammeddeeded</span> </p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Dellihrmoahmmed44@gmail.com</p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement}}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar> <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px'}}>Mohammeddeeded</span> </p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Dellihrmoahmmed44@gmail.com</p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement}}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar> <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px'}}>Mohammeddeeded</span> </p>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary}, ...styles.email}}>Dellihrmoahmmed44@gmail.com</p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement}}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
            </Subs>
        </Container>


        <Container style={{width: '40%'}}>
             {/* champions ****** */}
             <Subs style={{minHeight: '250px'}}>
                <SubTitle >
                    <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor}}}>Champions of the week !</p>
                    <Button endIcon={<KeyboardArrowRight/>} style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary}}} >more</Button>
                </SubTitle>

                {/* // content */}
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "70%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>Mohammeddeeded</p> 
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.secondary, marginTop: '-7px'}}}>score: 1002</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "70%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>Mohammeddeeded</p> 
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.secondary, marginTop: '-7px'}}}>score: 1002</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "70%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>Mohammeddeeded</p> 
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.secondary, marginTop: '-7px'}}}>score: 1002</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "70%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>Mohammeddeeded</p> 
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.secondary, marginTop: '-7px'}}}>score: 1002</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>6th year</Subabonnement></p>
                </SubsRow>
            </Subs>


            {/* quizzes */}
            <Subs>
                <SubTitle style={{justifyContent: 'start'}} >
                    <p style={{...uiSettings.typos.heading4, ...{color: uiSettings.colors.headingColor}}}>Most revised quizzes</p>
                </SubTitle>

                {/* // content */}
              
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "100%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>modile 2</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>156</Subabonnement></p>
                </SubsRow>
                <SubsRow>
                    <p style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor}, ...styles.username, ...{width: "100%"}}}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" style={{borderRadius: '12px'}}>
                            M
                        </Avatar>
                        <ChampionUsers >
                            <p style={{...uiSettings.typos.link_font, ...styles.username, ...{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', color: uiSettings.colors.headingColor}}}>Module 3</p> 
                        </ChampionUsers>
                    </p>
                    <p style={{...uiSettings.typos.link_font, ...styles.abonnement, ...{width: "30%"} }}><Subabonnement style={{...uiSettings.typos.link_font, ...{ ...{color: uiSettings.colors.orange, backgroundColor: uiSettings.colors.lightOrange, padding: '8px- 16px !important'}}}}>32</Subabonnement></p>
                </SubsRow>
            </Subs>


        </Container>
    </Home>
  )
}
