import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import "./styles.css";
import styled from 'styled-components'
import uiSettings from "../../../assets/ui-settings";
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Paper, TextField, colors } from "@mui/material";
import {  makeStyles } from "@mui/styles";
import { Switch } from 'antd';
import { AddOutlined, CheckBox, CheckCircleOutlineOutlined, CheckOutlined, Error, ErrorOutline, ErrorOutlineOutlined, Filter1Outlined, FilterListOutlined, KeyboardArrowDown, RemoveOutlined, Search, SearchOutlined } from "@mui/icons-material";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";

function valuetext(value) {
  return `${value}°C`;
}


const CustomPaper = (props) => {
  return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

const Home = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0px;
  height: 100%;
  padding-bottom: 20px !important;
  min-width:  ${props => props.isPhone && props.isSidebareOpened ? '400px' : 'unset'} ;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'} ;
    justify-content: space-between;
    align-items: center;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

const Container = styled.div`
    margin-top: 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px #0000001A;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: 0px;
`

const OptionList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10px;
    padding: 5px 0px;
    border-radius: 7px !important;
    overflow: auto;
    height: 300px;
    background-color: ${uiSettings.colors.bgblue};
    padding-bottom: 10px;

`

const OptionItem = styled.div`
    width: calc(100% - 30px);
    padding: 15px !important;
    background-color: ${uiSettings.colors.bgblue};
    color: ${uiSettings.colors.secondary};
    border-bottom: 1px solid ${uiSettings.colors.bgBlue};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px !important;
`
const OptionStatus = styled.div`
    background-color: ${uiSettings.colors.yellow} !important;
    color: black !important;
    font-size: 15px !important ;
    padding: 0px 10px !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

  const importedModules = [
    {
        id: 1,
        name: '',
        cours: [
            {name: '', selected: false},
        ]
    },
  ]
    

export default function CreateSession({styles,  isPhone, isSidebareOpened, handleError, setAlertMessage, setOpenAlert, setAlertType}) {
    
    const navigate = useNavigate()
    const [modules, setModules] = useState(importedModules);
    const [cours, setCours] = useState([]);
    const [moduleId, setModuleId] = useState('');
    const [moduleName, setModuleName] = useState('');
    const [displayedModules, setdisplayedModules] = useState(modules);
    const [courSearch, setCourSearch] = useState('');
    const Navigate = useNavigate()

    const [years, setYears] = useState([]);
    const [sources, setSources] = useState([]);

    const [title, setTitle] = useState("");
    const [autoTitle, setAutoTitle] = useState(false);
    const [description, setDescription] = useState('');

    function handlecour(item, name){
        var TestModules = displayedModules
        for (let i = 0; i < TestModules.length; i++) {
            if(TestModules[i].id === item ){
                if(name === 'select'){
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                            var lowercaseCour = TestModules[i].cours[j].name.toLocaleLowerCase()
                            var lowercaseKeyword = courSearch.toLocaleLowerCase()
                            if(lowercaseCour.includes(lowercaseKeyword)){
                            // if(TestModules[i].cours[j].name.includes(courSearch)){
                                TestModules[i].cours[j].selected = true
                                const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));
                                setdisplayedModules([...TestModules])
                                setModules([...TestModules])
                                setCours(TestModules[i].cours)
                                setModuleId(TestModules[i].id) 
                            }
                    }
                }else if(name === 'remove'){
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                        var lowercaseCour1 = TestModules[i].cours[j].name.toLocaleLowerCase()
                        var lowercaseKeyword1 = courSearch.toLocaleLowerCase()
                        if(lowercaseCour1.includes(lowercaseKeyword1)){
                        // if(TestModules[i].cours[j].name.includes(courSearch)){
                            TestModules[i].cours[j].selected = false
                            setModules([...TestModules])
                            const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));

                            setdisplayedModules([...TestModules])
                            setCours(TestModules[i].cours)
                            setModuleId(TestModules[i].id)
                        }
                    }
                }else{
                    for (let j = 0; j < TestModules[i].cours.length; j++) {
                        if(TestModules[i].cours[j].name === name){
                            console.log('name: ', TestModules[i].cours[j].name)
                            console.log('before: ', TestModules[i].cours[j].selected)
                            TestModules[i].cours[j].selected = !TestModules[i].cours[j].selected
                            const sortedModules = [...TestModules].sort((a, b) => a.name.localeCompare(b.name));


                            setdisplayedModules([...sortedModules])
                            setModules([...TestModules])
                            setCours(TestModules[i].cours)
                            setModuleId(TestModules[i].id)
                            console.log('after: ', TestModules[i].cours[j].selected)
                        }
                    }
                }
                
            }
        }
       
    }

    useEffect(() => {
        if(displayedModules[0]){
            if(displayedModules[moduleId - 1 ]){

            }else{
                setCours(displayedModules[0].cours)
                setModuleId(displayedModules[0].id)
                setModuleName(displayedModules[0].name)
            }
        }else{
            setCours([])
            setModuleId('')
            setModuleName('')
        }
    }, [displayedModules, module]);

    const [isLoading, setIsLoading] = useState(true);

    const [value, setValue] = React.useState([2004, 2013]);
    const [valueBeta, setValueBeta] = React.useState([2004, 2013]);

    const [isAllDates, setIsAllDates] = useState(true);
    const handleChange = (event, newValue) => {
      setValueBeta(newValue);
      //console.log(newValue)
    };

    const handleSliderChangeCommitted = (event, newValue) => {
        // Your logic here, e.g., updating the state
        console.log('done: ', newValue)
        setValue(newValue)
        handleChange(event, newValue);
    }

    const [numberOfQuestions, setnumberOfQuestions] = useState(120);
    function handleChangeNumberOfQuestions(type, number){
        if(type === 'new'){
            if(number >= 0 && number < 10001){
                setnumberOfQuestions( parseInt(number))
            }
        }
        else if(type === 'remove'){
            if(numberOfQuestions > 0){
                setnumberOfQuestions(numberOfQuestions - 1)
            }
        }else{
            if(numberOfQuestions < 10001){
                setnumberOfQuestions(numberOfQuestions + 1)
            }
        }
    }

    const [selectedTypesOfquestions, setSelectedTypesOfquestions] = useState([]); // State to store selected options
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedTypesOfquestions(newValue);
    };

    const [selectedsources, setSelectedsources] = useState([]); // State to store selected options
    const handleSourceChange = (event, newValue) => {
        setSelectedsources(newValue);
    };

    const [offerExpired, setOfferExpired] = useState(true);
    useEffect(() => {
        axios.post("/student/getBaseInfoToCreateSession")
        .then(function (response) {
          if(response.data.step === 'add_name'){
            navigate('/auth/full-name', { replace: true });
          }else if (response.data.step === 'add_phone'){
            navigate('/auth/phone-number', { replace: true });
          }else{
            if(response.data.response === 'done'){
                setOfferExpired(false)
                setIsLoading(false)
                console.log(response.data)
                if(response.data.modules && response.data.modules.length > 0){
                    setModules(response.data.modules)
                    const sortedModules = response.data.modules.sort((a, b) => a.name.localeCompare(b.name));
    
                    setdisplayedModules(sortedModules)
                    setCours(response.data.modules[0].cours)
                    setModuleId(response.data.modules[0].id)
                }
                setYears(response.data.years)
                setSources(response.data.sources)
            }else if (response.data.response === 'offerExpired'){
                setOfferExpired(true)
                setIsLoading(false)
            }
            
          }
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else{
            handleError(error)
          }
        })
    }, []);


    const [openFilter, setOpenFilter] = useState(false);
    const [openSessionTitle, setOpenSessionTitle] = useState(false);
   

    function handleSelectAllModules(action){
        const testModules = modules
        var select = false
        if(action === 'select'){
            select = true
        }
        for (let index = 0; index < testModules.length; index++) {
            const module = testModules[index];
            var lowercaseModule = module.name.toLocaleLowerCase()
            var lowercaseKeyword = moduleSearch.toLocaleLowerCase()
            if(lowercaseModule.includes(lowercaseKeyword)){
                for (let j = 0; j < module.cours.length; j++) {
                    const cour = module.cours[j];
                    cour.selected = select
                }
            }
            
        }
        setModules([...testModules])
    }

    const [moduleSearch, setModuleSearch] = useState('');
    function searchModule(item){
        setModuleSearch(item)
        if(item === ''){
            const sortedModules = [...modules].sort((a, b) => a.name.localeCompare(b.name));
            setdisplayedModules([...sortedModules])
            setCours(modules[0].cours);
            setModuleId(modules[0].id);

        }else{
            var testModules = []
            let i = 0 
            for (let index = 0; index < modules.length; index++) {
                const module = modules[index];
                var lowercaseModule = module.name.toLocaleLowerCase()
                var lowercaseKeyword = item.toLocaleLowerCase()
                if(lowercaseModule.includes(lowercaseKeyword)){
                    testModules.push(module)
                    i++
                }
            }
            if(i === 0) {
                setdisplayedModules([...[]]);
                setCours([...[]]);
                setModuleId('');
            }else{
                const sortedModules = [...testModules].sort((a, b) => a.name.localeCompare(b.name));
                setdisplayedModules(sortedModules)
                setCours([...testModules[0].cours]);
                setModuleId(testModules[0].id);
            }
            
        }
        
    }

    useEffect(() => {
        getQuestionsNumber()
    }, [modules, selectedTypesOfquestions, selectedsources, value, isAllDates]);




    function createSession(){
        axios.post("/student/CreateSession", {
            title: title,
            auto: autoTitle, 
            cours: modules,
            description: description,   
            types: selectedTypesOfquestions,
            sources: selectedsources,
            quantity: numberOfQuestions, 
            range: value,
            allDates: isAllDates,
            filter: openFilter
        })
        .then(function (response) {
            navigate('/dashboard/allSessions', { replace: false });
        })
        .catch(function (error) {
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else if(error.response.status === 400 && error.response.data.response === 'invalid_session_params'){
            if(error.response.data.errors[0]){
                setAlertMessage(error.response.data.errors[0].message)
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(function() {
                    setOpenAlert(false)
                }, 3000);
            }
            
          }else{
            handleError(error)
          }
        })
    }

    const [loadingNumber, setLoadingNumber] = useState(false);
    const [questionsNumber, setQuestionsNumber] = useState(0);

    function getQuestionsNumber(){
        setLoadingNumber(true)
        axios.post("/student/getQuestionsNumber", {
            cours: modules,
            types: selectedTypesOfquestions,
            sources: selectedsources,
            range: value,
            allDates: isAllDates,
            filter: openFilter
        })
        .then(function (response) {
            setLoadingNumber(false)
            setQuestionsNumber(response.data.number)
            console.log(response.data.number)
        })
        .catch(function (error) {
            setLoadingNumber(false)
          if(error.response.status === 403 && error.response.data.path === '/auth/identifier'){
            navigate('/auth/identifier', { replace: true });
          }else if(error.response.status === 400 && error.response.data.response === 'invalid_session_params'){
            if(error.response.data.errors[0]){
                setAlertMessage(error.response.data.errors[0].message)
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(function() {
                    setOpenAlert(false)
                }, 3000);
            }
            
          }else{
            handleError(error)
          }
        })
    }

    useEffect(() => {
        const sortedCourse = [...cours].sort((a, b) => a.name.localeCompare(b.name));
        setCours(sortedCourse)
    }, [cours]);

    if(isLoading){
        return(
            <Home style={{justifyContent: 'center', alignItems: 'center', width: 'calc(100% -20px)'}} isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                <CircularProgress />
            </Home> 
        )
    }else if(offerExpired){
        return(
            <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} style={{height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                {/* <ErrorOutlineOutlined style={{fontSize: '30px', color: uiSettings.colors.blue}}></ErrorOutlineOutlined>
                <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.headingColor, marginBottom: '20px', marginTop: '10px', flexDirection: 'row', display: 'flex'}}}>offer expired.</p> */}
                <div style={{width: '90%', maxWidth: '400px' , padding: '20px 10px', backgroundColor: uiSettings.colors.lightYellow, color: uiSettings.colors.orange, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '16px'}}>
                    <ErrorOutline style={{fontSize: "80px"}}/>
                    <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.orange, textAlign: 'center', marginBottom: '0px', marginTop: '15px', flexDirection: 'row', display: 'flex', flex: 1, padding: '0px 10px'}}}>Abonnement obligatoire</p>
                    <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, textAlign: 'center', marginBottom: '0px', marginTop: '5px', flex: 1, padding: '0px 10px'}}}><span style={{textDecorationLine: 'underline', cursor: 'pointer'}} onClick={()=> navigate('/dashboard/Abonnements')} >Ajouter un abonnement</span> pour pouvoir créer une nouvelle session.</p>
                </div>
            </Home>
        )
    }else{
        return (
            <Home isPhone={isPhone} isSidebareOpened={isSidebareOpened} >
                <Header>
                    <p style={{...styles.heading1, ...{color: uiSettings.colors.headingColor}}}>Créer une  <span style={{color: uiSettings.colors.blue}}> nouvelle session</span></p>
                    <Button   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display: isPhone? 'none' : 'block'}}}  onClick={() => createSession() }>Créer une session</Button>
                </Header>
        
                <Container style={{boxShadow: 'none', marginTop: '0px', paddingTop: '0px', display: 'none'}}>
                    <Header isPhone={isPhone}>
                        <Form style={{width: isPhone ? '100%' : '60%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>TITRE</p>
                            </FormTitle>
                            <TextField
                                    id=""
                                    placeholder={'Enter a title, like “Dermatologie - Cours : Psoriasis + Alopécies”'}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    variant='outlined'
                                    type={'text'}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}}
                                    }}
                                />
                        </Form>
        
                        <FormTitle style={{width: isPhone ? '100%' :'30%', alignSelf: 'end', marginTop: isPhone ? '10px' : '0px'}}>
                            <Switch className="custom-switch"  onchange={(e)=> setAutoTitle(e.target.checked)} ></Switch>
                            <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, marginLeft: '10px'}}}>Titre automatique</p>
                        </FormTitle>
                    </Header>
        
                    <Form style={{width: isPhone ? '100%' : '60%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>DESCRIPTION (optional)</p>
                            </FormTitle>
                            <TextField
                                    id=""
                                    placeholder={'Add a description...'}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant='outlined'
                                    type={'text'}
                                    multiline
                                    rows={3}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px' }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{padding: "16px"}}
                                    }}
                                />
                    </Form>
                </Container>
                
        
                {/* select cours */}
                <Container className="selectQuestions">
                    <SubContainer isPhone={isPhone}>
                        <Form style={{width: isPhone ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>MODULES</p>
                            </FormTitle>
        
                            <TextField
                                id=""
                                value={moduleSearch}
                                placeholder={'Recherche'}
                                onChange={(e) => searchModule(e.target.value)}
                                variant='outlined'
                                type={'text'}
                                style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                InputProps={{
                                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                        endAdornment: (
                                            <InputAdornment style={{marginRight: '10px'}} position="end">
                                                { <SearchOutlined />}
                                            </InputAdornment>
                                        ),
                                }}
                            />
        
                            <OptionList className="selectQuestions">
                                {
                                    [''].map((item) => {
                                        var message = 'Effacer tout'
                                        var action = 'remove'
                                        for (let index = 0; index < displayedModules.length; index++) {
                                            const module = displayedModules[index];
                                            for (let index = 0; index < module.cours.length; index++) {
                                                const cour = module.cours[index];
                                                if(cour.selected === false){
                                                    message = "Sélectionner tout"
                                                    action = 'select'
                                                }
                                                return (
                                                    <OptionItem onClick={() => handleSelectAllModules(action)} style={{cursor: 'pointer'}}>{message}</OptionItem>
                                                )
                                            }
                                        }
                                    })
                                }
                                {
                                    displayedModules.map((myModule) => { 
                                        var count = 0
                                        for (let index = 0; index < myModule.cours.length; index++) {
                                            const cour = myModule.cours[index];
                                            if(cour.selected === true) {
                                                count = count + 1
                                            }
                                        }
                                        
                                        return(
                                            <OptionItem style={{backgroundColor: myModule.name === moduleName ? uiSettings.colors.bgblue4 : 'unset', cursor: 'pointer' }} onClick={()=> {setCours(myModule.cours); setModuleId(myModule.id); setModuleName(myModule.name)}} >{myModule.name}<OptionStatus style={{display: count > 0 ? 'flex' : 'none'}}>{count}</OptionStatus> </OptionItem> 
                                        ) 
                                        
                                    })
                                }
                            </OptionList>
        
        
                        </Form>
        
                        <Form style={{width: isPhone ? '100%' : '47%', marginTop: isPhone ? '30px' : '20px'}}>
                                <FormTitle>
                                    <BarIcon>.</BarIcon>
                                    <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>COURS</p>
                                </FormTitle>
        
                                <TextField
                                    id=""
                                    placeholder={'Recherche'}
                                    value={courSearch}
                                    onChange={(e)=>{setCourSearch(e.target.value)}}
                                    variant='outlined'
                                    type={'text'}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}},
                                            endAdornment: (
                                                <InputAdornment style={{marginRight: '10px'}} position="end">
                                                    { <SearchOutlined />}
                                                </InputAdornment>
                                            ),
                                    }}
                                />
        
                                <OptionList className="selectQuestions">
                                    
                                        {
                                            [''].map((item)=>{
                                                if(displayedModules.length > 0 && cours.length>0){
                                                    var message = 'Effacer tout'
                                                    var action = 'remove'
                                                    var found = false
                                                    for (let index = 0; index < cours.length; index++) {
                                                        const cour = cours[index];
                                                        var lowercaseCour = cour.name.toLocaleLowerCase()
                                                        var lowercaseKeyword = courSearch.toLocaleLowerCase()
                                                        if(lowercaseCour.includes(lowercaseKeyword)){
                                                        // if(cour.name.includes(courSearch)){
                                                            found = true
                                                            if(cour.selected === false){
                                                                message = 'Sélectionner tout'
                                                                action = 'select'
                                                            }} 
                                                        }
                                                    if(found === true){
                                                       return (
                                                            <OptionItem onClick={() => handlecour(moduleId, action)} style={{cursor: 'pointer'}}>{message}</OptionItem>
                                                        )  
                                                    }
                                                   
                                                }
                                                
                                                
                                            })
                                        }
                                        {

                                            cours.map((cour)=>{
                                                var lowercaseCour = cour.name.toLocaleLowerCase()
                                                var lowercaseKeyword = courSearch.toLocaleLowerCase()
                                                if(lowercaseCour.includes(lowercaseKeyword)){
                                                // if(cour.name.includes(courSearch)){
                                                   return(
                                                        <OptionItem onClick={() => handlecour(moduleId, cour.name)} style={{backgroundColor: cour.selected? uiSettings.colors.bgblue4 :'inherit', cursor: 'pointer'}}>{cour.name} <CheckCircleOutlineOutlined style={{display: cour.selected ? 'block' : 'none', fontSize: '24px', padding: '0px', margin: '0px', color: uiSettings.colors.yellow, }} /></OptionItem>
                                                    )  
                                                }
                                            })
                                        }
                                </OptionList>
        
        
                        </Form>
                    </SubContainer>
                </Container>
        
        
                {/* questions filter ********* */}
                <Container>
                    <Header isPhone={isPhone} style={{alignItems: isPhone? 'start': 'center'}}>
                        <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>Comment souhaitez-vous les questions de votre session ?</p>
                        <Button onClick={()=> setOpenFilter(!openFilter)}   sx={{ textTransform: 'none' }} startIcon={<FilterListOutlined/>} endIcon={<KeyboardArrowDown/>}  style={{...styles.heading5, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, marginTop: isPhone ? '10px' : '0px'}}} >Sélectionnez un filtre</Button>
                    </Header>
        
                    <SubContainer className="animation1"  isPhone={isPhone} style={{ marginTop: '20px', display: openFilter ? 'flex' : 'none'}}>
                        <Form style={{width: isPhone ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Types de question</p>
                            </FormTitle>
        
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={typesOfQuestions}
                                value={selectedTypesOfquestions}
                                onChange={handleAutocompleteChange}
                                style={{...styles.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...styles.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) => option} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Sélectionner"}
                                    />
                                )}
                            />
        
                        </Form>
        
                        <Form style={{width: isPhone ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Sources</p>
                            </FormTitle>
        
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={sources}
                                value={selectedsources}
                                onChange={handleSourceChange}
                                style={{...styles.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                                InputProps={{
                                    style: {...styles.link_mid, ...{borderRadius: '7px'}}
                                }}
                                PaperComponent={CustomPaper}
                                getOptionLabel={(option) =>  option.source} // Specify the label property
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Sélectionner"}
                                    />
                                )}
                            />
        
                        </Form>
        
        
                    </SubContainer>
        
        
                    <SubContainer className="animation1"  isPhone={isPhone} style={{ marginTop: '20px', display: openFilter ? 'flex' : 'none', transition: '1s'}}>
                        <Form style={{width: isPhone ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Année</p>
                            </FormTitle>
        
                           <Box style={{marginTop: "30px"}}>
                                <Slider
                                    disabled={isAllDates}
                                    min={years[years.length - 1] ? parseInt(years[0].year) : 2013 }
                                    step={1}
                                    max={parseInt(years[years.length - 1] ? years[years.length - 1].year : 2000) }
                                    getAriaLabel={() => 'Temperature range'}
                                    value={valueBeta}
                                    onChange={handleChange}
                                    onChangeCommitted={handleSliderChangeCommitted}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                />
                                <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                            </Box>
        
                        </Form>
        
                        <Form style={{width: isPhone ? '100%' : '47%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>Nombre de questions</p>
                            </FormTitle>
        
                            <div style={{...styles.link_mid, ...{width: "calc(100% -32px)", backgroundColor: uiSettings.colors.bgblue, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 16px', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)'}}}>
                                <IconButton  style={{...styles.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('remove')}><RemoveOutlined/></IconButton>
                                {/* <p style={{...styles.link_mid, ...{color: uiSettings.colors.secondary}}}>{numberOfQuestions}</p> */}
                                <TextField
                                    className="numberTextField"
                                    id=""
                                    placeholder={'0'}
                                    value={numberOfQuestions}
                                    onChange={(e) => handleChangeNumberOfQuestions('new', e.target.value)}
                                    variant='outlined'
                                    type={'number'}
                                    max={5}
                                    style={{ backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', textAlign: 'center !important', border: 'none' }}
                                    InputProps={{
                                        maxLength: 3,
                                        style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px', textAlign: 'center', border: 'none'}},
                                    }}
                                />
                                <IconButton  style={{...styles.link_mid, ...{color: uiSettings.colors.secondary, padding: '5px'}}} onClick={()=> handleChangeNumberOfQuestions('add')}><AddOutlined/></IconButton>
                            </div>
                            <p style={{...styles.heading4, ...{color: uiSettings.colors.headingColor, marginTop: '10px'}}}>Questions disponibles: <span style={{color: uiSettings.colors.blue}} >{loadingNumber? 'calcule..' : questionsNumber}</span></p>
        
                            
        
                        </Form>
        
        
                    </SubContainer>
                   
                </Container>



                 {/* Session title ********* */}
                 <Container>
                    <Header isPhone={isPhone} style={{alignItems: isPhone? 'start': 'center'}}>
                        <p style={{...styles.heading3, ...{color: uiSettings.colors.headingColor}}}>Comment souhaitez-vous présenter le titre de la session ?</p>
                        <Button onClick={()=> setOpenSessionTitle(!openSessionTitle)}   sx={{ textTransform: 'none' }} startIcon={<AddOutlined/>} endIcon={<KeyboardArrowDown/>}  style={{...styles.heading5, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, marginTop: isPhone ? '10px' : '0px'}}} >Ajouter un titre</Button>
                    </Header>
        
                    <Container className="animation1" style={{boxShadow: 'none', marginTop: '0px', paddingTop: '0px', display: openSessionTitle ? 'flex' : 'none'}}>
                    <Header isPhone={isPhone}>
                        <Form style={{width: isPhone ? '100%' : '60%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>TITRE</p>
                            </FormTitle>
                            <TextField
                                    id=""
                                    placeholder={'Enter a title, like “Dermatologie - Cours : Psoriasis + Alopécies”'}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    variant='outlined'
                                    type={'text'}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px', }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{}}
                                    }}
                                />
                        </Form>
        
                        <FormTitle style={{width: isPhone ? '100%' :'30%', alignSelf: 'end', marginTop: isPhone ? '10px' : '0px'}}>
                            <Switch className="custom-switch"  onchange={(e)=> setAutoTitle(e.target.checked)} ></Switch>
                            <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor, marginLeft: '10px'}}}>Titre automatique</p>
                        </FormTitle>
                    </Header>
        
                    <Form style={{width: isPhone ? '100%' : '60%'}}>
                            <FormTitle>
                                <BarIcon>.</BarIcon>
                                <p style={{...styles.navText, ...{color: uiSettings.colors.headingColor}}}>DESCRIPTION (optional)</p>
                            </FormTitle>
                            <TextField
                                    id=""
                                    placeholder={'Add a description...'}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant='outlined'
                                    type={'text'}
                                    multiline
                                    rows={3}
                                    style={{width: "100%", backgroundColor: uiSettings.colors.bgblue, borderRadius: '8px 0px 0px 8px' }}
                                    InputProps={{
                                            style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black, borderRadius: '7px',}, ...{padding: "16px"}}
                                    }}
                                />
                    </Form>
                </Container>
                   
                </Container>
        
                <Header style={{justifyContent: 'end', margin: '20px 0px'}}>
                    <Button   sx={{ textTransform: 'none' }}  style={{...styles.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow}}} onClick={() => createSession() } >Créer une session</Button>
                </Header>
        
            </Home>
        
          )
    }

}



const typesOfQuestions = [ 'QCM', "QCS", 'Cas clinique']


const sourceOfQuestions = [
    { title: 'Alger', year: 2009 },
    { title: 'Blida', year: 1975 },
    { title: 'Oran', year: 1975 },
    { title: 'External', year: 1975 },
  ];

