import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components'
import uiSettings from '../../../assets/ui-settings'
import { lighten, darken, styled as styling, display } from '@mui/system';
import { Autocomplete, Avatar, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Slider, TextField } from '@mui/material';
import { Add, AddOutlined, Attachment, ClearOutlined, ContentCopyOutlined, DeleteOutlineOutlined, Filter, FilterList, HighlightOffOutlined, KeyboardArrowDown, ModeEditOutlineOutlined, MoreVert, NotInterestedOutlined, OpenInNew, Padding, Remove, RemoveCircle, RemoveOutlined, RemoveRedEyeOutlined, Search, SearchOutlined, VerticalAlignBottom, VerticalAlignTop } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Pagination from '@mui/material/Pagination';
import { css } from '@emotion/react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


function valuetext(value) {
  return `${value}°C`;
}

const CustomPaper = (props) => {
  return <Paper style={{ ...uiSettings.typos.heading5, ...{backgroundColor: uiSettings.colors.bgblue, color: uiSettings.colors.secondary, fontWeight: '400', boxShadow: '0px 0px 5px 0px #0000001A;', marginTop: "10px", marginBottom: "10px", borderRadius: "7px"}}} elevation={8} {...props} />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupHeader = styling('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: uiSettings.colors.headingColor,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(uiSettings.colors.secondary, 0.85)
      : darken(uiSettings.colors.blue, 0.8),
}));

const GroupItems = styling('ul')({
  padding: 0,
});

const Home = styled.div`
    width: calc(100% - 30px);
    height: calc(100-30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const Buttons = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
`

const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

const BarIcon = styled.div`
    width: 8px !important;
    height: 30px !important;
    background-color: ${uiSettings.colors.yellow};
    color: transparent;
    margin-right: 10px;
    border-radius: 16px;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.isPhone ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: 0px;
`

const OptionList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10px;
    padding: 5px 0px;
    border-radius: 7px !important;
    overflow: auto;
    height: 300px;
    background-color: ${uiSettings.colors.bgblue};
    padding-bottom: 10px;

`

const OptionItem = styled.div`
    width: calc(100% - 30px);
    padding: 15px !important;
    background-color: ${uiSettings.colors.bgblue};
    color: ${uiSettings.colors.secondary};
    border-bottom: 1px solid ${uiSettings.colors.bgBlue};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px !important;
`
const OptionStatus = styled.div`
    background-color: ${uiSettings.colors.yellow} !important;
    color: black !important;
    font-size: 15px !important ;
    padding: 0px 10px !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const NavbarSearch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 385px;
`





const CustomHeader = ({ column }) => {
  // Define a custom header component that does not include the menu icon
  return (
    <div>
      {column.headerName} ..
    </div>
  );
};

const CustomRowMenu = ({ row, setOpenWaitModal4, setSelectedQuiz }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const handleOpenMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleOpenMenu}>
        {/* Icon for the menu */}
        {/* You can replace this with your desired icon */}
        <MoreVert/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={()=> window.open('/admin/Questions/question/'+ row.id, '_blank')}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
         <RemoveRedEyeOutlined  style={{marginRight: '10px'}}/> view question
        </MenuItem>


        {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
         <ModeEditOutlineOutlined style={{marginRight: '10px'}}/> edit user profile
        </MenuItem> */}

        {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '5px 16px'}}}>
         <NotInterestedOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> ban user
        </MenuItem> */}

        <MenuItem onClick={()=> {setSelectedQuiz(row.id); setOpenWaitModal4(true)}}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '5px 16px'}}}>
         <DeleteOutlineOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> Delete question
        </MenuItem>
        
        {/* Add more menu items as needed */}
      </Menu>
    </div>
  );
};

const CustomRowMenu5 = ({ setOpenWaitModal4 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleOpenMenu}>
        {/* Icon for the menu */}
        {/* You can replace this with your desired icon */}
        <MoreVert/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >


        {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
         <ModeEditOutlineOutlined style={{marginRight: '10px'}}/> edit user profile
        </MenuItem> */}

        {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '5px 16px'}}}>
         <NotInterestedOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> ban user
        </MenuItem> */}

        <MenuItem onClick={()=> {setOpenWaitModal4(true); handleCloseMenu()}}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '5px 16px'}}}>
         <DeleteOutlineOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> Delete questions
        </MenuItem>
        
        {/* Add more menu items as needed */}
      </Menu>
    </div>
  );
};


function extractTextFromHTML(htmlContent) {
  // Create a temporary div element
  var tempDiv = document.createElement('div');
  
  // Set the HTML content of the div
  tempDiv.innerHTML = htmlContent;
  
  // Find all <p> tags
  var paragraphs = tempDiv.getElementsByTagName('p');
  
  // Initialize an empty string to store the extracted text
  var extractedText = '';
  
  // Iterate over each <p> tag and extract its text content
  for (var i = 0; i < paragraphs.length; i++) {
      // Add the text content of the <p> tag to the extracted text string
      extractedText += paragraphs[i].textContent.trim() + '\n';
  }
  
  // Return the extracted text
  return extractedText.trim();
}




export default function Questions() {

  const [openWaitModal4, setOpenWaitModal4] = useState(false);
  const onCloseWaitModal4 = () => {
    // Handle cancel button click, e.g., stop the ongoing process
    setOpenWaitModal4(false);
  };

  const [openWaitModal5, setOpenWaitModal5] = useState(false);
  const onCloseWaitModal5 = () => {
    // Handle cancel button click, e.g., stop the ongoing process
    setOpenWaitModal5(false);
  };
  
  const columns = [
    { 
      field: 'title', 
      headerName: 'Title', 
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap', color: uiSettings.colors.headingColor}}>
            {params.row.enonce.length > 0 ? extractTextFromHTML(params.row.enonce)  : params.row.question}
          </span>
          
        );
      },
    },
    { 
      field: 'year', 
      headerName: 'Year', 
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params.row.year ? params.row.year - 1 +'/' + params.row.year : ''}
          </>
          
        );
      },
    },
    { field: 'module', headerName: 'Module', width: 150 },
    { field: 'cour', headerName: 'Cour', width: 150 },
  
    {
      field: 'source',
      headerName: 'Faculty',
      width: 150,   
    },
    { 
      field: 'type', 
      headerName: 'Type', 
      width: 160,
      renderCell: (params) => {
        var bgColor = uiSettings.colors.lightOrange
        var color = uiSettings.colors.orange
        if(params.row.type === 'QCM'){
          var bgColor = uiSettings.colors.lightYellow
          var color = uiSettings.colors.yellow
        }else if(params.row.type === 'QCS'){
          var bgColor = uiSettings.colors.bgBlue
          var color = uiSettings.colors.blue
        }
        return (
          <>
            <span style={{padding: '8px 16px', borderRadius: '8px', backgroundColor: bgColor, color: color}}>{params.row.type}</span> 
          </>
          
        );
      },
    },
    { 
      field: 'options', 
      headerName: (<CustomRowMenu5 onClick={(e) => {e.stopPropagation()}} setOpenWaitModal4={setOpenWaitModal5} /> ), 
      width:  100,
      columnMenu: false,
      sortable: false,
      menubar: false,
      headerComponent: CustomHeader,
      renderCell: (params) => {
        return (
          <CustomRowMenu onClick={(e) => {e.stopPropagation()}} row={params.row} setOpenWaitModal4={setOpenWaitModal4} setSelectedQuiz={setSelectedQuiz} />
        );
      },
    },
    
  ];
  
  
  
  const CustomRowMenu2 = ({ row, merged }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <IconButton onClick={handleOpenMenu}>
          {/* Icon for the menu */}
          {/* You can replace this with your desired icon */}
          <MoreVert/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={()=> window.open('/admin/Questions/question/' + row.id, '_blank')}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <RemoveRedEyeOutlined  style={{marginRight: '10px'}}/> view question
          </MenuItem>
  
          <MenuItem disabled={merginQuizzes && isQuizMerged(merginQuizzes, row.id) ? true : false} onClick={(e)=> {mergeQuiz(row); handleCloseMenu(e)}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <ContentCopyOutlined  style={{marginRight: '10px'}}/>{merginQuizzes && isQuizMerged(merginQuizzes, row.id) ? 'merging..' : 'merge duplicates'}
          </MenuItem>
  
          {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <ModeEditOutlineOutlined style={{marginRight: '10px'}}/> edit user profile
          </MenuItem> */}
  
          {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '5px 16px'}}}>
           <NotInterestedOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> ban user
          </MenuItem> */}
  
          {/*<MenuItem disabled onClick={handleCloseMenu}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '5px 16px'}}}>
            <DeleteOutlineOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> Delete question
          </MenuItem>*/}
          
          {/* Add more menu items as needed */}
        </Menu>
      </div>
    );
  };


  const CustomRowMenu3 = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    return (
      <div style={{marginLeft: '5px'}}>
        {/* <IconButton onClick={handleOpenMenu}>
          <MoreVert/>
        </IconButton> */}
        <span onClick={handleOpenMenu} style={{cursor: 'pointer', borderBottom: 'white 1px solid'}}>Find duplicated quizzes</span>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {/* <MenuItem onClick={()=> findDuplicatedQuizByQuestion()}    style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}> */}
          <MenuItem onClick={()=> setOpenWaitModal2(true)}    style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <ContentCopyOutlined  style={{marginRight: '10px'}}/> by question
          </MenuItem>

          {/* <MenuItem onClick={()=> findDuplicatedQuiz()}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}> */}
          <MenuItem onClick={()=> setOpenWaitModal(true)}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <ContentCopyOutlined  style={{marginRight: '10px'}}/> by all parameters
          </MenuItem>
  
  
          {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, padding: '5px 16px'}}}>
           <ModeEditOutlineOutlined style={{marginRight: '10px'}}/> edit user profile
          </MenuItem> */}
  
          {/* <MenuItem onClick={handleCloseMenu} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '5px 16px'}}}>
           <NotInterestedOutlined style={{marginRight: '10px', color: uiSettings.colors.red}}/> ban user
          </MenuItem> */}
          
          {/* Add more menu items as needed */}
        </Menu>
      </div>
    );
  };

  // mui menu

  const [openAlert, setOpenAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');
  const [AlertType, setAlertType] = React.useState('');

  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openUploadModal, setOpenUploadModal] = React.useState(false);
  const handleClickOpenUploadModal = () => {
    setOpenUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };


  const [sortBy, setSortBy] = useState('All');

  const [filter, setFilter] = useState(true);

  const Navigate = useNavigate()


  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingUplaod, setLoadingUplaod] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setOpenUploadModal(true)
  };

  const handleUpload = () => {
    setAlertMessage('Uploading your csv file.., it may take few seconds.')
    setAlertType('info')
    setOpenAlert(true)
    setLoadingUplaod(true)
    setOpenUploadModal(false)
    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post('/admin/importQuiz', formData)
      .then(response => {
        setLoadingUplaod(false)
        if(response.data.response === 'success'){
          setAlertMessage('New quizzes uploaded successfully, execution time: ' + response.data.timeInSeconds + 's')
          setAlertType('success')
          setOpenAlert(true)
        }else if(response.data.response === 'CSVOnly'){
          setAlertMessage('You must select a CSV file.')
          setAlertType('error')
          setOpenAlert(true)
        }else if(response.data.response === 'fileNotFound'){
          setAlertMessage('File not found, try again.')
          setAlertType('error')
          setOpenAlert(true)
        }else if(response.data.response === 'serverError'){
          setAlertMessage('An unexpected error has occured.')
          setAlertType('error')
          setOpenAlert(true)
        }
      })
      .catch(error => {
        if(error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          
        setLoadingUplaod(false)
        console.error(error);
        setAlertMessage('An unexpected error has occured.')
        setAlertType('error')
        setOpenAlert(true)
      }
      });
  };

  const [quizzes, setQuizzes] = useState([]);
  const [duplicatedQuizzes, setDuplicatedQuizzes] = useState([]);
  const [duplicatedQuizzesByQuestion, setDuplicatedQuizzesByQuestion] = useState([]);
  const [quizzesLoading, setQuizzesLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [sources, setSources] = useState([]);
  const [dates, setDates] = useState([]);
  const [cours, setCours] = useState([]);
  //const [years, setYears] = useState([]);
  const [quizGroupCount, setQuizGroupCount] = useState(0);
  const [quizCount, setQuizCount] = useState(0);

  const getQuizzes = (size, offset, init) => {
    setQuizzesLoading(true)
    axios.post("/admin/getQuizzes", {size, offset})
        .then(function (response) {
          setQuizzesLoading(false)
          console.log(response.data)
          setQuizzes(response.data.quizzes)
          if(init === true){
            setModules(response.data.modules)
            setSources(response.data.sources)
            setDates(response.data.dates)
            setCours(response.data.cours)
            setYears(response.data.years)
            setSavedfilterModules([])
            setSavedfilterCours([])
            setSavedfilterType([])
            setSavedfilterExplication('All')
            setSavedfilterSources([])
            setfilterStartYear(response.data.years[0])
            setSavedfilterStartYear(response.data.years[0])
            setfilterEndYear(response.data.years[response.data.years.length - 1])
            setSavedfilterEndYear(response.data.years[response.data.years.length - 1])
            setQuizCount(response.data.quizCount)
            setQuizGroupCount(response.data.quizGroupCount)
          }
          // setQuizzes(prevDataArray => [...prevDataArray, ...response.data.quizzes])
        })
        .catch(function (error) {
          if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            
          setQuizzesLoading(false)
          //console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
        }
        })
  }

  function resetFilter(){    
    setfilterModules([])
    setfilterCours([])
    setfilterType([])
    setfilterExplication('All')
    setfilterSources([])
    setIsAllDates(true)
    setSavedfilterModules([])
    setSavedfilterCours([])
    setSavedfilterType([])
    setSavedfilterExplication('All')
    setSavedfilterSources([])
    setSavedIsAllDates(true)
    setResetCount(resetCount + 1)
  }



  const [exportRows, setExportRows] = useState([{page: 1, selectedRows: []}]);

  const fliterQuizzes = (size, offset, init) => {
    setQuizzesLoading(true)
    // setUpdatedSelectedRows(selectedRows)
    if(init === true){
      setSavedfilterModules(filterModules)
      setSavedfilterCours(filterCours)
      setSavedfilterType(filterType)
      setSavedfilterExplication(filterExplication)
      setSavedfilterSources(filterSources)
      setSavedfilterStartYear(filterStartYear)
      setSavedfilterEndYear(filterEndYear)
      setSavedIsAllDates(isAllDates)
      console.log('')
      setOpenFilterModal(false)
    }
    axios.post("/admin/filterQuizzes", {
      size, offset, modules: init === true ? filterModules : SavedfilterModules, cours: init === true ? filterCours : SavedfilterCours, types: init === true ? filterType : SavedfilterType, explication: filterExplication === 'All' ? '' : filterExplication,  sources: init === true ? filterSources : SavedfilterSources, startYear: init === true ? filterStartYear : SavedfilterStartYear, endYear: init === true ? filterEndYear : SavedfilterEndYear, isAllDates: init === true ? isAllDates : SavedisAllDates, search: quizSearch
    }).then(function (response) {
          setQuizzesLoading(false)
          console.log(response.data)
          setQuizzes(response.data.quizzes)
          if(init === true){
            setQuizCount(response.data.quizCount)
            setQuizGroupCount(response.data.quizGroupCount)
            setCurrentPage(1)
          }
          //selectedRows(updatedSelectedRows)
          document.querySelector('.MuiDataGrid-virtualScroller').scrollTop = 0;
          //scrollToTop(dataGridRef)
          //setModules(response.data.modules)
          //setSources(response.data.sources)
          //setCours(response.data.cours)
          //setYears(response.data.years)
          // setQuizzes(prevDataArray => [...prevDataArray, ...response.data.quizzes])
        })
        .catch(function (error) {
          if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            
          setQuizzesLoading(false)
          console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
        }
        })
  }

  useEffect(() => {
    getQuizzes(100, 0, true)
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setSelectedChanges(0)
    const selectedPage = exportRows.find((item) => item.page === page);
    if(selectedPage){
     

    }else{
      console.log('inserting new page..', page)
      selectedBase = exportRows
      selectedBase.push({page, selectedRows: []})
      console.log(selectedBase)
      setExportRows(selectedBase)
    }
    setCurrentPage(page);
    fliterQuizzes(100, page - 1, false)
  };

  const [selectedTypesOfquestions, setSelectedTypesOfquestions] = useState([]); // State to store selected options
  const handleAutocompleteChange = (event, newValue) => {
      setSelectedTypesOfquestions(newValue);
  };

  const [filterModules, setfilterModules] = useState([]); // State to store selected options
  const [SavedfilterModules, setSavedfilterModules] = useState([]); // State to store selected options
  const handleAutocompleteChangeModules = (event, newValue) => {
    setfilterModules(newValue);
  };

  const [filterSources, setfilterSources] = useState([]); // State to store selected options
  const [SavedfilterSources, setSavedfilterSources] = useState([]); // State to store selected options
  const handleAutocompleteChangeSources = (event, newValue) => {
    setfilterSources(newValue);
  };


  const [filterCours, setfilterCours] = useState([]); // State to store selected options
  const [SavedfilterCours, setSavedfilterCours] = useState([]); // State to store selected options
  const handleAutocompleteChangeCours = (event, newValue) => {
    setfilterCours(newValue);
  };

  const [filterStartYear, setfilterStartYear] = useState([]); // State to store selected options
  const [SavedfilterStartYear, setSavedfilterStartYear] = useState([]); // State to store selected options
  const handleAutocompleteChangeStartYear = (event, newValue) => {
    setfilterStartYear(newValue);
  };


  const [filterEndYear, setfilterEndYear] = useState([]); // State to store selected options
  const [SavedfilterEndYear, setSavedfilterEndYear] = useState([]); // State to store selected options
  const handleAutocompleteChangeEndYear = (event, newValue) => {
    setfilterEndYear(newValue);
  };

  const [filterType, setfilterType] = useState([]); // State to store selected options
  const [SavedfilterType, setSavedfilterType] = useState([]); // State to store selected options
  const handleAutocompleteChangeType = (event, newValue) => {
    setfilterType(newValue);
  };


  const [filterExplication, setfilterExplication] = useState('All'); // State to store selected options
  const [SavedfilterExplication, setSavedfilterExplication] = useState('All'); // State to store selected options
  const handleAutocompleteChangeExplication = (event, newValue) => {
    setfilterExplication(newValue);
  };




    const [isAllDates, setIsAllDates] = useState(true);
    const [SavedisAllDates, setSavedIsAllDates] = useState(true);
    const [years, setYears] = useState([]);
    const [value, setValue] = React.useState([2004, 2013]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [resetCount, setResetCount] = useState(0);
    useEffect(() => {
      fliterQuizzes(100, 0, true)
    }, [resetCount]);

    
    const [openFilterModal, setOpenFilterModal] = React.useState(false);
    const handleClickOpenFilterModal = () => {
      setfilterModules(SavedfilterModules)
      setfilterCours(SavedfilterCours)
      setfilterType(SavedfilterType)
      setfilterExplication(SavedfilterExplication)
      setfilterSources(SavedfilterSources)
      setfilterStartYear(SavedfilterStartYear)
      setfilterEndYear(SavedfilterEndYear)
      setIsAllDates(SavedisAllDates)
      setOpenFilterModal(true);
    };
    const handleCloseFilterModal = () => {
      setOpenFilterModal(false);
    };

    const options = cours.map((option) => {
      const firstLetter = option.cour[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedSelectedRows, setUpdatedSelectedRows] = useState([]);
    var selectedBase = [{page: 1, selectedRows: []}]
    
    const [selectedChanges, setSelectedChanges] = useState(1);

    const [selectedDuplicates, setSelectedDuplicates] = useState([]);
    const handleSelectedDuplicatesChange = (selection) => {
      setSelectedDuplicates(selection)
    }

    const handleSelectionChange = (selection) => {
      //console.log(selection)
      if(selectedChanges === 0){
        const myselectedPage = exportRows.find((item) => item.page === currentPage);
        setSelectedRows(myselectedPage ? myselectedPage.selectedRows : [])
        setSelectedChanges(selectedChanges + 1)
      }else{
        setSelectedRows(selection);
        setSelectedChanges(selectedChanges + 1)

        const index = exportRows.findIndex((item) => item.page === currentPage);
        if (index !== -1) {
          // Page found, update selectedRows
          console.log(selection)
          selectedBase = exportRows
          selectedBase[index].selectedRows = selection;
          console.log(selectedBase)
          setExportRows(selectedBase)
          //console.log('data updated')
        } else {
          // Page not found, add a new entry
        }
      }
    };

    const [quizSearch, setQuizSearch] = useState('');

    useEffect(() => {
      fliterQuizzes(100, 0, true)
    }, [quizSearch]);

    const [loadingExport, setLoadingExport] = useState(false);

    function exportQuiz(){
      const readyQuizToExport = []
      for (let i = 0; i < exportRows.length; i++) {
        const rows = exportRows[i];
        for (let index = 0; index < rows.selectedRows.length; index++) {
          const quiz = rows.selectedRows[index];
          readyQuizToExport.push(quiz)
        }
      }
      if(readyQuizToExport.length === 0){
        setAlertMessage('Select one quiz or more to export.')
        setAlertType('warning')
        setOpenAlert(true)
      }else{
        setAlertMessage('Exporting, please wait.')
        setAlertType('info')
        setOpenAlert(true)
        setLoadingExport(true)
        axios.post("/admin/exportQuiz", {
          quiz: readyQuizToExport  
        }).then(function (response) {
          setLoadingExport(false)
          if(response.data.response === 'serverError'){
            setAlertMessage(response.data.message)
            setAlertType('error')
            setOpenAlert(true)
          }else{
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a temporary URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href attribute to the temporary URL
            link.href = url;

            // Set the link's download attribute to the desired filename
            link.download = 'data.csv';

            // Append the link to the DOM
            document.body.appendChild(link);

            // Click the link to download the file
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);

            // Revoke the temporary URL to free up memory
            URL.revokeObjectURL(url);
          }
        })
        .catch(function (error) {
          if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            
          setLoadingExport(false)
          //console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
        }
        })
      }
    }

    function findDuplicatedQuiz(){
      //setOpenFilterModal(false)
      setSerchingDuplicatesOnProcess(true)
      //setOpenWaitModal(true)
      // setLoadingFetchDuplicates(true)
      axios.post("/admin/findDuplicatedQuizzes")
      .then(function (response) {
        setSerchingDuplicatesOnProcess(false)
        console.log(response.data.quizzes)
        setDuplicatedQuizzes(response.data.quizzes)
        setLoadingFetchDuplicates(false)
      })
      .catch(function (error) {
        setSerchingDuplicatesOnProcess(false)
        if(error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          
        //console.log(error)
        setAlertType('error')
        setAlertMessage('An unexpected error has occured')
        setOpenAlert(true)
      }
      })
    }

    function alphabeticalComparator(a, b, orderBy) {
      if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
        return -1;
      }
      if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    


    function findDuplicatedQuizByQuestion(){
      //setOpenFilterModal(false)
      console.log('start')
      setSerchingDuplicatesByQuestionOnProcess(true)
      setOpenWaitModal2(true)
      setLoadingFetchDuplicatesByQuestion(true)

      axios.post("/admin/findDuplicatedQuizzesByQuestion")
      .then(function (response) {
        setSerchingDuplicatesByQuestionOnProcess(false)
        console.log(response.data)
        setDuplicatedQuizzesByQuestion(response.data.quizzes)
        setLoadingFetchDuplicatesByQuestion(false)
      })
      .catch(function (error) {
        setSerchingDuplicatesByQuestionOnProcess(false)
        if(error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
        }else{
            
          // console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
        }
      })
    }

    const [openWaitModal, setOpenWaitModal] = useState(false);
    const [loadingFetchDuplicates, setLoadingFetchDuplicates] = useState(false);
    const onCloseWaitModal = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setOpenWaitModal(false);
    };

    const [openWaitModal2, setOpenWaitModal2] = useState(false);
    const [loadingFetchDuplicatesByQuestion, setLoadingFetchDuplicatesByQuestion] = useState(false);
    const onCloseWaitModal2 = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setOpenWaitModal2(false);
    };

    


    const [openWaitModal3, setOpenWaitModal3] = useState(false);
    const onCloseWaitModal3 = () => {
      // Handle cancel button click, e.g., stop the ongoing process
      setOpenWaitModal3(false);
    };

    const [merginQuizzes, setMerginQuizzes] = useState([]);


    const [serchingDuplicatesOnProcess, setSerchingDuplicatesOnProcess] = useState(false);
    const [serchingDuplicatesByQuestionOnProcess, setSerchingDuplicatesByQuestionOnProcess] = useState(false);

    const duplicatedColumns = [
      { 
        field: 'title', 
        headerName: 'Title', 
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
          return (
            <span style={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap', color: uiSettings.colors.headingColor}}>
              {params.row.enonce.length > 0 ? extractTextFromHTML(params.row.enonce) : params.row.question}
            </span>
            
          );
        },
      },
      { 
        field: 'year', 
        headerName: 'Year', 
        width: 130,
        renderCell: (params) => {
          return (
            <>
              {params.row.year ? params.row.year - 1 +'/' + params.row.year : ''}
            </>
            
          );
        },
      },
      { field: 'module', headerName: 'Module', width: 150 },
      { field: 'cour', headerName: 'Cour', width: 150 },
      
      {
          field: 'source',
          headerName: 'Faculty',
          width: 150,   
        },
        /*{ 
          field: 'type', 
          headerName: 'Type', 
          width: 160,
          renderCell: (params) => {
            var bgColor = uiSettings.colors.lightOrange
            var color = uiSettings.colors.orange
            if(params.row.type === 'QCM'){
              var bgColor = uiSettings.colors.lightYellow
              var color = uiSettings.colors.yellow
            }else if(params.row.type === 'QCS'){
              var bgColor = uiSettings.colors.bgBlue
              var color = uiSettings.colors.blue
            }
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <span style={{padding: '8px 16px', borderRadius: '8px', backgroundColor: bgColor, color: color}}>{params.row.type}</span> 
            </div>
            
            );
          },
        },*/
        { 
          field: 'count', 
          headerName: 'Copies', 
          width: 90,
          renderCell: (params) => {
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <span>{params.row.count}</span> 
            </div>
            
            );
          },
        },
        //{ field: 'count', headerName: 'Copies', width: 120, textAlign: 'center' },
        { 
          field: 'options', 
          headerName: (<IconButton>< MoreVert /></IconButton> ), 
          width:  100,
          columnMenu: false,
          sortable: false,
          menubar: false,
          headerComponent: CustomHeader,
          renderCell: (params) => {
            return (
              <CustomRowMenu2 onClick={(e) => e.stopPropagation()} row={params.row} merged={isQuizMerged(merginQuizzes, params.row.id)} />
            );
          },
        },
      
    ];

    const duplicatedByQuestionColumns = [
      { field: 'quiz_id', headerName: 'Id', width: 100 },
      { 
        field: 'question', 
        headerName: 'Question', 
        minWidth: 300,
        flex: 1,
        renderCell: (params) => {
          return (
            <span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap', color: uiSettings.colors.headingColor}}>
              {params.row.question}
            </span>
            
            );
          },
        },
        
        { 
          // field: '', 
          headerName: '', 
          width:  60,
          columnMenu: false,
          sortable: false,
          menubar: false,
          headerComponent: CustomHeader,
          renderCell: (params) => {
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
                <IconButton variant='outlined' style={{backgroundColor: uiSettings.colors.yellow}} onClick={()=> window.open('/admin/Questions/question/' + params.row.id, '_blank')}><OpenInNew style={{color: uiSettings.colors.white, backgroundColor: uiSettings.colors.yellow}}/></IconButton>
              </div>
              // / <CustomRowMenu2 onClick={(e) => e.stopPropagation()} row={params.row} merged={isQuizMerged(merginQuizzes, params.row.id)} />
            );
          },
        },
        { 
          field: 'enonce', 
          headerName: 'Enonce', 
          minWidth: 150,
          flex: 1,
          renderCell: (params) => {
            return (
              <span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis ', whiteSpace: 'nowrap', color: uiSettings.colors.headingColor}}>
                {extractTextFromHTML(params.row.enonce)}
              </span>
              
              );
            },
          },
        { 
          field: 'year', 
          headerName: 'Year', 
          width: 130,
          renderCell: (params) => {
            return (
              <>
              {params.row.year ? params.row.year - 1 +'/' + params.row.year : ''}
            </>
            
            );
          },
        },
      { field: 'module', headerName: 'Module', width: 150 },
      { field: 'cour', headerName: 'Cour', width: 150 },
      
      {
          field: 'source',
          headerName: 'Faculty',
          width: 150,   
        },
        { 
          field: 'type', 
          headerName: 'Type', 
          width: 160,
          renderCell: (params) => {
            var bgColor = uiSettings.colors.lightOrange
            var color = uiSettings.colors.orange
            if(params.row.type === 'QCM'){
              var bgColor = uiSettings.colors.lightYellow
              var color = uiSettings.colors.yellow
            }else if(params.row.type === 'QCS'){
              var bgColor = uiSettings.colors.bgBlue
              var color = uiSettings.colors.blue
            }
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <span style={{padding: '8px 16px', borderRadius: '8px', backgroundColor: bgColor, color: color}}>{params.row.type}</span> 
            </div>
            
            );
          },
        },
        { field: 'date', headerName: 'date', width: 100 },
        { field: 'a', headerName: 'a', width: 250 },
        { field: 'b', headerName: 'b', width: 250 },
        { field: 'c', headerName: 'c', width: 250 },
        { field: 'd', headerName: 'd', width: 250 },
        { field: 'e', headerName: 'e', width: 250 },
        { field: 'f', headerName: 'f', width: 250 },
        { field: 'g', headerName: 'g', width: 250 },
        { field: 'h', headerName: 'h', width: 250 },
        { field: 'i', headerName: 'i', width: 250 },
        { field: 'j', headerName: 'j', width: 250 },
        { field: 'a1', headerName: 'a1', width: 250 },
        { field: 'a2', headerName: 'a2', width: 250 },
        { field: 'a3', headerName: 'a3', width: 250 },
        { field: 'a4', headerName: 'a4', width: 250 },
        { field: 'a5', headerName: 'a5', width: 250 },
        { field: 'a6', headerName: 'a6', width: 250 },
        { field: 'a7', headerName: 'a7', width: 250 },
        { field: 'a8', headerName: 'a8', width: 250 },
       
      
    ];

    const isQuizMerged = (list, searchId) => {
      return list.includes(searchId);
    };

    function mergeQuiz(data){
      var list = merginQuizzes
      list.push(data.id)
      setMerginQuizzes(list)
      
      axios.post("/admin/mergeQuiz", { data })
      .then(function (response) {
        if(response.data.response === 'done'){
          const updatedArray = duplicatedQuizzes.filter(item => item.id !== data.id);
          setDuplicatedQuizzes(updatedArray);
          //console.log(response.data.quizzes)
          setAlertMessage('Quizzes were merged successfully.')
          setAlertType('success')
          setOpenAlert(true)
        }else{
          list = list.filter(id => id !== data.id);
          setMerginQuizzes(list)
          setAlertMessage('An unexpected error has occured.')
          setAlertType('error')
          setOpenAlert(true)
        }
      })
      .catch(function (error) {
        list = list.filter(id => id !== data.id);
        setMerginQuizzes(list)
        if(error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          
        //console.log(error)
        setAlertType('error')
        setAlertMessage('An unexpected error has occured')
        setOpenAlert(true)
      }
      })
    }

    const [loadingMergeSelectedDuplicates, setLoadingMergeSelectedDuplicates] = useState(false);
    
    function mergeSelectedDuplicates(){
      if(selectedDuplicates.length> 0) {
        setLoadingMergeSelectedDuplicates(true)
        axios.post("/admin/mergeSelectedQuiz", { selectedDuplicates : selectedDuplicates })
        .then(function (response) {
          setLoadingMergeSelectedDuplicates(false)
          if(response.data.response === 'done'){
            const updatedArray = duplicatedQuizzes.filter(item => !selectedDuplicates.includes(item.id));
            setDuplicatedQuizzes(updatedArray);
            setSelectedDuplicates([])          
            //console.log(response.data.quizzes)
            setAlertMessage('Quizzes were merged successfully.')
            setAlertType('success')
            setOpenAlert(true)
          }else{
            setAlertMessage('An unexpected error has occured.')
            setAlertType('error')
            setOpenAlert(true)
          }
        })
        .catch(function (error) {
          if(error.response.status === 403){
            Navigate('/admin/login', {replace: 'true'})
        }else{
            
          setLoadingMergeSelectedDuplicates(false)
          // console.log(error)
          setAlertType('error')
          setAlertMessage('An unexpected error has occured')
          setOpenAlert(true)
        }
        })
      }else{
        setAlertMessage('You should select at least one quiz.')
        setAlertType('warning')
        setOpenAlert(true)
      }
      
    }

    function mergeSelectedDuplicatesByQuestion(){
      if(selectedDuplicatesQuizByQuestion.length> 0) {
        console.log(selectedDuplicatesQuizByQuestion)
       const list = []
       for (let i = 0; i < selectedDuplicatesQuizByQuestion.length; i++) {
        const quiz = selectedDuplicatesQuizByQuestion[i];
        if(i>0){
          list.push({id: i, source: quiz.source, type: quiz.type, year: quiz.year, date: quiz.date})
        }
       }
       setNote(list)
       console.log('mainquiz: ',mainQuiz.quiz_id)
       console.log('quizzes selected: ',selectedDuplicatesQuizByQuestion)
       setOpenWaitModal3(true)
      }else{
        setAlertMessage('You should select at least one quiz.')
        setAlertType('warning')
        setOpenAlert(true)
      }
      
    }

   

    const [note, setNote] = useState([{id: 1, date: '', year: '', type: '', source: ''}]);
    const [noteCount, setNoteCount] = useState(1);
    function addNote() {
      console.log('adding note..')
      const list = note
      list.push({id: list.length > 0 ? list[list.length - 1].id + 1 : 1, date: '', year: '', type: '', source: ''})
      setNote(list)
      setNoteCount(list.length)
      console.log('changed')
    }

    function deleteNote(id) {
      console.log('adding note..')
      const list = note.filter( item => item.id !== id )
      setNote(list)
      setNoteCount(list.length)
      console.log('changed')
    }

    useEffect(() => {
      console.log('hi')
    }, [note]);

    const [mainQuiz, setMainQuiz] = useState('');
    const [selectedDuplicatesQuizByQuestion, setSelectedDuplicatesQuizByQuestion] = useState([]);
    const [selectedDuplicatesQuizIdsByQuestion, setSelectedDuplicatesQuizIdsByQuestion] = useState([]);
    
    

    const handleSelectedDuplicatesChangeByQuestion = (selection) => {
      //console.log(selection)
      const list = []
      for (let i = 0; i < selection.length; i++) {
        const myId = selection[i];
        //console.log(myId)
        const data = duplicatedQuizzesByQuestion.find(item => item.id === myId)
        list.push(data)
      }
      //console.log(list)
      setSelectedDuplicatesQuizByQuestion(list)
      setSelectedDuplicatesQuizIdsByQuestion(selection)
      setMainQuiz(list[0])
    }

    function handleChangeNote (item, place, data, index) {
      //console.log(place, data)
      if(place === "type"){
        item.type = data
      }else if(place === "source"){
        item.source = data.source
      }else if(place === "year"){
        item.year = data.year
      }else if(place === "date"){
        item.date = data.date
      } 
      //console.log(item)
      const list = note.filter(row => row.id !== item.id)
      // list.push(item)
      list.splice(index, 0, item);
      setNote(list)
    }

    const handleChangeMainQuiz = (event, newValue) => {
      setMainQuiz(newValue)
      console.log(newValue)
      const list = []
      for (let i = 0; i < selectedDuplicatesQuizByQuestion.length; i++) {
        const quiz = selectedDuplicatesQuizByQuestion[i];
        if(quiz.quiz_id !== newValue.quiz_id){
          list.push({id: i, source: quiz.source, type: quiz.type, year: quiz.year, date: quiz.date})
        }
       }
       setNote(list)
    }

    const [mergeAndNoteLoading, setMergeAndNoteLoading] = useState(false);
    function mergeAndNote (){
      setMergeAndNoteLoading(false)
      axios.post("/admin/mergeAndNote", { mainQuiz: mainQuiz, repeted: note, selectedQuizzes: selectedDuplicatesQuizByQuestion })
      .then(function (response) {
        setMergeAndNoteLoading(false)
        if(response.data.response === 'done'){
          const updatedArray = duplicatedQuizzesByQuestion.filter(item => !selectedDuplicatesQuizIdsByQuestion.includes(item.id));
          setDuplicatedQuizzesByQuestion(updatedArray);
          setSelectedDuplicatesQuizByQuestion([])          
          setSelectedDuplicatesQuizIdsByQuestion([])  
          setMainQuiz('')  
          setOpenWaitModal3(false)      
          //console.log(response.data.quizzes)
          setAlertMessage('Quizzes were merged successfully.')
          setAlertType('success')
          setOpenAlert(true)
        }else{
          setAlertMessage('An unexpected error has occured.')
          setAlertType('error')
          setOpenAlert(true)
        }
      })
      .catch(function (error) {
        if(error.response.status === 403){
          Navigate('/admin/login', {replace: 'true'})
      }else{
          
        setMergeAndNoteLoading(false)
        //console.log(error)
        setAlertType('error')
        setAlertMessage('An unexpected error has occured')
        setOpenAlert(true)
      }
      })
    }


    const [loadingDelete, setLoadingDelete] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState('');
    const [changes, setChanges] = useState(0);
    function deleteQuiz (){
        setLoadingDelete(true)
        setOpenWaitModal2(false)
        axios.post("/admin/deleteQuiz", {id: selectedQuiz})
        .then(function (response) {
            setLoadingDelete(false)
            if(response.data.response === 'done'){
              // remove it from the list
              var data = quizzes
              data = data.filter(item=> item.id !== selectedQuiz)
              setQuizzes(data)
              setChanges(changes + 1)
              setLoadingDelete(false)
              setAlertType('error')
              setAlertMessage('Quiz deleted.')
              setOpenAlert(true)
              setOpenWaitModal4(false)
            }
        })
        .catch(function (error) {
            // handle error
            if(error.response.status === 403){
                Navigate('/admin/login', {replace: 'true'})
            }else{
                setLoadingDelete(false)
                setAlertType('error')
                setAlertMessage('An unexpected error has occured')
                setOpenAlert(true)
            }
        })
    }


    function deleteQuizzes (){
        if(selectedRows.length > 0){
          setLoadingDelete(true)
          setOpenWaitModal2(false)
          axios.post("/admin/deleteQuizzes", {quizzes: selectedRows})
          .then(function (response) {
              setLoadingDelete(false)
              if(response.data.response === 'done'){
                // remove it from the list
                var data = quizzes
                for (let i = 0; i < response.data.deletedQuizzes.length; i++) {
                  const quiz = response.data.deletedQuizzes[i];
                  data = data.filter(item=> item.id !== quiz)
                }
                setQuizzes(data)
                setChanges(changes + 1)
                setLoadingDelete(false)
                setAlertType('error')
                setAlertMessage('Quizzes deleted.')
                setOpenAlert(true)
                setOpenWaitModal5(false)
                setSelectedRows([])
              }
          })
          .catch(function (error) {
              // handle error
              if(error.response.status === 403){
                  Navigate('/admin/login', {replace: 'true'})
              }else{
                  setLoadingDelete(false)
                  setAlertType('error')
                  setAlertMessage('An unexpected error has occured')
                  setOpenAlert(true)
              }
          })
        
        }else{
          setAlertType('error')
          setAlertMessage('You should select at least one quiz.')
          setOpenAlert(true)
          setOpenWaitModal5(false)
        }
    }
   
  
   

  return (
    <Home>
      <p style={{ ...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, marginBottom: '15px'}}}>Questions</p>
      <Header>

        {/* <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}>Total: {quizCount} questions</p> */}
        <NavbarSearch style={{width: '385px'}}>
          <TextField
              id=""
              placeholder={'Search'}
              value={quizSearch}
              onChange={(e) => setQuizSearch(e.target.value)}
              variant='outlined'
              type={'text'}
              style={{width: "100%", backgroundColor: uiSettings.colors.white, borderRadius: '8px 0px 0px 8px', }}
              InputProps={{
                      style: {...uiSettings.typos.link_mid, ...{color: uiSettings.colors.black}, ...{}}
              }}
          />
          <Search style={{padding: '15px 20px', fontSize: '26px', backgroundColor: uiSettings.colors.yellow, borderRadius: '0px 8px 8px 0px', marginLeft: '-5px', zIndex: '10'}}/>
        </NavbarSearch>
        <Buttons>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} style={{minWidth: '350px', padding: '10px'}}>
            {/* <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, margin: '10px 20px 0px 20px', minWidth: '450px' }}}>Filter quizzes</p> */}
            {/* <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, margin: '0px 20px 10px 20px '}}}>Filter quizzes. </p> */}
            <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, margin: '10px 20px 0px 20px', minWidth: '100vh' }}}></p>
           
            <SubContainer className="animation1"  isPhone={false} style={{ display: true ? 'flex' : 'none', margin: '0px 20px', marginTop: '20px', flexDirection: 'column' }}>
              <Form style={{width: false ? ' calc(100% - 40px)' : '100%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Module</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS', 'CasClinique']}
                      value={selectedTypesOfquestions}
                      onChange={handleAutocompleteChange}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.name} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>

              <Form style={{width: false ? ' calc(100% - 40px)' : '100%', margin: '0px 0px', marginTop: '20px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Cour</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS', 'CasClinique']}
                      value={selectedTypesOfquestions}
                      onChange={handleAutocompleteChange}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>
            </SubContainer>
           
            <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 20px', marginTop: '20px',}}>
              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Type</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS', 'CasClinique']}
                      value={selectedTypesOfquestions}
                      onChange={handleAutocompleteChange}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>

              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Source</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS', 'CasClinique']}
                      value={selectedTypesOfquestions}
                      onChange={handleAutocompleteChange}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>
            </SubContainer>


              {/* ////// start ************** */}
             
             
              <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '20px 20px 20px 20px', marginTop: '20px', flexDirection: 'column'}}>
                <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%', flexDirection: 'column'}}>
                  <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%'}}>
                    <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                        <FormTitle>
                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>From</p>
                        </FormTitle>

                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={['QCM', 'QCS', 'CasClinique']}
                            value={selectedTypesOfquestions}
                            onChange={handleAutocompleteChange}
                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"Sélectionner"}
                                />
                            )}
                        />

                    </Form>

                    <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                        <FormTitle>
                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>To</p>
                        </FormTitle>

                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={['QCM', 'QCS', 'CasClinique']}
                            value={selectedTypesOfquestions}
                            onChange={handleAutocompleteChange}
                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"Sélectionner"}
                                />
                            )}
                        />

                    </Form>
                  </SubContainer>
                  <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%'}}>
                    <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                  </SubContainer>                   
                </SubContainer>
                   
                <SubContainer className="animation1"  isPhone={false} style={{ margin: '0px 0px 10px 0px', display: true ? 'flex' : 'none', transition: '1s', justifyContent: 'end', alignSelf: 'end', alignItems: 'end'}}>
                  <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: uiSettings.colors.white, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px', marginRight: '10px'}}}>Cancel</Button>
                  <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Filter</Button>
                </SubContainer>          
            </SubContainer>



                {/* end *************** */}
            <SubContainer className="animation1"  isPhone={false} style={{ margin: '20px', display: false ? 'flex' : 'none', transition: '1s',}}>
              <Form style={{width: true ? '60%' : '47%', marginTop:'0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Année</p>
                  </FormTitle>

                  <Box style={{marginTop: "30px"}}>
                      <Slider
                          disabled={isAllDates}
                          min={years[years.length - 1] ? parseInt(years[0]) :2007 }
                          step={1}
                          max={parseInt(years[years.length - 1] ? years[years.length - 1] : 2002) }
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                      />
                      <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                  </Box>

              </Form>
        
        
        
            </SubContainer>
                        
            <SubContainer className="animation1"  isPhone={false} style={{ margin: '0px 20px 10px 20px', display: false ? 'flex' : 'none', transition: '1s', justifyContent: 'end'}}>
              <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: uiSettings.colors.white, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px', marginRight: '10px'}}}>Cancel</Button>
              <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Filter</Button>
            </SubContainer>           
                        
          </Menu>
          {/* <Button onClick={handleOpenMenu} sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{marginRight: '10px'}}} endIcon={<KeyboardArrowDown />} >All</Button> */}
          <Button onClick={()=> handleClickOpenFilterModal()}  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ border: false ? uiSettings.colors.headingColor + ' 1px solid' : 'white 0px solid', zIndex: 10, marginRight: SavedfilterModules.length === 0 && SavedfilterCours.length === 0 && SavedfilterType.length === 0 && SavedfilterExplication === 'All' && SavedfilterSources.length === 0 && SavedisAllDates === true ? '10px' : '0px' }}} startIcon={<FilterList />} endIcon={<KeyboardArrowDown />} type='text' >Filter</Button>
          <IconButton onClick={()=> resetFilter()}  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ display: SavedfilterModules.length === 0 && SavedfilterCours.length === 0 && SavedfilterType.length === 0 && SavedfilterExplication === 'All' && SavedfilterSources.length === 0 && SavedisAllDates === true ? 'none' : 'flex'   , marginLeft: '-20px', marginRight: '10px',border: false ? uiSettings.colors.headingColor + ' 1px solid' : 'white 0px solid',zIndex: 9,  color: uiSettings.colors.white, backgroundColor: uiSettings.colors.red, padding: '10px 5px 10px 25px',}}}  type='text' ><HighlightOffOutlined style={{}}/></IconButton>
          <Button  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButtonYellow, ...{marginRight: '10px',}}} onClick={()=> Navigate('/admin/Questions/NewQuestion')} startIcon={<Add />} >Add a question</Button>
          <input
            type="file"
            id="file-input"
            style={{ display: 'none' }}
            accept=".csv"
            onChange={handleFileChange}
          />
          <label htmlFor={loadingUplaod ? '' : "file-input"}>
            <LoadingButton disabled={loadingUplaod} component='span'  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.orange, color: 'white'}}} endIcon={<VerticalAlignBottom />}  loading={loadingUplaod} loadingPosition={'end'} >Import</LoadingButton>
          </label>
          <LoadingButton  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.blue, color: 'white', marginLeft: '10px',}}} endIcon={<VerticalAlignTop  />} onClick={() => exportQuiz()} loading={loadingExport} loadingPosition={'end'} >Export</LoadingButton>
        </Buttons>
      </Header>
      <div style={{ height: 'calc( 100vh - 290px)', width: '100%' }}>
        <DataGrid
          style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
          rows={quizzes}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection={true}
          disableRowSelectionOnClick={false}
          loading={quizzesLoading}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={handleSelectionChange}
        />
      </div>
      <Pagination
        count={quizGroupCount}
        variant="outlined"
        shape="rounded"
        style={{ alignSelf: 'center', margin: '10px', marginTop: '20px' }}
        page={currentPage}
        onChange={(event, page) => handlePageChange(page)}
      />

      {/* Addons */}
      <Dialog
        open={openUploadModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseUploadModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, margin: '10px 20px' }}}>{"Importing new quizzes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"  style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>
          Get ready to import new data to the server with a click of a button.
          </DialogContentText>
          <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.blue, alignItems: 'center', display: 'flex', marginTop: '40px', marginBottom: '10px'}}} > <Attachment style={{marginRight: '10px'}}/> {selectedFile ? selectedFile.name : 'No file is selected'}</p>
          <p style={{...uiSettings.typos.paragraphMobile, ...{color: uiSettings.colors.secondary, alignItems: 'center', display: 'flex', marginTop: '0px', borderTop: '1px solid ' + uiSettings.colors.secondary}}} > {selectedFile ? 'Selected file' : ''}</p>
        </DialogContent>
        <DialogActions style={{margin: '10px 10px'}}>
          <Button sx={{ textTransform: 'none' }} onClick={handleCloseUploadModal} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.white, color: uiSettings.colors.orange}}} >Cancel</Button>
          <Button sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.orange, color: 'white'}}} onClick={()=> handleUpload()}  >Import</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openWaitModal4} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Delete quiz</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Deleting quiz parmanently ?</p>
            </DialogContent>
            <DialogActions>
                <Button   sx={{ textTransform: 'none' }} onClick={()=> {setOpenWaitModal4(); console.log(selectedQuiz)}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
                <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }} onClick={()=> {deleteQuiz()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Delete</LoadingButton>
            </DialogActions>
        </Dialog>


        <Dialog open={openWaitModal5} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Delete quizzes</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start',}}>
                <p style={{ ...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary}}}>Deleting all the selected quizzes parmanently ?</p>
            </DialogContent>
            <DialogActions>
                <Button   sx={{ textTransform: 'none' }} onClick={()=> {setOpenWaitModal5(); console.log(selectedQuiz)}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.red, padding: '7px 16px', marginRight: '10px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
                <LoadingButton loading={loadingDelete} loadingPosition='center'  sx={{ textTransform: 'none' }} onClick={()=> {deleteQuizzes()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.red, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Delete</LoadingButton>
            </DialogActions>
        </Dialog>


      <Dialog
        open={openFilterModal}
        TransitionComponent={TransitionDown}
        keepMounted
        onClose={handleCloseFilterModal}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{padding: '0px'}}
      >
        
        <DialogContent style={{ padding: '0px'}}>
          {/* <p style={{...uiSettings.typos.heading3, ...{color: uiSettings.colors.headingColor, margin: '10px 20px 0px 20px', minWidth: '450px' }}}>Filter quizzes</p> */}
          {/* <p style={{...uiSettings.typos.paragraphDesktop, ...{color: uiSettings.colors.secondary, margin: '0px 20px 10px 20px ', textAlign: 'end'}}}></p> */}
          <Alert severity="warning"  style={{margin: '0px 20px', marginTop: '20px'}}> <span style={{display: 'flex', flexDirection: 'row',}}>Some quizzes may be duplicated, <CustomRowMenu3 /> </span>  </Alert>
          <SubContainer className="animation1"  isPhone={false} style={{ display: true ? 'flex' : 'none', margin: '0px 20px', marginTop: '20px', flexDirection: 'row' }}>
              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Module</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={modules}
                      value={filterModules}
                      onChange={handleAutocompleteChangeModules}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.name} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>

              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px', marginTop: '0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Cour</p>
                  </FormTitle>

                  {/* <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS', 'CasClinique']}
                      value={selectedTypesOfquestions}
                      onChange={handleAutocompleteChange}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  /> */}

                  <Autocomplete
                    id="grouped-demo"
                    multiple
                    value={filterCours}
                    onChange={handleAutocompleteChangeCours}
                    style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.cour}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder={"Sélectionner"}  />}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.headingColor, paddingLeft: '10px'}}} >{params.group}</GroupHeader>
                        <GroupItems style={{...uiSettings.typos.link_mid, ...{color: uiSettings.colors.secondary}}} >{params.children}</GroupItems>
                      </li>
                    )}
                  />

              </Form>
            </SubContainer>

            <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '20px 20px 20px 20px', marginTop: '20px',}}>
              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Explication</p>
                  </FormTitle>

                  <Autocomplete
                      id="tags-standard"
                      options={['All', 'With explication',  'Without explication']}
                      value={filterExplication}
                      onChange={handleAutocompleteChangeExplication}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>
            </SubContainer>
           
            <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 20px', marginTop: '20px',}}>
              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Type</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={['QCM', 'QCS',  'Cas clinique', 'Dossier clinique']}
                      value={filterType}
                      onChange={handleAutocompleteChangeType}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>

              <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Source</p>
                  </FormTitle>

                  <Autocomplete
                      multiple
                      id="tags-standard"
                      options={sources}
                      value={filterSources}
                      onChange={handleAutocompleteChangeSources}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.source} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>
            </SubContainer>


              {/* ////// start ************** */}
             
             
              <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '20px 20px 20px 20px', marginTop: '20px', flexDirection: 'column'}}>
                <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%', flexDirection: 'column'}}>
                  <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%'}}>
                    <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                        <FormTitle>
                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>From</p>
                        </FormTitle>

                        <Autocomplete
                            id="tags-standard"
                            options={years}
                            value={filterStartYear}
                            onChange={handleAutocompleteChangeStartYear}
                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option.year} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"Sélectionner"}
                                />
                            )}
                        />

                    </Form>

                    <Form style={{width: false ? ' calc(100% - 40px)' : '49%', margin: '0px 0px'}}>
                        <FormTitle>
                            <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>To</p>
                        </FormTitle>

                        <Autocomplete
                            id="tags-standard"
                            options={years}
                            value={filterEndYear}
                            onChange={handleAutocompleteChangeEndYear}
                            style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option.year} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"Sélectionner"}
                                />
                            )}
                        />

                    </Form>
                  </SubContainer>
                  <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 0px', marginTop: '0px', width: '100%'}}>
                    <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                  </SubContainer>                   
                </SubContainer>
                   
                <SubContainer className="animation1"  isPhone={false} style={{ margin: '0px 0px 10px 0px', display: true ? 'flex' : 'none', transition: '1s', justifyContent: 'end', alignSelf: 'end', alignItems: 'end'}}>
                  <Button   sx={{ textTransform: 'none' }} onClick={()=> setOpenFilterModal(false)}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: uiSettings.colors.white, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px', marginRight: '10px'}}}>Cancel</Button>
                  <Button   sx={{ textTransform: 'none' }} onClick={()=> {fliterQuizzes(100, 0, true)}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Filter</Button>
                </SubContainer>          
            </SubContainer>



                {/* end *************** */}
            <SubContainer className="animation1"  isPhone={false} style={{ margin: '20px', display: false ? 'flex' : 'none', transition: '1s',}}>
              <Form style={{width: true ? '60%' : '47%', marginTop:'0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Année</p>
                  </FormTitle>

                  <Box style={{marginTop: "30px"}}>
                      <Slider
                          disabled={isAllDates}
                          min={years[years.length - 1] ? parseInt(years[0]) :2007 }
                          step={1}
                          max={parseInt(years[years.length - 1] ? years[years.length - 1] : 2002) }
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                      />
                      <FormControlLabel control={<Checkbox checked={isAllDates}  onChange={(e)=> {console.log(e.target.checked); setIsAllDates(e.target.checked)}} />} label={<p  style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Toutes les années</p>} />
                  </Box>

              </Form>
        
        
        
            </SubContainer>
                        
            <SubContainer className="animation1"  isPhone={false} style={{ margin: '0px 20px 10px 20px', display: false ? 'flex' : 'none', transition: '1s', justifyContent: 'end'}}>
              <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.yellow, padding: '7px 16px', backgroundColor: uiSettings.colors.white, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px', marginRight: '10px'}}}>Cancel</Button>
              <Button   sx={{ textTransform: 'none' }}  style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, display:'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Filter</Button>
            </SubContainer> 


        </DialogContent>
      </Dialog>


    <Dialog open={openWaitModal} fullWidth={true} maxWidth={loadingFetchDuplicates? 'sm' : "lg"} >
      <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Finding duplicated quizzes</DialogTitle>
     
      <DialogContent style={{display: loadingFetchDuplicates ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'start'}}>
        <img src="/images/wait4.gif" alt="" srcset="" style={{height: '100px', width: '100px', marginTop: '20px'}} />
        <DialogContentText style={{...uiSettings.typos.paragraphDesktop, ...{margin: "10px 20px", textAlign: 'center', color: uiSettings.colors.secondary}}}>
          Please wait, the process is ongoing. This may take a couple of seconds.
        </DialogContentText>
      </DialogContent>

      <DialogContent style={{display: loadingFetchDuplicates? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start',}}>
        <Header>
          <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}>Total: {duplicatedQuizzes.length} quiz</p>
          <LoadingButton  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white, marginLeft: '10px',}}} endIcon={<SearchOutlined  />}  loading={serchingDuplicatesOnProcess} loadingPosition={'end'} onClick={()=> findDuplicatedQuiz()} >Find quizzes</LoadingButton>
        <Buttons>
          <LoadingButton  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginLeft: '10px',}}} endIcon={<ContentCopyOutlined  />}  loading={loadingMergeSelectedDuplicates} loadingPosition={'end'} onClick={()=> mergeSelectedDuplicates()} >Merge duplicates</LoadingButton>
        </Buttons>
      </Header>
        <div style={{ height: 'calc( 100vh - 290px)', width: '100%' }}>
          <DataGrid
            style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
            rows={duplicatedQuizzes}
            columns={duplicatedColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            loading={quizzesLoading}
            rowSelectionModel={selectedDuplicates}
            onRowSelectionModelChange={handleSelectedDuplicatesChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, display:loadingFetchDuplicates ? 'block' :  'none', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
        <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, display:loadingFetchDuplicates? 'none' : 'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Close</Button>
      </DialogActions>
    </Dialog>


    <Dialog open={openWaitModal2} fullWidth={true} maxWidth={loadingFetchDuplicates? 'sm' : "lg"} >
      <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Finding duplicated quizzes by question</DialogTitle>
     
      <DialogContent style={{display: loadingFetchDuplicates ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'start'}}>
        <img src="/images/wait4.gif" alt="" srcset="" style={{height: '100px', width: '100px', marginTop: '20px'}} />
        <DialogContentText style={{...uiSettings.typos.paragraphDesktop, ...{margin: "10px 20px", textAlign: 'center', color: uiSettings.colors.secondary}}}>
          Please wait, the process is ongoing. This may take a couple of seconds.
        </DialogContentText>
      </DialogContent>

      <DialogContent style={{display: loadingFetchDuplicates? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start',}}>
        <Header>
          <p style={{ ...uiSettings.typos.heading4, ...{color: uiSettings.colors.secondary}}}>Total: {duplicatedQuizzesByQuestion.length} quiz</p>
          <LoadingButton  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.blue, color: uiSettings.colors.white, marginLeft: '10px',}}} endIcon={<SearchOutlined  />}  loading={serchingDuplicatesByQuestionOnProcess} loadingPosition={'end'} onClick={()=> findDuplicatedQuizByQuestion()} >Find quizzes</LoadingButton>
        <Buttons>
          <LoadingButton  sx={{ textTransform: 'none' }} style={{ ...uiSettings.typos.adminButton, ...{ backgroundColor: uiSettings.colors.yellow, color: uiSettings.colors.headingColor, marginLeft: '10px',}}} endIcon={<ContentCopyOutlined  />}  loading={loadingMergeSelectedDuplicates} loadingPosition={'end'} onClick={()=> mergeSelectedDuplicatesByQuestion()} >Merge & add note</LoadingButton>
        </Buttons>
      </Header>
        <div style={{ height: 'calc( 100vh - 290px)', width: '100%' }}>
          <DataGrid
            style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.secondary, backgroundColor: uiSettings.colors.white}}}
            rows={duplicatedQuizzesByQuestion}
            columns={duplicatedByQuestionColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            loading={quizzesLoading}
            rowSelectionModel={selectedDuplicatesQuizIdsByQuestion}
            onRowSelectionModelChange={handleSelectedDuplicatesChangeByQuestion}
            sortModel={[
              {
                field: 'question',
                sort: 'asc', // or 'desc'
              },
            ]}
            sortComparator={alphabeticalComparator}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, display:loadingFetchDuplicates ? 'block' :  'none', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
        <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal2()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.white, padding: '7px 16px', backgroundColor: uiSettings.colors.blue, display:loadingFetchDuplicates? 'none' : 'block', alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Close</Button>
      </DialogActions>
    </Dialog>




    <Dialog open={openWaitModal3} fullWidth={true} maxWidth={'md' } >
      <DialogTitle style={{...uiSettings.typos.heading3, ...{margin: "10px 20px"}}}>Merge & add note</DialogTitle>
     
      <DialogContent style={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}>
      <SubContainer className="animation1"  isPhone={false} style={{  display: true ? 'flex' : 'none', margin: '0px 20px', marginTop: '20px', width:'calc( 100% - 40px )', flexDirection: 'column'}}>
              <Form style={{ margin: '0px 0px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Main quiz</p>
                  </FormTitle>

                  <Autocomplete
                      //multiple
                      id="tags-standard"
                      options={selectedDuplicatesQuizByQuestion}
                      value={mainQuiz}
                      onChange={handleChangeMainQuiz}
                      style={{...uiSettings.typos.link_mid, ...{width: "100%", backgroundColor: uiSettings.colors.bgblue }}}
                      InputProps={{
                          style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                      }}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.quiz_id} // Specify the label property
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"Sélectionner"}
                          />
                      )}
                  />

              </Form>

              <Form style={{width: true ? ' calc(100%)' : '49%', margin: '0px 0px', marginTop: '20px'}}>
                  <FormTitle>
                      <p style={{...uiSettings.typos.navText, ...{color: uiSettings.colors.headingColor}}}>Repeted in</p>
                  </FormTitle>
                  
                  <Button   sx={{ textTransform: 'none' }} endIcon={<AddOutlined/>} onClick={()=> {addNote()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, alignSelf: 'start', width: 'max-content', marginTop: '0px', marginBottom: '0px', display: note.length > 0 ? 'none' : 'flex'}}}>add</Button>


                  {
                    note.map((item, index) => {
                      return(
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: '10px'}}>
                          

                            <Autocomplete
                              id="tags-standard"
                              options={['QCM', 'QCS', 'CasClinique']}
                              value={item.type}
                              onChange={(event, newValue) => handleChangeNote(item, 'type', newValue, index)}
                              style={{...uiSettings.typos.link_mid, ...{width: "20%", marginRight: '10px', backgroundColor: uiSettings.colors.bgblue }}}
                              InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                              }}
                              PaperComponent={CustomPaper}
                              getOptionLabel={(option) => option} // Specify the label property
                              renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Type"}
                                  />
                              )}
                            />

                        <Autocomplete
                            id="tags-standard"
                            options={dates}
                            value={item}
                            onChange={(event, newValue) => handleChangeNote(item, 'date', newValue, index)}
                            style={{...uiSettings.typos.link_mid, ...{width: "20%", marginRight: '10px', backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option.date} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"Date"}
                                />
                            )}
                        />
                        <Autocomplete
                            id="tags-standard"
                            options={sources}
                            value={item}
                            onChange={(event, newValue) => handleChangeNote(item, 'source', newValue, index)}
                            style={{...uiSettings.typos.link_mid, ...{width: "20%", marginRight: '10px', backgroundColor: uiSettings.colors.bgblue }}}
                            InputProps={{
                                style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                            }}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) => option.source} // Specify the label property
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder={"source"}
                                />
                            )}
                        />

                          <Autocomplete
                              id="tags-standard"
                              options={years}
                              value={item}
                              onChange={(event, newValue) => handleChangeNote(item, 'year', newValue, index)}
                              style={{...uiSettings.typos.link_mid, ...{width: "20%", marginRight: '10px', backgroundColor: uiSettings.colors.bgblue }}}
                              InputProps={{
                                  style: {...uiSettings.typos.link_mid, ...{borderRadius: '7px'}}
                              }}
                              PaperComponent={CustomPaper}
                              getOptionLabel={(option) => option.year} // Specify the label property
                              renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"year"}
                                  />
                              )}
                          />

                        <IconButton style={{padding: "16px", backgroundColor: uiSettings.colors.bgBlue, color: uiSettings.colors.blue, borderRadius: '3px', marginRight: "10px", display: note[note.length - 1].id === item.id? 'flex' : 'none'}} onClick={() => addNote()}><AddOutlined/></IconButton>
                        <IconButton style={{padding: "16px", backgroundColor: uiSettings.colors.lightOrange, color: uiSettings.colors.orange, borderRadius: '3px'}} onClick={() => deleteNote(item.id)}><ClearOutlined/></IconButton>
                        </div>
                      )
                    })
                  }
                  
                  

              </Form>
            </SubContainer>
      </DialogContent>

      <DialogActions>
        <Button   sx={{ textTransform: 'none' }} onClick={()=> {onCloseWaitModal3()}} style={{...uiSettings.typos.link_font, ...{color: uiSettings.colors.yellow, marginRight: '10px', padding: '7px 16px', backgroundColor: uiSettings.colors.white, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Cancel</Button>
        <LoadingButton loadingPosition='center' loading={mergeAndNoteLoading}   sx={{ textTransform: 'none' }} onClick={()=> {mergeAndNote()}} style={{...uiSettings.typos.link_font, ...{color: mergeAndNoteLoading ? uiSettings.colors.yellow : uiSettings.colors.headingColor, padding: '7px 16px', backgroundColor: uiSettings.colors.yellow, alignSelf: 'end', width: 'max-content', marginTop: '0px', marginBottom: '0px'}}}>Merge</LoadingButton>
      </DialogActions>
    </Dialog>

    </Home>
  )
}




const rowss = [
  { id: 1, name: 'John Doe', age: 25 },
  { id: 2, name: 'Jane Doe', age: 30 },
  { id: 3, name: 'Bob Smith', age: 22 },
  // Add more rows as needed
];

const columnss = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'age', headerName: 'Age', width: 70 },
  // Add more columns as needed
];

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  {
    title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  {
    title: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  {
    title: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];
